function Background({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 28 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.7A2.7 2.7 0 0 1 7.7 3h12.6A2.7 2.7 0 0 1 23 5.7v12.6a2.7 2.7 0 0 1-2.7 2.7H7.7A2.7 2.7 0 0 1 5 18.3V5.7Zm14.927-.9h-2.546L6.8 15.381v2.546L19.927 4.8Zm-7.642 0h2.55L6.8 12.835v-2.55L12.285 4.8Zm-2.546 0H7.7a.9.9 0 0 0-.9.9v2.04L9.74 4.8ZM8.073 19.2h2.558L21.2 8.63V6.074L8.073 19.2Zm7.664 0h-2.56l8.023-8.023v2.56L15.737 19.2Zm2.546 0H20.3a.9.9 0 0 0 .9-.9v-2.017L18.283 19.2Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default Background
