import i18n from 'i18next'
// Import your translation files
import enTranslation from './locales/en/en.json'
import arTranslation from './locales/ar/ar.json'
import frTranslation from './locales/fr/fr.json'
import deTranslation from './locales/de/de.json'
import heTranslation from './locales/he/he.json'
import itTranslation from './locales/it/it.json'
import jaTranslation from './locales/ja/ja.json'
import koTranslation from './locales/ko/ko.json'
import ptTranslation from './locales/pt/pt.json'
import ruTranslation from './locales/ru/ru.json'
import zhHansTranslation from './locales/zh-Hans/zh-Hans.json'
import esTranslation from './locales/es/es.json'
import zhHantTranslation from './locales/zh-Hant/zh-Hant.json'
import trTranslation from './locales/tr/tr.json'


i18n.init({
  // debug: true,
  lng: 'en',
  fallbackLng: ["es", "ar", "fr", "de", "he", "it", "ja", "ko", "pt", "ru", "zh-Hans", "es", "zh-Hantt", "tr"],
  resources: {
    en: {
      translation: enTranslation,
    },
    ar: {
      translation: arTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    de: {
      translation: deTranslation,
    },
    he: {
      translation: heTranslation,
    },
    it: {
      translation: itTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
    ko: {
      translation: koTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
    "zh-Hans": {
      translation: zhHansTranslation,
    },
    es: {
      translation: esTranslation,
    },
    "zh-Hant": {
      translation: zhHantTranslation,
    },
    "tr": {
      translation: trTranslation,
    },
  },
  nsSeparator: false
})


export default i18n
