import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={5} stroke="#666" strokeWidth={2} />
    <rect x={11} width={2} height={4} rx={1} fill="#666" />
    <rect x={11} y={20} width={2} height={4} rx={1} fill="#666" />
    <rect y={13} width={2} height={4} rx={1} transform="rotate(-90 0 13)" fill="#666" />
    <rect x={20} y={13} width={2} height={4} rx={1} transform="rotate(-90 20 13)" fill="#666" />
    <rect
      x={4.222}
      y={21.192}
      width={2}
      height={4}
      rx={1}
      transform="rotate(-135 4.222 21.192)"
      fill="#666"
    />
    <rect x={18.364} y={7.05} width={2} height={4} rx={1} transform="rotate(-135 18.364 7.05)" fill="#666" />
    <rect x={2.808} y={4.222} width={2} height={4} rx={1} transform="rotate(-45 2.808 4.222)" fill="#666" />
    <rect x={16.95} y={18.364} width={2} height={4} rx={1} transform="rotate(-45 16.95 18.364)" fill="#666" />
  </svg>
)

export default SvgComponent
