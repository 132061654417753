import * as React from 'react'

const TextAlignLeft = props => (
  <svg width={24} height={24} fill={props.color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM1 14.665a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM1 19.998a1 1 0 0 1 1-1h12.134a1 1 0 0 1 0 2H2a1 1 0 0 1-1-1ZM1 9.333a1 1 0 0 1 1-1h12.134a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Z"
    />
  </svg>
)

export default TextAlignLeft
