import { ExportTemplate } from '@/scenes/engine/common/interfaces'
import { ObjectType, ObjectTypeFromAPI, propertyMapping } from '../common/constants'
import exportObject from '../utils/fabricToObject'
import objectToFabric from '../utils/objectToFabric'
import BaseHandler from './BaseHandler'

class TemplateHandler extends BaseHandler {
  exportTemplate(canvas?) {
    const canvasJSON: any = canvas ? canvas.toJSON(this.root.propertiesToInclude) : this.canvas.toJSON(this.root.propertiesToInclude)
    const frameOptions = this.root.frameHandler.getOptions()

    const template = {
      name: 'Untitled design',
      objects: [],
      background: {
        type: 'color',
        value: frameOptions.fill ? frameOptions.fill : '#fff',
      },
      frame: {
        width: frameOptions.width,
        height: frameOptions.height,
        top: frameOptions.top,
        left: frameOptions.left,
      },
    }
    
    const objects = canvasJSON.objects.filter(
      object => (object.type !== ObjectType.FRAME || (object.type === ObjectType.FRAME && typeof object.fill === 'object')) && !object.id?.includes('replace')
    )

    objects.forEach(object => {
      if (object.type === ObjectType.FRAME) {
        template.objects = template.objects.concat(object)
      } else {
        const exportedObject = exportObject.run(object, frameOptions)
        if(exportedObject){
          if(exportedObject._filterScalingX){
            exportedObject.scaleX = exportedObject.scaleX * exportedObject._filterScalingX
            exportedObject.scaleY = exportedObject.scaleY * exportedObject._filterScalingY
          }
          template.objects = template.objects.concat(exportedObject)
        } else {
          console.log('UNABLE TO EXPORT OBJECT: ', object)
        }
      }
    })

    // return template
    return template
  }

  exportToJSON(): ExportTemplate[] {
    const propertiesToExport = this.root.propertiesToExport
    const canvasJSON: any = this.canvas.toJSON(propertiesToExport)
    const frameOptions = this.root.frameHandler.getOptions()


    const filledObjects: ExportTemplate[] = canvasJSON.objects
      .filter(obj => Object.values(ObjectType).includes(obj.type) && obj.type !== ObjectType.FRAME)
      .map((obj, index) => {
        obj.center = obj.centerPoint
        delete obj.centerPoint
        switch (obj.type) {
          case ObjectType.BAZAART_BG:
            return {
              [ObjectTypeFromAPI.BACKGROUND_LAYER]: exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_IMAGE:
            return {
              [ObjectTypeFromAPI.IMAGE_LAYER]: exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_TEXT:
            this.setTextLayersServerProperties(obj)
            return {
              [ObjectTypeFromAPI.TEXT_LAYER]: exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_STICKER:
            return {
              [ObjectTypeFromAPI.STICKER_LAYER]: exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_SHAP:
            return { [ObjectTypeFromAPI.SHAPE_LAYER]: exportObject.run(obj,frameOptions,true) }
          case ObjectType.STATIC_VECTOR:
            return { [ObjectTypeFromAPI.VECTOR_LAYER]: exportObject.run(obj,frameOptions,true) }
          case ObjectType.STATIC_PATH:
          case ObjectType.DYNAMIC_PATH:
            return { [ObjectTypeFromAPI.PATH_LAYER]: exportObject.run(obj,frameOptions,true) }
          case ObjectType.BAZAART_DRAWING:
            return { [ObjectTypeFromAPI.DRAWING_LAYER]: exportObject.run(obj,frameOptions,true) }
          default:
            return {}
        }
      })
      console.log(filledObjects)
    return filledObjects
  }

  setTextLayersServerProperties(frameOptions: any) {
    const alignFromOption = () => {
      if (frameOptions.textAlign == 'left') {
        return 1
      }
      if (frameOptions.textAlign == 'center') {
        return 2
      }
      if (frameOptions.textAlign == 'right') {
        return 3
      }
    }

    frameOptions.textProperties.attributedText.string = frameOptions.text
    frameOptions.textProperties.attributedText.runs[0].attributes.NSFont.size = frameOptions.fontSize
    frameOptions.textProperties.attributedText.runs[0].attributes.NSFont.systemName = frameOptions.fontFamily

    frameOptions.textProperties.attributedText.runs[0].attributes.NSOriginalFont = {
      size: frameOptions.fontSize,
      systemName: frameOptions.fontFamily
    }

    frameOptions.textProperties.attributedText.runs[0].attributes.NSColor = frameOptions.clipPath.fill
    frameOptions.textProperties.attributedText.runs[0].attributes.NSParagraphStyle.NSWritingDirection = alignFromOption()

    if(frameOptions.backgroundColor){
      frameOptions.textProperties.presentBackground = true
      frameOptions.textProperties.textColorHex = frameOptions.backgroundColor
    }
    else{
      frameOptions.textProperties.presentBackground = false
      frameOptions.textProperties.textColorHex = frameOptions.fill
    }
  }

  async importTemplate(template) {
    this.root.transactionHandler.save()
    this.root.objectsHandler.clear(false)
    const frame = template.frame
    this.root.frameHandler.update(frame)

    const frameOptions = this.root.frameHandler.getOptions()
    for (const object of template.objects) {
      const element = await objectToFabric.run(object, frameOptions)
      if (element) {
        this.canvas.add(element)
      } else {
        console.log('UNABLE TO LOAD OBJECT: ', object)
      }
    }
    this.root.frameHandler.setBackgroundColor(
      template.background && template.background.type === 'color' ? template.background.value : '#ffffff'
    )
    this.root.transactionHandler.clear()
    this.root.zoomHandler.zoomToFit()
  }
}
export default TemplateHandler
