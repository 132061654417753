import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path fill="#C23429" d="M8 .5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 1 0 0-15Z" />
    <path
      fill="#fff"
      d="M8.802 10.313c-.646 0-1.252-.346-1.46-.744 0 0-.345 1.373-.42 1.638-.26.935-1.016 1.875-1.08 1.95-.04.053-.132.035-.143-.034-.018-.121-.208-1.304.017-2.267l.767-3.248s-.19-.381-.19-.947c0-.882.513-1.546 1.148-1.546.542 0 .802.41.802.894 0 .549-.346 1.362-.525 2.118-.15.634.317 1.148.94 1.148 1.13 0 1.893-1.454 1.893-3.173 0-1.31-.883-2.285-2.481-2.285-1.812 0-2.937 1.35-2.937 2.856 0 .52.156.888.392 1.171.11.133.127.185.087.335-.029.11-.092.375-.121.479-.04.15-.162.201-.3.15-.83-.34-1.223-1.252-1.223-2.28 0-1.695 1.43-3.726 4.263-3.726 2.28 0 3.78 1.65 3.78 3.415 0 2.342-1.299 4.09-3.22 4.09l.011.006Z"
    />
  </svg>
)
export default SvgComponent
