import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#photo_a)">
      <path
        stroke={lightTheme.colors.blackGray}
        strokeWidth={2}
        d="M25 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
      />
      <path
        fill={lightTheme.colors.blackGray}
        stroke={lightTheme.colors.blackGray}
        strokeWidth={2}
        d="m25.858 14.335-3.526-1.813c-.75-.386-1.126-.579-1.522-.662a3 3 0 0 0-1.066-.03c-.4.061-.787.233-1.56.577A1504.028 1504.028 0 0 1 6.22 17.69a.933.933 0 0 0-.56.855c0 1.03.835 1.866 1.866 1.866h15.269c.191 0 .287 0 .369-.003a4 4 0 0 0 3.834-3.834c.003-.08.003-.177.003-.368v-.12a2 2 0 0 0-1.035-1.696l-.107-.056Z"
      />
      <path
        fill={lightTheme.colors.blackGray}
        d="M10.999 11.504a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      />
    </g>
    <defs>
      <clipPath id="photo_a">
        <path fill="#fff" d="M4 2h24v20H4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
