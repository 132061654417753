import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    fill="none"
    {...props}
  >
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M11.24 7.307c2.35-2.365 6.167-2.415 8.508-.111 2.342 2.304 2.335 6.102-.015 8.467l-8.973 9.03c-2.35 2.365-6.167 2.415-8.509.112-2.341-2.304-2.334-6.103.016-8.468l8.973-9.03Zm6.972 1.434c1.561 1.536 1.557 4.069-.01 5.645l-.544.548-5.662-5.571.544-.547c1.567-1.577 4.111-1.61 5.672-.075Zm-7.084 12.765 5.364-5.399-5.662-5.57-5.364 5.399 5.662 5.57Zm-7.329-3.892c-1.567 1.577-1.571 4.11-.01 5.645 1.56 1.536 4.105 1.503 5.672-.074l.501-.504-5.661-5.571-.502.505Zm6.535-3.072a.999.999 0 0 0 1.407-.008.988.988 0 0 0-.008-1.4.999.999 0 0 0-1.407.008.987.987 0 0 0 .008 1.4Zm3.648 2.223a.999.999 0 0 1-1.407.008.987.987 0 0 1-.008-1.401.999.999 0 0 1 1.407-.008.988.988 0 0 1 .008 1.4Zm-5.95-1.468a.987.987 0 0 0 .008 1.4.999.999 0 0 0 1.408-.007.987.987 0 0 0-.008-1.401.999.999 0 0 0-1.407.008Zm3.709 3.671a.999.999 0 0 1-1.407.008.987.987 0 0 1-.008-1.4.999.999 0 0 1 1.407-.008.988.988 0 0 1 .008 1.4Z"
      clipRule="evenodd"
    />
    <path
      fill={lightTheme.colors.blackGray}
      d="M23.246 6.269c-.288-2.083-.214-2.083-2.494-2.495a.279.279 0 0 1-.174-.087.266.266 0 0 1-.004-.359c.046-.05.11-.08.178-.084 2.28-.304 2.214-.373 2.494-2.488a.255.255 0 0 1 .08-.182.27.27 0 0 1 .434.09c.013.031.02.065.019.1.263 2.083.3 2.075 2.476 2.48.067.006.13.036.176.086.045.048.07.113.07.179 0 .157-.105.24-.28.265-2.16.342-2.18.397-2.445 2.48a.259.259 0 0 1-.08.17.271.271 0 0 1-.453-.155h.003ZM16.604 4.321c-.191-1.392-.181-1.366-1.65-1.642a.185.185 0 0 1-.117-.058.177.177 0 0 1-.048-.12.17.17 0 0 1 .046-.121.178.178 0 0 1 .12-.056c1.47-.203 1.463-.25 1.65-1.664a.17.17 0 0 1 .056-.114.178.178 0 0 1 .121-.046c.046 0 .09.017.122.049a.17.17 0 0 1 .053.118c.177 1.391.216 1.386 1.669 1.657a.183.183 0 0 1 .119.057c.03.033.047.076.047.12 0 .103-.069.156-.187.176-1.443.23-1.471.242-1.648 1.632a.17.17 0 0 1-.048.124.18.18 0 0 1-.193.044.177.177 0 0 1-.099-.091.17.17 0 0 1-.015-.066l.002.001ZM24.313 12.034c-.191-1.392-.181-1.367-1.65-1.643a.185.185 0 0 1-.117-.057.177.177 0 0 1-.048-.12.17.17 0 0 1 .046-.122.178.178 0 0 1 .12-.056c1.47-.202 1.463-.25 1.65-1.663a.17.17 0 0 1 .055-.115.179.179 0 0 1 .122-.045c.046 0 .09.017.122.048a.17.17 0 0 1 .053.118c.177 1.392.216 1.387 1.669 1.658a.183.183 0 0 1 .119.056c.03.033.047.076.047.121 0 .103-.069.156-.187.176-1.443.23-1.471.242-1.648 1.632a.173.173 0 0 1-.105.163.182.182 0 0 1-.195-.032.173.173 0 0 1-.055-.12h.002Z"
    />
  </svg>
)
export default SvgComponent
