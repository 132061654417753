import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '@services/api'
import { AxiosError } from 'axios'

export const setImageElement = createAction<any[]>('imageElements/setImageElements')

export const getImageElements = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  'imageElements/getImageElements',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const imageElements = await api.getImageElement()
      dispatch(setImageElement(imageElements))
    } catch (err) {
      return rejectWithValue(((err as AxiosError).response?.data as any)?.error.data || null)
    }
  }
)
