import { createAction } from '@reduxjs/toolkit'
import { ClipboardType, ActionType } from './reducer'

export const setZoomRatio = createAction<number>('editor/setZoomRatio')
export const setIsOpenErrorModalRemoveBg = createAction<boolean>('editor/setIsOpenErrorModalRemoveBg')
export const setHasClipboardImage = createAction<ClipboardType>('editor/setHasClipboardImage')
export const setCallbackFromCreationPage = createAction<{
  action: ActionType
  value?: any
  analytic?: any
}>('editor/setCallbackFromCreationPage')
