import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect
      width={16}
      height={10}
      x={4}
      y={11}
      stroke="#666"
      strokeWidth={2}
      rx={1}
    />
    <path stroke="#666" strokeWidth={2} d="M8 7a4 4 0 1 1 8 0v4H8V7Z" />
    <rect width={2} height={4} x={11} y={14} fill="#666" rx={1} />
  </svg>
)
export default SvgComponent
