import { Filter } from '@pixi/core';
import { IAdjustmentFilter } from '@scenes/engine/utils/PixijsFilters/IAdjustmentFilter'
import fragment from "./exposure.frag";
import vertex from "../default.vert";

// @ts-ignore
export class ExposureFilter implements IAdjustmentFilter {
  exposure: number;
  pixijsFilter: Filter | null;

  minimumForKey(key: string): number {
    if (key == "exposure") {
      return -1.5;
    }
  }

  maximumForKey(key: string): number {
    if (key == "exposure") {
      return 1.5;
    }
  }

  defaultForKey(key: string): number {
    if (key == "exposure") {
      return 0.0;
    }
  }

  constructor() {
    this.exposure = this.defaultForKey("exposure");
  }

  buildFilter({exposure}) {
    if (this.isValid(exposure)) { this.exposure = exposure; }

    if (!this.pixijsFilter) {
      this.pixijsFilter = ExposureFilter.generateFilter(this.exposure);
    } else {
      this.pixijsFilter.uniformGroup.uniforms["exposure"] = this.exposure;
    }
  }

  isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

  static generateFilter(value: number): Filter {
    let filter = new Filter(
      vertex,
      fragment,
        {
          exposure: value
        }
    );
    return filter;
  }
}