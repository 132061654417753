import { Filter } from '@pixi/core';
import fragment from "./premultiply.frag";
import vertex from "../default.vert";

// @ts-ignore
export class PremultiplyFilter {
  pixijsFilter: Filter | null;

  constructor() {
    this.pixijsFilter = PremultiplyFilter.generateFilter();
  }

  static generateFilter(): Filter {
    let filter = new Filter(
      vertex,
      fragment,
        {

        }
    );
    return filter;
  }
}
