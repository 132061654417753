import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect
      width={16}
      height={10}
      x={4}
      y={11}
      stroke="#666"
      strokeWidth={2}
      rx={1}
    />
    <rect width={2} height={4} x={11} y={14} fill="#666" rx={1} />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M12 4c1.306 0 2.418.835 2.83 2h2.07A5.002 5.002 0 0 0 7 7v5h10v-2H9V7a3 3 0 0 1 3-3Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
