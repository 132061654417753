import React from 'react'

function Drop() {
  return (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.799145 0.286645C1.19309 -0.100426 1.82623 -0.094852 2.2133 0.299095L6.50143 4.66339L10.7909 0.301046C11.1781 -0.0927558 11.8112 -0.0980916 12.205 0.289128C12.5988 0.676347 12.6042 1.30949 12.2169 1.70329L7.35686 6.64599C6.88653 7.12432 6.11541 7.12417 5.64525 6.64566L0.786696 1.7008C0.399625 1.30686 0.405198 0.673717 0.799145 0.286645Z"
        fill="#666666"
      />
    </svg>
  )
}

export default Drop
