import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#4285F4"
      d="M22.5 12.242c0-.708-.063-1.38-.17-2.034H12.228v4.04h5.784c-.26 1.326-1.02 2.446-2.146 3.207v2.688h3.45C21.338 18.27 22.5 15.51 22.5 12.24Z"
    />
    <path
      fill="#34A853"
      d="M12.228 22.75c2.897 0 5.32-.967 7.09-2.607l-3.451-2.687c-.966.645-2.19 1.04-3.639 1.04-2.798 0-5.167-1.891-6.017-4.444H2.653v2.768c1.762 3.511 5.382 5.93 9.575 5.93Z"
    />
    <path
      fill="#FBBC05"
      d="M6.211 14.052A6.25 6.25 0 0 1 5.871 12c0-.716.126-1.406.34-2.051V7.18H2.653a10.643 10.643 0 0 0 0 9.639l3.558-2.768Z"
    />
    <path
      fill="#EA4335"
      d="M12.228 5.505c1.582 0 2.995.547 4.112 1.613l3.058-3.064c-1.85-1.738-4.274-2.804-7.17-2.804-4.193 0-7.814 2.419-9.575 5.93L6.211 9.95c.85-2.554 3.219-4.444 6.017-4.444Z"
    />
  </svg>
)
export default SvgComponent
