import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.857 21.217A9.558 9.558 0 0 1 12 22a9.53 9.53 0 0 1-3.845-.783 10.265 10.265 0 0 1-3.2-2.172 10.284 10.284 0 0 1-2.173-3.195A9.518 9.518 0 0 1 2 12c0-1.379.261-2.66.778-3.852a10.224 10.224 0 0 1 2.171-3.199 10.206 10.206 0 0 1 3.204-2.17A9.562 9.562 0 0 1 11.989 2c1.378 0 2.661.261 3.858.778a10.206 10.206 0 0 1 3.204 2.171c.925.926 1.648 1.99 2.171 3.199A9.578 9.578 0 0 1 22 12c0 1.379-.261 2.66-.778 3.852a10.265 10.265 0 0 1-5.366 5.365Z"
      stroke="#666"
      strokeWidth={2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.238 4.725c0 .5-.41.909-.91.909a.912.912 0 0 1-.908-.909c0-.5.409-.909.909-.909s.909.41.909.91Zm-.91 4.544c.5 0 .91-.409.91-.909s-.41-.908-.91-.908-.908.409-.908.909.409.908.909.908Zm-3.635 4.09c.755 0 1.364-.609 1.364-1.363s-.61-1.363-1.364-1.363c-.754 0-1.363.609-1.363 1.363s.609 1.363 1.363 1.363Zm0-3.635c.755 0 1.364-.609 1.364-1.363 0-.755-.61-1.364-1.364-1.364-.754 0-1.363.61-1.363 1.364 0 .754.609 1.363 1.363 1.363Zm4.545 2.272c0 .5-.41.909-.91.909a.912.912 0 0 1-.908-.91c0-.5.409-.908.909-.908s.909.409.909.909Zm6.361.454a.45.45 0 0 0 .455-.454.45.45 0 0 0-.455-.455.45.45 0 0 0-.454.455c0 .254.2.454.454.454Zm.455-7.725a.45.45 0 0 1-.455.455.45.45 0 0 1-.454-.455c0-.254.2-.454.454-.454.255 0 .455.2.455.454Zm-.455 4.09a.45.45 0 0 0 .455-.454.45.45 0 0 0-.455-.455.45.45 0 0 0-.454.455c0 .254.2.454.454.454Zm-2.726-4.09c0 .5-.41.909-.909.909a.912.912 0 0 1-.909-.909c0-.5.41-.909.909-.909.5 0 .909.41.909.91Zm-.909 4.544c.5 0 .909-.409.909-.909s-.41-.908-.909-.908c-.5 0-.909.409-.909.909s.41.908.909.908Zm.909 2.727c0 .5-.41.909-.909.909a.912.912 0 0 1-.909-.91c0-.5.41-.908.909-.908.5 0 .909.409.909.909ZM3.691 17.003c.755 0 1.364-.609 1.364-1.363 0-.755-.61-1.364-1.364-1.364-.754 0-1.363.61-1.363 1.364 0 .754.61 1.363 1.363 1.363Zm3.636-.454c.5 0 .909-.41.909-.91s-.41-.908-.91-.908-.908.409-.908.909.409.909.909.909Zm.909 2.726c0 .5-.41.909-.91.909a.912.912 0 0 1-.908-.909c0-.5.409-.909.909-.909s.909.41.909.909Zm6.361.454a.45.45 0 0 0 .455-.454.45.45 0 0 0-.455-.455.45.45 0 0 0-.454.455c0 .254.2.454.454.454Zm.455-4.09a.45.45 0 0 1-.455.455.45.45 0 0 1-.454-.455c0-.254.2-.454.454-.454.255 0 .455.2.455.454Zm-4.09.91c.5 0 .909-.41.909-.91s-.409-.908-.909-.908-.909.409-.909.909.41.909.91.909Zm.909 2.726c0 .5-.41.909-.909.909a.912.912 0 0 1-.909-.909c0-.5.41-.909.91-.909.499 0 .908.41.908.909Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
