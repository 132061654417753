import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import ButtonCustom from '@/components/ButtonCustom'
import { KIND } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import Icons from '../../Icons'
import AutoScroll from '@/components/AutoScroll'
import { useStyletron } from 'styletron-react'
import { ObjectType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import { useSelector } from 'react-redux'
import { selectFonts } from '@/store/slices/fonts/selectors'
import groupBy from 'lodash/groupBy'
import { customAmplitude } from '@/utils/customAmplitude'
import { Point } from '@/scenes/engine/objects/media-repository/point'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { StaticText } from 'fabric/fabric-impl'
import { useTranslation } from 'react-i18next'
import HeadingInspector from '@/components/HeadingInspector'


const TEXT_PRESENT = [
  {
    url: 'https://assets.bazaart.me/tp/new_post.png',
    fontPack: 'Featured',
    fontFamily: 'Marcellus-Regular',
    string: 'NEW POST',
    color: '#3e3e3e',
    fontSize: 208,
    alignment: 2,
    charSpacing: 10,
    lineHeight: 8,
  },
  {
    url: 'https://assets.bazaart.me/tp/Morning_routi.png',
    fontPack: 'Signature',
    fontFamily: 'Signature',
    string: 'Morning\nRoutine',
    color: '#b349ff',
    fontSize: 164,
    alignment: 2,
    charSpacing: 0,
    lineHeight: 8,
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-14.png',
    fontPack: 'Featured',
    fontFamily: 'Quicksand-Bold',
    string: 'COFFEE\nBREAK',
    color: '#2affc4',
    fontSize: 192,
    alignment: 2,
    charSpacing: 0,
    lineHeight: 8,
    outline: {
      color: '#a5ffe6',
      strokeWidth: 5
    },
    shadow: {
      angle: 0,
      blur: 18,
      color: '#2bffc3',
      distance: 0,
      opacity: 100
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-11.png',
    fontPack: 'Artsy',
    fontFamily: 'PinkLemonadeRegular',
    string: 'Happy\nBirthday!',
    color: '#ec9aff',
    fontSize: 227,
    alignment: 2,
    charSpacing: 52,
    lineHeight: 8,
    outline: {
      color: '#e0f004',
      strokeWidth: 12
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-13.png',
    fontPack: 'Featured',
    fontFamily: 'ZillaSlab-Bold',
    string: 'subscribe\nand become\na member',
    color: '#6a3d29',
    fontSize: 215,
    alignment: 0,
    charSpacing: 4,
    lineHeight: 8,
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart%20(61).png',
    fontPack: 'Dreamy',
    fontFamily: 'GreatVibes-Regular',
    string: 'Thank you!',
    color: '#eb10a9',
    fontSize: 164,
    alignment: 2,
    charSpacing: 0,
    lineHeight: 8,
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart%20(63).png',
    fontPack: 'Love',
    fontFamily: 'FoolForLove',
    string: 'YOU ARE\nTHE ONE\nI LOVE !',
    color: '#fd0909',
    fontSize: 90,
    alignment: 2,
    charSpacing: 55,
    lineHeight: 11,
    outline: {
      color: '#a8eb6e',
      strokeWidth: 22
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-8.png',
    fontPack: 'Strong',
    fontFamily: 'BungeeInline-Regular',
    string: 'RETRO VIBES',
    color: '#e7cd49',
    fontSize: 215,
    alignment: 2,
    charSpacing: 4,
    lineHeight: 0,
    outline: {
      color: lightTheme.colors.blackGray,
      strokeWidth: 6
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-12.png',
    fontPack: 'Featured',
    fontFamily: 'BebasNeue-Regular',
    string: 'JOIN OUR\nCOMMUNITY',
    color: lightTheme.colors.blackGray,
    fontSize: 220,
    alignment: 0,
    charSpacing: 0,
    lineHeight: 7,
  },
  {
    url: 'https://assets.bazaart.me/tp/fun.png',
    fontPack: 'Strong',
    fontFamily: 'Shrikhand-Regular',
    string: 'FUN',
    color: '#f1ff3a',
    fontSize: 217,
    alignment: 2,
    charSpacing: 0,
    lineHeight: 8,
    outline: {
      color: '#ff2bbf',
      strokeWidth: 24
    },
    shadow: {
      angle: 68,
      blur: 32,
      color: '#ff2bbf',
      distance: 4,
      opacity: 36
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-10.png',
    fontPack: 'Strong',
    fontFamily: 'YesevaOne',
    string: 'FOLLOW',
    color: '#e1b945',
    fontSize: 176,
    alignment: 2,
    charSpacing: 0,
    lineHeight: 8,
  },
  {
    url: 'https://assets.bazaart.me/tp/PM.png',
    fontPack: 'Yummy',
    fontFamily: 'VT323-Regular',
    string: 'PM 2:55\nSep.12 2024',
    color: lightTheme.colors.blackGray,
    fontSize: 173,
    alignment: 0,
    charSpacing: 13,
    lineHeight: 11,
  },
  {
    url: 'https://assets.bazaart.me/tp/fire.png',
    fontPack: 'Halloween',
    fontFamily: 'MeltedMonsterRegular',
    string: 'FIRE',
    color: '#ff0404',
    fontSize: 218,
    alignment: 2,
    charSpacing: 13,
    lineHeight: 7,
    outline: {
      color: '#fff24a',
      strokeWidth: 5
    },
    shadow: {
      angle: 12,
      blur: 60,
      color: '#fff24a',
      distance: 8,
      opacity: 100
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-15.png',
    fontPack: 'Signature',
    fontFamily: 'FancySignature',
    string: 'comming soon...',
    color: '#454545',
    fontSize: 176,
    alignment: 2,
    charSpacing: 0,
    lineHeight: 0,
  },
  {
    url: 'https://assets.bazaart.me/tp/pixel_perfect.png',
    fontPack: 'Gaming',
    fontFamily: 'PressStart2P-Regular',
    string: 'pixel\nperfect',
    color: '#c141ff',
    fontSize: 164,
    alignment: 2,
    charSpacing: 12,
    lineHeight: 8,
    outline: {
      color: '#4d0062',
      strokeWidth: 22
    },
    shadow: {
      angle: 0,
      blur: 22,
      color: '#6700b8',
      distance: 0,
      opacity: 100
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/happy_hour.png',
    fontPack: 'Strong',
    fontFamily: 'ClimateCrisis-Regular',
    string: 'HAPPY\nHOUR',
    color: '#0101ff',
    fontSize: 217,
    alignment: 2,
    charSpacing: 0,
    lineHeight: 8,
    outline: {
      color: '#bdcfff',
      strokeWidth: 24
    }
  },
  // {
  //   url: 'https://assets.bazaart.me/tp/really.png',
  //   fontPack: 'Urban',
  //   fontFamily: 'SedgwickAveDisplay-Regular',
  //   string: 'REALLY??',
  //   color: '#80fa46',
  //   fontSize: 215,
  //   alignment: 2,
  //   charSpacing: 4,
  //   lineHeight: 6,
  //   outline: {
  //     color: '#009f00',
  //     strokeWidth: 17
  //   },
  //   shadow: {
  //     angle: 0,
  //     blur: 35,
  //     color: '#e1e1e1',
  //     distance: 3,
  //     opacity: 100
  //   }
  // },
]

function Text({ isOpen, setIsOpen }) {
  const [isBoxShadow, setIsBoxShadow] = useState(false)

  const fonts = useSelector(selectFonts)

  const fontGroups = groupBy(fonts, 'category')
  

  const editor = useEditorContext().editor
  const { setPopoverActive, objDragging, setObjDragging } = useAppContext()
  const canvasImageRenderer = CanvasImageRenderer.getInstance()
  const shadowFilter = canvasImageRenderer.shadowFilter
  const outlineFilter = canvasImageRenderer.outlineFilter


  const convertValue = (uiValue: number, logicMaximum, logicMinimum): number => {
    if ([null, undefined].includes(logicMaximum && logicMinimum)) {
      return uiValue
    }

    let normalizedUIValue = (uiValue - 0) / (100 - 0)
    let normalizedLogicValue = normalizedUIValue * (logicMaximum - logicMinimum) + logicMinimum
    return normalizedLogicValue
  }
  const getOffset = (font): Point => {
    let radians = font.shadow.angle
    const offX = Math.cos(radians) * font.shadow.distance
    const offY = Math.sin(radians) * font.shadow.distance

    let p = new Point(offX, offY)
    return p
  }
  const degreesToRadians = (degrees: number): number => {
    let radians = (degrees / 180) * Math.PI
    return radians
  }

  const addStaticText = (font?) => {
    const frameOption = editor.handlers.frameHandler.getOptions()
    const options = {
      type: ObjectType.BAZAART_TEXT,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: 0.8,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      opacity: 1,
      textProperties: {
        attributedText: {
          runs: [
            {
              attributes: {
                NSColor: font ? font.color : lightTheme.colors.blackGray,
                NSFont: { size: 0, systemName: font ? font.family : 'Poppins-SemiBold' },
                NSParagraphStyle: { NSWritingDirection: 1, NSAlignment: font? font.alignment : 2 },
                NSKern: 0,
              },
            },
          ],
          string: font ? font.text : 'Add your text',
        },
        arcAngle: 0,
        alignment: font? font.alignment : 2,
        imageToTextTransformation: {
          fontRelativeToWidth: 0.1,
          sizeRelativeToWidth: {
            width: 1,
            height: 0.2,
          },
          originRelativeToWidth: {
            x: 0.5,
            y: 0.5,
          },
        },
      },
      charSpacing: font? font.charSpacing : 0,
      lineHeight: font? font.lineHeight / 10 : 0,
      fontSize: font ? font.fontSize : null,
      absoluteRotation: 0,
      effects: {
        outline: font && font.outline ? {
          ...font.outline,
          thickness: convertValue(font.outline.strokeWidth, outlineFilter.maximumForKey("thickness"), outlineFilter.minimumForKey("thickness"))
        } : null,
        shadow: font && font.shadow ? {
          ...font.shadow,
          offsetX: Math.floor(getOffset(font).x ),
          offsetY: Math.floor(getOffset(font).y),
          blur: convertValue(font.shadow.blur, shadowFilter.maximumForKey("blurText"), shadowFilter.minimumForKey("blurText")),
          angle: degreesToRadians(font.shadow.angle),
          distance : convertValue(font.shadow.distance, shadowFilter.maximumForKey("distance"), shadowFilter.minimumForKey("distance")),
          opacity: convertValue(font.shadow.opacity, shadowFilter.maximumForKey("opacity"), shadowFilter.minimumForKey("opacity")),
        } : null,
      },
      strokeWidth: font && font.outline ? convertValue(font.outline.strokeWidth, outlineFilter.maximumForKey("thickness"), outlineFilter.minimumForKey("thickness")) * 0.8 * frameOption.width : null,
      metadata: {
        text: font ? font.text : 'Add your text',
        fontSize: font ? font.fontSize : 100,
        fontWeight: 900,
        fontFamily: font ? font.family : 'Poppins-SemiBold',
        name: font ? font.name : 'Aniston',
        originX: 'center',
      },
      isPresetText: font ? font.isPresetText : false
    }
    editor.handlers.objectsHandler.add(options).then((object)=>{
      let addedTextObject = object as StaticText

      // @ts-ignore
      // addedTextObject.set('width', addedTextObject.calcTextWidth() + 20)
      let textWidth = addedTextObject.calcTextWidth()
      if(textWidth > (frameOption.width * 0.8)){
        addedTextObject.fontSize *= addedTextObject.width / (addedTextObject.width + 1)
      }
      else{
        addedTextObject.width = textWidth + 20
        // @ts-ignore
        if(addedTextObject.effects && addedTextObject.effects.outline) {
          // @ts-ignore
          addedTextObject.strokeWidth = Math.floor(addedTextObject.effects.outline.thickness * Math.max(addedTextObject.width, addedTextObject.height))
        }

        // @ts-ignore
        if (addedTextObject.effects && addedTextObject.effects.shadow) {
          // @ts-ignore
          const offset = getOffset(addedTextObject.effects);
          // @ts-ignore
          addedTextObject.shadow = {
            // @ts-ignore
            color: addedTextObject.shadow.color,
            offsetX: Math.floor(offset.x * addedTextObject.width),
            offsetY: Math.floor(offset.y * addedTextObject.width),
            // @ts-ignore
            blur: Math.floor(addedTextObject.effects.shadow.blur * addedTextObject.width),
          }
        }
      }

    })
    
    setPopoverActive(null)
    font
      ? customAmplitude('Selected tool', {
          Tool: 'bazaart.add.text_style',
        })
      : customAmplitude('Selected tool', {
          Tool: 'bazaart.add.text',
        })
  }

  const addDynamicText = () => {
    const options = {
      type: 'DynamicText',
      width: 320,
      metadata: {
        text: 'Add dynamic text',
        fontSize: 32,
        fontWeight: 500,
        fontFamily: 'Inter',
        textAlign: 'center',
      },
    }
    editor.handlers.objectsHandler.add(options)
  }

  const handleStyleSelect = (textItem) => {
    const {fontFamily, fontPack, string, color, fontSize, alignment, charSpacing, lineHeight, outline, shadow} = textItem
    if (fontGroups[fontPack]) {
      const font = fontGroups[fontPack].find(f => f.family === fontFamily)
      if (font) {
        const fontFile = font.files['regular' as any]
        const fontOpt = {
          name: font.name,
          family: font.family,
          url: fontFile,
          options: { style: 'normal', weight: 400 },
        }
        // @ts-ignore
        const fontFace = new FontFace(fontOpt.family, `url(${fontOpt.url})`, fontOpt.options)
        fontFace
          .load()
          .then(loadedFont => {
            document.fonts.add(loadedFont)
            fontFace.loaded.then(() => {
              addStaticText({
                ...font,
                color,
                text: string,
                fontSize,
                alignment,
                charSpacing,
                lineHeight,
                outline,
                shadow,
                isPresetText: true
              })
            })
          })
          .catch(err => console.log(err))
      }
    }
  }

  const { t } = useTranslation()
  useEffect(() => {
    if(objDragging) {
      if(objDragging.type === ObjectType.BAZAART_TEXT && objDragging.dropped) {
        handleStyleSelect(objDragging.item)
        setObjDragging({
          item: null,
          type: null,
          dropped: false
        })
      }
    }
  }, [objDragging])

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="popover-text"
        style={{
          overflow: 'hidden',
          boxSizing: 'border-box',
          position: 'fixed',
          top: 'calc(-50vh + 48px + 180px)',
          left: '80px',
          background: '#ffffff',
          width: '340px',
          height: 'calc(100vh - 96px)',
          flex: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
        }}
      >
        <div style={{ 
          boxShadow: isBoxShadow ? '0px 1px 6px 0px rgba(0, 0, 0, 0.10)' : 'none',
        }}>
          <HeadingInspector
            hasBoxShadow={false}
            hasNavigation={false}
            title={t('Text')}
            hasClose={true}
            handleClose={() => setIsOpen()}
          ></HeadingInspector>
          <div
            style={{
              textAlign: 'center',
              zIndex: 100,
              paddingBottom: '12px',
            }}
          >
            <ButtonCustom
              kind={KIND.primary}
              type={SizeButton.LARGE}
              onClick={() => addStaticText()}
              style={{ color: 'white', margin: '0 auto', width: '292px' }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
                <Icons.PlusIcon fill="#fff" />
                <p style={{ ...lightTheme.typography.Small14Semibold, color: '#fff' }}>{t('Add text')}</p>
              </div>
            </ButtonCustom>
          </div>
        </div>
        <AutoScroll
          style={{ position: 'relative'}}
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
        >
          <div style={{ boxSizing: 'border-box', width: '100%', height: 'fit-content' }}>
            <div
              style={{
                height: '26px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              <p style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title}}>{t('Styles')}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
              {TEXT_PRESENT.map((textItem, index) => (
                <TextItem
                  key={textItem.url}
                  textItem={textItem}
                  handleOnClick={() => {
                    handleStyleSelect(textItem)
                  }}
                />
              ))}
            </div>
          </div>
        </AutoScroll>
      </div>
    </ThemeProvider>
  )
}

export default Text

function TextItem({ textItem, handleOnClick }) {
  const { objDragging, setObjDragging } = useAppContext()

  const [css] = useStyletron()
  return (
    <div
      className={css({
        width: '142px',
        height: '142px',
        padding: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        border: '1px solid rgba(0,0,0,0.1)',
        cursor: 'pointer',
        ':hover': {
          background: lightTheme.colors.grayScale50,
        },
        ':active': {
          background: lightTheme.colors.grayScale100,
        },
      })}
      onClick={() => handleOnClick()}
      onDrag={() => {
        if (!objDragging.item || objDragging.item?.string !== textItem.string) {
          setObjDragging({
            item: textItem,
            type: ObjectType.BAZAART_TEXT,
          })
        }
      }}
    >
      <img src={textItem.url} alt="" width="100%" height="100%" />
    </div>
  )
}
