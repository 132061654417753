class ShortcutManager {
  //delete
  isDelete(event: KeyboardEvent) {
    return event.key === 'Delete' || event.key === 'Backspace'
  }

  // save or update template
  isCtrlS(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.code === 'KeyS'
  }

  // select all
  isCtrlA(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.code === 'KeyA'
  }

  // copy
  isCtrlC(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.code === 'KeyC'
  }

  // paste
  isCtrlV(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.code === 'KeyV'
  }
  // redo
  isCtrlY(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.code === 'KeyY'
  }

  // cut
  isCtrlX(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.code === 'KeyX'
  }

  // duplicate
  isCtrlD(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.code === 'KeyD'
  }

  // nudge
  isArrowUp(event: KeyboardEvent) {
    return event.code === 'ArrowUp'
  }

  // nudge
  isArrowDown(event: KeyboardEvent) {
    return event.code === 'ArrowDown'
  }

  // nudge
  isArrowLeft(event: KeyboardEvent) {
    return event.code === 'ArrowLeft'
  }

  // nudge
  isArrowRight(event: KeyboardEvent) {
    return event.code === 'ArrowRight'
  }

  // modifier
  isShift(event: KeyboardEvent) {
    return event.shiftKey
  }

  // lineHeight--
  isAltDown(event: KeyboardEvent) {
    return event.altKey && event.code === 'ArrowDown'
  }

  // lineHeight++
  isAltUp(event: KeyboardEvent) {
    return event.altKey && event.code === 'ArrowUp'
  }

  // charSpacing++
  isAltRight(event: KeyboardEvent) {
    return event.altKey && event.code === 'ArrowRight'
  }
  // charSpacing--
  isAltLeft(event: KeyboardEvent) {
    return event.altKey && event.code === 'ArrowLeft'
  }

  // redo
  isCtrlShiftZ(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.shiftKey && event.code === 'KeyZ'
  }

  // undo
  isCtrlZ(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && !event.shiftKey && event.code === 'KeyZ'
  }

  // zoom reset
  isCtrlOne(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.key === '1'
  }

  // zoom in
  isCtrlMinus(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.key === '-'
  }

  // zoom out
  isCtrlEqual(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.key === '='
  }

  // zoom to fit
  isCtrlZero(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.key === '0'
  }

  // group
  isCtrlG(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && !event.shiftKey && event.code === 'KeyG'
  }
  
   // redo
  isCtrlShiftG(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.shiftKey && event.code === 'KeyG'
  }

  // brushSize-- 
  isOpeningBrace(event: KeyboardEvent) {
    return event.key === '['
  }
  // brushSize++ 
  isClosingBrace(event: KeyboardEvent) {
    return event.key === ']'
  }

  // bringForward
  isCtrlOpeningBrace(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && !event.altKey && event.code === 'BracketLeft'
  }
  // sendBackwards
  isCtrlClosingBrace(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && !event.altKey && event.code === 'BracketRight'
  }
  // bringToFront
  isCtrlAltOpeningBrace(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.altKey && event.code === 'BracketLeft'
  }
  // sendToBack
  isCtrlAltClosingBrace(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.altKey && event.code === 'BracketRight'
  }

  isSpace(event: KeyboardEvent) {
    return event.code === 'Space'
  }

  isMeta(event: KeyboardEvent) {
    return event.metaKey
  }

  isCtrl(event: KeyboardEvent) {
    return event.ctrlKey
  }
}

export default new ShortcutManager()
