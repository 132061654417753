import array from 'new-array';
import Image from 'image-js'

/**
 * @memberof Image
 * @instance
 * @param {object} [options]
 * @param {number} [options.top=0]
 * @param {number} [options.bottom=0]
 * @param {number} [options.left=0]
 * @param {number} [options.right=0]
 * @param {array<number>} [options.color]
 * @return {Image}
 */
export default function padWithOffset(options = {}) {
  // @ts-ignore
  let { top = 0, left = 0, bottom = 0, right = 0, color } = options;

  this.checkProcessable('pad', {
    bitDepth: [8, 16],
  });

  if (color.length !== this.channels) {
      throw new Error(
        `pad: the color array must have the same length as the number of channels. Here: ${this.channels}`,
      );
    }
    for (let i = 0; i < color.length; i++) {
      if (color[i] === 0) {
        color[i] = 0.001;
      }
    }

  let newWidth = this.width + left + right;
  let newHeight = this.height + top + bottom;
  let channels = this.channels;

  let newImage = Image.createFrom(this, { width: newWidth, height: newHeight });

  for (let i = left; i < newWidth - right; i++) {
    for (let k = 0; k < channels; k++) {
      let value =
        color[k] || newImage.data[(top * newWidth + i) * channels + k];
      for (let j = 0; j < bottom; j++) {
        newImage.data[(j * newWidth + i) * channels + k] = value;
      }
      value =
        color[k] ||
        newImage.data[
          ((newHeight - bottom - 1) * newWidth + i) * channels + k
        ];
      for (let j = newHeight - bottom; j < newHeight; j++) {
        newImage.data[(j * newWidth + i) * channels + k] = value;
      }
    }
  }

   for (let j = 0; j < newHeight; j++) {
    for (let k = 0; k < channels; k++) {
      let value =
        color[k] || newImage.data[(j * newWidth + left) * channels + k];
      for (let i = 0; i < left; i++) {
        newImage.data[(j * newWidth + i) * channels + k] = value;
      }
      value =
        color[k] ||
        newImage.data[(j * newWidth + newWidth - right - 1) * channels + k];
      for (let i = newWidth - right; i < newWidth; i++) {
        newImage.data[(j * newWidth + i) * channels + k] = value;
      }
    }
  }

  return newImage;
}