import { useContext, useEffect, useState } from 'react'
import { EditorContext, RemoveEditorContext } from '@/scenes/engine'
import useAppContext from '@/hooks/useAppContext'
import { lightTheme } from '@/customTheme'
import EnhanceObjectHandler from '@/scenes/engine/handlers/remove-handler/EnhanceObjectHandler'
import { StaticImage } from 'fabric/fabric-impl'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import api from '@/services/api'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'

function EnhanceTool() {
  const removeEditor = useContext(RemoveEditorContext).editor
  const { setIsLoadedJsonSuccess } = useAppContext()
  const { setisOpenPixelManipulationObject, isOpenPixelManipulationObject, toolType } = useAppContext()
  const [toolActive, setToolActive] = useState('before')
  const [isLoadedResult, setIsLoadedResult] = useState(false)
  const { canvas } = useContext(RemoveEditorContext)
  const { activeObject } = useContext(EditorContext)


  useEffect(() => {
    removeEditor.handlers.setPixelToolType('Enhance')
    setisOpenPixelManipulationObject(true)

    if(activeObject) {
      getResultEnhance(activeObject)
    }
  }, [])

  useEffect(() => {
    if (!isOpenPixelManipulationObject || !removeEditor || toolType !== 'Enhance') {
      return
    }
    let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')

    ;(async () => {
      removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject
      await removeEditor.handlers.pixelManipulationObjectHandler.setupImage()
       await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()
       // duplicate image so it can be animated to fade afterwards
       await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()

      canvasRemoveTool.style.zIndex = '1'
      let enhanceObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as EnhanceObjectHandler
      enhanceObjectHandler.handleAnimate()
    })()
  }, [isOpenPixelManipulationObject, toolType])

  const getResultEnhance = async (activeObject) => {
    let refObject = activeObject as unknown as StaticImage

    // @ts-ignore
    let id = refObject.id as string
    let assetStateId = refObject.layerAssetStateId as string
    // @ts-ignore
    let originalImage = await MediaImageRepository.getInstance().getImage(
      id,
      assetStateId,
      MediaImageType.original
    )
    refObject.isLatest = false
    // 
    setIsLoadedJsonSuccess(false)
    await api.enhance(
      originalImage,
    ).then(async data => {
      await handleEnhanceImage(data)
      setIsLoadedResult(true)
    }).catch(err => {
      console.log('err', err)
      setIsLoadedResult(true)
      setIsLoadedJsonSuccess(true)
    })
  }

  const handleEnhanceImage = async (data) => {
    const imageProcessing = new MediaImageRepositoryProcessing()
    const base64 = await imageProcessing.convertImageToBase64(data)
    let objEnhance = canvas.getObjects()[1] as unknown as StaticImage
    // @ts-ignore
    if(!objEnhance._originalScaleX && !objEnhance._originalScaleY) {
      // @ts-ignore
      objEnhance._originalScaleX = objEnhance.scaleX
      // @ts-ignore
      objEnhance._originalScaleY = objEnhance.scaleY
    }
    objEnhance.replaceImage(base64, true).then(() => {
      objEnhance.useNewTextureNextTime()
      canvas.renderAll();
      removeEditor.handlers.transactionRemoveHandler.save()
    })
    setIsLoadedJsonSuccess(true)
  }

  useEffect(() => {
    if(isLoadedResult) {
      setToolActive('after')
    }
  }, [isLoadedResult])

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
        <div
          style={{
            display: 'flex',
            gap: '4px',
            padding: '6px',
            background: lightTheme.colors.grayScale50,
            borderRadius: '26px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '6px',
              padding: '8px 0',
              background: toolActive === 'before' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              width: '100%',
              justifyContent: 'center',
            }}
            onClick={() => {
              setToolActive('before')
              const objEnhance = canvas.getObjects()[0]
              objEnhance.moveTo(1)
            }}
          >
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: toolActive === 'before' ? 'rgba(255, 5, 96, 1)' : 'rgba(106, 106, 106, 1)',
              }}
            >
              Before
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '6px',
              padding: '8px 0',
              background: toolActive === 'after' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              width: '100%',
              justifyContent: 'center',
            }}
            onClick={async () => {
              setToolActive('after')
              const objEnhance = canvas.getObjects()[1]
              objEnhance.moveTo(0)
            }}
          >
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: toolActive === 'after' ? 'rgba(255, 5, 96, 1)' : 'rgba(106, 106, 106, 1)',
              }}
            >
              After
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnhanceTool
