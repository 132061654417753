import React, { useState, useEffect, useRef } from 'react';
import styles from './AnimatedInspectorWrapper.module.css';

interface AnimatedWrapperProps {
  isVisible: boolean;
  onClose: () => void;
  addWithAnimation: boolean;
  closeWithAnimation: boolean;
  isInspectorPanelOnTop: boolean;
  children: React.ReactNode;
}

const AnimatedInspectorWrapper: React.FC<AnimatedWrapperProps> = ({ isVisible, onClose, addWithAnimation, closeWithAnimation, isInspectorPanelOnTop, children }) => {
  const [shouldRender, setShouldRender] = useState(isVisible);

  const [isVisibleAnimationClass, setIsVisibleAnimationClass] = useState<string>('');
  const [isOnTopAnimationClass, setIsOnTopAnimationClass] = useState<string>('');

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
    }

    // Handle animations when isVisible changes
    if (isVisible) {
      setIsVisibleAnimationClass(addWithAnimation ? styles.enter : styles.enterWithoutAnimation);
    } else {
      setIsOnTopAnimationClass('');
      setIsVisibleAnimationClass(closeWithAnimation ? styles.exit : styles.exitWithoutAnimation);
    }
  }, [isVisible, addWithAnimation, closeWithAnimation]);

  useEffect(() => {
    // Handle animations when isInspectorPanelOnTop changes, but only if isVisible is true
    if (isVisible && (isVisibleAnimationClass == styles.enter || isVisibleAnimationClass == styles.enterWithoutAnimation)) {
      if (isInspectorPanelOnTop) {
        setIsOnTopAnimationClass(styles.onTop); // Handle when panel is on top
      } else {
        setIsOnTopAnimationClass(styles.nonOnTop); // Handle when panel is not on top
      }
    }
    if (!isVisible) {
      setIsOnTopAnimationClass('');
    }
  }, [isInspectorPanelOnTop]);


  const handleAnimationEnd = () => {
    if (!isVisible) {
      setShouldRender(false);
      onClose();
    }
  };

  return (
    shouldRender && (
      <div
        className={`${isVisibleAnimationClass}`}
        onAnimationEnd={handleAnimationEnd}
      >
        <div
          className={`${isOnTopAnimationClass}`}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default AnimatedInspectorWrapper;
