import ButtonCustom from '@/components/ButtonCustom'
import HeadingInspector from '@/components/HeadingInspector'
import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'
import LanguageMenu from '../LanguageMenu'
import { customAmplitude } from '@/utils/customAmplitude'
import { selectUser } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { PremiumOnPlatformType } from '../SignInManager'
import { useTranslation } from 'react-i18next'
import useAppContext from '@/hooks/useAppContext'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useAppDispatch } from '@/store/store'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { ActionType } from '@/store/slices/editor/reducer'
import { useHistory, useLocation } from 'react-router-dom'
import { handleCurrentState, setShowedRemoveObjectTutorial } from '@/utils/tutorial'
import { useEditorContext } from '@/scenes/engine'
import Icons from '../../../Icons'

export default function MiniMenu({
  handleClickContact,
  isOpenSelectLanguage,
  setIsOpenSelectLanguage,
  isOpenManageSubscription = null,
  setIsOpenManageSubscription = null,
  setIsOpenModalManage = null,
  hasPremium = false,
  closeMenu = null,
}) {
  const user = useSelector(selectUser)
  const { t } = useTranslation()
  const { setIsOpenTutorial, setShowModalType } = useAppContext()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { editor, activeObject, canvas } = useEditorContext()
  const location = useLocation();

  const styles = !user ? {
    width: '296px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
    padding: '16px',
    paddingTop: !user ? isOpenSelectLanguage ? 0 : '16px' : 0,
  } : null
  return (
    <div
      style={{
        ...styles,
        borderRadius: '16px',
        backgroundColor: lightTheme.colors.white,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
        { !isOpenSelectLanguage && !isOpenManageSubscription && (
          <>
            {hasPremium && (
              <ButtonCustom
                type={SizeButton.STANDARD}
                kind="tertiary"
                style={{
                  padding: '8px',
                  ...lightTheme.typography.Small14regular,
                  border: 'none',
                  justifyContent: 'left',
                  ':hover': {
                    border: 'none',
                  },
                }}
                onClick={() => {
                  setIsOpenManageSubscription(true)
                  customAmplitude('Settings - Manage Subscription')
                }}
              >
                {t('Manage subscription')}
              </ButtonCustom>
            )}
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind="tertiary"
              style={{
                padding: '8px',
                ...lightTheme.typography.Small14regular,
                border: 'none',
                justifyContent: 'left',
                ':hover': {
                  border: 'none',
                },
              }}
              onClick={() => setIsOpenSelectLanguage(true)}
            >
              {t('Language')}
            </ButtonCustom>
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind="tertiary"
              style={{
                padding: '8px',
                ...lightTheme.typography.Small14regular,
                border: 'none',
                justifyContent: 'left',
                ':hover': {
                  border: 'none',
                },
              }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                const isFromCreatePage = location.pathname === '/create'
                // No handlers is initialized yet when user is in create page
                if(!isFromCreatePage) {
                  handleCurrentState(editor.handlers, isFromCreatePage)
                }
                dispatch(setCallbackFromCreationPage({
                  action: ActionType.QUICK_TUTORIAL
                }))
                canvas?.discardActiveObject()
                setIsOpenTutorial(true)
                setShowedRemoveObjectTutorial()
                location.pathname === '/' ? history.replace('/') : history.push('/')
                setShowModalType(null)
                if (closeMenu) {
                  closeMenu()
                }
              }}
            >
              {t('Try Remove objects')}
            </ButtonCustom>
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind="tertiary"
              style={{
                padding: '8px',
                ...lightTheme.typography.Small14regular,
                border: 'none',
                justifyContent: 'left',
                ':hover': {
                  border: 'none',
                },
              }}
              onClick={() => {
                customAmplitude('Get App Modal', { Source: 'settings'})
                setShowModalType(ShowModalType.DOWNLOAD_APP)
                closeMenu()
              }}
            >
              {t('Get the app')}
            </ButtonCustom>
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind="tertiary"
              style={{
                padding: '8px',
                ...lightTheme.typography.Small14regular,
                border: 'none',
                justifyContent: 'left',
                ':hover': {
                  border: 'none',
                },
              }}
              onClick={() => {
                setShowModalType(ShowModalType.WHAT_NEW)
                closeMenu()
                customAmplitude('Whats New Prompt', {
                  Source: 'Settings'
                })
              }}
            >
              {t("What's new")}
            </ButtonCustom>
          </>
        )}
        {isOpenSelectLanguage && <LanguageMenu handleNavigation={() => setIsOpenSelectLanguage(false)} />}
        {isOpenManageSubscription && (
          <>
            <HeadingInspector
              hasNavigation={true}
              handleNavigation={() => setIsOpenManageSubscription(false)}
              title={t('Manage subscription')}
              style={{
                padding: 0,
                height: '64px',
              }}
            ></HeadingInspector>
            <div style={{
              height: '72px',
              width: '100%',
              backgroundColor: lightTheme.colors.grayScale50,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              padding: '12px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <div style={{flex: 1}}>
                <h5 className='linear-gradient-text-to-left' style={{...lightTheme.typography.Small14Bold}}>{t('You are Premium')}</h5>
                <p onClick={() => window.open('https://support.bazaart.com/en/content/what-s-included-in-bazaart-premium', '_blank')} style={{...lightTheme.typography.Small14regular, textDecoration: 'underline', cursor: 'pointer'}}>{t('Learn more')}</p>
              </div>
              <div style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Icons.NowPremiumIcon />
              </div>
            </div>
            <span style={{
              height: '1px',
              width: '100%',
              backgroundColor: lightTheme.colors.grayScale100,
              margin: '20px 0'
            }}></span>
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind="tertiary"
              style={{
                padding: '8px',
                ...lightTheme.typography.Small14regular,
                border: 'none',
                justifyContent: 'left',
                ':hover': {
                  border: 'none',
                },
              }}
              onClick={() => {
                if (user.userPremiumOnPlatform === PremiumOnPlatformType.ios) {
                  setIsOpenModalManage(true)
                } else if (user.userPremiumOnPlatform === PremiumOnPlatformType.web) {
                  window.open(user.manageUpdateUrl, '_blank')
                }
              }}
            >
              {t('Update payment method')}
            </ButtonCustom>
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind="tertiary"
              style={{
                padding: '8px',
                ...lightTheme.typography.Small14regular,
                border: 'none',
                justifyContent: 'left',
                ':hover': {
                  border: 'none',
                },
              }}
              onClick={() => {
                // @ts-ignore
                window.dataLayer.push({ event: 'cancel_subscription'});

                if (user.userPremiumOnPlatform === PremiumOnPlatformType.ios) {
                  setIsOpenModalManage(true)
                } else if (user.userPremiumOnPlatform === PremiumOnPlatformType.web) {
                  window.open(user.manageCancelUrl, '_blank')
                }
              }}
            >
              {t('Cancel subscription')}
            </ButtonCustom>
          </>
        )}
      </div>
    </div>
  )
}
