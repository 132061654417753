import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.727 21.952A6.793 6.793 0 0 1 12 22.5a6.83 6.83 0 0 1-2.733-.548 7.005 7.005 0 0 1-2.228-1.514 7.09 7.09 0 0 1-1.498-2.241A6.967 6.967 0 0 1 5 15.446c0-.724.102-1.438.305-2.144.203-.705.454-1.389.751-2.05.298-.663.598-1.283.899-1.862l3.616-6.968c.315-.615.791-.922 1.429-.922.617 0 1.093.307 1.43.922l3.605 6.968c.3.579.602 1.2.903 1.861.302.662.554 1.346.757 2.051.203.706.305 1.42.305 2.144 0 .984-.18 1.9-.541 2.75a7.083 7.083 0 0 1-3.731 3.755Zm-5.304-2.03c.77.46 1.628.69 2.577.69.942 0 1.799-.23 2.571-.69a5.164 5.164 0 0 0 1.844-1.86c.458-.782.686-1.654.686-2.616 0-.876-.178-1.737-.535-2.583a38.75 38.75 0 0 0-1.2-2.572l-3.252-6.37c-.028-.051-.064-.077-.109-.077s-.078.026-.099.076l-3.283 6.37a36.98 36.98 0 0 0-1.194 2.573 6.651 6.651 0 0 0-.53 2.583c0 .962.228 1.834.685 2.615a5.178 5.178 0 0 0 1.84 1.862Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
