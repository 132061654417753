function Layers({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8395 9.01848L12.1307 2.44334C11.7832 2.18097 11.3038 2.18097 10.9563 2.44334L2.24751 9.01848C1.73108 9.40839 1.73108 10.1841 2.24751 10.574L10.9563 17.1491C11.3038 17.4115 11.7832 17.4115 12.1307 17.1491L20.8395 10.574C21.356 10.1841 21.356 9.40839 20.8395 9.01848Z"
        stroke="#666666"
        strokeWidth="2"
      />
      <path
        d="M2.27478 15.0952L10.941 21.8947C11.2947 22.1723 11.7924 22.1718 12.1456 21.8935L20.7758 15.0952"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Layers
