import { IStorePack } from '@/interfaces/editor'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '@services/api'
import { AxiosError } from 'axios'
import { nanoid } from 'nanoid'

export const setPack = createAction<any[]>('packs/setPacks')

export const getPack = createAsyncThunk<void, string, { rejectValue: Record<string, string[]> }>(
  'packs/getPacks',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const pack: any = await api.getPack(id)
      // pack.map(bg => {
      //   const guid = nanoid()
      //   bg.bazaartId = guid
      //   let assetStateId = nanoid()
      //   bg.layerAssetStateId = assetStateId;

      //   let resizeThumbnailURL = getResizeUrl({ size: '0x32', url: bg.thumbnail.slice(8) })
      //   MediaImageRepository.getInstance().storeImageUrl(guid, assetStateId, MediaImageType.thumbnail, resizeThumbnailURL)

      //   return bg
      // })
      dispatch(setPack(pack))
    } catch (err) {
      return rejectWithValue(((err as AxiosError).response?.data as any)?.error.data || null)
    }
  }
)
