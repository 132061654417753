import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#EEA52D"
      fillRule="evenodd"
      d="M17.877 14.09c0 .35.01.696.04 1.007h-1.119c-.03-.256-.04-.51-.04-.757-.605.743-1.325.957-2.323.957-1.48 0-2.272-.783-2.272-1.688 0-1.31 1.078-1.772 2.949-1.815a71.398 71.398 0 0 1 1.562-.014v-.128c0-.877-.563-1.238-1.538-1.238-.724 0-1.257.3-1.312.817H12.56c.134-1.29 1.49-1.617 2.682-1.617 1.428 0 2.635.508 2.635 2.019v2.458Zm-2.682-1.49c-1.09.036-1.768.233-1.768.951 0 .465.37.97 1.312.97 1.261 0 1.935-.689 1.935-1.817v-.124c-.442 0-.988.004-1.48.02Z"
      clipRule="evenodd"
    />
    <path
      fill="#DC3B41"
      fillRule="evenodd"
      d="M1.225 12.76c.065 1.091.818 1.737 1.853 1.737.717 0 1.355-.292 1.568-.928h1.24c-.24 1.29-1.611 1.728-2.79 1.728C.95 15.297 0 14.116 0 12.523c0-1.754.983-2.909 3.115-2.909 1.697 0 2.94.888 2.94 2.826v.32h-4.83Zm3.566-.8c-.047-1.125-.859-1.546-1.727-1.546-.936 0-1.683.474-1.814 1.546h3.54Z"
      clipRule="evenodd"
    />
    <path
      fill="#0B69A5"
      fillRule="evenodd"
      d="M6.054 7.5h1.203v3.021c.589-.702 1.4-.906 2.2-.906 1.34 0 2.829.904 2.829 2.857 0 1.635-1.184 2.825-2.851 2.825-.873 0-1.694-.313-2.2-.933 0 .248-.015.498-.042.734h-1.18c.02-.382.041-.857.041-1.242V7.5Zm3.085 6.975c1.117 0 1.882-.804 1.882-2.018 0-1.214-.765-2.02-1.882-2.02-1.112 0-1.883.806-1.883 2.02s.771 2.018 1.883 2.018Z"
      clipRule="evenodd"
    />
    <path
      fill="#76BA43"
      fillRule="evenodd"
      d="m24 9.815-3.719 7.304h-1.346l1.069-2.028-2.799-5.276h1.407l2.06 4.122 2.053-4.122H24Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
