import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#fff" />
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M43.984 28.497a5.499 5.499 0 1 0 0-10.998 5.499 5.499 0 0 0 0 10.998Zm-.657-6.156v-1.528a.657.657 0 0 1 1.315 0v1.528h1.527a.657.657 0 0 1 0 1.314h-1.527v1.528a.657.657 0 1 1-1.315 0v-1.528H41.8a.657.657 0 0 1 0-1.314h1.528Z"
      clipRule="evenodd"
    />
    <path
      fill={lightTheme.colors.blackGray}
      stroke={lightTheme.colors.blackGray}
      strokeWidth={2}
      d="m42.68 34.568-3.82-1.994c-.813-.424-1.22-.636-1.649-.728a3.203 3.203 0 0 0-1.155-.033c-.433.067-.852.256-1.69.635a1615.663 1615.663 0 0 1-12.962 5.811 1.027 1.027 0 0 0-.606.94c0 1.134.904 2.053 2.021 2.053H39.36c.208 0 .312 0 .4-.003 2.255-.093 4.062-1.928 4.154-4.217.003-.09.003-.195.003-.406v-.131a2.205 2.205 0 0 0-1.122-1.866l-.115-.061Z"
    />
    <path
      fill={lightTheme.colors.blackGray}
      d="M26.583 31.455c1.496 0 2.708-1.232 2.708-2.75 0-1.52-1.212-2.75-2.708-2.75-1.496 0-2.708 1.23-2.708 2.75 0 1.518 1.212 2.75 2.708 2.75Z"
    />
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M44.916 30.198V39.7c0 1.753-1.403 3.2-3.167 3.2h-19.5c-1.763 0-3.166-1.447-3.166-3.2V24.3c0-1.753 1.403-3.2 3.166-3.2h14.726a7.269 7.269 0 0 0-.25 2H22.25c-.63 0-1.166.523-1.166 1.2v15.4c0 .677.536 1.2 1.166 1.2h19.5c.63 0 1.167-.523 1.167-1.2v-9.52a7.32 7.32 0 0 0 2 .018Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
