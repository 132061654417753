import { useEditorContext } from '@/scenes/engine'
import { Button, SHAPE, KIND, SIZE } from 'baseui/button'
import Icons from '../../../Icons'

function CopyStyle() {
  const editor = useEditorContext().editor
  return (
    <Button onClick={() => 
      // editor.handlers.objectsHandler.copyStyle()
      console.log("TODO: CopyStyle copyStyle")
      } 
      size={SIZE.default} kind={KIND.tertiary} shape={SHAPE.square}>
      <Icons.CopyStyle size={24} />
    </Button>
  )
}

export default CopyStyle
