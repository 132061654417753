import { IStorePack } from '@/interfaces/editor'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '@services/api'
import { AxiosError } from 'axios'

export const setBackgroundPacks = createAction<IStorePack[]>('backgroundPacks/setBackgroundPacks')

export const getBackgroundPacks = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  'backgroundPacks/getBackgroundPacks',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const backgroundPacks = await api.getBackgroundPacks()

      dispatch(setBackgroundPacks(backgroundPacks))
    } catch (err) {
      return rejectWithValue(((err as AxiosError).response?.data as any)?.error.data || null)
    }
  }
)
