import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.019 7.713 1.098-2.867 2.118 2.867H18.02Zm2.983 1.846-6.481 7.296 2.794-7.296h3.687Zm-4.617-2.806-2.414-2.908h3.527l-1.113 2.908Zm-7.157.96 2.764-3.338 2.773 3.338H9.228ZM6.686 9.559l2.792 7.295-6.48-7.295h3.688Zm-.183-5.714h3.526L7.616 6.753 6.503 3.845ZM2.765 7.713l2.119-2.867 1.099 2.867H2.765Zm12.55 1.846L12 18.22l-3.315-8.66h6.629Zm8.58-1.367-4.283-5.817a.961.961 0 0 0-.634-.367L5.149 2h-.01a.985.985 0 0 0-.688.299L.17 8.089a.942.942 0 0 0-.017 1.056l11.143 12.562a.936.936 0 0 0 .699.293c.218 0 .438-.067.617-.206l11.16-12.552a.946.946 0 0 0 .122-1.05Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
