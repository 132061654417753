import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.016 24C15.32 24 18 21.404 18 18.18c0-1.648-.69-3.095-1.991-4.265-.23-.202-.284-.33-.284-.628l.01-9.319C15.736 1.585 14.091 0 12.017 0S8.253 1.585 8.253 3.968l.022 9.32c0 .297-.054.425-.284.627C6.678 15.075 6 16.532 6 18.18 6 21.404 8.68 24 12.016 24Zm0-1.903c-2.275 0-4.102-1.778-4.102-3.969 0-1.344.657-2.508 1.805-3.26.34-.211.47-.412.47-.835V4.084c0-1.322.733-2.17 1.827-2.17 1.061 0 1.794.848 1.794 2.17v9.949c0 .423.132.624.47.836 1.15.751 1.806 1.915 1.806 3.26 0 2.19-1.827 3.968-4.07 3.968Zm2.532-3.948a2.547 2.547 0 1 1-5.096 0c0-.979.553-1.798 1.373-2.234.34-.18.446-.308.446-.819V9.66c0-.543.32-.883.724-.883.415 0 .734.34.734.883v5.436c0 .51.106.638.447.82.819.435 1.372 1.255 1.372 2.233Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
