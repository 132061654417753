import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <circle cx={9} cy={9} r={9} fill="#FCD34D" />
    <path
      fill={props.disabled ? lightTheme.colors.grayScale50 : lightTheme.colors.blackGray}
      d="M8.994 5a.85.85 0 0 0-.869.825c.004.31.184.592.465.73.01.07.015.143.013.215 0 1.183-.881 2.14-1.971 2.14-1.028 0-1.872-.86-1.97-1.949a.646.646 0 0 0 .257-.514.69.69 0 0 0-.71-.67.69.69 0 0 0-.709.67.68.68 0 0 0 .477.634c.387 1.671.707 3.369 1.096 5.044a1.55 1.55 0 0 0 1.518 1.19h4.773c.741 0 1.384-.514 1.54-1.239.353-1.64.706-3.355 1.107-4.984a.67.67 0 0 0 .489-.633.706.706 0 0 0-.71-.672.706.706 0 0 0-.71.671c0 .19.085.37.233.49-.086 1.1-.93 1.974-1.97 1.974-1.09 0-1.971-.957-1.971-2.14a1.27 1.27 0 0 1 .012-.216.823.823 0 0 0 .44-.937.836.836 0 0 0-.83-.629Z"
    />
  </svg>
)
export default SvgComponent
