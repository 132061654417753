export function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    // Remove the hash at the start if it’s there
    hex = hex.replace('/^#/', '');
    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return { r, g, b };
}
export function isColorBright(hex: string): boolean {
    const rgb = hexToRgb(hex);
    if (!rgb) {
        throw new Error('Invalid hex color');
    }
    // Calculate brightness (standard formula)
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    // Return true if the color is bright, false if dark
    return brightness > 128;
}