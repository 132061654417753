import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#111"
      d="M18.875 11.084c-.177-1.284-.168-1.26-1.522-1.515a.17.17 0 0 1-.109-.054.163.163 0 0 1-.044-.11.156.156 0 0 1 .042-.112.164.164 0 0 1 .11-.052c1.357-.187 1.35-.23 1.523-1.535a.157.157 0 0 1 .052-.106.165.165 0 0 1 .112-.042c.042 0 .082.016.113.045.03.029.047.068.048.109.163 1.284.199 1.28 1.54 1.53.041.003.08.021.109.052.028.03.044.07.043.11 0 .096-.063.145-.171.163-1.332.212-1.358.223-1.52 1.506a.16.16 0 0 1-.16.165.165.165 0 0 1-.155-.094.156.156 0 0 1-.014-.061l.003.001ZM13.21 9.42c-.177-1.283-.168-1.26-1.522-1.515a.17.17 0 0 1-.109-.053.163.163 0 0 1-.044-.11.157.157 0 0 1 .042-.113.164.164 0 0 1 .11-.051c1.357-.187 1.35-.23 1.522-1.536a.157.157 0 0 1 .052-.105.165.165 0 0 1 .112-.042c.043 0 .083.016.113.044.03.029.048.068.05.11.162 1.284.198 1.279 1.538 1.529.042.003.081.022.11.052a.16.16 0 0 1 .043.111c0 .095-.063.144-.172.163-1.331.212-1.357.223-1.52 1.506a.158.158 0 0 1-.097.15.167.167 0 0 1-.217-.08.156.156 0 0 1-.014-.06h.002Z"
    />
    <path
      fill="#111"
      fillRule="evenodd"
      d="M16.19 13.213a1.385 1.385 0 0 0-1.911-.015l-3.527 3.311-.035.032-.089.083a39.2 39.2 0 0 1-.569.513 3.74 3.74 0 0 1-.133.11c-.02.015-.054.041-.094.067a1.022 1.022 0 0 1-.106.06l-.002.001c-.018.01-.186.096-.411.096-.226 0-.393-.086-.412-.096H8.9a1.02 1.02 0 0 1-.2-.128 3.534 3.534 0 0 1-.133-.11 37.607 37.607 0 0 1-.57-.513l-.089-.083-.027-.025-1.084-.968a.923.923 0 0 0-1.23 0l-3.926 3.51-1.23-1.375 3.925-3.511a2.77 2.77 0 0 1 3.691 0l1.099.98.007.007.032.03.085.079.063.058.062-.058.085-.079.03-.028.001-.001 3.524-3.308a3.23 3.23 0 0 1 4.46.036l5.95 5.767-1.285 1.325-5.95-5.767Z"
      clipRule="evenodd"
    />
    <path
      fill="#111"
      d="M3.227 21.462h17.544c1.09 0 1.9-.266 2.432-.798.531-.532.797-1.33.797-2.393V6.19c0-1.064-.266-1.861-.797-2.393C22.67 3.266 21.86 3 20.77 3H3.227C2.155 3 1.35 3.266.81 3.798c-.54.532-.81 1.33-.81 2.393v12.08c0 1.064.27 1.861.81 2.393.54.532 1.346.798 2.418.798Zm.026-1.836c-.456 0-.809-.123-1.058-.367-.249-.245-.373-.604-.373-1.077V6.28c0-.482.124-.842.373-1.083.25-.24.602-.36 1.058-.36h17.493c.456 0 .808.12 1.057.36.25.24.374.601.374 1.083v11.902c0 .473-.125.832-.374 1.077-.249.244-.601.367-1.057.367H3.253Z"
    />
    <path
      fill="#111"
      d="M6.339 12.13c-.292-2.117-.218-2.117-2.533-2.535a.283.283 0 0 1-.177-.089.27.27 0 0 1-.004-.364.271.271 0 0 1 .18-.085c2.316-.31 2.25-.38 2.534-2.528a.26.26 0 0 1 .082-.186.275.275 0 0 1 .44.092.26.26 0 0 1 .02.101c.266 2.117.304 2.11 2.514 2.52a.276.276 0 0 1 .179.088c.046.05.071.114.07.182 0 .16-.106.243-.284.269-2.192.348-2.214.404-2.482 2.519a.263.263 0 0 1-.082.174.275.275 0 0 1-.367.011.263.263 0 0 1-.093-.17h.003Z"
    />
  </svg>
)
export default SvgComponent
