import {
  Template,
  TemplatesBySuperCategory,
  TemplateConfig,
  TemplateSuperCategory,
  TemplateDetails,
} from '@/interfaces/editor'
import { createReducer } from '@reduxjs/toolkit'
import {
  setTemplateConfig,
  setTemplatesSuperCategories,
  setBackgoundActive,
  setTemplatesBySuperCategory,
  setTemplateId,
  setTemplateDetails
} from './actions'

export interface TemplatesState {
  templatesSuperCategories: TemplateSuperCategory[]
  templatesBySuperCategory: TemplatesBySuperCategory
  templateId: number | null
  templateConfig: TemplateConfig | null
  backgroundActive: {
    id: number
    category: string
  } | null,
  templateDetails: TemplateDetails
}

const initialState: TemplatesState = {
  templatesSuperCategories: [],
  templatesBySuperCategory: null,
  templateId: null,
  templateConfig: null,
  backgroundActive: null,
  templateDetails : null
}

export const templatesReducer = createReducer(initialState, builder => {
  builder.addCase(setTemplatesSuperCategories, (state, { payload }) => {
    state.templatesSuperCategories = payload
  })
  builder.addCase(setTemplatesBySuperCategory, (state, { payload }) => {
    state.templatesBySuperCategory = payload
  })
  builder.addCase(setTemplateId, (state, { payload }) => {
    state.templateId = payload
  })
  builder.addCase(setTemplateConfig, (state, { payload }) => {
    state.templateConfig = payload
  })
  builder.addCase(setBackgoundActive, (state, { payload }) => {
    state.backgroundActive = payload
  })
  builder.addCase(setTemplateDetails, (state, { payload }) => {
    state.templateDetails = payload
  })
})
