import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <rect width={2} height={14} x={16.243} y={6.343} fill="#fff" rx={1} transform="rotate(45 16.243 6.343)" />
    <rect
      width={2}
      height={14}
      x={17.657}
      y={16.243}
      fill="#fff"
      rx={1}
      transform="rotate(135 17.657 16.243)"
    />
  </svg>
)
export default SvgComponent
