import { fabric } from 'fabric'
import { StaticImage } from 'fabric/fabric-impl'
import { ObjectType } from '../common/constants'

export class StaticOverlayObject extends fabric.StaticImage {
  static type = ObjectType.BAZAART_OVERLAY

  initialize(element, options: StaticOverlayOptions) {
    //@ts-ignore
    super.initialize(element, {
      ...options,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      selectable: false,
      hoverCursor: 'default',
      hasBorders: false,
      evented: false,
    })

    return this
  }
  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  static fromObject(options: StaticOverlayOptions, callback: Function) {
    fabric.util.loadImage(
      options.src,
      function (img) {
        //   @ts-ignore
        return callback && callback(new fabric.StaticOverlay(img, options))
      },
      null,
      // @ts-ignore
      { crossOrigin: 'anonymous' }
    )
  }
}

fabric.StaticOverlay = fabric.util.createClass(StaticOverlayObject, {
  type: StaticOverlayObject.type
})
fabric.StaticOverlay.fromObject = StaticOverlayObject.fromObject

export interface StaticOverlayOptions extends fabric.IImageOptions {
  id: string
  name?: string
  description?: string
  subtype: string
  src: string
}

declare module 'fabric' {
  namespace fabric {
    class StaticOverlay extends StaticOverlayObject {
      constructor(options: StaticOverlayOptions)
    }
  }
}
