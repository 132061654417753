import { IStorePack } from '@/interfaces/editor'
import { createReducer } from '@reduxjs/toolkit'
import { setGraphicPacks } from './actions'

export interface GraphicPacksState {
  graphicPacks: IStorePack[]
}

const initialState: GraphicPacksState = {
  graphicPacks: [],
}

export const graphicPacksReducer = createReducer(initialState, builder => {
  builder.addCase(setGraphicPacks, (state, { payload }) => {
    state.graphicPacks = payload
  })
})
