import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill={props.fill ? props.fill : "#fff"}
      d="M12 23c6.018 0 11-4.993 11-11 0-6.018-4.993-11-11.01-11C5.981 1 1 5.982 1 12c0 6.007 4.993 11 11 11Zm0-1.833A9.122 9.122 0 0 1 2.844 12c0-5.09 4.055-9.167 9.145-9.167A9.14 9.14 0 0 1 21.167 12 9.132 9.132 0 0 1 12 21.167Zm-4.94-3.7h9.858c.431 0 .636-.29.636-.679 0-1.186-1.78-4.249-5.565-4.249s-5.564 3.063-5.564 4.25c0 .387.204.679.636.679Zm4.93-5.801c1.53.01 2.77-1.305 2.77-3.03 0-1.618-1.24-2.956-2.77-2.956-1.532 0-2.772 1.338-2.772 2.955 0 1.726 1.24 3.02 2.771 3.03Z"
    />
  </svg>
)
export default SvgComponent
