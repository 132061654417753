import { useContext } from 'react'
import { RemoveEditorContext } from '../context'

export function useRemoveEditorContext() {
  const { setCanvas, canvas, activeObject, setActiveObject, zoomRatio, editor } = useContext(RemoveEditorContext)

  return {
    setCanvas,
    canvas,
    activeObject,
    setActiveObject,
    zoomRatio,
    editor
  }
}
