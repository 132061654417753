import { FC, createContext, useState } from 'react'
import { fabric } from 'fabric'
import RemoveEditorEventManager from '../RemoveEditorEventManager'

export interface IRemoveEditorContext {
  canvas: fabric.Canvas | null
  setCanvas: (canvas: fabric.Canvas) => void
  activeObject: fabric.Object | null
  setActiveObject: (object: fabric.Object | null) => void
  editor: RemoveEditorEventManager | null
  setEditor: (handlers: RemoveEditorEventManager) => void
  zoomRatio: number
  setZoomRatio: (value: number) => void
}

export const RemoveEditorContext = createContext<IRemoveEditorContext>({
  canvas: null,
  setCanvas: () => {},
  activeObject: null,
  setActiveObject: () => {},
  editor: null,
  setEditor: () => {},
  zoomRatio: 1,
  setZoomRatio: () => {}
})

export const RemoveEditorProvider: FC = ({ children }) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)
  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null)
  const [editor, setEditor] = useState<RemoveEditorEventManager | null>(null)
  const [zoomRatio, setZoomRatio] = useState(1)

  const context = {
    canvas,
    setCanvas,
    activeObject,
    setActiveObject,
    editor,
    setEditor,
    zoomRatio,
    setZoomRatio
  }

  return <RemoveEditorContext.Provider value={context}>{children}</RemoveEditorContext.Provider>
}
