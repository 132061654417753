import RemoveEditorEventManager from '../../RemoveEditorEventManager'
import { FabricCanvas, RemoveHandlerOptions } from '../../common/interfaces'
import { IEditorContext, IRemoveEditorContext } from '../../context'
import Handlers from '../Handlers'

import RemoveHandlers from './RemoveHandlers'

class RemoveBaseHandler {
  public canvas: FabricCanvas
  public root: RemoveHandlers
  public mainContext: IEditorContext
  public context: IRemoveEditorContext
  protected editorEventManager: RemoveEditorEventManager
  constructor({ canvas, root, context, editorEventManager, mainContext }: RemoveHandlerOptions) {
    this.canvas = canvas
    this.root = root as any
    this.mainContext = mainContext
    this.context = context
    this.editorEventManager = editorEventManager
  }
}
export default RemoveBaseHandler
