import {
  Template,
  TemplatesBySuperCategory,
  TemplateConfig,
  TemplateSuperCategory,
  TemplateDetails,
} from '@/interfaces/editor'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '@services/api'
import { AxiosError } from 'axios'

export const setTemplatesSuperCategories = createAction<TemplateSuperCategory[]>(
  'templates/setTemplatesSuperCategories'
)
export const setTemplatesBySuperCategory = createAction<TemplatesBySuperCategory>(
  'templates/setTemplateByCategory'
)
export const setTemplateDetails = createAction<TemplateDetails>('templates/setTemplateDetails')
export const setTemplateId = createAction<number>('templates/setTemplateId')
export const setTemplateConfig = createAction<TemplateConfig>('templates/setTemplateConfig')
export const setBackgoundActive = createAction<{ id: number; category: string }>(
  'templates/setBackgoundActive'
)

export const getTemplatesSuperCategories = createAsyncThunk<
  void,
  never,
  { rejectValue: Record<string, string[]> }
>('templates/getTemplatesSuperCategories', async (_, { rejectWithValue, dispatch }) => {
  try {
    const templatesSuperCategories = await api.getTemplatesSuperCategories()
    dispatch(setTemplatesSuperCategories(templatesSuperCategories))
  } catch (err) {
    return rejectWithValue(((err as AxiosError).response?.data as any)?.error.data || null)
  }
})

export const getTemplateDetails = createAsyncThunk<number, string, { rejectValue: Record<string, string[]> }>(
  'templates/getTemplateDetails',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const templateDetails = await api.getTemplateDetails(id)

      dispatch(setTemplateDetails(templateDetails))
    } catch (err) {
      return rejectWithValue(((err as AxiosError).response?.data as any)?.error.data || null)
    }
  }
)
