import React from 'react'

function Close({ size, fill = null }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="16.2427"
        y="6.34277"
        width="2"
        height="14"
        rx="1"
        transform="rotate(45 16.2427 6.34277)"
        fill={fill ? fill : '#666666'}
      />
      <rect
        x="17.6567"
        y="16.2432"
        width="2"
        height="14"
        rx="1"
        transform="rotate(135 17.6567 16.2432)"
        fill={fill ? fill : '#666666'}
      />
    </svg>
  )
}

export default Close
