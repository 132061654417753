import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      d="M11.607 15.58h2.213v2.222c0 .246.073.455.21.637l.045.055.046.046c.164.154.382.227.637.227a.933.933 0 0 0 .965-.965V15.58h2.214c.282 0 .519-.091.692-.264.182-.174.264-.401.264-.684 0-.282-.091-.519-.264-.692-.173-.182-.41-.264-.692-.264h-2.213v-2.213a.954.954 0 0 0-.274-.701.933.933 0 0 0-.692-.273c-.255 0-.473.073-.637.227l-.046.046-.045.055a1.02 1.02 0 0 0-.21.646v2.213h-2.213c-.291 0-.528.091-.701.264a.933.933 0 0 0-.273.693c0 .282.09.5.273.683a.985.985 0 0 0 .701.264Z"
    />
    <path
      fill="#666"
      d="M21.29 8.016c-.476-.467-1.203-.71-2.145-.71h-2.601v-1.54c0-.933-.243-1.642-.718-2.118-.476-.467-1.203-.71-2.146-.71L4.844 2.93c-.942 0-1.67.242-2.145.709-.476.467-.719 1.185-.719 2.118v8.502c0 .933 0 1.78.75 2.516.476.467 1.203.709 2.146.709h2.608l-.031 1.584c0 .934.242 1.643.718 2.12.476.466 1.203.708 2.146.708h8.819c.951 0 1.67-.242 2.145-.709.476-.467.718-1.185.718-2.119v-8.942c0-.934-.242-1.643-.718-2.119l.009.009Zm-6.56-.718-4.414.009c-.942 0-1.67.242-2.145.709-.476.467-.718 1.185-.718 2.119l.031 5.545H4.903c-.35 0-.55-.054-.8-.27-.371-.323-.301-.641-.31-1.223V5.838c0-.368.09-.646.28-.826.179-.18.448-.269.798-.269l8.782.009c.35 0 .61.09.8.27.179.179.277.457.277.825v1.45Zm-5.464 2.917c0-.368.09-.646.278-.825.18-.18.45-.27.8-.27h8.774c.35 0 .619.09.799.27.179.18.278.457.278.825v8.764c0 .368-.09.646-.279.826-.18.18-.448.269-.799.269h-8.774c-.35 0-.619-.09-.799-.27-.18-.179-.278-.457-.278-.825v-8.764Z"
    />
  </svg>
)
export default SvgComponent
