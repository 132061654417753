import { useState, useRef, useEffect, useCallback } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { useSelector } from 'react-redux'
import { selectGraphicPacks } from '@/store/slices/graphicsPacks/selectors'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import AutoScroll from '@/components/AutoScroll'
import Search from '../../Toolbox/ToolboxItems/components/Search'
import { ObjectType } from '@/scenes/engine/common/constants'
import Gallery from '@/components/Gallery'
import ApiService from '@services/api'
import { debounce } from 'lodash'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { nanoid } from 'nanoid'
import useAppContext from '@/hooks/useAppContext'
import { PackItem } from '@/components/Gallery/Gallery'
import { Size } from '@/scenes/engine/objects/media-repository/size'
import { loadImageFromURL } from '@/scenes/engine/utils/image-loader'
import Loading from '@/components/Loading'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import HeadingInspector from '@/components/HeadingInspector'
import { PopoverType } from '@/constants/app-options'

function Graphics({ close, isOpen, idFromDeeplink }) {
  const editor = useEditorContext().editor
  const graphicPacks = useSelector(selectGraphicPacks)

  const { objDragging, setObjDragging, popoverActive } = useAppContext()

  const [isBoxShadow, setIsBoxShadow] = useState(false)
  // result search
  const [resultSearch, setResultSearch] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [searchValue, setSearchValue] = useState(null)

  const listSectionRef = useRef(null)

  //
  const [defaultSection, setDefaultSection] = useState(5)
  const hasPremium = useSelector(selectUserIsPremium)
  const searchAbortController = useRef(null);

  useEffect(() => {
    // @ts-ignore
    let sectionHeight = window.innerHeight - 96
    setDefaultSection(Math.ceil(sectionHeight / 235) > 5 ? Math.ceil(sectionHeight / 235) : 5)
  }, [])

  const addImageToCanvas = async graphicItem => {
    let guid = nanoid()
    let assetStateId = nanoid()
    let latestImage = getResizeUrl({ size: '1280x1280', url: graphicItem.image.slice(8) })
    let lowQualityImage = graphicItem.thumbnail ? graphicItem.thumbnail : getResizeUrl({ size: '0x32', url: graphicItem.image.slice(8) })
    await MediaImageRepository.getInstance().storeImageUrl(
      guid,
      assetStateId,
      MediaImageType.thumbnail,
      lowQualityImage
    )

    let image = await loadImageFromURL(lowQualityImage)
    // @ts-ignore
    let layerSize = new Size(image.width, image.height)
    let frame = editor.handlers.frameHandler.get()
    let canvasAspectRatio = frame.width / frame.height
    let layerAspectRatio = layerSize.width / layerSize.height
    let width = 0.35

    if (layerAspectRatio < canvasAspectRatio) {
      width = (width * layerAspectRatio) / canvasAspectRatio
    }

    const object = {
      type: ObjectType.BAZAART_STICKER,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: width,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
    }
    editor.handlers.objectsHandler.add(object).then(async (object_added)=>{
    let imageProcessing = new MediaImageRepositoryProcessing()

    // const imageData = await imageProcessing.convertImageToBase64(graphicItem.image)
    let resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(graphicItem.image, 1280)

    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(resizedImage)

      MediaImageRepository.getInstance().storeImageUrl(
        guid,
        assetStateId,
        MediaImageType.latest,
        latestImage
      )
      MediaImageRepository.getInstance().storeImageUrl(
        guid,
        assetStateId,
        MediaImageType.original,
        latestImage
      )
      await MediaImageRepository.getInstance().storeImageBase64(
        guid,
        assetStateId,
        MediaImageType.mask,
        maskInfo.blob
      )
      editor.handlers.objectsHandler.replaceImageSource(latestImage, object_added)
    })
    
    close()
  }
  const handleSearch = async value => {
    if (!value) {
      setResultSearch(null)
      setIsSearching(false)
      return;
    }
    setIsSearching(true)

    if (searchAbortController.current) {
      searchAbortController.current.abort()
    }

    searchAbortController.current = new AbortController()
    const { signal } = searchAbortController.current

    try {
      const data = await ApiService.searchStickers(value, signal);
      setResultSearch(data)
      
      const eventProperties = {
        Query: value,
        results_count: data.length,
      }
      if(value && value.length > 1) {
        customAmplitude('Graphics Search', eventProperties)
      }

    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.error("Error during search:", error);
        setResultSearch([]);
      }
    } finally {
      setIsSearching(false);
    }
  }

  const debounceHandleSearch = useCallback(debounce((value) => {
    handleSearch(value)
  }, 300), [])

  const handleReset = () => {
    if (searchAbortController.current) {
      searchAbortController.current.abort()
    }
    setResultSearch(null);
    handleSearch('')
    setSearchValue('')
  }

  const { t } = useTranslation()

  const [idByDeepLink, setIdByDeepLink] = useState(null)
  
  useEffect(() => {
    if(popoverActive === PopoverType.GRAPHICS && idFromDeeplink) {
      setIdByDeepLink(idFromDeeplink)
    }
  }, [isOpen, idFromDeeplink])

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="popover-graphic"
        style={{
          overflow: 'hidden',
          boxSizing: 'border-box',
          position: 'fixed',
          top: 'calc(-50vh + 48px + 180px)',
          left: '80px',
          background: '#ffffff',
          width: '340px',
          height: 'calc(100vh - 96px)',
          flex: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
        }}
      >
        <div style={{ boxShadow: isBoxShadow ? '0px 0px 6px rgba(0, 0, 0, 0.1)' : 'none' }}>
          <HeadingInspector
            hasBoxShadow={false}
            hasNavigation={false}
            title={t('Graphics')}
            hasClose={true}
            handleClose={() => close()}
          ></HeadingInspector>
          <div
            style={{
              padding: '0 24px 12px',
              width: '100%',
              boxSizing: 'border-box',
              background: '#fff',
              zIndex: 1000,
            }}
          >
            <Search
              handleValueChange={debounceHandleSearch}
              size="standard"
              valueExternal={searchValue}
              setValueExternal={setSearchValue}
              handleReset={() => handleReset()}
            />
          </div>
        </div>
        <AutoScroll
          style={{ position: 'relative' }}
          ref={listSectionRef}
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
            if (
              Math.round(listSectionRef.current.scrollTop) + listSectionRef.current.clientHeight >=
              listSectionRef.current.scrollHeight - 1 && Math.round(listSectionRef.current.scrollTop) + listSectionRef.current.clientHeight <=
              listSectionRef.current.scrollHeight + 1
            ) {
              setDefaultSection(pre => pre + 10)
            }
          }}
        >
          {!resultSearch &&
            !isSearching &&
            graphicPacks.map((b, index) => {
              if (index < defaultSection) {
                return (
                  <div key={index} className="section-graphics">
                    <Gallery
                      pack={graphicPacks[index]}
                      onItemSelected={addImageToCanvas}
                      style={{ marginTop: index === 0 ? '8px' : '32px' }}
                      closePopover={close}
                      // @ts-ignore
                      isFree={graphicPacks[index].is_free}
                      triggerSubAction={Number(graphicPacks[index].id) === Number(idByDeepLink)}
                    />
                  </div>
                )
              }
            })}
          {/* display result search */}
          {resultSearch && resultSearch.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                columnGap: '8px',
                rowGap: 0,
                marginTop: '8px',
              }}
            >
              {resultSearch.map((graphicItem, index) => {
                return (
                  <PackItem
                    key={graphicItem.thumbnail}
                    style={{
                      wrap: {
                        width: '67px',
                        height: '67px',
                        padding: '8px 8px 9px 9px',
                      },
                    }}
                    pack={graphicItem}
                    onClick={() => addImageToCanvas(graphicItem)}
                    onDrag={() => {
                      if (!objDragging.item || objDragging.item.image !== graphicItem.image) {
                        setObjDragging({
                          item: graphicItem,
                          type: ObjectType.BAZAART_STICKER,
                        })
                      }
                      !!close && close()
                    }}
                    isLocked={!hasPremium && index >= 5}
                    CTA="BtSubscriptionStickers"
                  ></PackItem>
                )
              })}
            </div>
          )}

          {resultSearch && resultSearch.length === 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                flexDirection: 'column',
              }}
            >
              <p style={{ ...lightTheme.typography.LabelLarge, color: lightTheme.colors.blackGray, margin: 0 }}>
                {t('No results')}
              </p>
              <p
                style={{
                  ...lightTheme.typography.Small12regular,
                  color: 'rgba(153, 153, 153, 1)',
                  margin: 0,
                }}
              >
                {t('for \"%@\"', { arg:searchValue})}
              </p>
            </div>
          )}

          {isSearching ? (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-150%)',
                zIndex: 1000,
                paddingLeft: '12px',
              }}
            >
              <Loading />
            </div>
          ) : null}
        </AutoScroll>
      </div>
    </ThemeProvider>
  )
}

export default Graphics
