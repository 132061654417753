import AutoScroll from '@/components/AutoScroll'
import GridCustom from '@/components/GridCustom'
import HeadingInspector from '@/components/HeadingInspector'
import { lightTheme } from '@/customTheme'
import { ThemeProvider } from 'baseui'
import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { fabric } from 'fabric'
import { loadImageFromURL } from '@/scenes/engine/utils/image-loader'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { useSelector } from 'react-redux'
import { selectUserIsPremium } from '@/store/slices/user/selectors'

const LIST = [
  {
    id: '1_Normal',
    description: 'Normal',
    name: 'Normal',
    // display_image: '',
  },
  {
    id: '2_Chrome',
    description: 'Chrome',
    name: 'CIPhotoEffectChrome',
    // display_image: '',
  },
  {
    id: '3_Fade',
    description: 'Fade',
    name: 'CIPhotoEffectFade',
    // display_image: '',
  },
  {
    id: '4_Transfer',
    description: 'Transfer',
    name: 'CIPhotoEffectTransfer',
    // display_image: '',
  },
  {
    id: '5_Process',
    description: 'Process',
    name: 'CIPhotoEffectProcess',
    // display_image: '',
  },
  {
    id: '6_Instant',
    description: 'Instant',
    name: 'CIPhotoEffectInstant',
    // display_image: '',
  },
  {
    id: '7_Mono',
    description: 'Mono',
    name: 'CIPhotoEffectMono',
    // display_image: '',
  },
  {
    id: '8_Tonal',
    description: 'Tonal',
    name: 'CIPhotoEffectTonal',
    // display_image: '',
  },
  {
    id: '9_Noir',
    description: 'Noir',
    name: 'CIPhotoEffectNoir',
    // display_image: '',
  },
]

function Blending({ fitlerActiveId, setFilterActiveId, filterObject = null }) {
  const [isOpenFull, setIsOpenFull] = useState(false)
  const [indexActive, setIndexActive] = useState()
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const [blendingItems, setBlendingItems] = useState([])
  const activeObject = useEditorContext().activeObject as any
  const { canvas, editor } = useEditorContext()
  const filterPacks = useSelector(selectImageElements)
  const [preValue, setPreValue] = useState(1)
  const [preFilter, setPreFilter] = useState('')
  const [preFilters, setPreFilters] = useState([])
  // const [filterObject, setFilterObject] = useState(null)

  // useEffect(() => {
  //   setFilterObject(background ? background : activeObject)
  // }, [background, activeObject])

  useEffect(() => {
    if (!filterObject) {
      return
    }
    setPreValue(filterObject.filterIntensity ?? 1)
    setPreFilters(filterObject.filters ?? [])
    setPreFilter(filterObject.filter ?? '')
    setFilterActiveId(filterObject.filter ?? null)
    getImageWithFilter()
  }, [filterObject])

  const handleSelectItem = async (filter, isIntensity = false, isFinal = false) => {
    const isDifferentFilter = !isIntensity && filterObject.filter !== filter.name
    if (isDifferentFilter) {
      filterObject.filterIntensity = 1
      filter.filterIntensity = 1
    }
    const isItensityChanged = !!filter.filterIntensity && preValue !== filter.filterIntensity
    if (!isDifferentFilter && !isItensityChanged) {
      return
    }
    let sliderOptions: any = { isSliderUpdate: isFinal }
    if (isFinal) {
      sliderOptions = {
        isSliderUpdate: isFinal,
        property: ['filterIntensity', 'filter', 'filters'],
        value: [preValue, preFilter, preFilters],
      }
      setPreValue(filterObject.filterIntensity)
      setPreFilters(filterObject.filters)
      setPreFilter(filterObject.filter)
    }
    editor.handlers.objectsHandler.updateActive(
      { filterIntensity: filterObject.filterIntensity },
      filterObject,
      sliderOptions
    )
    filterObject.filters = []
    filterObject.filter = `${filter.name}`
    filterObject.filterIntensity = filter.filterIntensity
    await editor.canvasImageRenderer.render(filterObject, filterPacks)
    if (!isIntensity && isFinal) {
      setPreFilters(filterObject.filters)
      setPreFilter(filterObject.filter)
    }
  }

  const getImageWithFilter = () => {
    // const staticCanvas = new fabric.StaticCanvas(null, { width: 200, height: 200 })
    // let latestImageUrl = filterObject._originalElement?.currentSrc
    //   ? filterObject._originalElement.currentSrc
    //   : await MediaImageRepository.getInstance().getImage(
    //       filterObject.id,
    //       filterObject.layerAssetStateId,
    //       MediaImageType.thumbnail
    //     )
    // if (!latestImageUrl) {
    //   return
    // }
    // const img: any = await loadImageFromURL(latestImageUrl)
    // let scaleFactor = Math.max(200 / img.width, 200 / img.height)

    let res = LIST.map(filter => {
      // const fabricImage: any = new fabric.StaticImage(img, {
      //   scaleX: scaleFactor,
      //   scaleY: scaleFactor,
      // })
      // fabricImage.useTextureOf(filterObject)
      // fabricImage.filter = filter.name
      // fabricImage.filterIntensity = 1.0
      // await editor.canvasImageRenderer.render(fabricImage, filterPacks)
      // staticCanvas.add(fabricImage)
      // fabricImage.center()
      // const filteredImage = staticCanvas.toDataURL({ format: 'png', quality: 0.3 })
      return {
        ...filter,
        filterPacks: filterPacks,
        // display_image: filteredImage,
        filterName: filter.name,
        filterIntensity:
          filterObject.filter === filter.name && filterObject.filterIntensity
            ? filterObject.filterIntensity
            : 1,
      }
    })
    setBlendingItems(res)
  }

  return (
    <>
      {!isOpenFull ? (
        <GridCustom
          itemsPerLine={4}
          name="Basic"
          handleSelectItem={handleSelectItem}
          listItem={blendingItems}
          style={{ marginTop: 0 }}
          seeAll={true}
          activeIdExternal={fitlerActiveId}
          setActiveIdExternal={setFilterActiveId}
          filterObject={filterObject}
          hasPremium={true}
        ></GridCustom>
      ) : null}
      {isOpenFull && (
        <ThemeProvider theme={lightTheme}>
          <div
            style={{
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              padding: '0px 8px 0px 0px',
              width: isOpenFull ? '340px' : '0',
              borderRadius: '12px',
              overflow: 'hidden',
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              right: '0px',
              background: '#fff',
              zIndex: 1,
              transition: 'width .4s ease-in',
            }}
          >
            <HeadingInspector
              hasBoxShadow={isBoxShadow}
              hasNavigation={true}
              handleNavigation={() => setIsOpenFull(false)}
              title="Blending"
            ></HeadingInspector>
            <AutoScroll
              handleScroll={e => {
                if (e.target.scrollTop > 0) {
                  setIsBoxShadow(true)
                } else {
                  setIsBoxShadow(false)
                }
              }}
            >
              {blendingItems && (
                <GridCustom
                  itemsPerLine={4}
                  name="Basic"
                  handleSelectItem={handleSelectItem}
                  listItem={blendingItems}
                  seeAll={true}
                  style={{ marginTop: 0 }}
                  activeIdExternal={indexActive}
                  setActiveIdExternal={setIndexActive}
                  filterObject={filterObject}
                />
              )}
            </AutoScroll>
          </div>
        </ThemeProvider>
      )}
    </>
  )
}

export default Blending
