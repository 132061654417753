export class Size {
    width: number;
    height: number

    constructor(width: number, height: number) {
        this.width = width;
        this.height= height;
    }

    multiply(scalar: number): Size {
        let newSize = new Size(Math.ceil(this.width * scalar), Math.ceil(this.height * scalar));
        return newSize;
    }
}
