function Resize({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V6Z"
        stroke="#666666"
        strokeWidth="2"
      />
      <path
        d="M2 8H16C17.1046 8 18 8.89543 18 10V19H5C3.34315 19 2 17.6569 2 16V8Z"
        stroke="#666666"
        strokeWidth="2"
      />
      <path d="M2 8H13V19H5C3.34315 19 2 17.6569 2 16V8Z" stroke="#666666" strokeWidth="2" />
    </svg>
  )
}

export default Resize
