import { PixelManipulationObjectHandler } from './PixelManipulationObjectHandler'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { loadImageFromURL } from '@scenes/engine/utils/image-loader'
import { MediaImageRepositoryProcessing } from '@scenes/engine/objects/media-repository/media_image_repository_processing'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { fabric } from 'fabric'
import { ObjectType } from '@scenes/engine/common/constants'

class EraserObjectHandler extends PixelManipulationObjectHandler {
  originalWidth: number
  originalHeight: number
  background: any
  maxScrollY = 0
  maxScrollX = 0
  maxScrollDown = 0
  maxScrollRight = 0

  async setupImage() {
    if (!this.activeObject) return

    let originalInBase64 = await MediaImageRepository.getInstance().getImage(this.activeObject.bazaartGuid, this.activeObject.layerAssetStateId, MediaImageType.original)
    let maskInBase64 = await MediaImageRepository.getInstance().getImage(this.activeObject.bazaartGuid, this.activeObject.layerAssetStateId, MediaImageType.mask)

    let processing = new MediaImageRepositoryProcessing()
    let mask = await processing.loadImage(maskInBase64)
    let original = await processing.loadImage(originalInBase64)
    let masked = await processing.applyMaskToImage(original, mask)

    this.img = await loadImageFromURL(masked)

    const containerElement = document.querySelector('#wrap-canvas-remove-tool .remove-container-class') as HTMLElement
    containerElement.style.transformOrigin = 'center center'
  }

  private adjustRectToBoundingBox(): Rectangle {
    let bb = this.activeObject.boundingBox
    bb = bb ? bb : { x: 1, y: 1, width: 1, height: 1 }
    let scaleX = 1 / bb.width
    let scaleY = 1 / bb.height

    let rectWithBB = new Rectangle(this.startX - bb.x * this.startWidth * scaleX, this.startY - bb.y * this.startHeight * scaleY, this.startWidth * scaleX, this.startHeight * scaleY)

    return rectWithBB
  }

  addImageToCanvas = async () => {
    const fabricImage = new fabric.StaticImage(this.img, {
      type: ObjectType.STATIC_IMAGE,
      selectable: false,
      hasControls: false,
      evented: false,
      opacity: 1,
    }) as any
    this.canvas.add(fabricImage)
  }

  public setInitialPosition() {
    if (!this.activeObject) {
      return
    }
    this.activeObject.visible = false
    this.activeObject.hasBorders = false

    this.updatePositionFollowMainCanvas()
    this.activeObject.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
      mtr: false,
    })
    this.activeObject.canvas.renderAll()
    
    let brush = this.canvas.freeDrawingBrush as any
    brush.rotationRadians = (this.startDegree * Math.PI) / 180

    setTimeout(async () => {
      let processing = new MediaImageRepositoryProcessing()
      let originalInBase64 = await MediaImageRepository.getInstance().getImage(this.activeObject.bazaartGuid, this.activeObject.layerAssetStateId, MediaImageType.original)
      let original = await processing.loadImage(originalInBase64)
      brush.setupCanvasForRestore(original)
    })
  }

  updatePositionFollowMainCanvas = () => {
    this.setupInitialPosition()
    this.setLayoutSize()
    this.setBackgroundContainer()
    this.setCanvasContainerSize()
    this.updateScrollBarPosition()
  }

  setLayoutSize = () => {
    let wrapCanvasRemoveZoomTool = document.getElementById('wrap-canvas-remove-zoom-tool')
    wrapCanvasRemoveZoomTool.style.width = `${this.mainContext.editor.handlers.scrollbarHandler.scrollWidth}px`
    wrapCanvasRemoveZoomTool.style.height = `${this.mainContext.editor.handlers.scrollbarHandler.scrollHeight}px`
    wrapCanvasRemoveZoomTool.style.transformOrigin = 'top left'

    // -------------------------------------------
    let wrapCanvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
    wrapCanvasRemoveTool.style.background = '#F2F2F2'
  }

  setBackgroundContainer = () => {
    // Pink background
    const containerElement = document.querySelector('#wrap-canvas-remove-bg') as HTMLElement
    const eraserBackgroundElement = document.getElementById('eraser-background') as HTMLImageElement
    let wrapCanvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')

    const frame = this.mainContext.editor.handlers.frameHandler.get()
    const canvasCenterElement = frame.getBoundingRect()
    containerElement.style.transition = 'none'
    containerElement.style.transform = 'none'
    containerElement.style.overflow = 'hidden'

    containerElement.style.borderRadius = `${15 * this.mainContext.canvas.getZoom() / this.mainContext.editor.handlers.frameHandler.getFitRatio()}px`
    containerElement.style.transition = 'background .8s'
    containerElement.style.background = 'rgba(255, 5, 5, 0.6)'
    const isScrollXVisible = this.mainContext.editor.handlers.scrollbarHandler.isScrollXVisible(true)
    const isScrollYVisible = this.mainContext.editor.handlers.scrollbarHandler.isScrollYVisible(true)

    const vt = this.mainContext.canvas.viewportTransform
    // the boundary left and top viewport point
    const maxScrollLeft = isScrollXVisible ? this.mainContext.editor.handlers.scrollbarHandler.getMaxScrollLeft() : vt[4]
    const maxScrollTop = isScrollYVisible ? this.mainContext.editor.handlers.scrollbarHandler.getMaxScrollTop() : vt[5]

    // calc viewport top and left of frame
    const frameViewportTop = -frame.top * vt[0]
    const frameViewportLeft = -frame.left * vt[0]

    // calc the ratio from the boundary left and top to the frame viewport left and top
    const percentleft = (maxScrollLeft - frameViewportLeft) / (isScrollXVisible ? this.mainContext.editor.handlers.scrollbarHandler.scrollWidth : wrapCanvasRemoveTool.offsetWidth)
    const percentTop = (maxScrollTop - frameViewportTop) / (isScrollYVisible ? this.mainContext.editor.handlers.scrollbarHandler.scrollHeight : wrapCanvasRemoveTool.offsetHeight)

    // calc the left and top position base on the ratio
    const left = isScrollXVisible ? this.mainContext.editor.handlers.scrollbarHandler.scrollWidth * percentleft : wrapCanvasRemoveTool.offsetWidth * percentleft
    const top = isScrollYVisible ? this.mainContext.editor.handlers.scrollbarHandler.scrollHeight * percentTop : wrapCanvasRemoveTool.offsetHeight * percentTop

    containerElement.style.top = `${top}px`
    containerElement.style.left = `${left}px`
    containerElement.style.width = `${canvasCenterElement.width}px`
    containerElement.style.height = `${canvasCenterElement.height}px`

    // Eraser background
    eraserBackgroundElement.style.top = `${top}px`
    eraserBackgroundElement.style.left = `${left}px`
    eraserBackgroundElement.style.width = `${canvasCenterElement.width}px`
    eraserBackgroundElement.style.height = `${canvasCenterElement.height}px`
  }

  setCanvasContainerSize = () => {
    const transformTool = document.querySelector('#wrap-canvas-remove-transform-rotate') as HTMLElement
    const frame = this.mainContext.editor.handlers.frameHandler.get()
    const canvasCenterElement = frame.getBoundingRect()
    let rectWithBB = this.adjustRectToBoundingBox()
    const rangeTop = this.startY - canvasCenterElement.top
    const rangeLeft = this.startX - canvasCenterElement.left

    transformTool.style.top = `${rangeTop}px`
    transformTool.style.left = `${rangeLeft}px`

    transformTool.style.width = `${this.startWidth}px`
    transformTool.style.height = `${this.startHeight}px`

    transformTool.style.transformOrigin = 'center center'
    transformTool.style.transform = `rotate(${this.startDegree}deg)`

    this.canvas.setWidth(rectWithBB.width).setHeight(rectWithBB.height)
    const elementInsideRotation = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    let offsetX = rectWithBB.x - this.startX
    let offsetY = rectWithBB.y - this.startY
    elementInsideRotation.style.left = `${offsetX}px`
    elementInsideRotation.style.top = `${offsetY}px`
    // @ts-ignore
    let lowerCanvas = this.canvas.contextContainer.canvas

    this.originalWidth = lowerCanvas.width
    this.originalHeight = lowerCanvas.height

    lowerCanvas.style.width = '100%'
    lowerCanvas.style.height = '100%'

    lowerCanvas.setAttribute('width', this.img.width)
    lowerCanvas.setAttribute('height', this.img.height)

    // @ts-ignore
    let upperCanvas = this.canvas.upperCanvasEl

    upperCanvas.style.width = '100%'
    upperCanvas.style.height = '100%'

    upperCanvas.setAttribute('width', rectWithBB.width)
    upperCanvas.setAttribute('height', rectWithBB.height)

    let ratioX = lowerCanvas.width / upperCanvas.width
    let ratioY = lowerCanvas.height / upperCanvas.height
    let ratio = Math.min(ratioX, ratioY)
    this.maskScaleX = this.img.width < rectWithBB.width ? ratioX : ratio
    this.maskScaleY = this.img.height < rectWithBB.height ? ratioY : this.maskScaleY
    let maskScale = Math.max(this.maskScaleX, this.maskScaleY)
    // @ts-ignore
    let brush = this.root.canvasRemoveHandler.canvas.freeDrawingBrush as any
    brush.setMaskScale(maskScale)
    this.root.canvasRemoveHandler.setBrushSizeOnZoom()
  }
  
  updateScrollBarPosition = () => {
    let wrapCanvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
    const maxScrollTop = this.mainContext.editor.handlers.scrollbarHandler.getMaxScrollTop()
    const maxScrollLeft = this.mainContext.editor.handlers.scrollbarHandler.getMaxScrollLeft()
    if (this.mainContext.editor.handlers.scrollbarHandler.isScrollXVisible(true)) {
      // calc scroll top base on boundary viewport top to the current viewport top
      wrapCanvasRemoveTool.style.overflowX = 'auto'
      this.maxScrollRight = maxScrollLeft - (this.mainContext.editor.handlers.scrollbarHandler.scrollWidth - this.mainContext.canvas.getWidth())
      const percentX = (maxScrollLeft - this.mainContext.canvas.viewportTransform[4]) / (maxScrollLeft - this.maxScrollRight)
      this.maxScrollX = this.mainContext.editor.handlers.scrollbarHandler.scrollWidth - wrapCanvasRemoveTool.clientWidth
      wrapCanvasRemoveTool.scrollLeft = Math.ceil(percentX * this.maxScrollX - this.mainContext.editor.handlers.scrollbarHandler.PADDING_OVERLAP / 2)
    } else {
      wrapCanvasRemoveTool.style.overflowX = 'hidden'
      wrapCanvasRemoveTool.scrollLeft = 0
    }

    if (this.mainContext.editor.handlers.scrollbarHandler.isScrollYVisible(true)) {
      // calc scroll left base on boundary viewport left to the current viewport left
      wrapCanvasRemoveTool.style.overflowY = 'auto'
      this.maxScrollDown = maxScrollTop - (this.mainContext.editor.handlers.scrollbarHandler.scrollHeight - this.mainContext.canvas.getHeight())
      const percentY = (maxScrollTop - this.mainContext.canvas.viewportTransform[5]) / (maxScrollTop - this.maxScrollDown)
      this.maxScrollY = this.mainContext.editor.handlers.scrollbarHandler.scrollHeight - wrapCanvasRemoveTool.clientHeight
      wrapCanvasRemoveTool.scrollTop = Math.ceil(percentY * this.maxScrollY)
    } else {
      wrapCanvasRemoveTool.style.overflowY = 'hidden'
      wrapCanvasRemoveTool.scrollTop = 0
    }
    let scrollBarX = document.getElementById('scrollbarX')
    let scrollBarY = document.getElementById('scrollbarY')
    scrollBarX.style.zIndex = '-1'
    scrollBarY.style.zIndex = '-1'
  }

  handleRemoveToolResize = () => {
    if (this.isEraserToolResize) {
      this.isEraserToolResize = false
      return
    }
    this.updatePositionFollowMainCanvas()
  }

  FinishToolState(): Promise<void> {
    
    let brush = this.canvas.freeDrawingBrush as any
    brush.finalize()
    // @ts-ignore
    let base64 = this.canvas.lowerCanvasEl.toDataURL();
    this.canvas.isDrawingMode = false
    
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        const bgElement = document.querySelector('#wrap-canvas-remove-bg') as HTMLElement
        bgElement.style.background = 'rgba(255, 5, 5, 0)'
      })
      setTimeout(async () => {
        await this.root.eventsRemoveHandler.processEraserTool(base64, true)
        
        brush.dispose()

        this.resetActiveObjectState()
        this.canvas.isDrawingMode = true
        resolve()
      }, this.duration)
   
    })
  }

  public reset() {
    this.resetTransforms()
  }

  resetTransforms() {
    // @ts-ignore
    let lowerCanvas = this.canvas.contextContainer.canvas
    lowerCanvas.width = this.originalWidth
    lowerCanvas.height = this.originalHeight

    const rotateElement = document.querySelector('#wrap-canvas-remove-tool #wrap-canvas-remove-transform-rotate') as HTMLElement
    const bgElement = document.querySelector('#wrap-canvas-remove-tool #wrap-canvas-remove-bg') as HTMLElement
    let wrapCanvasRemoveZoomTool = document.getElementById('wrap-canvas-remove-zoom-tool')
    wrapCanvasRemoveZoomTool.style.width = `100%`
    wrapCanvasRemoveZoomTool.style.height = `100%`
    let elements = [rotateElement, bgElement]
    let stylesToReset = ['transform', 'top', 'left', 'bottom', 'right', 'width', 'height', 'overflow']
    for (let element of elements) {
      for (let styleToReset of stylesToReset) {
        element.style.removeProperty(styleToReset)
      }
    }
  }
}

export default EraserObjectHandler
