import { useStyletron } from 'baseui'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function WrapImage({ srcImage, size = null, ratioFrame = 1, hasHoverState = true }) {
  const [css] = useStyletron()
  return (
    <div
      className={css({
        position: 'relative',
        width: '100%',
        height: '100%',
        ':hover div': {
          display: hasHoverState ? 'block !important' : 'none',
        },
        borderRadius: '6px',
        overflow: 'hidden',
      })}
    >
      {srcImage ? (
        <LazyLoadImage
          width={'100%'}
          height={'100%'}
          src={srcImage}
          alt=""
          style={{ objectFit: 'fill' }}
        />
      ) : null}
      <div
        className="overlay"
        style={{
          position: 'absolute',
          inset: 0,
          background: 'rgba(0,0,0,0.2)',
          display: 'none',
        }}
      ></div>
    </div>
  )
}

export default WrapImage