import { lightTheme } from '@/customTheme'
import useAppContext from '@/hooks/useAppContext'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React from 'react'
import ButtonCustom from '../ButtonCustom'
import { KIND, SHAPE } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import { LabelLarge } from 'baseui/typography'
import Icons from '@/scenes/Editor/components/Icons'
import { t } from 'i18next'

function ModalUnsuportType() {
  const { showModalType, setShowModalType } = useAppContext()
  return (
    <Modal
      onClose={() => setShowModalType(null)}
      isOpen={showModalType === ShowModalType.UNSUPPORTED_FILE_TYPE}
      animate
      closeable={true}
      focusLock={false}
      returnFocus={false}
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Backdrop: {
          style: ({ $theme }) => ({
            ...lightTheme.modal.BackdropStyle
          }),
        },
        Root: {
          style: ({ $theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            zIndex: 10000,
          }),
        },
        Dialog: {
          style: ({ $theme, $isOpen, $isVisible }) => ({
            width: '460px',
            height: '190px',
            padding: '24px',
            ...lightTheme.modal.DialogStyle({ $theme, $isOpen, $isVisible })
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: 'none'
          })
        }
      }}
    >
      <ModalHeader
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0',
          height: '48px',
          paddingBottom: '16px',
        }}
      >
        <p style={{ ...lightTheme.typography.Header20bold }}>{t('Unsupported file type')}</p>

        <ButtonCustom
          shape={SHAPE.square}
          kind={KIND.minimal}
          type={SizeButton.SMALL}
          onClick={() => setShowModalType(null)}
        >
          <Icons.Close size={24} />
        </ButtonCustom>
      </ModalHeader>
      <ModalBody style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '0' }}>
        <LabelLarge
          $style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#666666',
            lineHeight: '22px',
          }}
        >
          {t('Files for upload should be .jpg or .png and 10 MB or less.')}
        </LabelLarge>
      </ModalBody>
      <ModalFooter
        style={{
          height: '72px',
          margin: '0',
          padding: '0',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'end',
          gap: '8px',
        }}
      >
        <ButtonCustom
          kind={KIND.primary}
          type={SizeButton.STANDARD}
          onClick={() => setShowModalType(null)}
          style={{ width: 'fit-content', padding: '8px 12px' }}
        >
          <p style={{ fontSize: '14px', fontWeight: '600', lineHeight: '24px' }}>{t('Ok, got it!')}</p>
        </ButtonCustom>
      </ModalFooter>
    </Modal>
  )
}

export default ModalUnsuportType
