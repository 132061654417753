import { lightTheme } from '@/customTheme'
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import { useEffect, useState } from 'react'
import ButtonCustom from '../ButtonCustom'
import { KIND, SHAPE } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import { Input } from 'baseui/input'
import { LabelLarge } from 'baseui/typography'
import Icons from '@/scenes/Editor/components/Icons'
import { useTranslation } from 'react-i18next'
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { Block } from 'baseui/block'
import { useStyletron } from 'baseui'

export const convertUnitValue = (preUnit, currUnit = 'px', value) => {
  let width, height
  width = value.width
  height = value.height

  if (preUnit === 'px' && currUnit === 'cm') {
    width = width * 2.54 / 96
    height = height * 2.54 / 96
  } else if (preUnit === 'cm' && currUnit === 'px') {
    width = width * 96 / 2.54
    height = height * 96 / 2.54
  } else if (preUnit === 'px' && currUnit === 'in') {
    width = width / 96
    height = height / 96
  } else if (preUnit === 'in' && currUnit === 'px') {
    width = width * 96
    height = height * 96
  } else if (preUnit === 'cm' && currUnit === 'in') {
    width = width / 2.54
    height = height / 2.54
  } else if (preUnit === 'in' && currUnit === 'cm') {
    width = width * 2.54
    height = height * 2.54
  }
  width = currUnit === 'px' ? Math.round(width) : Number(width.toFixed(2))
  height = currUnit === 'px' ? Math.round(height) : Number(height.toFixed(2))
  return { width, height }
}

function ModalCustomSize({isOpen, onClose, customSize, setCustomSize, applyCustomSize, aspectRatio, setAspectRatio, unit, setUnit}) {
  const [maxValue, setMaxValue] = useState(5000)
  const [minValue, setMinValue] = useState(16)
  const [lockedProportions, setLockedProportions] = useState(true)
  const [css] = useStyletron()
  const [isOpenUnitMenu, setIsOpenUnitMenu] = useState(false)

  const { t } = useTranslation()

  const handleConfirmCustomSize = () => {
    if (
      customSize.width < minValue ||
      customSize.width > maxValue ||
      customSize.height < minValue ||
      customSize.height > maxValue
    ) {
      return
    } else {
      const { width, height } = convertUnitValue(unit, 'px', {width: customSize.width, height: customSize.height})
      applyCustomSize({ width, height })
      onClose()
    }
  }


  useEffect(() => {
    switch (unit) {
      case 'px':
        setMaxValue(5000)
        setMinValue(16)
        break
      case 'cm':
        setMaxValue(132)
        setMinValue(0.42)
        break
      case 'in':
        setMaxValue(52)
        setMinValue(0.16)
        break
      default:
        break
    }
  }, [unit])
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      animate
      closeable={true}
      focusLock={false}
      returnFocus={false}
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            zIndex: '10000',
          }),
        },
        Dialog: {
          style: ({ $theme, $isOpen, $isVisible }) => ({
            width: '389px',
            height: '494px',
            padding: '24px',
            transition: 'none',
            ...lightTheme.modal.DialogStyle({ $theme, $isOpen, $isVisible })
          }),
        },
        Backdrop: {
          style: ({ $theme }) => ({
            transition: 'none',
            ...lightTheme.modal.BackdropStyle
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: 'none',
          }),
        },
      }}
    >
      <ModalHeader
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0',
          height: '48px',
          paddingBottom: '16px',
        }}
      >
        <p style={{ ...lightTheme.typography.Header20bold, color: '#000' }}>{t('Custom size')}</p>

        <ButtonCustom
          shape={SHAPE.square}
          kind={KIND.minimal}
          type={SizeButton.SMALL}
          onClick={onClose}
        >
          <Icons.Close size={24} />
        </ButtonCustom>
      </ModalHeader>
      <ModalBody style={{ display: 'flex', flexDirection: 'column', margin: '0' }}>
        <p style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title, margin: 0, marginBottom: '8px' }}>{t('Size')}</p>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', marginBottom: '8px' }}>
          <Input
            autoFocus
            value={customSize.width}
            error={false}
            type="number"
            endEnhancer={unit}
            startEnhancer="Width"
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  position: 'relative',
                  padding: '0 !important',
                  border: '0 !important',
                  height: '48px',
                  background: '#fff',
                }),
              },
              Input: {
                style: ({ $theme }) => ({
                  background: '#fff',
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px',
                  ':hover': {
                    borderColor: '#B2B2B2',
                  },
                  ':focus': {
                    borderColor: '#000',
                    color: '#000',
                  },
                  padding: '20px 12px 4px',
                  color: '#666666',
                  fontSize: '14px',
                }),
              },

              StartEnhancer: {
                style: ({ $theme }) => ({
                  position: 'absolute',
                  padding: '0 !important',
                  top: '8px',
                  left: '12px',
                  bottom: '26px',
                  background: '#fff !important',
                  fontSize: '12px',
                  color: '#999999',
                  lineHeight: '14px',
                }),
              },
              EndEnhancer: {
                style: ({ $theme }) => ({
                  position: 'absolute',
                  padding: '0',
                  top: '20px',
                  right: '12px',
                  bottom: '6px',
                  background: '#fff !important',
                  fontSize: '14px',
                  color: '#666666',
                }),
              },
            }}
            min={minValue}
            max={maxValue}
            onKeyPress={event => {
              if (event.key === '.' && unit === 'px') {
                event.preventDefault()
              } else if (event.key === 'Enter') {
                handleConfirmCustomSize()
              }
            }}
            onChange={e => {
              setCustomSize(pre => {
                // @ts-ignore
                let value = e.target.value
                if (lockedProportions) {
                  let newWidth = Number(value > maxValue ? maxValue : value);
                  let newHeight  = unit === 'px' ? Math.round(newWidth / aspectRatio) : Number((newWidth / aspectRatio).toFixed(2));
                  if(aspectRatio < 1) {
                    if(newHeight > maxValue) {
                      newHeight = maxValue;
                      newWidth = unit === 'px' ? Math.round(newHeight * aspectRatio) : Number((newHeight * aspectRatio).toFixed(2));
                    }
                  }
                  return { ...pre, width: newWidth, height: newHeight };
                } else {
                  return { ...pre, width: value > maxValue ? maxValue : Number(value) };
                }
              })
            }}
            onBlur={e => {
              if(customSize.width < minValue) {
                if(lockedProportions) {
                  let newWidth = minValue;
                  let newHeight = unit === 'px' ? Math.round(newWidth / aspectRatio) : Number((newWidth / aspectRatio).toFixed(2));
                  if(aspectRatio < 1) {
                    if(newHeight > maxValue) {
                      newHeight = maxValue;
                      newWidth = unit === 'px' ? Math.round(newHeight * aspectRatio) : Number((newHeight * aspectRatio).toFixed(2));
                    }
                  }
                  setCustomSize(pre => ({ ...pre, width: newWidth, height: newHeight }))
                }
                setCustomSize(pre => ({ ...pre, width: minValue }))
              }
            }}
          />
          <Input
            value={customSize.height}
            error={false}
            type="number"
            endEnhancer={unit}
            startEnhancer="Height"
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  position: 'relative',
                  padding: '0 !important',
                  border: '0 !important',
                  height: '48px',
                  background: '#fff',
                }),
              },
              Input: {
                style: ({ $theme }) => ({
                  background: '#fff',
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px',
                  ':hover': {
                    borderColor: '#B2B2B2',
                  },
                  ':focus': {
                    borderColor: '#000',
                    color: '#000',
                  },
                  padding: '20px 12px 4px',
                  color: '#666666',
                  fontSize: '14px',
                }),
              },
              StartEnhancer: {
                style: ({ $theme }) => ({
                  position: 'absolute',
                  padding: '0 !important',
                  top: '8px',
                  left: '12px',
                  bottom: '26px',
                  background: '#fff !important',
                  fontSize: '12px',
                  color: '#999999',
                  lineHeight: '14px',
                }),
              },
              EndEnhancer: {
                style: ({ $theme }) => ({
                  position: 'absolute',
                  padding: '0',
                  top: '20px',
                  right: '12px',
                  bottom: '6px',
                  background: '#fff !important',
                  fontSize: '14px',
                  color: '#666666',
                }),
              },
            }}
            min={minValue}
            max={maxValue}
            onKeyPress={event => {
              if (event.key === '.' && unit === 'px') {
                event.preventDefault()
              } else if (event.key === 'Enter') {
                handleConfirmCustomSize()
              }
            }}
            onChange={e =>
              setCustomSize(pre => {
                // @ts-ignore
                let value = e.target.value
                if (lockedProportions) {
                  let newHeight = Number(value > maxValue ? maxValue : value);
                  let newWidth = unit === 'px' ? Math.round(newHeight * aspectRatio) : Number((newHeight * aspectRatio).toFixed(2));
                  if(aspectRatio > 1) {
                    if(newWidth > maxValue) {
                      newWidth = maxValue;
                      newHeight = unit === 'px' ? Math.round(newWidth / aspectRatio) : Number((newWidth / aspectRatio).toFixed(2));
                    }
                  }
                  return { ...pre, width: newWidth, height: newHeight };
                } else {
                  return { ...pre, height: value > maxValue ? maxValue : Number(value) };
                }
              })
            }
            onBlur={e => {
              if(customSize.height < minValue) {
                if(lockedProportions) {
                  let newHeight = minValue;
                  let newWidth = unit === 'px' ? Math.round(newHeight * aspectRatio) : Number((newHeight * aspectRatio).toFixed(2));
                  if(aspectRatio > 1) {
                    if(newWidth > maxValue) {
                      newWidth = maxValue;
                      newHeight = unit === 'px' ? Math.round(newWidth / aspectRatio) : Number((newWidth / aspectRatio).toFixed(2));
                    }
                  }
                  setCustomSize(pre => ({ ...pre, width: newWidth, height: newHeight }))
                }
                setCustomSize(pre => ({ ...pre, height: minValue }))
              }
            }}
          />
        </div>
        <p style={{ margin: 0, ...lightTheme.typography.Small12regular, color: '#999' }}>
          {t(
            `Enter a size between ${minValue} and ${maxValue} ${
              unit === 'px' ? 'pixels' : unit === 'cm' ? 'centimeters' : 'inches'
            }.`
          )}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ ...lightTheme.typography.Small14Semibold,color:lightTheme.colors.text.text_black_title, margin: '24px 0 8px' }}>
            {t('Aspect Ratio')}
          </p>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
            alignItems: 'center',
            border: '1px solid #e5e5e5',
            height: '48px',
            borderRadius: '8px',
            padding: '0 8px 0 16px',
            ':hover': {
              background: '#F2F2F2',
              border: '1px solid transparent',
            }
          })}
        >
          {lockedProportions ? <Icons.LockIcon /> : <Icons.UnLockIcon />}
          <p style={{ ...lightTheme.typography.Small14Semibold,color:lightTheme.colors.text.text_600_default }}>{t('Lock Proportions')}</p>
          <div style={{ marginLeft: 'auto' }}>
            <Checkbox
              checked={lockedProportions}
              onChange={e => {
                if(!lockedProportions) {
                  setAspectRatio(customSize.width / customSize.height)
                }                       
                setLockedProportions(!lockedProportions)
              }}
              checkmarkType={STYLE_TYPE.toggle_round}
              overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    width: '48px',
                    // height: '20px',
                    margin: 0,
                  }),
                },
                ToggleTrack: {
                  style: ({ $theme }) => ({
                    backgroundColor: lockedProportions ? '#FF0560' : lightTheme.colors.grayScale100,
                    margin: '0 !important',
                    borderRadius: '10px',
                    height: '20px',
                  }),
                },
                Toggle: {
                  style: ({ $theme }) => ({
                    width: '16px',
                    height: '16px',
                    marginLeft: lockedProportions ? '6px' : '2px',
                  }),
                },
              }}
            ></Checkbox>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '24px 0 8px',
          }}
        >
          <p style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title}}>{t('Units')}</p>
        </div>
        <StatefulPopover
          placement={PLACEMENT.bottomLeft}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                marginTop: '4px',
                zIndex: '10000',
              }),
            },
          }}
          content={({ close }) => (
            <>
              <Block
                backgroundColor={'#ffffff'}
                padding={'8px 4px'}
                overrides={{
                  Block: {
                    style: {
                      display: 'block',
                      borderRadius: '16px',
                      width: '158px',
                      padding: '16px',
                      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
                    },
                  },
                }}
              >
                {['px', 'cm', 'in'].map((value, index) => (
                  <Block
                    onClick={() => {
                      setUnit(value)
                      const {width, height} = convertUnitValue(unit, value, {width: customSize.width, height: customSize.height})
                      setCustomSize(pre => ({...pre, width, height}))
                      close()
                    }}
                    $style={{
                      height: '40px',
                      fontSize: '14px',
                      fontWeight: '500',
                      cursor: 'pointer',
                      padding: '8px',
                      borderRadius: '8px',
                      ':hover': {
                        background: '#F2F2F2',
                      },
                      ':active': {
                        background: lightTheme.colors.grayScale100,
                      },
                    }}
                    key={index}
                  >
                    {value}
                  </Block>
                ))}
              </Block>
            </>
          )}
          onOpen={() => setIsOpenUnitMenu(true)}
          onClose={() => setIsOpenUnitMenu(false)}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
              alignItems: 'center',
              border: isOpenUnitMenu ? '1px solid #B2B2B2' : '1px solid #e5e5e5',
              height: '48px',
              borderRadius: '8px',
              padding: '0 12px 0 16px',
              width: '158.5px',
              ':hover': {
                border: '1px solid #B2B2B2'
              },
              ':active': {
                border: '1px solid #B2B2B2'
              }
            })}
          >
            <p style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px', color: '#666' }}>{t(unit)}</p>
            <div style={{ transform: 'rotate(90deg)', width: '24px' }}>
              <Icons.ArrowMedium />
            </div>
          </div>
        </StatefulPopover>
      </ModalBody>
      <ModalFooter
        style={{
          // height: '72px',
          margin: '40px 0 0 0',
          padding: '0',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'end',
          gap: '8px',
        }}
      >
        <ButtonCustom
          kind={KIND.secondary}
          type={SizeButton.STANDARD}
          onClick={onClose}
          style={{ width: '72px' }}
        >
          <LabelLarge>{t('Cancel')}</LabelLarge>
        </ButtonCustom>
        <ButtonCustom
          kind={KIND.primary}
          type={SizeButton.STANDARD}
          onClick={() => {
            handleConfirmCustomSize()
          }}
          style={{
            width: '72px',
            background:
              customSize.width < minValue ||
              customSize.width > maxValue ||
              customSize.height < minValue ||
              customSize.height > maxValue
                ? '#F2F2F2'
                : '#FF0560',
          }}
          disabled={
            customSize.width < minValue ||
            customSize.width > maxValue ||
            customSize.height < minValue ||
            customSize.height > maxValue
          }
        >
          <LabelLarge
            $style={{
              color:
                customSize.width < minValue ||
                customSize.width > maxValue ||
                customSize.height < minValue ||
                customSize.height > maxValue
                  ? '#ccc'
                  : '#fff',
            }}
          >
            Done
          </LabelLarge>
        </ButtonCustom>
      </ModalFooter>
    </Modal>
  )
}

export default ModalCustomSize
