import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={141}
    height={88}
    fill="none"
    {...props}
  >
    <g clipPath="url(#drop_image_a)">
      <g clipPath="url(#drop_image_b)">
        <rect
          width={36.54}
          height={42.841}
          fill="#FCDBE0"
          rx={5.89}
          transform="scale(-1 1) rotate(-15 70.771 520.593)"
        />
        <path
          fill="#F7A4B2"
          d="m99.814 55.77 4.264 7.813-2.313 8.635-24.95-6.686 16.651-11.3a4.41 4.41 0 0 1 6.348 1.537Z"
        />
        <path
          fill="#FF0560"
          d="m116.958 55.534 7.248 13.241-2.364 8.824-35.6-9.54 24.642-14.226a4.41 4.41 0 0 1 6.074 1.701Z"
        />
        <circle
          cx={4.095}
          cy={4.095}
          r={4.095}
          fill="#FF0560"
          transform="scale(-1 1) rotate(-15 89.402 440.247)"
        />
      </g>
      <rect
        width={38.199}
        height={44.499}
        x={1.016}
        y={-0.586}
        stroke="#fff"
        strokeWidth={1.659}
        rx={6.72}
        transform="scale(-1 1) rotate(-15 70.771 527.722)"
      />
      <g clipPath="url(#drop_image_c)">
        <rect
          width={36.54}
          height={42.841}
          x={8.297}
          y={35.998}
          fill="#FCDBE0"
          rx={5.89}
          transform="rotate(-15 8.297 35.998)"
        />
        <path
          fill="#F7A4B2"
          d="m40.811 55.712-4.264 7.814 2.314 8.634 24.95-6.685-16.652-11.3a4.41 4.41 0 0 0-6.348 1.537Z"
        />
        <path
          fill="#FF0560"
          d="m23.667 55.477-7.248 13.24 2.364 8.824 35.6-9.539L29.74 53.775a4.41 4.41 0 0 0-6.074 1.702Z"
        />
        <circle
          cx={34.742}
          cy={40.978}
          r={4.095}
          fill="#FF0560"
          transform="rotate(-15 34.742 40.978)"
        />
      </g>
      <rect
        width={38.199}
        height={44.499}
        x={7.281}
        y={35.412}
        stroke="#fff"
        strokeWidth={1.659}
        rx={6.72}
        transform="rotate(-15 7.281 35.412)"
      />
      <g filter="url(#drop_image_d)">
        <g clipPath="url(#drop_image_e)">
          <rect
            width={50.672}
            height={59.409}
            x={43.961}
            y={10.782}
            fill="#FCDBE0"
            rx={3.495}
          />
          <path
            fill="#F7A4B2"
            d="m80.439 48.858-8.517 8.937V70.19h35.82l-18.25-21.113a6.116 6.116 0 0 0-9.053-.22Z"
          />
          <path
            fill="#FF0560"
            d="M57.555 42.39 43.094 57.523v12.668h51.11L66.3 42.29a6.116 6.116 0 0 0-8.746.1Z"
          />
          <circle cx={77.601} cy={26.945} r={5.679} fill="#FF0560" />
        </g>
        <rect
          width={52.42}
          height={61.157}
          x={43.087}
          y={9.909}
          stroke="#fff"
          strokeWidth={1.747}
          rx={4.368}
        />
      </g>
    </g>
    <defs>
      <clipPath id="drop_image_a">
        <path fill="#fff" d="M0 0h141v87.918H0z" />
      </clipPath>
      <clipPath id="drop_image_b">
        <rect
          width={36.54}
          height={42.841}
          fill="#fff"
          rx={5.89}
          transform="scale(-1 1) rotate(-15 70.771 520.593)"
        />
      </clipPath>
      <clipPath id="drop_image_c">
        <rect
          width={36.54}
          height={42.841}
          x={8.297}
          y={35.998}
          fill="#fff"
          rx={5.89}
          transform="rotate(-15 8.297 35.998)"
        />
      </clipPath>
      <clipPath id="drop_image_e">
        <rect
          width={50.672}
          height={59.409}
          x={43.961}
          y={10.782}
          fill="#fff"
          rx={3.495}
        />
      </clipPath>
      <filter
        id="drop_image_d"
        width={70.76}
        height={79.492}
        x={33.917}
        y={8.206}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={7.465} />
        <feGaussianBlur stdDeviation={4.147} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.920833 0 0 0 0 0.713646 0 0 0 0 0.739544 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1972_13221"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1972_13221"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
