import { Filter, Texture } from '@pixi/core'

export class RestoringMaskFilter extends Filter {
    constructor(
        eraseTexture: Texture, 
        restoreTexture: Texture, 
        originalMaskTexture: Texture,
        accumlatedMaskTexture: Texture,
    ) {
        const fragmentShader = `
            precision mediump float;

            varying vec2 vTextureCoord;
            uniform sampler2D uSampler; // First texture
            
            uniform sampler2D eraseTexture;
            uniform sampler2D restoreTexture;
            uniform sampler2D originalMaskTexture;
            uniform sampler2D accumlatedMaskTexture;

            void main(void) {
                vec4 image = texture2D(uSampler, vTextureCoord);
                vec4 erase = texture2D(eraseTexture, vTextureCoord);
                vec4 restore = texture2D(restoreTexture, vTextureCoord);
                vec4 originalMask = texture2D(originalMaskTexture, vTextureCoord);
                vec4 accumlatedMask = texture2D(accumlatedMaskTexture, vTextureCoord);

                float alpha = min(originalMask.a, min(1.0, max(0.0, min(accumlatedMask.a, (1.0 - erase.r)) + restore.r)));
                gl_FragColor = vec4(image.rgb, alpha); // Use the red channel of mask
            }
        `;

        super(null, fragmentShader, {
            eraseTexture: eraseTexture,
            restoreTexture: restoreTexture,
            originalMaskTexture: originalMaskTexture,
            accumlatedMaskTexture: accumlatedMaskTexture
        });
    }
}
