import { useEffect } from 'react';

function useStyletronHotjarSync() {
  useEffect(() => {
    // document.head.insertBefore(syncStylesEl, document.head.children[0]);

    const shadowHost = document.createElement("div");
    document.head.appendChild(shadowHost);
    const shadowRoot = shadowHost.attachShadow({ mode: "open" });

    const syncStylesEl = document.createElement("style");
    syncStylesEl.type = "text/css";
    shadowRoot.appendChild(syncStylesEl);
    const processedSheets = new WeakSet(); // Cache to track processed stylesheets
    const seenRules = new Set();
    let syncHotJarStylesTimeout;
    function debouncedSyncHotJarStyles() {
      clearTimeout(syncHotJarStylesTimeout);
      syncHotJarStylesTimeout = setTimeout(() => {
        const newRules = [];
        Array.from(document.styleSheets)
        .filter(sheet => sheet.ownerNode.nodeName === 'STYLE' && !processedSheets.has(sheet))
        .forEach(sheet => {
          processedSheets.add(sheet);
          Array.from(sheet.cssRules).forEach(rule => {
            const ruleText = rule.cssText;
            if (!seenRules.has(ruleText)) { // Avoid duplicates 
              seenRules.add(ruleText);
              newRules.push(ruleText);
            }
          });
        });
        if (newRules.length > 0) {
          syncStylesEl.textContent += newRules.join("\n");
        }
      }, 1000);
    }

    const originalInsertRule = CSSStyleSheet.prototype.insertRule;
    CSSStyleSheet.prototype.insertRule = function (rule, index) {
        const result = originalInsertRule.call(this, rule, index);
        debouncedSyncHotJarStyles();
        return result;
    };

    const originalDeleteRule = CSSStyleSheet.prototype.deleteRule;
    CSSStyleSheet.prototype.deleteRule = function (index) {
      originalDeleteRule.call(this, index);
      debouncedSyncHotJarStyles();
    };

    //@ts-ignore
    if (window.hj && window.hj.settings && window.hj.settings.record) {
        // Initial sync
        debouncedSyncHotJarStyles();
    }
  }, []);
}

export default useStyletronHotjarSync;