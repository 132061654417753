import React from 'react'

function Transparent({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="3"
        y="3"
        width="18"
        height="18"
        rx="2"
        fill="#D9D9D9"
        fillOpacity="0.5"
        stroke="#666666"
        strokeWidth="2"
      />
      <g clipPath="url(#clip0_420_7796)">
        <rect x="4" y="4" width="3.2" height="3.2" fill="white" />
        <rect x="7.2002" y="4" width="3.2" height="3.2" fill="#666666" />
        <rect x="10.3999" y="4" width="3.2" height="3.2" fill="white" />
        <rect x="13.6001" y="4" width="3.2" height="3.2" fill="#666666" />
        <rect x="16.7998" y="4" width="3.2" height="3.2" fill="white" />
        <rect x="4" y="7.2002" width="3.2" height="3.2" fill="#666666" />
        <rect x="7.2002" y="7.2002" width="3.2" height="3.2" fill="white" />
        <rect x="10.3999" y="7.2002" width="3.2" height="3.2" fill="#666666" />
        <rect x="13.6001" y="7.2002" width="3.2" height="3.2" fill="white" />
        <rect x="16.7998" y="7.2002" width="3.2" height="3.2" fill="#666666" />
        <rect x="4" y="10.4004" width="3.2" height="3.2" fill="white" />
        <rect x="7.2002" y="10.4004" width="3.2" height="3.2" fill="#666666" />
        <rect x="10.3999" y="10.4004" width="3.2" height="3.2" fill="white" />
        <rect x="13.6001" y="10.4004" width="3.2" height="3.2" fill="#666666" />
        <rect x="16.7998" y="10.4004" width="3.2" height="3.2" fill="white" />
        <rect x="4" y="13.5996" width="3.2" height="3.2" fill="#666666" />
        <rect x="7.2002" y="13.5996" width="3.2" height="3.2" fill="white" />
        <rect x="10.3999" y="13.5996" width="3.2" height="3.2" fill="#666666" />
        <rect x="13.6001" y="13.5996" width="3.2" height="3.2" fill="white" />
        <rect x="16.7998" y="13.5996" width="3.2" height="3.2" fill="#666666" />
        <rect x="4" y="16.7998" width="3.2" height="3.2" fill="white" />
        <rect x="7.2002" y="16.7998" width="3.2" height="3.2" fill="#666666" />
        <rect x="10.3999" y="16.7998" width="3.2" height="3.2" fill="white" />
        <rect x="13.6001" y="16.7998" width="3.2" height="3.2" fill="#666666" />
        <rect x="16.7998" y="16.7998" width="3.2" height="3.2" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_420_7796">
          <rect x="4" y="4" width="16" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Transparent
