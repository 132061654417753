import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : "#666"}
      fillRule="evenodd"
      d="m1.805 13.434 9.26 9.26a1.04 1.04 0 0 0 1.47.001l6.122-6.12L7.926 5.842l-6.121 6.121a1.04 1.04 0 0 0 0 1.47Zm6.857-8.327 10.731 10.731.902-.902a2.08 2.08 0 0 0 0-2.94l-2.362-2.362L22.865 4.7a2.168 2.168 0 0 0-3.066-3.066l-4.933 4.932-2.362-2.362a2.08 2.08 0 0 0-2.94 0l-.902.902Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent