import { Filter } from '@pixi/core';
import { IAdjustmentFilter } from '@scenes/engine/utils/PixijsFilters/IAdjustmentFilter'
import vertex from "../default.vert";
import fragment from "./fade.frag";

// TODO: implement this - https://developer.apple.com/documentation/coreimage/cicolorclamp
// use clamp in glsl

export class FadeFilter implements IAdjustmentFilter {
  fade: number;
  pixijsFilter: Filter | null;

  minimumForKey(key: string): number {
    if (key == "fade") {
      return 0;
    }
  }

  maximumForKey(key: string): number {
    if (key == "fade") {
      return 0.1;
    }
  }

  defaultForKey(key: string): number {
    if (key == "fade") {
      return 0.0;
    }
  }

  constructor() {
    this.fade = this.defaultForKey("fade");
  }

  buildFilter({fade}) {
    if (this.isValid(fade)) { this.fade = fade; }

    let lowValue = this.normalize("fade", this.minimumForKey("fade")) + this.fade;
    let topValue = this.normalize("fade", this.maximumForKey("fade")) - this.fade;

    if (!this.pixijsFilter) {
      this.pixijsFilter = FadeFilter.generateFilter(
        this.vectorize(lowValue, 0.0),
        this.vectorize(topValue, 1.0)
      );
    } else {
      this.pixijsFilter.uniformGroup.uniforms["minVal"] = this.vectorize(lowValue, 0.0);
      this.pixijsFilter.uniformGroup.uniforms["maxVal"] = this.vectorize(topValue, 1.0);
    }
  }

  private isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

  private normalize(key: string, value: number): number {
    let minBzrtValue = this.minimumForKey(key);
    let maxBzrtValue = this.maximumForKey(key);
    let minFilterValue = 0.0;
    let maxFilterValue = 1.0;

    let normalizedBzrtValue = (value - minBzrtValue) / Math.abs(maxBzrtValue - minBzrtValue);
    let normalizedFilterValue = normalizedBzrtValue * (maxFilterValue - minFilterValue) + minFilterValue;
    return normalizedFilterValue;
  }

  private vectorize(value: number, alpha: number): number[]{
    let retVector = [value, value, value, alpha];
    return retVector;
  }

  static generateFilter(minValue: number[], maxValue: number[]): Filter {
    let filter = new Filter(
      vertex,
      fragment,
        {
          minVal: minValue,
          maxVal: maxValue
        }
    );
    return filter;
  }
}