import * as React from 'react'

const SvgComponent = props => (
  <svg width={44} height={76} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={43} y={0.5} width={75} height={42} rx={3.5} transform="rotate(90 43 .5)" fill="#F2F2F2" />
    <path
      d="M21.993 48.986C28.001 48.986 33 44.001 33 37.993 33 31.986 27.987 27 21.98 27 15.986 27 11 31.986 11 37.993c0 6.008 5 10.993 10.993 10.993Zm0-2.07c-4.945 0-8.895-3.964-8.895-8.923 0-4.958 3.937-8.922 8.882-8.922a8.895 8.895 0 0 1 8.936 8.922 8.884 8.884 0 0 1-8.923 8.923Zm-1.975-4.754 6.062-3.57a.674.674 0 0 0 0-1.185l-6.062-3.569c-.477-.286-1.13-.068-1.13.477v7.37c0 .545.599.803 1.13.477Z"
      fill="#FF455B"
    />
    <rect x={43} y={0.5} width={75} height={42} rx={3.5} transform="rotate(90 43 .5)" stroke="#E5E5E5" />
  </svg>
)

export default SvgComponent
