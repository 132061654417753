import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    {...props}
  >
    <path
      d="m14.103 12.697 5.535 3.1c.033.017.05.037.05.061s-.017.045-.05.06l-7.183 4.047a.966.966 0 0 1-.455.133.966.966 0 0 1-.455-.133l-7.183-4.046c-.033-.016-.05-.037-.05-.06 0-.025.017-.045.05-.061l5.842-3.283-1.833-1.054-5.178 2.956c-.434.25-.742.482-.922.696-.18.214-.271.463-.271.745 0 .283.09.533.27.751.181.218.489.453.923.703l7.442 4.264c.262.145.502.252.72.321.216.069.432.103.645.103.213 0 .428-.034.646-.103.217-.069.457-.176.72-.321l7.44-4.264c.435-.25.743-.485.923-.703.18-.218.271-.468.271-.75 0-.283-.09-.532-.27-.746-.181-.214-.489-.446-.923-.696l-4.896-2.799-1.808 1.079ZM12 14.284c.213 0 .428-.035.646-.104.217-.068.457-.175.72-.32l7.44-4.277c.435-.25.743-.482.923-.696.18-.214.271-.463.271-.745 0-.283-.09-.531-.27-.745-.181-.214-.489-.45-.923-.709l-7.442-4.264a3.994 3.994 0 0 0-.72-.321A2.136 2.136 0 0 0 12 2c-.213 0-.428.034-.646.103a3.994 3.994 0 0 0-.72.321l-7.44 4.264c-.435.259-.743.495-.923.709-.18.214-.271.462-.271.745 0 .282.09.53.27.745.181.214.489.446.923.696l7.442 4.277c.262.145.502.252.72.32.216.07.432.104.645.104Z"
    />
  </svg>
)
export default SvgComponent
