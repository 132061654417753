// @ts-nocheck

import { fabric } from 'fabric'
import { controlPositionIcons } from './controls'
import { loadImageFromURL } from '../../utils/image-loader'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { nanoid } from 'nanoid'
import CanvasImageRenderer from '@scenes/engine/utils/canvasImageRenderer'
import store from '@/store/store'
import { ObjectType } from '../../common/constants'

export enum ControlPositions {
  TOP_LEFT = 'tl',
  TOP = 't',
  TOP_RIGHT = 'tr',
  RIGHT = 'r',
  BOTTOM_RIGHT = 'br',
  BOTTOM = 'b',
  BOTTOM_LEFT = 'bl',
  LEFT = 'l',
}

export class StaticImageObject extends fabric.Image {
  static type = 'StaticImage'
  public _editingMode = false
  __editingImage = null
  private _inset: Inset = null

  cornerLengthEditing = 5
  effects: {
    [key: string]: {
      [key: string]: any
    }
  } = {}
  effects_from_template: {
    [key: string]: {
      [key: string]: any
    }
  } = {}
  useBzrtBgMask = false

  /**
   * Color of the corner stroke in editing mode.
   */
  cornerStrokeColorEditing = 'black'

  /**
   * Size of the corner stroke in editing mode.
   */
  cornerSizeEditing = 2

  hasTransparency = true

  layerAssetStateId = null

  sizeOnCanvas = {
    width: 0.5,
    height: 0.5,
  }

  isTemplateLayer = false

  _filterScalingX = 1
  _filterScalingY = 1

  cacheCounter = 0

  isIntializedNormalizeMask = true
  isIntializedNormalizedImage = true
  
  isIntialized () {
    return this.isIntializedNormalizeMask && this.isIntializedNormalizedImage;
  };

  useNewTextureNextTime() {
    this.cacheCounter ++
  }

  getTextureKey(){
    return this.cacheKey + this.layerAssetStateId + this.cacheCounter;
  }

  useTextureOf(sourceObject:StaticImage)
  {
    this.cacheKey = sourceObject.cacheKey
    this.layerAssetStateId = sourceObject.layerAssetStateId
    this.cacheCounter = sourceObject.cacheCounter
  }

  async replaceImage(
    image: base64Image,
    withResize: boolean,
    magicLayer?: boolean = false
  ): Promise<boolean> {
    let self = this
    return loadImageFromURL(image).then(img => {
      //@ts-ignore

      let imageScaleX = self.width / img.width
      let imageScaleY = self.height / img.height

      !magicLayer && self.setElement(img)

      if (withResize) {
        let newScale = Math.max(imageScaleX, imageScaleY)
        self._originalScaleX *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleX : newScale
        self._originalScaleY *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleY : newScale
        self.scaleX *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleX : newScale
        self.scaleY *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleY : newScale
      }

      self.set('dirty', true)
        //@ts-ignore
      self.applyFilters()
    })
  }

  async replaceImageUrl(url: string, withResize: boolean, magicLayer?: boolean = false, generateNewMask: boolean = false) {
    let assetStateId = nanoid()
    let prevMask = await MediaImageRepository.getInstance().getImage(this.id ,this.layerAssetStateId, MediaImageType.mask)

    await MediaImageRepository.getInstance().storeImageUrl(
      this.id,
      assetStateId,
      MediaImageType.original,
      url
    )

    await MediaImageRepository.getInstance().storeImageBase64(
      this.id,
      assetStateId,
      MediaImageType.mask,
      prevMask
    )
    

    let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(this.id, assetStateId)
    await MediaImageRepository.getInstance().storeLatestImageInfo(this.id, assetStateId, latestImageInfo)
    this.boundingBox = latestImageInfo.boundingBox;
    await this.replaceImage(latestImageInfo.latestImage.toDataURL(), withResize, magicLayer)

    this.set('layerAssetStateId', assetStateId)
    if(!magicLayer) {
      this.set('dirty', true)
      this.applyFilters()
      this.canvas?.requestRenderAll()
    }
  }

  registerEditingEvents(this: fabric.Image) {
    // register to dubble click event to start crop mode - disbable for now
    // this.on('mousedblclick', () => {
    //   if (!this._editingMode) {
    //     return this.enterEditingMode()
    //   } else {
    //     this.exitEditingMode()
    //   }
    // })
    // replace image :
    var input = document.createElement('input')
    input.type = 'file'
    input.onchange = e => {
      var file = e.target.files[0]
      if (file) {
        var reader = new FileReader()
        reader.onload = async event => {
          await this.replaceImageUrl(event.target.result)
        }

        reader.readAsDataURL(file)
      }
    }

    this.on('mousedblclick', () => {
      // input.click();
      // return;
    })
    this.on('deselected', () => {
      this.exitEditingMode()
      this.perPixelTargetFind = true
    })
    this.on('selected',()=>{
      // on selected state we want that transpaernt pixels will be selectable too, for batter dragging (using on _checkTarget func)
      this.perPixelTargetFind = false
    })
  }

  enterEditingMode(this: fabric.Image) {
    if (this.selectable && this.canvas) {
      this._editingMode = true
      // patch to support crop
      this.set({
        originX: 'left',
        originY: 'top',
        top: this.top - (this.height * this.scaleY) / 2,
        left: this.left - (this.width * this.scaleX) / 2,
      })
      this.clone((image: fabric.Image) => {
        const element = image.getElement()
        const { top = 0, left = 0, cropX = 0, cropY = 0, scaleX = 1, scaleY = 1 } = image
        image.set({
          top: top - cropY * scaleY,
          left: left - cropX * scaleX,
          height: element.height,
          width: element.width,
          cropX: 0,
          cropY: 0,
          opacity: 0.4,
          selectable: false,
          evented: false,
          excludeFromExport: true,
        })
        this.__editingImage = image
        this.canvas!.add(this.__editingImage)
        this.on('moving', (this.__editingOnMoving = this.__editingOnMoving.bind(this)))
        this.controls = this.__editingControls()
        this.fire('enter:editing', { target: this })
        this.canvas?.requestRenderAll()
      })
    }
  }

  exitEditingMode(this: fabric.Image) {
    if (this.selectable && this.canvas) {
      this._editingMode = false
      if (this.__editingImage) {
        this.canvas.remove(this.__editingImage)
        this.__editingImage = null
        // patch to support crop
        this.set({
          originX: 'center',
          originY: 'center',
          top: this.top + (this.height * this.scaleY) / 2,
          left: this.left + (this.width * this.scaleX) / 2,
        })
      }
      this.off('moving', this.__editingOnMoving)
      this.controls = fabric.Object.prototype.controls
      this.fire('exit:editing', { target: this })
      this.canvas?.requestRenderAll()
    }
  }

  // @ts-ignore
  __editingControls(this: fabric.Image) {
    const controls = Object.values(ControlPositions)
    return controls.map(this.__createEditingControl.bind(this))
  }

  __createEditingControl(this: fabric.Image, position: ControlPositions) {
    const cursor = position.replace('t', 's').replace('l', 'e').replace('b', 'n').replace('r', 'w')

    return new fabric.Control({
      cursorStyle: cursor + '-resize',
      actionName: `edit_${this.type}`,
      render: controlPositionIcons[position],
      positionHandler: this.__editingControlPositionHandler.bind(this, position),
      actionHandler: this.__editingActionHandlerWrapper(position),
    })
  }

  __editingActionHandlerWrapper(this: fabric.Image, position: ControlPositions) {
    return (_event: MouseEvent, _transform: any, x: number, y: number) => {
      this.__editingSetCrop(position, x, y, true)
      return true
    }
  }

  // @ts-ignore
  __editingOnMoving(this: fabric.Image, event: fabric.IEvent) {
    if (this._editingMode && event.pointer) {
      this.__editingSetCrop(ControlPositions.TOP_LEFT, this.left!, this.top!)
    }
  }

  __editingSetCrop(
    this: fabric.Image,
    position: ControlPositions,
    x: number,
    y: number,
    resize: boolean = false
  ) {
    if (this.__editingImage) {
      const { top = 0, left = 0, width = 0, height = 0, scaleX = 1, scaleY = 1 } = this.__editingImage

      if (position.includes('t')) {
        const maxTop = top + height * scaleY - (resize ? 0 : this.getScaledHeight())
        const minTop = Math.min(y, maxTop, this.top! + this.getScaledHeight())
        this.top = Math.max(minTop, top)
        const cropY = Math.min((Math.min(Math.max(y, top), this.top) - top) / scaleY, height)
        if (resize) {
          this.height = Math.max(0, Math.min(this.height! + (this.cropY! - cropY), height))
        }
        this.cropY = cropY
      } else if (position.includes('b') && resize) {
        const minHeight = Math.min((y - top) / scaleY - this.cropY!, height - this.cropY!)
        this.height = Math.max(0, minHeight)
      }
      if (position.includes('l')) {
        const maxLeft = left + width * scaleX - (resize ? 0 : this.getScaledWidth())
        const minLeft = Math.min(x, maxLeft, this.left! + this.getScaledWidth())
        this.left = Math.max(minLeft, left)
        const cropX = Math.min((Math.min(Math.max(x, left), this.left) - left) / scaleX, width)
        if (resize) {
          this.width = Math.max(0, Math.min(this.width! + (this.cropX! - cropX), width))
        }
        this.cropX = cropX
      } else if (position.includes('r') && resize) {
        const minWidth = Math.min((x - left) / scaleX - this.cropX!, width - this.cropX!)
        this.width = Math.max(0, minWidth)
      }
    }
  }

  __editingControlPositionHandler(this: fabric.Image, position: ControlPositions) {
    const xMultiplier = position.includes('l') ? -1 : position.length > 1 || position === 'r' ? 1 : 0
    const yMultiplier = position.includes('t') ? -1 : position.length > 1 || position === 'b' ? 1 : 0
    const x = (this.width! / 2) * xMultiplier
    const y = (this.height! / 2) * yMultiplier

    return fabric.util.transformPoint(
      new fabric.Point(x, y),
      fabric.util.multiplyTransformMatrices(this.canvas!.viewportTransform!, this.calcTransformMatrix())
    )
  }

  __renderEditingControl(
    this: fabric.Image,
    position: ControlPositions,
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number
  ) {
    ctx.save()
    ctx.strokeStyle = this.cornerStrokeColorEditing
    ctx.lineWidth = this.cornerSizeEditing
    ctx.translate(left, top)
    if (this.angle) {
      ctx.rotate(fabric.util.degreesToRadians(this.angle))
    }
    ctx.beginPath()
    const x = position.includes('l') ? -ctx.lineWidth : position.includes('r') ? ctx.lineWidth : 0
    const y = position.includes('t') ? -ctx.lineWidth : position.includes('b') ? ctx.lineWidth : 0
    if (position === 'b' || position === 't') {
      ctx.moveTo(x - this.cornerLengthEditing / 2, y)
      ctx.lineTo(x + this.cornerLengthEditing, y)
    } else if (position === 'r' || position === 'l') {
      ctx.moveTo(x, y - this.cornerLengthEditing / 2)
      ctx.lineTo(x, y + this.cornerLengthEditing)
    } else {
      if (position.includes('b')) {
        ctx.moveTo(x, y - this.cornerLengthEditing)
      } else if (position.includes('t')) {
        ctx.moveTo(x, y + this.cornerLengthEditing)
      }
      ctx.lineTo(x, y)
      if (position.includes('r')) {
        ctx.lineTo(x - this.cornerLengthEditing, y)
      } else if (position.includes('l')) {
        ctx.lineTo(x + this.cornerLengthEditing, y)
      }
    }
    ctx.stroke()
    ctx.restore()
  }

  //@ts-ignore
  initialize(element, options) {
    // @ts-ignore
    this.registerEditingEvents()
    this._inset = new Inset(0, 0, 0, 0)

      if (options._originalScaleX && options._originalScaleY) {
        options.scaleX = options._originalScaleX / (options._filterScalingX ? options._filterScalingX : 1)
        options.scaleY = options._originalScaleY / (options._filterScalingY ? options._filterScalingY : 1)
      } else {
        options._originalScaleX = options.scaleX
        options._originalScaleY = options.scaleY
      }
      

      super.initialize(element, options)

      
      let that = this
      this.on('scaling', function (e) {
        that._originalScaleX = that.scaleX * that._filterScalingX
        that._originalScaleY = that.scaleY * that._filterScalingY
      })


    return this
  }

  static fromObject(options: any, callback: Function) {
    fabric.util.loadImage(
      options.src,
      async function (img) {
        let state = store.getState()
        options.filters = await CanvasImageRenderer.getInstance().getFilters(options, state.editor?.imageElements?.imageElements);
        let staticImage = new fabric.StaticImage(img, options)

        return callback && callback(staticImage)
      },
      null,
      // @ts-ignore
      { crossOrigin: 'anonymous' }
    )
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }

  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }

  applyFilters(inputFilters) {
    if (!this.dirty) {
      return;
    }
    let imgElement = this._originalElement

    let filters = inputFilters || this.filters || []
    let inputSize = new Size(imgElement.width, imgElement.height)
    let paddedSize = filters.reduce(
      (total, filter) => (filter.padSize ? filter.padSize(total) : total),
      inputSize
    )
    let scaleX = inputSize.width / paddedSize.width
    let scaleY = inputSize.height / paddedSize.height

    let inputInset = new Inset(0, 0, 0, 0)
    let inset = filters.reduce(
      (total, filter) => (filter.applyInset ? filter.applyInset(total, inputSize) : total),
      inputInset
    )

    filters = filters.filter(function (filter) {
      return filter && !filter.isNeutralState()
    })

    this._element = fabric.filterBackend.applyFiltersWithBoundingBox(
      filters,
      this._originalElement,
      inputSize,
      inset,
      scaleX,
      scaleY,
      this.getTextureKey()
    )

    this._filterScalingX = scaleX
    this._filterScalingY = scaleY
    this._inset = inset

    this.scaleX = this._originalScaleX / this._filterScalingX
    this.scaleY = this._originalScaleY / this._filterScalingY


    return this
  }
  

  _calculateCurrentDimensions() {
    let wh = super._calculateCurrentDimensions()
    wh.x *= this._filterScalingX
    wh.y *= this._filterScalingY
    return wh
  }

  toLocalPoint(point, originX, originY) {
    point.x /= this._filterScalingX
    point.y /= this._filterScalingY

    var center = this.getCenterPoint(),
      p,
      p2

    center.x /= this._filterScalingX
    center.y /= this._filterScalingY

    if (typeof originX !== 'undefined' && typeof originY !== 'undefined') {
      p = this.translateToGivenOrigin(center, 'center', 'center', originX, originY)
    }
    else {
      p = new fabric.Point(this.left, this.top)
    }

    p2 = new fabric.Point(point.x, point.y)
    if (this.angle) {
      p2 = fabric.util.rotatePoint(p2, center, -fabric.util.degreesToRadians(this.angle))
    }
    return p2.subtractEquals(p)
  }

  _calcYOffset(): number {
    return this._inset.top - this._inset.bottom
  }

  _calcXOffset(): number {
    return this._inset.left - this._inset.right
  }

  _getCacheCanvasDimensions() {
    let dims = super._getCacheCanvasDimensions()
    if(!this._originalElement) { return dims }
    let insetOffsetX =
      (this._filterScalingX * Math.abs(this._calcXOffset()) * dims.width) / this._originalElement.width
    let insetOffsetY =
      (this._filterScalingY * Math.abs(this._calcYOffset()) * dims.height) / this._originalElement.height
    dims.width += insetOffsetX
    dims.height += insetOffsetY
    return dims
  }

  _renderFill(ctx) {
    var elementToDraw = this._element
    if (!elementToDraw) {
      return
    }

    let insetOffsetX = this._calcXOffset() * this._filterScalingX
    let insetOffsetY = this._calcYOffset() * this._filterScalingY

    var scaleX = this._filterScalingX,
      scaleY = this._filterScalingY,
      w = this.width,
      h = this.height,
      min = Math.min,
      max = Math.max,
      // crop values cannot be lesser than 0.
      cropX = max(this.cropX, 0),
      cropY = max(this.cropY, 0),
      elWidth = elementToDraw.naturalWidth || elementToDraw.width,
      elHeight = elementToDraw.naturalHeight || elementToDraw.height,
      sX = cropX * scaleX,
      sY = cropY * scaleY,
      // the width height cannot exceed element width/height, starting from the crop offset.
      sW = min(w * 1.0, elWidth - sX),
      sH = min(h * 1.0, elHeight - sY),
      x = -(w + insetOffsetX) / 2,
      y = -(h + insetOffsetY) / 2,
      maxDestW = min(w, elWidth / scaleX - cropX),
      maxDestH = min(h, elHeight / scaleY - cropY)

    elementToDraw && ctx.drawImage(elementToDraw, sX, sY, sW, sH, x, y, maxDestW, maxDestH)
  }
}

fabric.StaticImage = fabric.util.createClass(StaticImageObject, {
  type: StaticImageObject.type,
  perPixelTargetFind: true,
  useBzrtBgMask: false,
  cacheCounter: 0
})
fabric.StaticImage.fromObject = StaticImageObject.fromObject

export interface StaticImageOptions extends fabric.IImageOptions {
  id: string
  name?: string
  description?: string
  subtype: string
  src: string
  useBzrtBgMask: boolean
  cacheCounter: number
}

declare module 'fabric' {
  namespace fabric {
    class StaticImage extends StaticImageObject {
      registerEditingEvents: () => void
      constructor(element: any, options: any)
    }

    interface IUtil {
      isTouchEvent(event: Event): boolean
      getPointer(event: Event, a?: any): Point
    }
    interface Image {
      useNewTextureNextTime: () => void
      enterEditingMode(): void
      isIntialized: () => void
      exitEditingMode(): void
      _editingMode: boolean
      __editingImage: Image | null
      __editingOnMoving: (event: IEvent) => void
      isLatest: boolean
      hasTransparency: boolean
      cornerLengthEditing: number
      cornerSizeEditing: number
      cornerStrokeColorEditing: string
      __editingControls(): { [key: string]: Control }
      __editingControlPositionHandler(position: ControlPositions): Point
      __editingSetCrop(position: ControlPositions, x: number, y: number, resize?: boolean): void
      __createEditingControl(position: ControlPositions): Control
      __editingActionHandlerWrapper(position: ControlPositions): Control['actionHandler']
    }
  }
}
