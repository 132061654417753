import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    {...props}
  >
    <path
      d="M5 22c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 20V6c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 4h4.175c.183-.583.542-1.063 1.075-1.438A2.98 2.98 0 0 1 12 2c.667 0 1.263.187 1.788.562A2.75 2.75 0 0 1 14.85 4H19c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 22H5Zm0-2h14V6h-2v3H7V6H5v14Zm7-14a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 4a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 5c0 .283.096.52.288.712A.965.965 0 0 0 12 6Z"
    />
  </svg>
)
export default SvgComponent
