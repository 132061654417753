 import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      fillRule="evenodd"
      d="M5.5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12.5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM19.5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
