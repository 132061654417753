import { IUpload, Uploading } from '@/interfaces/editor'
import { createReducer } from '@reduxjs/toolkit'
import { closeUploading, setUploading, setUploads } from './actions'

export interface UploadsState {
  uploads: IUpload[]
  uploading: Uploading | null
}

const initialState: UploadsState = {
  uploads: [],
  uploading: null,
}

export const uploadsReducer = createReducer(initialState, builder => {
  builder.addCase(setUploads, (state, { payload }) => {
    if(payload.length) {
      if(payload[0].fromCreationPage) {
        state.uploads = payload
      } else {
        state.uploads.unshift(...payload)
      }
    } else {
      state.uploads = []
    }
  })
  builder.addCase(setUploading, (state, { payload }) => {
    state.uploading = payload
  })
  builder.addCase(closeUploading, state => {
    state.uploading = null
  })
})
