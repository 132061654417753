import { styled } from 'baseui'
import Icons from '../Icons'
import React, { useEffect, useState } from 'react';
import HeadingInspector from '@/components/HeadingInspector'
import AutoScroll from '@/components/AutoScroll'
import { useInspector } from './InspectorDataProvider';
import { useEditorContext } from '@/scenes/engine'
import FooterActionButtons from './ToolboxItems/components/FooterActionButtons'


const InspectorContainer = styled('div', props => ({
  backgroundColor: props.$theme.colors.background,
  boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.08)',
  marginLeft: '1px',
  display: 'flex',
  width: '340px',
  height: '100%',
  borderRadius: '12px',
  zIndex: 0,
  alignItems: 'flex_start',
  flexDirection: 'column'
}))

const ToggleInspector = styled('div', props => ({
  display: 'flex',
  alignItems: 'center',
  transform: 'translate(1px, 0)',
  zIndex: 1,
}))

interface InspectorPanelProps {
  title: string;
  hasNavigation?: boolean;
  hasClose?: boolean;
  hasAutoScroll?: boolean;
  hasFooterActionButtons?: boolean;
  onClickPrimary?:()=>void,
  onClickSecondary?:()=>void,
  isPrimaryButtonActive?: boolean,
  isSecondaryButtonActive?: boolean,
  hasQuestionMarkButton?: boolean,
  questionMarkButtonAction?: ()=>void,
  onClickSecondaryShowDiscardModal?: boolean
}

const InspectorPanel: React.FC<InspectorPanelProps> = ({ 
  title, 
  hasNavigation = false, 
  hasClose = false, 
  hasAutoScroll = true,
  hasFooterActionButtons = false,
  onClickPrimary=()=>{},
  onClickSecondary=()=>{},
  isPrimaryButtonActive = false,
  isSecondaryButtonActive = true,
  hasQuestionMarkButton = false,
  questionMarkButtonAction=()=>{},
  onClickSecondaryShowDiscardModal = true,
  children }) => {
  const { canvas } = useEditorContext()
  const [isBoxShadow, setIsBoxShadow] = useState(false);
  const { hideInspector, setIsOpenInspector, isOpenInspector } = useInspector();
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

  useEffect(() => {
    const targetNode = document.getElementById('scrollbarY-container');
    if (!targetNode) return;

    const checkScrollbarClass = () => {
      const hasScrollbarClass = targetNode.classList.contains('scrollbar-visible');
      setIsScrollbarVisible(hasScrollbarClass);
    };

    // Create a MutationObserver to watch for changes in the class attribute
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          checkScrollbarClass();
        }
      }
    });

    observer.observe(targetNode, { attributes: true });
    checkScrollbarClass();

    return () => {
      observer.disconnect();
    };
  }, []);

  return <div style={{
    right: isOpenInspector ? '16px' : (isScrollbarVisible ? '-330px' : '-340px'),
    position: 'absolute',
    top: '16px',
    bottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: '0.4s ease-in-out'
  }
}>
    <ToggleInspector style={{cursor: 'pointer'}} onClick={()=>{ setIsOpenInspector(!isOpenInspector) }}>
      <svg
        width="18"
        height="93"
        viewBox="0 0 18 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        filter="drop-shadow(-7px 0px 4px rgba(0, 0, 0, 0.04))"
      >
        <path
          d="M0 21.6179C0 14.9778 3.46643 8.81952 9.14304 5.37474L18 0V93L9.82717 88.4945C3.76491 85.1525 0 78.7778 0 71.8554V21.6179Z"
          fill="white"
        />
      </svg>

      <div
        style={{
          position: 'absolute',
          marginLeft: isOpenInspector ? '8px' : '3px',
          transform: isOpenInspector ? 'rotate(360deg)' : 'rotate(180deg)',
          transition: '0.4s ease-in-out'
        }}
      >
        <Icons.InspectorArrow size={12} />
      </div>

  </ToggleInspector>

  <InspectorContainer>{
    <>
    <HeadingInspector
      hasBoxShadow={isBoxShadow}
      hasNavigation={hasNavigation}
      hasClose={hasClose}
      handleNavigation={()=>{hideInspector()}}
      handleClose={()=>{canvas.discardActiveObject(); canvas.requestRenderAll()}}
      title={title}
      hasQuestionMarkButton={hasQuestionMarkButton}
      questionMarkButtonAction={questionMarkButtonAction}
      onClickSecondaryShowDiscardModal={onClickSecondaryShowDiscardModal}
    ></HeadingInspector>
    { hasAutoScroll ? ( 
        <AutoScroll
        handleScroll={e => {
          if (e.target.scrollTop > 0) {
            setIsBoxShadow(true)
          } else {
            setIsBoxShadow(false)
          }
        }}
        style={{ marginTop: 0 }}
        >{children}
        </AutoScroll>) :
        (<>{children}</>)
    }
    { hasFooterActionButtons && 
      <FooterActionButtons 
        onClickPrimary={onClickPrimary}
        onClickSecondary={onClickSecondary} 
        isPrimaryButtonActive={isPrimaryButtonActive}
        isSecondaryButtonActive={isSecondaryButtonActive}
        onClickSecondaryShowDiscardModal={onClickSecondaryShowDiscardModal}
      >
      </FooterActionButtons> }
    </>
  }</InspectorContainer>
  </div>
}

export default InspectorPanel
