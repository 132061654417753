import * as React from 'react'

const TextAlignCenter = props => (
  <svg width={25} height={24} fill={props.color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.333 4a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2h-20a1 1 0 0 1-1-1ZM1.333 14.665a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2h-20a1 1 0 0 1-1-1ZM5.266 19.998a1 1 0 0 1 1-1H18.4a1 1 0 0 1 0 2H6.266a1 1 0 0 1-1-1ZM5.266 9.333a1 1 0 0 1 1-1H18.4a1 1 0 1 1 0 2H6.266a1 1 0 0 1-1-1Z"
    />
  </svg>
)

export default TextAlignCenter
