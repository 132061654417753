import { lightTheme } from '@/customTheme'
import Icons from '@/scenes/Editor/components/Icons'
import React from 'react'

type ScrollButtonProps = {
  direction: 'left' | 'right'
  handle: () => void
  styles: React.CSSProperties
}

function ScrollButton({ direction, handle, styles }: ScrollButtonProps) {
  return (
    <button
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: lightTheme.colors.white,
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)',
        position: 'absolute',
        zIndex: 100,
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        ...styles
      }}
      onClick={handle}
    >
      <Icons.ArrowMedium direction={direction} fill={lightTheme.colors.blackGray}/>
    </button>
  )
}

export default ScrollButton
