import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.857 21.217A9.558 9.558 0 0 1 12 22a9.53 9.53 0 0 1-3.845-.783 10.265 10.265 0 0 1-3.2-2.172 10.284 10.284 0 0 1-2.173-3.195A9.518 9.518 0 0 1 2 12c0-1.379.261-2.66.778-3.852a10.224 10.224 0 0 1 2.171-3.199 10.206 10.206 0 0 1 3.204-2.17A9.562 9.562 0 0 1 11.989 2c1.378 0 2.661.261 3.858.778a10.206 10.206 0 0 1 3.204 2.171c.925.926 1.648 1.99 2.171 3.199A9.578 9.578 0 0 1 22 12c0 1.379-.261 2.66-.778 3.852a10.265 10.265 0 0 1-5.366 5.365Z"
      stroke="#666"
      strokeWidth={2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.299 7.515c.225-.614-.098-.937-.744-.71 0 0-4.038 1.324-5.782 3.068a2.44 2.44 0 0 0 0 3.426 2.48 2.48 0 0 0 3.456-.002c1.744-1.744 3.07-5.782 3.07-5.782Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
