import { useState, CSSProperties, useEffect } from 'react'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { Slider } from 'baseui/slider'
import { Block } from 'baseui/block'
import { Input } from 'baseui/input'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import { useTranslation } from 'react-i18next'

function SliderCustom({
  icon,
  name,
  listValues,
  minValue,
  maxValue,
  value,
  step,
  onChangeValue,
  onFinalChangeValue,
  logicMaximum,
  logicMinimum,
  style
}: {
  icon: JSX.Element
  name: string
  listValues: number[]
  minValue: number
  maxValue: number
  value: number
  step?: number
  onChangeValue: Function
  onFinalChangeValue?: Function
  logicMaximum?: number
  logicMinimum?: number
  style?: CSSProperties
}) {
  const [inputValue, setInputValue] = useState(minValue)
  const [showDropDown, setShowDropDown] = useState(true)
  const handleUpdateValue = (uiValue, isFinal, isInput = false) => {
    setInputValue(+uiValue)
    if(isInput) {
      return
    }
    let logicValue = convertUIToLogic(uiValue)
    onChangeValue(uiValue, logicValue)
    if (isFinal && onFinalChangeValue) {
      onFinalChangeValue(uiValue, logicValue)
    }
  }

  const convertUIToLogic = (uiValue: number): number => {
    if ([null, undefined].includes(logicMaximum && logicMinimum)) {
      return uiValue
    }

    let normalizedUIValue = (uiValue - minValue) / (maxValue - minValue)
    let normalizedLogicValue = normalizedUIValue * (logicMaximum - logicMinimum) + logicMinimum
    return normalizedLogicValue
  }

  const convertLogicToUI = (inputValue: number): number => {
    if ([null, undefined].includes(logicMaximum && logicMinimum)) {
      return inputValue // it means inputValue is actual uiValue
    }
    let logicValue = inputValue
    let normalizedLogicValue = (logicValue - logicMinimum) / (logicMaximum - logicMinimum)
    let normalizedUIValue = normalizedLogicValue * (maxValue - minValue) + minValue
    return Math.floor(normalizedUIValue)
  }

  useEffect(() => {
    setInputValue(convertLogicToUI(value))
  },[value])

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <div style={{ height: '62px', width: '100%', display: 'flex', gap: '12px', flexDirection: 'column', ...style }} className="mt-50">
        <div
          style={{
            width: '100%',
            height: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '6px',
          }}
        >
          {icon}
          <span
            style={{
              ...lightTheme.typography.Small14Semibold,
              color:lightTheme.colors.text.text_600_default,
              marginTop: '1px',
              textTransform: 'capitalize',
            }}
          >
            {t(name)}
          </span>
          <StatefulPopover
            placement={PLACEMENT.bottomLeft}
            overrides={{
              Body: {
                style: ({ $theme }) => ({
                  marginTop: '4px',
                  left: '-2px',
                  zIndex: '1000',
                }),
              },
            }}
            content={({ close }) =>
                <>
                {
                  listValues.length ? <Block
                  backgroundColor={'#ffffff'}
                  padding={'8px 4px'}
                  overrides={{
                    Block: {
                      style: {
                        display: showDropDown ? 'block' : 'none',
                        borderRadius: '8px',
                      },
                    },
                  }}
                >
                  {listValues.map((size, index) => (
                    <Block
                      onClick={() => {
                        handleUpdateValue(size, true)
                        setInputValue(size)
                        close()
                      }}
                      $style={{
                        height: '32px',
                        fontSize: '14px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        padding: '4px 12px',
                        borderRadius: '8px',
                        display: 'flex ',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ':hover': {
                          background: '#F2F2F2',
                        },
                      }}
                      key={index}
                    >
                      {size}
                    </Block>
                  ))}
                </Block> : null
                }
                </>
            }
          >
            <div style={{ marginLeft: 'auto' }}>
              <Input
                onKeyDown={e => {
                  if(e.key === 'Enter') {
                    if(inputValue < minValue) {
                      handleUpdateValue(minValue, true)
                    }else if(inputValue > maxValue) {
                      handleUpdateValue(maxValue, true)
                    } else {
                      handleUpdateValue(inputValue, true)
                    }
                    setShowDropDown(false)
                  }
                }}
                value={ inputValue }
                onChange={e => {
                  const value = e.currentTarget.value
                  const isNumber = /^[0-9]+$/.test(value)
                  if ((isNumber || value === '')) {
                    handleUpdateValue(value, true, true)
                  }
                }}
                clearOnEscape
                min={minValue}
                max={maxValue}
                onFocus={e => {
                  setShowDropDown(true)
                  setTimeout(() => {
                    e.target.select()
                  }, 100)
                }}
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      width: '44px',
                      height: '24px',
                      border: '0px',
                      padding: 0,
                      display: 'flex',
                      ':focus': {
                        border: '0px',
                      },
                    }),
                  },
                  Input: {
                    style: ({ $theme }) => ({
                      textAlign: 'center',
                      padding: '0 0 0 0',
                      background: '#F2F2F2',
                      borderRadius: '6px',
                      fontSize: '14px',
                      fontWeight: 500,
                      color: lightTheme.colors.blackGray,
                      ':hover': {
                        background: lightTheme.colors.grayScale100,
                      },
                      ':focus': {
                        background: '#FFFFFF',
                        border: '1px solid #666666 ',
                      },
                    }),
                  },
                }}
              />
            </div>
          </StatefulPopover>
        </div>
        <div
          style={{
            height: '24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Slider
            min={minValue}
            max={maxValue}
            value={[inputValue > maxValue ? maxValue : inputValue]}
            step={step}
            onChange={({ value }) => {
              handleUpdateValue(value[0], false)
            }}
            onFinalChange={({ value }) => {
              handleUpdateValue(value[0], true)
            }}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  // height: '24px',
                }),
              },
              Track: {
                style: ({ $theme }) => ({
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }),
              },
              InnerThumb: ({ $value, $thumbIndex }) => <>{}</>,
              ThumbValue: () => null,
              Thumb: {
                style: () => ({
                  height: '20px',
                  width: '20px',
                  borderRadius: '50%',
                  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
                  backgroundColor: '#fff',
                }),
              },
              InnerTrack: {
                style: ({ $theme }) => {
                  if (minValue >= 0) {
                    return {
                      // @ts-ignore
                      background: `linear-gradient(to right, ${$theme.colors.grayScale600} 0%, ${
                        // @ts-ignore
                        $theme.colors.grayScale600
                        // @ts-ignore
                      } ${((inputValue - minValue) / (maxValue - minValue)) * 100}%, ${$theme.colors.grayScale100} ${
                        ((inputValue - minValue) / (maxValue - minValue)) * 100
                      }%, ${
                        // @ts-ignore
                        $theme.colors.grayScale100
                      } 100%)`,
                    }
                  } else {
                    if (inputValue >= 0) {
                      return {
                        // @ts-ignore
                        background: `linear-gradient(to right, ${$theme.colors.grayScale600} 0%, ${
                          // @ts-ignore
                          $theme.colors.grayScale600
                          // @ts-ignore
                        } ${(inputValue / (maxValue - minValue)) * 100 + 50}%, ${
                          // @ts-ignore
                          $theme.colors.grayScale100
                        } ${(inputValue / (maxValue - minValue)) * 100 + 50}%, ${
                          // @ts-ignore
                          $theme.colors.grayScale100
                        } 100%)`,
                      }
                    } else {
                      return {
                        // @ts-ignore
                        background: `linear-gradient(to right, ${$theme.colors.grayScale600} 0%, ${
                          // @ts-ignore
                          $theme.colors.grayScale600
                          // @ts-ignore
                        } ${((maxValue + inputValue) / (maxValue - minValue)) * 100}%, ${
                          // @ts-ignore
                          $theme.colors.grayScale100
                        } ${((maxValue + inputValue) / (maxValue - minValue)) * 100}%, ${
                          // @ts-ignore
                          $theme.colors.grayScale100
                        } 100%)`,
                      }
                    }
                  }
                },
              },
              Tick: {
                style: ({ $theme }) => ({
                  display: 'none',
                }),
              },
              TickBar: {
                style: ({ $theme }) => ({
                  display: 'none',
                }),
              },
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default SliderCustom
