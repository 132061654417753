import * as React from 'react'

const SvgComponent = props => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="49" height="49" rx="24.5" fill="#E5E5E5"/>
    <rect x="0.5" y="0.5" width="49" height="49" rx="24.5" stroke="#E5E5E5"/>
  </svg>

)

export default SvgComponent
