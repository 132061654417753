function Download({ size, fill }: { size: number, fill?: string }) {
  return (
    // <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M5 15C5.55228 15 6 15.4477 6 16V18H18V16C18 15.4477 18.4477 15 19 15C19.5523 15 20 15.4477 20 16V18.5C20 19.3284 19.3284 20 18.5 20H5.5C4.67157 20 4 19.3284 4 18.5V16C4 15.4477 4.44772 15 5 15Z"
    //     fill={fill ? fill : 'white'}
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M12 4C12.5523 4 13 4.37884 13 4.84615V14.1538C13 14.6212 12.5523 15 12 15C11.4477 15 11 14.6212 11 14.1538V4.84615C11 4.37884 11.4477 4 12 4Z"
    //     fill={fill ? fill : 'white'}
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M11.9848 13.2273L8.63009 9.72604C8.25079 9.33018 7.61806 9.33018 7.23876 9.72604C6.88171 10.0987 6.88171 10.6865 7.23876 11.0592L11.3177 15.3162C11.4946 15.5009 11.7346 15.6046 11.9848 15.6046C12.2351 15.6046 12.4751 15.5009 12.652 15.3162L16.7309 11.0592C17.088 10.6865 17.088 10.0987 16.7309 9.72604C16.3516 9.33018 15.7189 9.33018 15.3396 9.72604L11.9848 13.2273Z"
    //     fill={fill ? fill : 'white'}
    //   />
    // </svg>
    <svg width={size} height={size} viewBox="0 0 24 24" fill={fill ? fill : 'white'} xmlns="http://www.w3.org/2000/svg" >
      <path fillRule="evenodd" clipRule="evenodd" d="M5 15C5.55228 15 6 15.4477 6 16V18H18V16C18 15.4477 18.4477 15 19 15C19.5523 15 20 15.4477 20 16V18.5C20 19.3284 19.3284 20 18.5 20H5.5C4.67157 20 4 19.3284 4 18.5V16C4 15.4477 4.44772 15 5 15Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.5523 4 13 4.37884 13 4.84615V14.1538C13 14.6212 12.5523 15 12 15C11.4477 15 11 14.6212 11 14.1538V4.84615C11 4.37884 11.4477 4 12 4Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9848 13.2273L8.63009 9.72604C8.25079 9.33018 7.61806 9.33018 7.23876 9.72604C6.88171 10.0987 6.88171 10.6865 7.23876 11.0592L11.3177 15.3162C11.4946 15.5009 11.7346 15.6046 11.9848 15.6046C12.2351 15.6046 12.4751 15.5009 12.652 15.3162L16.7309 11.0592C17.088 10.6865 17.088 10.0987 16.7309 9.72604C16.3516 9.33018 15.7189 9.33018 15.3396 9.72604L11.9848 13.2273Z" />
    </svg>

  )
}

export default Download
