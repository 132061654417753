import useAppContext from '@/hooks/useAppContext'
import { useEditorContext } from '@/scenes/engine'
import { InspectorPanelType, InspectorProvider } from '../Toolbox/InspectorProvider'
import { PixelManipulationCommonObjects } from '@/common/interfaces'

const Inspector: React.FC<{ 
  isDisabled: boolean, 
  pixelManipulationCommonObjects: PixelManipulationCommonObjects }> = 
  ({ isDisabled, pixelManipulationCommonObjects, children }) => {
  const { isOpenInspector, setIsOpenInspector } = useAppContext()
  const { activeObject } = useEditorContext()

  return (
    <>
      <InspectorProvider
        activeObject={activeObject}
        setIsOpenInspector={setIsOpenInspector}
        isOpenInspector={isOpenInspector}
        isDisabled={isDisabled}
        pixelManipulationDoneClick={()=>pixelManipulationCommonObjects.onDoneClick.current?.() }
        pixelManipulationCancelClick={()=>pixelManipulationCommonObjects.onCancelClick.current?.()}
        didPixelManipulationPerformAction={pixelManipulationCommonObjects.didPerformAction}
        isPixelManipulationInProcess={pixelManipulationCommonObjects.isInProcess}
      >
        {children}
      </InspectorProvider>
    </>
  );
}

export default Inspector
