import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M5.5 7.334a.917.917 0 1 0 1.833 0 .917.917 0 0 0-1.833 0Zm.917-2.75c1.197 0 2.216.765 2.593 1.833h10.24a.917.917 0 1 1 0 1.833H9.01a2.751 2.751 0 0 1-5.187 0H2.75a.917.917 0 0 1 0-1.833h1.073a2.751 2.751 0 0 1 2.594-1.833ZM1.833 14.667c0-.506.41-.917.917-.917h10.24a2.751 2.751 0 0 1 5.187 0h1.073a.917.917 0 1 1 0 1.834h-1.073a2.751 2.751 0 0 1-5.187 0H2.75a.917.917 0 0 1-.917-.917Zm13.75.917a.917.917 0 1 1 0-1.834.917.917 0 0 1 0 1.834Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
