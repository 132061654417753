import React, { CSSProperties } from 'react'
import { Button, KIND, SIZE } from 'baseui/button'
import { LabelLarge } from 'baseui/typography'
import { StyleObject } from 'styletron-standard'
import { lightTheme } from '@/customTheme'

function BtnOpenNewSlide({
  startEnhancer,
  endEnhancer,
  handleOnClick,
  nameAction,
  subAction,
  style,
}: {
  startEnhancer?: JSX.Element
  endEnhancer: JSX.Element
  handleOnClick: Function
  nameAction: string
  subAction?: string
  style?: CSSProperties
}) {
  return (
    <div className="mt-50" style={{...style}}>
        <Button
        onClick={() => handleOnClick()}
        startEnhancer={startEnhancer}
        endEnhancer={endEnhancer}
        kind={KIND.minimal}
        size={SIZE.large}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              ...$theme,
              border: `1px solid ${$theme.colors.grayScale100}`,
              padding: '7px 12px 7px 16px',
              width: '100%',
              height: '48px',
              ':hover': {
                border: `1px solid ${$theme.colors.grayScale50}`,
              },
              ':focus': {
                border: `1px solid ${$theme.colors.grayScale100}`,
              },
            }),
          },
          StartEnhancer: {
            style: ({ $theme }) => ({
              ...$theme,
              marginRight: '8px',
            }),
          },
        }}
      >
        <div style={{ width: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <span
            style={{
              ...lightTheme.typography.Small14Semibold,
              color:lightTheme.colors.text.text_600_default,
              lineHeight: subAction ? '18px' : '14px',
              marginTop: subAction ? 0 : '4px',
              textTransform: 'capitalize',
            }}
          >
            {nameAction}
          </span>
          <p
            style={{
              margin: '0',
              fontWeight: '400',
              fontSize: '11px',
              lineHeight: '12px',
              letterSpacing: '-0.2px',
              color: '#b2b2b2',
            }}
          >
            {subAction}
          </p>
        </div>
      </Button>
    </div>
  )
}

export default BtnOpenNewSlide
