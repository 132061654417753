import { ObjectType } from '../common/constants'

class ExportObject {
  run(item, options,isExport?: boolean) {
    let object
    switch (item.type) {
      case ObjectType.STATIC_IMAGE:
      case ObjectType.BAZAART_IMAGE:
          object={
            ...object = this[ObjectType.STATIC_IMAGE](item, options,isExport),
            fullImgUrl:item.fullImgUrl,
            hasPeople:item.hasPeople,
            shouldUseMattedImage:item.shouldUseMattedImage,
          }
          break;
      case ObjectType.BAZAART_STICKER:
        object={
          ...object = this[ObjectType.STATIC_IMAGE](item, options,isExport),
          fullImgUrl:item.fullImgUrl,
          sourceGraphicsItemName:item.sourceGraphicsItemName,
          sourceGraphicsPackName:item.sourceGraphicsPackName,
          sourceGraphicsType:item.sourceGraphicsType,
        }
        break;
      case ObjectType.BAZAART_BG:
        object={
          ...object = this[ObjectType.STATIC_IMAGE](item, options,isExport),
          backgrondIdentifier:item.backgrondIdentifier,
          isAnimated:item.isAnimated,
          isBackgroundImage:item.isBackgroundImage,
          startTime:item.startTime,
          _endTime:item._endTime,
          shouldUseMattedImage:item.shouldUseMattedImage,
        }
        break;
      case ObjectType.BAZAART_SHAP:
        object={
          ...object = this[ObjectType.STATIC_IMAGE](item, options,isExport),
          features:item.features,
          effects:item.effects,
          transformation:item.transformation,
          videoSpeed:item.videoSpeed,
        }
        break;
      case ObjectType.BACKGROUND_IMAGE:
      case ObjectType.BAZAART_DRAWING:
        object = this[ObjectType.STATIC_IMAGE](item, options,isExport)
        break
      case ObjectType.STATIC_TEXT:
      case ObjectType.BAZAART_TEXT:
        object = this[ObjectType.STATIC_TEXT](item, options,isExport)
        break
      case ObjectType.STATIC_VECTOR:
        object = this[ObjectType.STATIC_VECTOR](item, options,isExport)
        break
      case ObjectType.STATIC_PATH:
        object = this[ObjectType.STATIC_PATH](item, options,isExport)
        break
      case ObjectType.BAZAART_OVERLAY:
        object = this[ObjectType.BAZAART_OVERLAY](item, options,isExport)
        break
      case ObjectType.GROUP.toLowerCase():
        object = this[ObjectType.GROUP](item, options,isExport)
        break
    }
    return object
  }

  [ObjectType.STATIC_TEXT](item, options,isExport) {
    const baseOptions = this.getBaseOptions(item, options)
    let object;
    if (isExport) {
      const { features,textProperties } = item 
      object = {
        ...baseOptions,
        features:features,
        textProperties:textProperties
      }
    }else{
      const { fontFamily, textAlign, fontSize, fontWeight, charSpacing, lineHeight, fill, text, angle } = item
      const scaledFontSize = fontSize
      const metadata = {
        ...item.metadata,
        angle,
        fill,
        fontWeight,
        charspacing: charSpacing,
        fontSize: scaledFontSize,
        template: text,
        fontFamily,
        textAlign,
        lineheight: lineHeight,
        text: item.text,
      }
  
      object = {
        ...baseOptions,
        metadata,
      }
    }
    
    this.removeUndefinedFields(object)
    return object
  }

  [ObjectType.STATIC_IMAGE](item, options,isExport) {
    const baseOptions = this.getBaseOptions(item, options)
    let object;
    if(isExport){
      object={...baseOptions}
    }else{
      const {filters,src,cropX,cropY,type}=item
       object = {
        ...baseOptions,
        filters: filters,
        metadata: {
          src: src,
          cropX: cropX,
          cropY: cropY,
        },
      }
      object.type = type
    }
    
    this.removeUndefinedFields(object)
    return object
  }

  [ObjectType.STATIC_VECTOR](item, options,isExport) {
    const baseOptions = this.getBaseOptions(item, options)
    let object;
    if(isExport){
      object = {
        ...baseOptions,
      }
    }else{
      object = {
        ...baseOptions,
        metadata: {
          src: item.src,
        },
      }
    }
    this.removeUndefinedFields(object)
    return object
  }

  [ObjectType.STATIC_PATH](item, options,isExport) {
    const baseOptions = this.getBaseOptions(item, options)
    let object;
    if(isExport){
      object={
        ...baseOptions
      }
    }else{
      object = {
        ...baseOptions,
        metadata: {
          value: item.path,
          fill: item.fill,
        },
      }
    }
    this.removeUndefinedFields(object)
    return object
  }

  [ObjectType.BAZAART_OVERLAY](item, options,isExport) {
    const baseOptions = this.getBaseOptions(item, options)
    let object;
    if(isExport){
      const {blending,isAnimated,overlayType,startTime,_endTime}=item
       object = {
        ...baseOptions,
        blending: blending,
        isAnimated: isAnimated,
        overlayType: overlayType,
        startTime: startTime,
        _endTime:_endTime
      }
    }else{
       object = {
        ...baseOptions,
      }
      object.type = item.type
    }
    this.removeUndefinedFields(object)
    return object
  }

  [ObjectType.GROUP](item, options, isExport){
    const baseOptions = this.getBaseOptions(item, options)
    // baseOptions.originX = 'center'
    // baseOptions.originY = 'center'
    const groupObjects = item.objects.map((object) => {
      let flated_obj = this.run(object, options, isExport)
      // flated_obj.left = flated_obj.left + (item.left + item.width/2)
      // flated_obj.top = flated_obj.top + (item.top + item.height/2)
      // flated_obj.opacity = item.opacity
      return flated_obj
    })
    if(isExport){
      return groupObjects
    }
    return {
      ...baseOptions,
      type: ObjectType.GROUP,
      objects: groupObjects,
      metadata : item.metadata,
    }
  }

  removeUndefinedFields(obj) {
    for (const key in obj) {
        if (obj[key] === undefined) {
            delete obj[key];
        }
    }
    return obj;
  }

  getBaseOptions(item, options) {
    const baseOptions = {
      ...item,
      bazaartGuid: item?.bazaartGuid,
      imageHeight:item?.imageHeight,
      imageWidth:item?.imageWidth,
      itemType:item?.itemType,
    }
    return baseOptions
  }
}

export default new ExportObject()
