import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.356 21a1 1 0 0 1 1-1h13.642a1 1 0 1 1 0 2H8.356a1 1 0 0 1-1-1Z" fill={props.fill ? props.fill : "#666"} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.41 11.345a2 2 0 0 0 0-2.828l-5.932-5.931a2 2 0 0 0-2.828 0L1.469 14.767a1.6 1.6 0 0 0 0 2.263l4.383 4.383a2 2 0 0 0 1.414.585h4.489l10.654-10.653ZM15.063 4l5.931 5.931-10.069 10.067h-3.66l-4.1-4.1L15.064 4Z"
      fill={props.fill ? props.fill : "#666"}
    />
    <path
      d="M21.43 8.433c.724.725.738 1.886.03 2.594l-7.192 7.192-8.126-8.126L13.334 2.9c.708-.708 1.869-.694 2.594.03l5.502 5.502Z"
      fill={props.fill ? props.fill : "#666"}
    />
  </svg>
)

export default SvgComponent
