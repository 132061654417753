import { MediaImageType } from "./media_image_type"
export class ImageFilesystem {

  // thanks - https://www.typescriptlang.org/docs/handbook/utility-types.html
    private _cache: Map<string, Map<string, Map<Partial<MediaImageType>, string>>>;

    constructor() {
      this._cache = new Map();
    }

    async duplicate(originalLayer: string, newLayer: string, layerStateId: string) {
      let originalLayerMap = this._cache.get(originalLayer);
      if (!originalLayerMap) {
        return;
      }

      const allImgTypes = Object.values(MediaImageType);
      let getAllAssetsPromises = allImgTypes.map (imgType =>  {
        return this.getImage(originalLayer, layerStateId, imgType as MediaImageType)?.then((image)=>{
          return {
            type: imgType,
            image: image
          }
        })
      });

      let allAssets = await Promise.all(getAllAssetsPromises);
      let allFilteredAssets = allAssets.filter(asset => asset?.image);

      let setAllAssetsPromises = allFilteredAssets.map((asset)=>{
        return this.setImage(newLayer, layerStateId, asset.type as MediaImageType, asset.image as string);
      });

      await Promise.all(setAllAssetsPromises);
    }

    getImage(layerId: string, layerStateId: string, imgType: MediaImageType): Promise<string | null> {
      let layerMap = this._cache.get(layerId);
      if (!layerMap) {
        return null;
      }

      let didSeeStateId = false;
      let reversedMap = new Map(Array.from(layerMap).reverse());

      for (let [stateId, layerAssetsMap] of reversedMap) {
        didSeeStateId = didSeeStateId || stateId === layerStateId;
        if (didSeeStateId && layerAssetsMap.get(imgType)) {
            let image = layerAssetsMap.get(imgType);
            return Promise.resolve(image);
        }
      }
      return null;
    }

    setImage(layerId: string, layerStateId: string, imgType: MediaImageType, image: string): Promise<boolean> {
      if (!this._cache.get(layerId)) {
        this._cache.set(layerId, new Map());
      }

      let layerMap = this._cache.get(layerId);
      if (!layerMap.get(layerStateId)) {
        layerMap.set(layerStateId, new Map());
      }

      let layerAssetMap = layerMap.get(layerStateId);
      layerAssetMap.set(imgType, image);
      return Promise.resolve(true);
    }

    removeImage(layerId: string, layerStateId: string, imgType: MediaImageType) {
         let assetMap = this._cache.get(layerId)?.get(layerStateId);
         assetMap.delete(imgType);
    }
}