import React from 'react'

function Adjust({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8C6 8.55228 6.44772 9 7 9C7.55228 9 8 8.55228 8 8C8 7.44772 7.55228 7 7 7C6.44772 7 6 7.44772 6 8ZM7 5C8.30622 5 9.41746 5.83481 9.82929 7H21C21.5523 7 22 7.44772 22 8C22 8.55228 21.5523 9 21 9H9.82929C9.41746 10.1652 8.30622 11 7 11C5.69378 11 4.58254 10.1652 4.17071 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H4.17071C4.58254 5.83481 5.69378 5 7 5ZM2 16C2 15.4477 2.44772 15 3 15H14.1707C14.5825 13.8348 15.6938 13 17 13C18.3062 13 19.4175 13.8348 19.8293 15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H19.8293C19.4175 18.1652 18.3062 19 17 19C15.6938 19 14.5825 18.1652 14.1707 17H3C2.44772 17 2 16.5523 2 16ZM18 16C18 15.4477 17.5523 15 17 15C16.4477 15 16 15.4477 16 16C16 16.5523 16.4477 17 17 17C17.5523 17 18 16.5523 18 16Z"
        fill="#666666"
      />
    </svg>
  )
}

export default Adjust
