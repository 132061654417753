import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#FF2400"
      fillRule="evenodd"
      d="M22.132 10.516c1.147 0 1.868.596 1.868 1.54 0 1.009-.721 1.632-1.875 1.632h-1.192v1.48h-1.495v-4.652h2.694Zm-9.781 0c1.147 0 1.868.596 1.868 1.54 0 1.009-.721 1.632-1.875 1.632h-1.192v1.48H9.657v-4.652h2.694Zm-5.226-.174c1.317 0 2.044.682 2.044 1.81v.222H6.41l.013.06c.08.301.388.48.832.48.294 0 .573-.113.759-.314l1.07.39c-.36.511-1.042.826-1.887.826-1.344 0-2.15-.643-2.15-1.724 0-1.068.813-1.75 2.078-1.75Zm9.679 0c1.245 0 2.15.603 2.15 1.73 0 1.128-.905 1.744-2.15 1.744-1.246 0-2.15-.616-2.15-1.744 0-1.127.904-1.73 2.15-1.73ZM4.56 9v4.653H1.868C.72 13.653 0 13.056 0 12.113c0-1.01.72-1.632 1.874-1.632h1.193V9h1.494Zm12.243 2.247c-.42 0-.695.275-.695.826 0 .543.275.838.695.838.42 0 .695-.294.695-.838 0-.55-.276-.826-.695-.826Zm4.929.271h-.8v1.187h.793c.452 0 .695-.204.695-.597 0-.386-.236-.59-.688-.59Zm-9.783 0h-.8v1.187h.794c.452 0 .694-.204.694-.597 0-.386-.236-.59-.688-.59Zm-8.883-.054h-.793c-.453 0-.695.203-.695.596 0 .387.236.59.688.59h.8v-1.186Zm4.073-.39c-.44 0-.695.227-.73.599h1.425c0-.358-.255-.599-.695-.599Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
