import React, { useState, useRef, useEffect } from 'react';
import styles from './Modal.module.css';

// Provide default values for the context
const currentlyOpenModals = {
  names: new Set<string>(),
  openModal: (name: string) => {currentlyOpenModals.names.add(name)},
  closeModal: (name: string) => {currentlyOpenModals.names.delete(name); },
  getShouldAnimate: (name: string) => {
    let currentModelCount = currentlyOpenModals.names.has(name) ? 1 : 0;
    if (currentlyOpenModals.names.size - currentModelCount > 0) {
      return false
    }
    return true
  }
};

type ScaleModalProps = {
  isOpen: boolean;
  name: string; // Unique name for the modal
  useAnimations: boolean;
  children: React.ReactNode;
};

const ScaledAppearanceModal: React.FC<ScaleModalProps> = ({ isOpen, name, useAnimations, children }) => {
  const [shouldShowModal, setShouldShowModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldShowModal(true);
      currentlyOpenModals.openModal(name);
    }
  }, [isOpen, currentlyOpenModals]);

  return (
    ((shouldShowModal) &&
      <div
        style={{width: '100%', height: '100%'}}
        className={`${isOpen ? (useAnimations && currentlyOpenModals.getShouldAnimate(name) ? styles.scaleNatural : styles.scaleNaturalNoAnimation) : (useAnimations && currentlyOpenModals.getShouldAnimate(name) ? styles.scaleDown : styles.scaleDownNoAnimation)}`}
        onAnimationEnd={() => {
          if (!isOpen) {
            setShouldShowModal(false);
            currentlyOpenModals.closeModal(name);
          }
        }}
      >
        <div className={`${styles.modalContent}`} onClick={(e) => e.stopPropagation()} style={{width: '100%', height: '100%'}}>
          {children}
        </div>
      </div>
    )
  );
};

export default ScaledAppearanceModal;
