import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? props.size : 16}
    height={props.size ? props.size : 16}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : "#666"}
      fillRule="evenodd"
      d="M7 0a7 7 0 1 0 4.193 12.606l3.105 3.102a1 1 0 0 0 1.413-1.416l-3.104-3.1A7 7 0 0 0 7 0ZM2 7a5 5 0 1 1 10 0A5 5 0 0 1 2 7Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
