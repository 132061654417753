import { createReducer } from '@reduxjs/toolkit'
import { setImageElement } from './actions'

const initialState: any = {
  imageElements: [],
}

export const imageElementsReducer = createReducer(initialState, builder => {
  builder.addCase(setImageElement, (state, { payload }) => {
    state.imageElements = payload
  })
})
