import { Rectangle } from "./rectangle"
import ImageJs from 'image-js'


export class LatestImageInfo {
    latestImage: ImageJs;
    fittedImage: ImageJs;
    mask: ImageJs;
    fittedMask: ImageJs;
    boundingBox: Rectangle;

    constructor(latestImage: ImageJs, fittedImage: ImageJs, mask: ImageJs, fittedMask: ImageJs, boundingBox: Rectangle) {
        this.latestImage = latestImage;
        this.fittedImage = fittedImage;
        this.fittedMask = fittedMask;
        this.boundingBox = boundingBox;
        this.mask = mask;
    }
}

