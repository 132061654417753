import { EditorConfig, FabricCanvas, HandlerOptions } from '../common/interfaces'
import Handlers from '.'
import { IEditorContext } from '../context'
import EditorEventManager from '../EditorEventManager'

class BaseHandler {
  public canvas: FabricCanvas
  public root: Handlers
  public context: IEditorContext
  protected config: EditorConfig
  protected editorEventManager: EditorEventManager
  constructor({ canvas, root, context, editorEventManager, config }: HandlerOptions) {
    this.canvas = canvas
    this.root = root
    this.context = context
    this.editorEventManager = editorEventManager
    this.config = config
  }
}
export default BaseHandler
