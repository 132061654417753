import { fabric } from 'fabric'
import BaseHandler from './BaseHandler'
import store from '@/store/store'
import { setZoomRatio } from '@/store/slices/editor/action'
import { customAmplitude } from '@/utils/customAmplitude'

class ZoomHandler extends BaseHandler {
  minZoom = 10
  maxZoom = 300
  initialViewportX = 0
  initialViewportY = 0
  centerOffset = this.root.frameHandler.FRAME_PADDING_ADD_MENU / 2.0 - this.root.frameHandler.FRAME_PADDING_INSPECTOR / 2.0
  private isOpenInspector = true;
  public baseStep = 0.14
  sliderStep = 2.9
  // pre zoom before enter erase state
  preZoomBeforeErase
  public setIsOpenInspector(isOpenInspector) {
    this.isOpenInspector = isOpenInspector
  }

  zoomIn(pointer = null) {
    let zoomRatio = this.canvas.getZoom()
    zoomRatio += this.baseStep * zoomRatio
    if (zoomRatio <= 30) {
      const center = this.canvas.getCenter()
      let zoomPoint = new fabric.Point(center.left + this.centerOffset, center.top)
      if(pointer) {
        zoomPoint.x = pointer.x
        zoomPoint.y = pointer.y
      }
      this.zoomToPoint(zoomPoint, zoomRatio)
      store.dispatch(setZoomRatio(zoomRatio))
      const eventProperties = {
        Tool: 'bazaart.zoom',
      }
      customAmplitude('Selected tool', eventProperties)
    }
  }

  zoomOut(pointer = null) {
    let zoomRatio = this.canvas.getZoom()
    const prevZoomRatio = zoomRatio
    zoomRatio -= this.baseStep * zoomRatio
    if (zoomRatio >= 0.1) {
      if(prevZoomRatio > this.root.frameHandler.getFitRatio() && zoomRatio <= this.root.frameHandler.getFitRatio() ) {
        this.zoomToFit()
      } else {
        const center = this.canvas.getCenter()
        let zoomPoint = new fabric.Point(center.left + this.centerOffset, center.top)
        if(pointer) {
          zoomPoint.x = pointer.x
          zoomPoint.y = pointer.y
        }
        this.zoomToPoint(zoomPoint, zoomRatio)
        store.dispatch(setZoomRatio(zoomRatio))
      }
      const eventProperties = {
        Tool: 'bazaart.zoom',
      }
      customAmplitude('Selected tool', eventProperties)
    }
  }

  zoomToOne() {
    const center = this.canvas.getCenter()
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
    this.zoomToPoint(new fabric.Point(center.left, center.top), 1)
    store.dispatch(setZoomRatio(1))
  }

  zoomToFit() {
    const zoomFitRatio = this.root.frameHandler.getFitRatio()
    const center = this.canvas.getCenter()
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
    this.zoomToPoint(new fabric.Point(this.isOpenInspector ? center.left : center.left + this.root.frameHandler.FRAME_PADDING_INSPECTOR / 2.0, center.top), zoomFitRatio, true)
    store.dispatch(setZoomRatio(zoomFitRatio))
    this.root.scrollbarHandler.checkScrollBarVisibility()
    this.initialViewportX = this.canvas.viewportTransform[4];
    this.initialViewportY = this.canvas.viewportTransform[5];
  }

  zoomToRatio(zoomRatio) {
    const center = this.canvas.getCenter()
    this.zoomToPoint(new fabric.Point(center.left + this.centerOffset, center.top), zoomRatio)
    store.dispatch(setZoomRatio(zoomRatio))
  }

  zoomToPoint(point, zoom, toFit = false) {
    // const minZoom = 10
    // const maxZoom = 300
    let zoomRatio = zoom
    if (zoom <= this.minZoom / 100) {
      zoomRatio = this.minZoom / 100
    } else if (zoom >= this.maxZoom / 100) {
      zoomRatio = this.maxZoom / 100
    }
    if (toFit) {
      // when resizing canvas we apply an affine transform with scalex and scaley (== zoom) on both size and center of the canvas.
      // We want to reposition the center to the initial value so we substract the change.
      //
      //
      //   (4,4) _______________
      //        |               |
      //        |               |
      //        |    (10, 10)   |
      //        |               |
      //        |               |
      //        |_______________|
      //
      //  Downscale by 2:
      //   (2,2) ________
      //        |       |
      //        | (5, 5)|
      //        |_______|
      //
      //  but we wanted to keep it at the center of the view! so move it back there manually
      //   (4,4) ________
      //        |       |
      //        | (5, 5)|
      //        |_______|
      // TADAM

      let oldCenter = point
      let newCenter = new fabric.Point(point.x * zoom, point.y * zoom)

      let centerX = oldCenter.x - newCenter.x
      let centerY = oldCenter.y - newCenter.y

      this.canvas.setViewportTransform([zoom, 0, 0, zoom, centerX + this.centerOffset, centerY])
    } else {
      this.canvas.zoomToPoint(point, zoomRatio)
      this.root.scrollbarHandler.updateScrollPosition()
    }

    store.dispatch(setZoomRatio(zoomRatio))
    this.canvas.forEachObject(obj => obj.setCoords())
    this.root.transactionHandler.fireWithoutSave('canvas:modified')
    const frame = this.root.frameHandler.get()
    const hasTransparentBg = frame.fill && !(typeof frame.fill  === 'string')
    hasTransparentBg && this.root.frameHandler.setTransparentBg(false)
  }
}

export default ZoomHandler