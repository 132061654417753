import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.988 9h-.247L2 20.741v.247a1 1 0 0 0 1 1h.414l11.574-11.574V10a1 1 0 0 0-1-1ZM8.23 9h2.683L2 17.913V15.23L8.23 9ZM5.402 9H3a1 1 0 0 0-1 1v2.402L5.402 9Zm9.586 4.242-8.746 8.746h2.836l5.91-5.91v-2.836Zm-1 8.746h-2.081l3.081-3.081v2.081a1 1 0 0 1-1 1ZM3 7a3 3 0 0 0-3 3v10.988a3 3 0 0 0 3 3h10.988a3 3 0 0 0 3-3V10a3 3 0 0 0-3-3H3ZM23.641 8.228a1 1 0 0 1 .126 1.41L23 8.995l.767.641-.006.007-.016.019-.056.066a27.128 27.128 0 0 1-.847.95c-.242.258-.515.536-.767.755a3.574 3.574 0 0 1-.408.315c-.072.046-.164.1-.269.144a1.133 1.133 0 0 1-1.027-.047l-.002-.001a1.736 1.736 0 0 1-.22-.148 4.246 4.246 0 0 1-.336-.298c-.217-.21-.467-.48-.697-.735a40.338 40.338 0 0 1-.817-.936l-.055-.066-.02-.023.762-.64-.762.64a1 1 0 0 1 1.532-1.286l.017.02.052.062a43.79 43.79 0 0 0 .776.89c.13.143.257.28.374.404.128-.124.267-.268.41-.419a24.213 24.213 0 0 0 .78-.877l.051-.06.013-.015.003-.003m1.41-.127a1 1 0 0 0-1.41.126l1.41-.126Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.01 1a1 1 0 0 1 1-1c2.331 0 4.703 1.043 6.413 2.643 1.714 1.602 2.874 3.88 2.514 6.38a1 1 0 0 1-1.98-.284c.242-1.68-.518-3.343-1.9-4.636C16.672 2.808 14.775 2 13.01 2a1 1 0 0 1-1-1Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
