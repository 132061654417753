import { lightTheme } from '@/customTheme'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { selectUser } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { styled } from 'baseui'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import useAppContext from '@/hooks/useAppContext'
import Icons from '@/scenes/Editor/components/Icons'
import { useEffect, useState } from 'react'
import api from '@/services/api'

const WrapAutoScroll = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.style,
}))

function ModalWhatNew({ onClosed = () => {} }) {
  const user = useSelector(selectUser)
  const { setShowModalType } = useAppContext()
  const [data, setData] = useState(null)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onClickPrimaryButton = e => {
    e.preventDefault()
    e.stopPropagation()
    if(!user || !user.userIsPremium) {
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: 'BtSubscriptionWhatsNew',
          callback: () => {
          }
        })
      )
      customAmplitude('Whats New - Try Premium')
    } else {
    }
    setShowModalType(null)
  }

  const getWhatsNew = async() => {
    const data = await api.getWhatsNew().then(data => {
      return data
    }).catch(err => err)
    const newIds = localStorage.getItem('whatNewIds') ?? []
    const filterData = data.objects.filter(d => newIds.includes(d.id))
    if(filterData.length) {
      setData(filterData)
    } else {
      setShowModalType(null)
    }
  }

  useEffect(() => {
    getWhatsNew()
  }, [])

  return (
    <>
      {
        data && data.length ? <div
        style={{
          width: '100vw',
          height: '100vh',
          maxWidth: '920px ',
          maxHeight: '652px',
          minWidth: '920px ',
          minHeight: '652px',
          flexDirection: 'row',
          background: 'rgba(255, 255, 255, 1)',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'absolute',
          display: 'flex',
        }}
        id="modal-what-new"
      >
        <>
          {/* left */}
          <WrapAutoScroll
            style={{
              padding: '232px 39px 0 32px',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              width: '50%',
              justifyContent: 'start'
            }}
          >
            <h2 style={{ margin: 0, ...lightTheme.typography.Small16Bold }}>
              {t('New in Bazaart')}
            </h2>
            <h4
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
                margin: '4px 0 32px 0',
                ...lightTheme.typography.Title32Bold,
                fontFamily: 'ES-Rebond-Grotesque-Classic, New-Hero, Roboto',
              }}
            >
              {data ? t(data[0]?.text) : ''}
            </h4>
            <ButtonCustom
              type={SizeButton.LARGE}
              kind={KIND.primary}
              style={{
                position: 'relative',
                ...lightTheme.typography.Small14Semibold,
                color: '#fff',
                margin: 0,
                minWidth: '165px',
                width: 'fit-content'
              }}
              onClick={e => onClickPrimaryButton(e)}
            >
              {user?.userIsPremium ? t('Got it!') : t('Try it now!')}
            </ButtonCustom>
          </WrapAutoScroll>
          {/* right */}
          <div
            style={{
              width: '50%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingLeft: 0,
              background: 'rgba(249, 249, 249, 1)',
            }}
          >
            <video
              width="100%"
              height="100%"
              style={{
                objectFit: 'cover',
              }}
              loop
              autoPlay
              muted
              playsInline
              src={data ? data[0].video : ''}
              // poster={srcVideo}
            >
            </video>
          </div>
          {/* clsoe icon */}
          <div
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.stopPropagation()
              onClosed()
              setShowModalType(null)
            }}
          >
            <Icons.WhiteCloseIcon size={24} />
          </div>
        </>
      </div> : null
      }
    </>
  )
}

export default ModalWhatNew
