export class PromiseResultWithParams<Param, Result> {
    parameters: Param;
    result: Result;

    /**
     *
     */
    constructor(parameters: Param, result: Result) {
        this.parameters = parameters;
        this.result = result;
    }
}

export class PromiseResolver<K, Param, Result> { 
    private pendingPromises: Map<K, { resolve: (value: PromiseResultWithParams<Param, Result>) => void, reject: (reason?: any) => void, params: Param }[]> = new Map();
    private activePromises: Map<K, Promise<Result>> = new Map();

    constructor() {}

    // Function to add a promise for a given key with parameters
    add(key: K, params: Param, executor: (params: Param) => Promise<Result>): Promise<PromiseResultWithParams<Param, Result>> {
        return new Promise<PromiseResultWithParams<Param, Result>>((resolve, reject) => {
            if (!this.pendingPromises.has(key)) {
                this.pendingPromises.set(key, []);
            }
            
            this.pendingPromises.get(key)!.push({ resolve, reject, params });

            if (!this.activePromises.has(key)) {
                const promise = this.execute(key, executor);
                this.activePromises.set(key, promise);
            }
        });
    }

    // Function to execute the promise and resolve all pending promises for the key
    private async execute(key: K, executor: (params: Param) => Promise<Result>): Promise<Result> {
        const pending = this.pendingPromises.get(key);
        if (!pending) {
            return Promise.reject("No pending promises found for the key.");
        }

        try {
            const params = pending[0].params; // Take the parameters from the first pending promise
            const result = await executor(params);
            this.resolveAll(key, result);
            return result;
        } catch (error) {
            this.rejectAll(key, error);
            throw error;
        } finally {
            this.pendingPromises.delete(key);
            this.activePromises.delete(key);
        }
    }

    // Function to resolve all pending promises for a given key with their unique parameters
    private resolveAll(key: K, result: Result) {
        const pending = this.pendingPromises.get(key);
        if (pending) {
            pending.forEach(({ params, resolve }) => {
                let res = { parameters: params, result: result };
                resolve(res);
            });
        }
    }

    // Function to reject all pending promises for a given key
    private rejectAll(key: K, reason: any) {
        const pending = this.pendingPromises.get(key);
        if (pending) {
            pending.forEach(({ reject }) => reject(reason));
        }
    }
}
