import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <g filter="url(#soft)" opacity={0.6}>
      <circle cx={25} cy={25} r={13} fill="#666" />
    </g>
    <defs>
      <filter
        id="soft"
        width={36.4}
        height={36.4}
        x={6.8}
        y={6.8}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_9248_29728"
          stdDeviation={2.6}
        />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
