import { lightTheme } from '@/customTheme'
import { CSSProperties } from 'react'

function Loading({ style, fill = lightTheme.colors.primary }: { style?: CSSProperties, fill?: string }) {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: 'rotation 2s infinite linear',
        ...style,
      }}
    >
      <style>{`
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
        `}</style>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99904 29.8555C6.33171 26.9676 5.60836 23.6309 5.93009 20.3118C6.25182 16.9927 7.60255 13.8571 9.79348 11.3431C11.9844 8.82924 14.9061 7.0627 18.1501 6.29044C21.3941 5.51819 24.7983 5.7788 27.887 7.03587C30.9756 8.29293 33.5943 10.4836 35.377 13.3017C37.1598 16.1199 38.0175 19.4246 37.8302 22.754C37.6429 26.0834 36.4201 29.271 34.3326 31.8715C32.2451 34.4719 29.3973 36.3552 26.1872 37.258L25.4536 34.6497C28.1201 33.8997 30.4857 32.3354 32.2197 30.1753C33.9537 28.0153 34.9694 25.3674 35.125 22.6018C35.2805 19.8362 34.5681 17.0911 33.0873 14.7503C31.6064 12.4094 29.4312 10.5897 26.8656 9.54547C24.3 8.50128 21.4722 8.2848 18.7776 8.92628C16.0829 9.56776 13.656 11.0351 11.8361 13.1233C10.0162 15.2115 8.89419 17.8162 8.62694 20.5732C8.35969 23.3302 8.96055 26.1019 10.3455 28.5007L7.99904 29.8555Z"
          fill={fill}
        />
      </svg>
    </div>
  )
}

export default Loading
