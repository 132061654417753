import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <rect width={49} height={49} x={0.5} y={0.5} fill="#E5E5E5" rx={3.5} />
    <path
      fill="#8DB849"
      fillRule="evenodd"
      d="M24.535 18.452a.693.693 0 0 1 .408.107c-.183.095-.36.232-.526.41-.431.463-.761 1.18-.893 1.872l-1.182.366c.233-1.09 1.146-2.725 2.193-2.755Zm.924 1.79-1.439.445c.14-.532.403-1.062.727-1.41.12-.129.289-.273.488-.356.188.392.229.946.224 1.32Zm1.12-.347-.669.207c-.005-.343-.046-.82-.206-1.234.515.098.768.68.875 1.027Zm3.243.8a.136.136 0 0 0-.123-.113c-.051-.004-1.048-.02-1.048-.02l-.916-.891c-.082-.083-.243-.058-.305-.04l-.419.13a2.93 2.93 0 0 0-.2-.492c-.297-.566-.732-.866-1.257-.867h-.002c-.036 0-.072.004-.108.007a1.101 1.101 0 0 0-.921-.408c-.679.02-1.354.509-1.902 1.379-.385.612-.678 1.381-.761 1.976l-1.336.414c-.393.123-.405.136-.457.506-.039.28-1.067 8.233-1.067 8.233L27.62 32l3.735-.928s-1.524-10.305-1.533-10.376Z"
      clipRule="evenodd"
    />
    <path
      fill="#5A863E"
      fillRule="evenodd"
      d="M29.698 20.582c-.05-.004-1.047-.02-1.047-.02s-.834-.809-.916-.891a.205.205 0 0 0-.116-.054V32l3.736-.928-1.534-10.376a.136.136 0 0 0-.123-.114Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFFFFE"
      fillRule="evenodd"
      d="m25.55 22.503-.435 1.623s-.484-.22-1.057-.184c-.842.053-.85.584-.842.717.045.726 1.955.884 2.063 2.585.084 1.337-.71 2.253-1.854 2.325-1.373.086-2.13-.724-2.13-.724l.292-1.237s.76.573 1.37.535a.538.538 0 0 0 .525-.577c-.06-.947-1.615-.891-1.713-2.447-.083-1.31.777-2.637 2.675-2.756.73-.047 1.105.14 1.105.14Z"
      clipRule="evenodd"
    />
    <rect width={49} height={49} x={0.5} y={0.5} stroke="#E5E5E5" rx={3.5} />
  </svg>
)
export default SvgComponent
