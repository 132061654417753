import { BasePixiFilter } from '@scenes/engine/utils/PixijsFilters/BasePixiFilter'
import { Filter } from '@pixi/core';
import { Size } from '@scenes/engine/objects/media-repository/size'
import fragment from "./vibrance.frag";
import vertex from "../default.vert";

// @ts-ignore
export class VibranceFilter implements IAdjustmentFilter {
  vibrance: number;
  pixijsFilter: Filter

  minimumForKey(key: string): number {
    if (key == "vibrance") {
      return -1.2;
    }
  }
  maximumForKey(key: string): number {
    if (key == "vibrance") {
      return 1.2;
    }
  }

  defaultForKey(key: string): number {
    if (key == "vibrance") {
      return 0.0;
    }
  }


  constructor() {
    this.vibrance = this.defaultForKey("vibrance");
  }

  buildFilter({vibrance}) {
    if (this.isValid(vibrance)) { this.vibrance = vibrance; }
    if (!this.pixijsFilter) {
      this.pixijsFilter = VibranceFilter.generateFilter(this.vibrance);
    } else {
      this.pixijsFilter.uniformGroup.uniforms["uVibrance"] = this.vibrance;
    }
  }

  isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

  static generateFilter(vibrance: number): Filter {
    let filter = new Filter(
      vertex,
      fragment,
        {
          uVibrance: vibrance
        }
    );
    return filter;
  }
}