import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <g filter="url(#normal)">
      <circle cx={25} cy={25} r={15} fill="#666" />
    </g>
    <defs>
      <filter
        id="normal"
        width={50}
        height={50}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_9248_29726"
          stdDeviation={5}
        />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
