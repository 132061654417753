import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#666"
      strokeWidth={2}
      d="M7.143 20.217A9.558 9.558 0 0 0 11 21a9.53 9.53 0 0 0 3.845-.783 10.266 10.266 0 0 0 3.2-2.172 10.286 10.286 0 0 0 2.173-3.195A9.52 9.52 0 0 0 21 11c0-1.379-.261-2.66-.778-3.852a10.225 10.225 0 0 0-2.171-3.199 10.206 10.206 0 0 0-3.204-2.17A9.562 9.562 0 0 0 11.011 1a9.618 9.618 0 0 0-3.858.778A10.206 10.206 0 0 0 3.949 3.95a10.224 10.224 0 0 0-2.171 3.199A9.578 9.578 0 0 0 1 11c0 1.379.261 2.66.778 3.852a10.265 10.265 0 0 0 5.366 5.365Z"
    />
    <mask
      id="a"
      width={20}
      height={20}
      x={1}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.013 21c1.38 0 2.677-.259 3.889-.776a10.04 10.04 0 0 0 3.188-2.143 9.938 9.938 0 0 0 2.14-3.184c.513-1.211.77-2.51.77-3.897a9.842 9.842 0 0 0-.77-3.884 10.013 10.013 0 0 0-5.322-5.34A9.762 9.762 0 0 0 11.025 1c-1.397 0-2.7.259-3.907.776a10.081 10.081 0 0 0-5.341 5.34A9.774 9.774 0 0 0 1 11a9.587 9.587 0 0 0 .77 3.897 10.165 10.165 0 0 0 2.147 3.184 9.965 9.965 0 0 0 3.182 2.143c1.212.517 2.516.776 3.914.776Z"
      />
    </mask>
    <path
      fill="#666"
      d="M11.038 3.2H3.37v2h7.667zM11.172 6.6H1.505v2h9.667zM11.038 10H1.37v2h9.667zM11.038 13.4h-9v2h9zM11.038 16.8H3.9v2h7.137z"
    />
    <path fill="#666" d="M12.038.977h-2v20.09h2z" />
  </svg>
)
export default SvgComponent
