import * as React from 'react'

const SvgComponent = props => (
  <svg width="50" height="29" viewBox="0 0 50 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.02631" width="49" height="27.2895" rx="3.5" fill="#E5E5E5"/>
    <rect x="0.5" y="1.02631" width="49" height="27.2895" rx="3.5" stroke="#E5E5E5"/>
  </svg>

)

export default SvgComponent
