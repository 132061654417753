import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={lightTheme.colors.blackGray}
      d="M1.837 6.726c0-1.174.022-2.349-.009-3.522C1.812 2.586 2.26 1.992 3.051 2c1.59.016 3.18.005 4.77.005h2.288c.768.001 1.23.453 1.231 1.215.003 2.335.003 4.67 0 7.007 0 .766-.457 1.213-1.228 1.213-2.359 0-4.718-.011-7.077.007-.716.006-1.224-.54-1.207-1.198.03-1.173.009-2.348.009-3.523Zm4.738 2.777h2.542c.24 0 .265-.023.265-.253V4.202c0-.231-.03-.26-.263-.26-1.695 0-3.39.002-5.085-.002-.184 0-.26.062-.26.249.003 1.69.003 3.379 0 5.068-.001.188.073.25.258.248.848-.006 1.695-.002 2.543-.002ZM6.603 13.964a3.326 3.326 0 0 1 1.365-.828c1.656-.503 3.418.424 4.015 2.11.404 1.142.23 2.22-.368 3.25-.545.938-1.276 1.726-2.119 2.398-.75.599-1.55 1.138-2.335 1.695-.365.258-.74.243-1.122.006-1.405-.872-2.718-1.854-3.785-3.127-.59-.705-1.064-1.478-1.21-2.402-.296-1.889.924-3.732 2.762-4.025 1.018-.162 1.907.135 2.673.811l.124.112Zm3.827 2.411c.003-.219-.087-.541-.262-.84-.577-.99-1.836-1.044-2.485-.101a2.492 2.492 0 0 0-.24.488c-.148.368-.462.595-.844.594a.883.883 0 0 1-.84-.595 2.728 2.728 0 0 0-.128-.306c-.41-.783-1.282-1.079-1.992-.679-.569.32-.948 1.082-.866 1.728.076.599.38 1.09.744 1.55.83 1.05 1.88 1.85 2.988 2.581.048.032.164.015.217-.023.485-.345.977-.683 1.44-1.057.74-.599 1.42-1.26 1.908-2.088.204-.346.358-.712.36-1.252ZM17.536 22.552h-4.534c-.61-.002-1.006-.435-.9-.988.027-.114.071-.223.132-.323a6737.1 6737.1 0 0 1 4.559-7.837c.36-.62 1.133-.628 1.494-.008a3465.32 3465.32 0 0 1 4.578 7.872c.355.612-.022 1.269-.736 1.281-.604.011-1.21.003-1.814.003h-2.779Zm2.689-1.945-2.683-4.614-2.683 4.614h5.366ZM17.687 2.005c2.733-.005 4.98 2.214 4.986 4.917.006 2.773-2.233 4.905-4.849 4.972-2.792.071-5.09-2.157-5.106-4.865-.016-2.798 2.19-5.019 4.969-5.024Zm3.034 4.967c.012-1.648-1.342-3.016-2.998-3.03-1.638-.013-3.024 1.33-3.063 2.91a3.045 3.045 0 0 0 3.022 3.112c1.634.033 3.027-1.346 3.04-2.992Z"
    />
  </svg>
)
export default SvgComponent
