import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path fill="#F56400" d="M8 15.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z" />
    <path
      fill="#fff"
      d="M4.49 7.646V6.45c0-.044.005-.072.08-.072h1.016c.178 0 .275.151.346.435l.058.227h.173c.031-.644.058-.923.058-.923s-.435.049-.692.049h-1.3l-.697-.022v.186l.235.044c.165.03.204.066.218.217 0 0 .013.444.013 1.176s-.013 1.172-.013 1.172c0 .133-.054.182-.218.213l-.235.044v.186l.697-.022H5.39c.262 0 .87.022.87.022.013-.16.102-.883.115-.963h-.164l-.173.396c-.138.31-.337.332-.56.332h-.66c-.222 0-.328-.088-.328-.279V7.855s.483 0 .652.013c.124.01.2.044.24.218l.053.23h.19l-.014-.58.027-.587h-.19l-.063.257c-.04.169-.066.2-.24.218-.194.022-.656.018-.656.018v.004Zm2.631-1.073c-.062.275-.124.488-.342.63a.76.76 0 0 1-.319.124v.169h.396v1.362c0 .38.253.568.59.568.261 0 .532-.111.625-.342l-.093-.12a.45.45 0 0 1-.364.187c-.195 0-.301-.133-.301-.47V7.478l.656.049.036-.302-.693.027v-.674l-.19-.005Zm1.402 2.095-.169.004a3.998 3.998 0 0 1 0 .62s.337.125.68.125c.46 0 .829-.222.829-.657 0-.745-1.118-.634-1.118-1.135 0-.209.186-.289.39-.289.16 0 .293.058.32.142l.11.333.165-.009c.013-.177.022-.38.048-.545-.142-.062-.44-.098-.625-.098-.435 0-.785.19-.785.617 0 .74 1.09.59 1.09 1.135 0 .195-.119.333-.39.333-.249 0-.372-.129-.42-.257l-.125-.32Zm2.674.512c-.257.718-.572.904-.855.904-.12 0-.177-.054-.195-.138l-.066-.345-.186.009c-.036.203-.071.43-.12.615.111.084.297.128.443.128.306 0 .77-.04 1.196-1.05l.713-1.682c.057-.137.08-.15.248-.217l.093-.035v-.155l-.42.022-.453-.022v.155l.116.035c.115.036.168.08.168.16 0 .04-.013.08-.036.142-.066.168-.478 1.169-.589 1.381l.111-.04a38.92 38.92 0 0 1-.536-1.426.221.221 0 0 1-.013-.07c0-.071.049-.13.155-.151l.146-.031v-.155l-.606.022-.483-.022v.155l.08.027c.11.035.137.066.204.221.367.841.54 1.302.774 1.904l.111-.34Z"
    />
  </svg>
)
export default SvgComponent
