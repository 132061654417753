import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
    <g filter="url(#a_brush)">
      <circle cx={15} cy={15} r={8} fill="#757577" />
    </g>
    <defs>
      <filter
        id="a_brush"
        width={29.333}
        height={29.333}
        x={0.333}
        y={0.333}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_7065_9284" stdDeviation={3.333} />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent