import { useEditorContext } from '@/scenes/engine'
import { useEffect, useState } from 'react'
import Icons from '../../../Icons'
import { KIND, SHAPE } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import ButtonCustom from '@/components/ButtonCustom'
import SliderCustom from '@/components/SliderCustom'
import { LabelLarge } from 'baseui/typography'
import CanvasImageRenderer from '@scenes/engine/utils/canvasImageRenderer'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { useSelector } from 'react-redux'
import * as amplitude from '@amplitude/analytics-browser'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'


function Adjust({ inputObject }) {


  const rangeValueMap = CanvasImageRenderer.getInstance().adjustmentFilterFactory.rangeValueForProperty
  const [isInitial, setIsInitial] = useState(true)
  const [shouldRedraw, setShouldRedraw] = useState(false)

  const filterPacks = useSelector(selectImageElements)

  const [contrastValue, setContrastValue] = useState(rangeValueMap.get('contrast').defaultValue)
  const [saturationValue, setSaturationValue] = useState(rangeValueMap.get('saturation').defaultValue)
  const [vibranceValue, setVibranceValue] = useState(rangeValueMap.get('vibrance').defaultValue)
  const [blurValue, setBlurValue] = useState(rangeValueMap.get('blur').defaultValue)
  const [exposureValue, setExposureValue] = useState(rangeValueMap.get('exposure').defaultValue)
  const [tintValue, setTintValue] = useState(rangeValueMap.get('tint').defaultValue)
  const [shadowValue, setShadowValue] = useState(rangeValueMap.get('shadow').defaultValue)
  const [fadeValue, setFadeValue] = useState(rangeValueMap.get('fade').defaultValue)
  const [sharpnessValue, setSharpnessValue] = useState(rangeValueMap.get('sharpness').defaultValue)
  const [highlightsValue, setHighlightsValue] = useState(rangeValueMap.get('highlights').defaultValue)
  const [temperatureValue, setTemperatureValue] = useState(rangeValueMap.get('temperature').defaultValue)

  const [preEffectsValue, setPreEffectsValue] = useState({})
  const [preFiltersValue, setPreFiltersValue] = useState([])

  const [filterObject, setFilterObject] = useState(null)

  const minUIValue = 0
  const maxUIValue = 100

  const editor = useEditorContext().editor

  useEffect(() => {
    setFilterObject(inputObject)
  }, [inputObject])

  useEffect(() => {
    if (!filterObject) { return }
    // @ts-ignore
    convertStateToUI(filterObject.effects?.adjustments)
  }, [filterObject])


  const [isInit, setIsInit] = useState(true)
  const [isReset, setIsReset] = useState(false)

  useEffect(() => {
    if (!editor) return
    const handleChanges = (e) => {
      if(e.afterUndo) {
        setIsInit(true)
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
  }, [editor])

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
    } else if (!isReset) {
      renderImage();
    }
  }, [contrastValue, saturationValue, vibranceValue, blurValue, exposureValue, tintValue,
    shadowValue, fadeValue, sharpnessValue, highlightsValue, temperatureValue])

  const updateEffect = (finalValue = false) => {
    let sliderOptions: any = { isSliderUpdate: finalValue }
    if (finalValue) {
      //@ts-ignore
      sliderOptions = { isSliderUpdate: finalValue, property: ['effects', 'filters'], value: [preEffectsValue, preFiltersValue] }
      //@ts-ignore
      let assign = Object.assign({}, filterObject.effects);
      setPreEffectsValue(assign)
      //@ts-ignore
      let filters = Object.assign([], filterObject.filters);
      //@ts-ignore
      setPreFiltersValue(filters)
    }
    //@ts-ignore
    editor.handlers.objectsHandler.updateActive({ effects: filterObject.effects, filters: filterObject.filters }, filterObject, sliderOptions)
  }

  const convertStateToUI = (adjustments: any) => {
    // if (!adjustments) { return; }
    setContrastValue(adjustments ? adjustments.contrast : rangeValueMap.get('contrast').defaultValue)
    setSaturationValue(adjustments ? adjustments.saturation : rangeValueMap.get('saturation').defaultValue)
    setVibranceValue(adjustments ? adjustments.vibrance : rangeValueMap.get('vibrance').defaultValue)
    setExposureValue(adjustments ? adjustments.exposure : rangeValueMap.get('exposure').defaultValue);
    setTemperatureValue(adjustments ? adjustments.temperature : rangeValueMap.get('temperature').defaultValue);
    setTintValue(adjustments ? adjustments.tint : rangeValueMap.get('tint').defaultValue);
    setFadeValue(adjustments ? adjustments.fade : rangeValueMap.get('fade').defaultValue);
    setSharpnessValue(adjustments ? adjustments.sharpness : rangeValueMap.get('sharpness').defaultValue);
    setHighlightsValue(adjustments ? adjustments.highlights : rangeValueMap.get('highlights').defaultValue);
    setShadowValue(adjustments ? adjustments.shadow : rangeValueMap.get('shadow').defaultValue);
    setBlurValue(adjustments ? adjustments.blur : rangeValueMap.get('blur').defaultValue)

    // @ts-ignore
    let assign = Object.assign({}, filterObject.effects);
    setPreEffectsValue(assign)
    //@ts-ignore
    let filters = Object.assign([], filterObject.filters);
    setPreFiltersValue(filters)
    setIsReset(false)
  }

  const convertUIToState = (): any => {
    let adjustments = {
      contrast: contrastValue,
      temperature: temperatureValue,
      vibrance: vibranceValue,
      sharpness: sharpnessValue,
      brightness: 0, // default
      tint: tintValue,
      blur: blurValue,
      highlights: highlightsValue,
      saturation: saturationValue,
      fade: fadeValue,
      exposure: exposureValue,
      shadow: shadowValue
    }
    return adjustments
  }

  const reset = () => {

    let resetAdjustments = {
      contrast: rangeValueMap.get('contrast').defaultValue,
      saturation: rangeValueMap.get('saturation').defaultValue,
      vibrance: rangeValueMap.get('vibrance').defaultValue,
      exposure: rangeValueMap.get('exposure').defaultValue,
      blur: rangeValueMap.get('blur').defaultValue,
      tint: rangeValueMap.get('tint').defaultValue,
      shadow: rangeValueMap.get('shadow').defaultValue,
      fade: rangeValueMap.get('fade').defaultValue,
      sharpness: rangeValueMap.get('sharpness').defaultValue,
      highlights: rangeValueMap.get('highlights').defaultValue,
      temperature: rangeValueMap.get('temperature').defaultValue
    }
    setIsReset(true)
    setIsInitial(true);
    (async () => {
      await doRenderImage(null)

      // @ts-ignore
      filterObject.effects.adjustments = resetAdjustments

      convertStateToUI(resetAdjustments)
      updateEffect(true)
    })()
  }

  const renderImage = () => {
    setShouldRedraw(true)
  }

  useEffect(() => {
    if (!shouldRedraw) {
      return
    }

    setShouldRedraw(false)
    let adjustments = convertUIToState()
    if (isInitial) {
      setIsInitial(false)
      // for (let adjustmentName in adjustments) {
      //   adjustments[adjustmentName] = adjustments[adjustmentName] == rangeValueMap.get(adjustmentName).minimumValue
      //     ? rangeValueMap.get(adjustmentName).defaultValue : adjustments[adjustmentName]
      // }
      convertStateToUI(adjustments)
    }
    (async () => {
      await doRenderImage(adjustments)
    })()
  })

  const doRenderImage = async (adjustments: any = null) => {
    // @ts-ignore
    filterObject.effects.adjustments = adjustments ? Object.assign({}, adjustments) : {}
    // @ts-ignore
    await CanvasImageRenderer.getInstance().render(filterObject, filterPacks)
    editor.handlers.objectsHandler.renderAll()
  }

  const updateContrast = async (value: number) => {
    setContrastValue(value);
    setSaturationValue(saturationValue);
    updateEffect(false)
  }

  const updateSaturation = async (value: number) => {
    setSaturationValue(value)
    setContrastValue(contrastValue)
    updateEffect(false)
  }

  const updateTint = async (value: number) => {
    setTintValue(value);
    setTemperatureValue(temperatureValue);
    updateEffect(false)
  }

  const updateTemperature = async (value: number) => {
    setTemperatureValue(value);
    setTintValue(tintValue);
    updateEffect(false)
  }

  const updateExposure = async (value: number) => {
    setExposureValue(value);
    updateEffect(false)
  }

  const updateVibrance = async (value: number) => {
    setVibranceValue(value);
    updateEffect(false)
  }

  const updateBlur = async (value: number) => {
    setBlurValue(value);
    updateEffect(false)
  }

  const updateHighlights = async (value: number) => {
    setHighlightsValue(value);
    setShadowValue(shadowValue);
    updateEffect(false)
  }

  const updateShadow = async (value: number) => {
    setShadowValue(value);
    setHighlightsValue(highlightsValue);
    updateEffect(false)
  }

  const updateSharpness = async (value: number) => {
    setSharpnessValue(value);
    updateEffect(false)
  }

  const updateFade = async (value: number) => {
    setFadeValue(value);
    updateEffect(false)
  }

  const { t } = useTranslation()

  return (
    <>
        {/* Exposure */}
        <SliderCustom
          icon={<Icons.Exposure size={24} />}
          name={t('Exposure')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('exposure').maximumValue}
          logicMinimum={rangeValueMap.get('exposure').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateExposure(logicValue)}
          value={exposureValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
          style={{marginTop: 0}}
        />
        {/* Contrast */}
        <SliderCustom
          icon={<Icons.Contrast size={24} />}
          name={t('Contrast')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('contrast').maximumValue}
          logicMinimum={rangeValueMap.get('contrast').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateContrast(logicValue)}
          value={contrastValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Saturation */}
        <SliderCustom
          icon={<Icons.Saturation size={24} />}
          name={t('Saturation')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('saturation').maximumValue}
          logicMinimum={rangeValueMap.get('saturation').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateSaturation(logicValue)}
          value={saturationValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Vibrance */}
        <SliderCustom
          icon={<Icons.Vibrance size={24} />}
          name={t('Vibrance')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('vibrance').maximumValue}
          logicMinimum={rangeValueMap.get('vibrance').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateVibrance(logicValue)}
          value={vibranceValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Temperature */}
        <SliderCustom
          icon={<Icons.Warmth size={24} />}
          name={t('Warmth')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('temperature').maximumValue}
          logicMinimum={rangeValueMap.get('temperature').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateTemperature(logicValue)}
          value={temperatureValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Tint */}
        <SliderCustom
          icon={<Icons.Tint size={24} />}
          name={t('Tint')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('tint').maximumValue}
          logicMinimum={rangeValueMap.get('tint').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateTint(logicValue)}
          value={tintValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Shadow */}
        <SliderCustom
          icon={<Icons.Shadow size={24} />}
          name={t('Shadow')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('shadow').maximumValue}
          logicMinimum={rangeValueMap.get('shadow').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateShadow(logicValue)}
          value={shadowValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Highlights */}
        <SliderCustom
          icon={<Icons.HighLight size={24} />}
          name={t('Highlights')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('highlights').maximumValue}
          logicMinimum={rangeValueMap.get('highlights').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateHighlights(logicValue)}
          value={highlightsValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Fade */}
        <SliderCustom
          icon={<Icons.Fade size={24} />}
          name={t('Fade')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('fade').maximumValue}
          logicMinimum={rangeValueMap.get('fade').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateFade(logicValue)}
          value={fadeValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Sharpness */}
        <SliderCustom
          icon={<Icons.Sharpness size={24} />}
          name={t('Sharpness')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          logicMaximum={rangeValueMap.get('sharpness').maximumValue}
          logicMinimum={rangeValueMap.get('sharpness').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateSharpness(logicValue)}
          value={sharpnessValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* Blur */}
        <SliderCustom
          icon={<Icons.Blur size={24} />}
          name={t('Blur')}
          minValue={minUIValue}
          maxValue={maxUIValue}
          step={10}
          logicMaximum={rangeValueMap.get('blur').maximumValue}
          logicMinimum={rangeValueMap.get('blur').minimumValue}
          listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          onChangeValue={(uiValue, logicValue) => updateBlur(logicValue)}
          value={blurValue}
          onFinalChangeValue={(uiValue, logicValue) => {
            const eventProperties = {
              Tool: 'bazaart.style.effects',
              'Layer Type': filterObject.type,
            }
            customAmplitude('Selected tool', eventProperties)
            updateEffect(true)
          }}
        />
        {/* btn reset */}
        <div className="mt-50">
          <ButtonCustom 
            kind={KIND.tertiary}
            type={SizeButton.LARGE}
            style={{ marginBottom: '50px' }}
            onClick={async () => {
              reset()
            }}
          >
            <LabelLarge>{t('Reset')}</LabelLarge>
          </ButtonCustom>
        </div>
    </>
  )
}

export default Adjust
