import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M15.034 12.205a5.085 5.085 0 0 1 2.394-4.23 5.206 5.206 0 0 0-4.055-2.198c-1.706-.18-3.36 1.024-4.23 1.024-.885 0-2.224-1.006-3.665-.976A5.443 5.443 0 0 0 .933 8.604c-1.965 3.411-.5 8.424 1.383 11.182.942 1.35 2.042 2.858 3.483 2.804 1.409-.058 1.935-.9 3.636-.9 1.685 0 2.18.9 3.649.866 1.512-.024 2.465-1.356 3.373-2.719A11.18 11.18 0 0 0 18 16.687a4.915 4.915 0 0 1-2.966-4.482ZM12.259 3.964A4.972 4.972 0 0 0 13.39.408a5.033 5.033 0 0 0-3.259 1.69A4.774 4.774 0 0 0 8.97 5.524a4.21 4.21 0 0 0 3.289-1.559Z"
    />
  </svg>
)
export default SvgComponent
