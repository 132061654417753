import {DropShadowFilter} from '@pixi/filter-drop-shadow';
import type { FilterSystem, RenderTexture } from '@pixi/core';
import type { CLEAR_MODES } from '@pixi/constants';
import { BlurFilter } from '@pixi/filter-blur'
import { DropShadowFilterOptions } from 'pixi-filters'
import { IValueRangeFilter } from '@scenes/engine/utils/PixijsFilters/IValueRangeFilter'
import { settings } from '@pixi/core'
import { FilterSimpleResize } from '@scenes/engine/utils/PixijsFilters/filter-simple-resize'


/**
 * Drop shadow filter.<br>
 * ![original](../tools/screenshots/dist/original.png)![filter](../tools/screenshots/dist/drop-shadow.png)
 * @class
 * @extends PIXI.Filter
 * @memberof PIXI.filters
 * @see @pixi/filter-drop-shadow
 * @see pixi-filters
 */
class DropShadowHDFilter extends DropShadowFilter implements IValueRangeFilter
{
  minimumForKey(key: string): number {
    switch (key){
      case "blur": return 0;
      case "opacity": return 0;
      case "angle": return 0;
      case "distance": return 0;
    }
    return 0;
  }

  maximumForKey(key: string): number {
    switch (key){
      case "blur": return 0.15 * 3;
      case "blurText": return 0.15 * 0.67;
      case "opacity": return 1.0;
      case "angle": return 360;
      case "distance": return 0.4;
    }
  }

  defaultForKey(key: string): number {
    switch (key){
      case "blur": return 0.08 * 3;
      case "blurText": return 0.08 * 0.32;
      case "opacity": return 0.4;
      case "angle": return 45;
      case "distance": return 0.06;
    }
  }

  private _hdBlurFilter: BlurFilter;
  private _resizeFilter: FilterSimpleResize;

  constructor(options?: Partial<DropShadowFilterOptions>) {
    const opt: DropShadowFilterOptions = options
            ? { ...DropShadowFilter.defaults, ...options, ...{quality: 40.0} }
            : DropShadowFilter.defaults;
    super(options);

    const { kernels, blur, quality, pixelSize, resolution } = opt;
    this._hdBlurFilter = kernels
      ? new BlurFilter(kernels.length)
      : new BlurFilter(blur, quality, 10);

    this._hdBlurFilter.repeatEdgePixels = false;
    this._resizeFilter = new FilterSimpleResize(1, 1);
    this.shadowOnly = true;
  }

  apply(filterManager: FilterSystem, input: RenderTexture, output: RenderTexture, clear: CLEAR_MODES): void
    {
        let target = filterManager.getFilterTexture();

        // @ts-ignore
        this._tintFilter.apply(filterManager, input, target, 1);
        this._hdBlurFilter.apply(filterManager, target, output, clear);

        if (this.shadowOnly !== true)
        {
            filterManager.applyFilter(this, input, output, 0);
        }

        filterManager.returnFilterTexture(target);
    }
}

export { DropShadowHDFilter };