import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      d="M1.335 9.931c.347.336.817.303 1.164-.022l.75-.75 1.176-1.254v9.439c0 1.88.952 2.821 2.855 2.821h6.83c.583 0 .919-.29.907-.806-.01-.515-.324-.817-.907-.817H7.303c-.806 0-1.265-.426-1.265-1.277V7.882L7.224 9.16l.75.75c.348.325.818.358 1.165.022a.79.79 0 0 0-.022-1.164L6.25 5.912c-.65-.65-1.377-.65-2.037 0L1.357 8.767a.79.79 0 0 0-.022 1.164Zm7.647-5.273c0 .515.314.806.896.806h6.819c.806 0 1.265.437 1.265 1.276v9.383l-1.187-1.276-.75-.74c-.347-.335-.817-.369-1.164-.022-.336.325-.325.829.022 1.154l2.855 2.855c.66.65 1.389.65 2.038 0l2.866-2.855c.347-.325.359-.829.023-1.154-.347-.347-.818-.313-1.165.023l-.761.739-1.165 1.265v-9.45c0-1.881-.951-2.822-2.855-2.822H9.88c-.583 0-.908.291-.897.818Z"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M1.271 9.995a.88.88 0 0 1 .024-1.293l2.854-2.854c.342-.336.71-.513 1.087-.513.376 0 .742.177 1.078.513L9.18 8.702a.88.88 0 0 1 .024 1.293l-.001.001a.88.88 0 0 1-.648.262.994.994 0 0 1-.64-.283l-.003-.002-.752-.753-1.031-1.108v9.153c0 .41.11.703.303.894.194.19.484.293.872.293h6.808c.306 0 .554.08.728.24.175.16.262.39.269.665.006.279-.083.508-.262.666-.177.156-.43.232-.735.232H7.28c-.966 0-1.706-.238-2.205-.73-.499-.494-.74-1.226-.74-2.181V8.133l-1.023 1.09-.752.752a.994.994 0 0 1-.64.283.88.88 0 0 1-.649-.262Zm3.154-2.09v9.439c0 1.88.952 2.821 2.856 2.821h6.83c.582 0 .918-.29.906-.806-.01-.515-.324-.817-.906-.817H7.303c-.806 0-1.265-.426-1.265-1.277V7.883l1.187 1.276.75.75c.347.325.817.358 1.164.023a.79.79 0 0 0-.022-1.165L6.25 5.912c-.649-.65-1.377-.65-2.037 0L1.357 8.767a.79.79 0 0 0-.022 1.165c.347.335.817.302 1.165-.023l.75-.75 1.175-1.254Zm12.35 6.942 1.187 1.276V6.74c0-.84-.459-1.276-1.265-1.276H9.878c-.582 0-.896-.291-.896-.806-.01-.526.314-.818.896-.818h6.841c1.904 0 2.856.941 2.856 2.822v9.45l1.164-1.265.761-.739c.348-.336.818-.37 1.165-.022.336.324.325.828-.022 1.153l-2.867 2.855c-.65.65-1.377.65-2.038 0l-2.855-2.855c-.347-.325-.358-.829-.022-1.153.347-.348.817-.314 1.164.022l.75.739Zm3.065 3.31c-.337.337-.703.514-1.078.514-.376 0-.745-.177-1.087-.513l-2.855-2.855c-.381-.358-.396-.92-.022-1.282a.868.868 0 0 1 .648-.27c.23.008.455.111.642.292l.753.743 1.031 1.108V6.74c0-.403-.11-.696-.303-.889-.194-.192-.485-.297-.872-.297H9.878c-.305 0-.553-.076-.726-.233-.175-.158-.26-.386-.26-.662-.005-.282.08-.514.255-.674.175-.158.426-.235.731-.235h6.841c.966 0 1.706.239 2.205.732.499.493.74 1.225.74 2.18v9.22l1.012-1.1.762-.739a.979.979 0 0 1 .642-.292.868.868 0 0 1 .648.27c.373.362.359.924-.023 1.283l-2.865 2.854Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
