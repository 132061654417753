import { useEditorContext } from '@/scenes/engine'
import { Button, SHAPE, KIND, SIZE } from 'baseui/button'
import Icons from '../../../Icons'

function Duplicate() {
  const editor = useEditorContext().editor
  return (
    <Button
      onClick={() => {
        editor.handlers.objectsHandler.clone()
      }}
      size={SIZE.default}
      kind={KIND.tertiary}
      shape={SHAPE.square}
    >
      <Icons.Duplicate size={24} />
    </Button>
  )
}

export default Duplicate
