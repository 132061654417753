function Vectors({ size }: { size: number }) {
  return (
    <svg height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 22">
      <path
        fill="currentColor"
        d="M4.76 6.297c0-1.068.02-2.136-.008-3.203-.014-.56.392-1.101 1.112-1.094 1.446.014 2.891.004 4.337.004h2.08c.698.002 1.118.412 1.119 1.105.002 2.123.002 4.246 0 6.37 0 .696-.416 1.103-1.117 1.103-2.144 0-4.289-.01-6.434.006-.65.005-1.112-.49-1.096-1.089.027-1.067.007-2.135.007-3.202ZM9.068 8.82h2.31c.219 0 .242-.021.242-.23V4c0-.21-.027-.235-.24-.235-1.54 0-3.081.001-4.622-.003-.168 0-.237.057-.237.227.003 1.536.003 3.072 0 4.607 0 .171.067.227.235.226.77-.006 1.541-.002 2.312-.002ZM9.093 12.877a3.025 3.025 0 0 1 1.242-.754c1.505-.457 3.107.386 3.65 1.919.367 1.038.208 2.018-.335 2.953-.495.854-1.16 1.57-1.926 2.181-.683.545-1.41 1.035-2.123 1.54-.332.236-.673.222-1.02.006-1.277-.793-2.471-1.684-3.44-2.842-.537-.641-.969-1.343-1.1-2.184-.27-1.717.84-3.392 2.51-3.659.925-.147 1.733.123 2.43.738l.112.101Zm3.48 2.191c.002-.199-.08-.492-.239-.764-.524-.9-1.668-.948-2.258-.092-.09.14-.163.29-.219.445-.134.333-.42.54-.767.54a.802.802 0 0 1-.764-.541 2.483 2.483 0 0 0-.116-.279c-.372-.711-1.165-.98-1.811-.617-.517.29-.862.984-.787 1.571.07.544.345.991.676 1.41.754.953 1.709 1.68 2.716 2.345.044.029.15.014.198-.02.44-.314.888-.622 1.308-.962.674-.544 1.292-1.145 1.736-1.898.185-.315.325-.647.326-1.137ZM19.032 20.683H14.91c-.555-.001-.914-.395-.817-.897a.971.971 0 0 1 .12-.294c1.38-2.376 2.76-4.75 4.143-7.125.329-.564 1.03-.57 1.36-.007 1.39 2.384 2.777 4.77 4.16 7.156.323.557-.02 1.154-.668 1.165-.55.01-1.1.002-1.649.002h-2.527Zm2.445-1.768-2.44-4.194-2.438 4.194h4.878ZM19.17 2.004c2.485-.004 4.527 2.013 4.533 4.47.005 2.522-2.03 4.46-4.408 4.52-2.539.065-4.628-1.96-4.643-4.422a4.503 4.503 0 0 1 4.518-4.568Zm2.758 4.516c.011-1.498-1.22-2.742-2.726-2.754-1.489-.013-2.749 1.208-2.784 2.645a2.768 2.768 0 0 0 2.747 2.829c1.486.03 2.752-1.224 2.763-2.72Z"
      ></path>
    </svg>
  )
}

export default Vectors
