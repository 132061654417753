import Image from 'image-js'
import { RGB } from 'image-js/src/image/model/model';
import { getKind } from 'image-js/src/image/core/kind';
import { RGBA } from 'image-js/src/image/core/kindNames';

/**
 * Call back that converts the RGB channels to grey. It will be clamped after.
 * @callback GreyAlgorithmCallback
 * @param {number} red - value of the red channel
 * @param {number} green - value of the green channel
 * @param {number} blue - value of the blue channel
 * @return {number} value of the grey channel
 */

/**
 * Converts the current image to greyscale.
 * The source image has to be RGB.
 * If there is an alpha channel we need to decide what to do:
 * * keepAlpha : we will keep the alpha channel and you will get a GREY / A image
 * * mergeAlpha : we will multiply each pixel of the image by the alpha
 * @memberof Image
 * @instance
 * @param {object} [options]
 * @param {GreyAlgorithm|GreyAlgorithmCallback} [options.algorithm='luma709'] - Algorithm to get the grey value from RGB values
 * @param {boolean} [options.keepAlpha=false] - If true, the RGB values are treated
 *          separately from the alpha channel and the method returns a GREYA image.
 * @param {boolean} [options.mergeAlpha=true] - If true, the alpha channel will be used to scale the grey pixel.
 * @param {Image} [options.out]
 * @return {Image}
 */
export default function alphaFromGrey(options = {}) {
  let newImage = Image.createFrom(this, {
    alpha: 1,
    colorModel: RGB,
    kind: RGBA,
    width: this.width,
    height: this.height
  });

  for (let i = 0; i < this.data.length; i += this.channels) {
    let m = newImage.channels;
    for (let j = 0; j < m-1; j++) {
      newImage.data[i*m+j] = 255;
    }
    newImage.data[i*m+1] = this.data[i];
  }
  return newImage;
}