import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      fillRule="evenodd"
      d="m13.76 12.293-4.77 4.571a1.313 1.313 0 1 0 1.816 1.896l5.801-5.558a1.26 1.26 0 0 0 .393-.91 1.26 1.26 0 0 0-.393-.908l-5.801-5.559a1.313 1.313 0 0 0-1.817 1.896l4.771 4.572Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
