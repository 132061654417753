import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={2} height={14} x={11} y={5} fill={props.fill ?? lightTheme.colors.blackGray} rx={1} />
    <rect
      width={2}
      height={14}
      x={19}
      y={11}
      fill={props.fill ?? lightTheme.colors.blackGray}
      rx={1}
      transform="rotate(90 19 11)"
    />
  </svg>
)
export default SvgComponent
