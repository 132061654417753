import React from 'react'

function Back({ size, fill }: { size?: number; fill?: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2395 11.7071L15.0106 7.13563C15.5501 6.61876 15.5501 5.75656 15.0106 5.23969C14.5028 4.75313 13.7018 4.75313 13.194 5.23969L7.39302 10.798C7.14137 11.0391 7 11.3661 7 11.7071C7 12.0481 7.14137 12.3751 7.39302 12.6162L13.194 18.1745C13.7018 18.6611 14.5028 18.6611 15.0106 18.1745C15.5501 17.6576 15.5501 16.7954 15.0106 16.2786L10.2395 11.7071Z"
        fill={fill ?? '#666666'}
      />
    </svg>
  )
}

export default Back
