import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={lightTheme.colors.blackGray}
      d="M23.764 21c1.974 0 3.485-.88 4.241-2.483h.077v1.31c0 .726.448 1.173 1.095 1.173.664 0 1.126-.463 1.126-1.188V8.738c0-.725-.462-1.203-1.126-1.203-.647 0-1.095.478-1.095 1.203v1.28h-.077c-.771-1.558-2.329-2.468-4.241-2.468-3.24 0-5.399 2.7-5.399 6.725 0 4.041 2.16 6.725 5.399 6.725Zm.586-2.02c-2.206 0-3.579-1.805-3.579-4.705 0-2.9 1.373-4.72 3.579-4.72 2.22 0 3.655 1.867 3.655 4.736 0 2.868-1.419 4.688-3.655 4.688ZM3.172 20.86c.725 0 1.095-.277 1.358-1.049l1.403-3.917h6.88l1.403 3.917c.262.771.632 1.05 1.357 1.05s1.203-.448 1.203-1.142c0-.232-.046-.447-.154-.74l-5.46-14.7C10.838 3.416 10.267 3 9.372 3c-.878 0-1.45.416-1.758 1.28l-5.46 14.715c-.108.293-.154.493-.154.74 0 .694.447 1.126 1.172 1.126Zm3.424-7.049 2.73-7.743h.078l2.73 7.743H6.596Z"
    />
  </svg>
)
export default SvgComponent
