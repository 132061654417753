function Background({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.76352 22H18.2366C19.4774 22 20.4149 21.683 21.049 21.049C21.683 20.4151 22 19.4867 22 18.2639V3.73152C22 2.50874 21.683 1.58112 21.049 0.948667C20.4149 0.316222 19.4774 0 18.2366 0H3.76352C2.5284 0 1.59236 0.315459 0.955416 0.946379C0.318472 1.57731 0 2.50569 0 3.73152V18.2639C0 19.4928 0.318472 20.4227 0.955416 21.0536C1.59236 21.6845 2.5284 22 3.76352 22ZM3.88143 19.9638C3.28119 19.9638 2.82228 19.809 2.5047 19.4993C2.1871 19.1896 2.0283 18.7241 2.0283 18.1028V3.89719C2.0283 3.28037 2.1871 2.81598 2.5047 2.50405C2.82228 2.19212 3.28119 2.03616 3.88143 2.03616H18.1186C18.7141 2.03616 19.1718 2.19212 19.4917 2.50405C19.8117 2.81598 19.9717 3.28037 19.9717 3.89719V18.1028C19.9717 18.7241 19.8117 19.1896 19.4917 19.4993C19.1718 19.809 18.7141 19.9638 18.1186 19.9638H3.88143Z"
        fill="#666666"
      />
      <path
        d="M6.18806 17.6685C6.79867 17.6685 7.20607 17.3143 7.41025 16.6059L8.47464 14.1476H13.8046L14.8807 16.6059C15.0945 17.3143 15.5059 17.6685 16.1148 17.6685C16.4811 17.6685 16.7726 17.5667 16.9893 17.3631C17.2061 17.1595 17.3145 16.8858 17.3145 16.5419C17.3145 16.3337 17.2544 16.0582 17.1343 15.7156L12.9913 5.70003C12.6772 4.79096 12.0584 4.33643 11.1349 4.33643C10.2269 4.33643 9.61126 4.79096 9.28797 5.70003L5.15669 15.7156C5.04051 16.0582 4.98242 16.3317 4.98242 16.536C4.98242 16.8838 5.0908 17.1595 5.30755 17.3631C5.5243 17.5667 5.8178 17.6685 6.18806 17.6685ZM9.06425 12.2481L11.0754 6.96544H11.2151L13.2322 12.2481H9.06425Z"
        fill="#666666"
      />
    </svg>
  )
}

export default Background
