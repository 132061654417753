import useAppContext from '@/hooks/useAppContext'
import { useEditorContext } from '@/scenes/engine'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { setUploads } from '@/store/slices/uploads/actions'
import { useAppDispatch } from '@/store/store'
import { customAmplitude } from '@/utils/customAmplitude'
import LogoIcon from '@assets/images/logo_only.svg'
import LogoCreatePage from '@assets/images/logo-create-page.svg'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
function Logo({ size }: { size: number }) {
  const { setShowModalType } = useAppContext()
  const editor = useEditorContext().editor
  const [historyStatus, setHistoryStatus] = useState({ hasUndo: false, hasRedo: false })
  const history = useHistory()
  const dispatch = useAppDispatch()
  useEffect(() => {
    const handleHistoryChange = (data: any) => {
      setHistoryStatus({ ...historyStatus, hasUndo: data.hasUndo, hasRedo: data.hasRedo })
    }
    if (editor) {
      setHistoryStatus({
        hasUndo: editor.handlers.transactionHandler.undos.length > 0,
        hasRedo: editor.handlers.transactionHandler.redos.length > 0,
      })
      editor.on('history:changed', handleHistoryChange)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleHistoryChange)
      }
    }
  }, [editor])

  const location = useLocation()

  return (
    <img
      src={location.pathname.includes('create') ? LogoCreatePage : LogoIcon}
      alt="logo"
      style={{
        cursor: 'pointer',
        width: 'fite-content',
        height: size,
      }}
      onClick={() => {
        if(location.pathname.includes('create')) {
          // nothing
        } else {
          if (historyStatus.hasRedo || historyStatus.hasUndo) {
            setShowModalType(ShowModalType.CONFIRM_EXIT_EDITING)
          } else {
            dispatch(setUploads([]))
            history.push('/create')
            customAmplitude('Open', { Source: 'Editor' })
          }
        }
       
      }}
    />
  )
}

export default Logo
