import { IRemoveEditorContext } from '.'
import { RemoveEditorOptions } from './common/interfaces'
import EventManager from './utils/EventManager'
import RemoveHandlers from './handlers/remove-handler/RemoveHandlers'
import { customAmplitude } from '@/utils/customAmplitude'

class RemoveEditorEventManager extends EventManager {
  public handlers: RemoveHandlers
  public context: IRemoveEditorContext

  constructor(props: RemoveEditorOptions) {
    super()
    this.context = props.context
    this.handlers = new RemoveHandlers({ ...props, editorEventManager: this })
  }

  public destroy = () => {
    this.handlers.destroy()
  }

  public undo = () => {
    const eventProperties = {
      Tool: 'bazaart.undo',
    }
    customAmplitude('Selected tool', eventProperties)
    this.handlers.transactionRemoveHandler.undo()
  }
  public redo = () => {
    const eventProperties = {
      Tool: 'bazaart.redo',
    }
    customAmplitude('Selected tool', eventProperties)
    this.handlers.transactionRemoveHandler.redo()
  }

  public exportPNG = async () => {
    // return this.handlers.designHandler.toDataURL()
  }

  importFromJSON = () => {
    return null
  }
}

export default RemoveEditorEventManager
