import React, { useEffect, useState } from 'react'
import ContextMenuItem from './ContextMenuItem'
import Icons from '../Icons'
import { useStyletron } from 'baseui'
import { useEditorContext } from '@/scenes/engine'
import { ObjectType } from '@/scenes/engine/common/constants'
import {fabric} from 'fabric'
import { t } from 'i18next'

const PositionContextMenu = ({ close, style }) => {
  const { canvas, activeObject, editor } = useEditorContext()
  const [isToplayer, setIsTopLayer] = useState(false)
  const [isBottomLayer, setIsBottomLayer] = useState(false)
  const [triggerChange, setTriggerChange] = useState(false)

  const isMac = navigator.userAgent.indexOf('Mac OS X') !== -1


  useEffect(() => {
    const filterObjs = canvas?.getObjects().filter(obj => {
      if(obj.type) {
        return obj.type !== ObjectType.FRAME && obj.type !== ObjectType.BAZAART_BG && obj
      } else {
        if (obj instanceof fabric.Group) {
          return obj
        }
      }
    })
    // @ts-ignore
    setIsTopLayer(filterObjs[filterObjs.length - 1].id === activeObject?.id)
    // @ts-ignore
    setIsBottomLayer(filterObjs[0].id === activeObject?.id)
  }, [canvas, triggerChange])
  const [css] = useStyletron()

  const onFrontButton = () => {
    editor.handlers.objectsHandler.bringToFront()
    setTriggerChange(!triggerChange)
  }
  const onUpButton = () => {
    editor.handlers.objectsHandler.bringForward()
    setTriggerChange(!triggerChange)
  }
  const onDownButton = () => {
    editor.handlers.objectsHandler.sendBackwards()
    setTriggerChange(!triggerChange)
  }
  const onBackButton = () => {
    editor.handlers.objectsHandler.sendToBack()
    setTriggerChange(!triggerChange)
  }
  return (
    <div
      id="position-context-menu"
      className={css({
        position: 'absolute',
        transform: 'translateY(-50%)',
        zIndex: 1000,
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        filter: 'drop-shadow(0 0 12px rgba(0, 0, 0, 0.15))',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        width: '235px',
        ...style
      })}
      onMouseLeave={() => close()}
    >
      <ContextMenuItem
        startEnhancer={<Icons.Forward size={24} />}
        content={t('Front')}
        endEnhancer={`${isMac ? '⌥ ⌘' : 'Ctrl + Alt'} + ]`}
        handleClick={() => onFrontButton()}
        disabled={isToplayer}
      />
      <ContextMenuItem
        startEnhancer={<Icons.BringFront size={24} />}
        content={t('Up')}
        endEnhancer={`${isMac ? '⌘' : 'Ctrl'} + ]`}
        handleClick={() => onUpButton()}
        disabled={isToplayer || (isBottomLayer && canvas?.getObjects().length === 2)}
      />
      <ContextMenuItem
        startEnhancer={<Icons.BringBack size={24} />}
        content={t('Down')}
        endEnhancer={`${isMac ? '⌘' : 'Ctrl'} + [`}
        handleClick={() => onDownButton()}
        disabled={(isToplayer && canvas?.getObjects().length === 2) || isBottomLayer}
      />
      <ContextMenuItem
        startEnhancer={<Icons.Backward />}
        content={t('Back')}
        endEnhancer={`${isMac ? '⌥ ⌘' : 'Ctrl + Alt'} + [`}
        handleClick={() => onBackButton()}
        disabled={isBottomLayer}
      />
    </div>
  )
}

export default PositionContextMenu
