import * as React from 'react'

const SvgComponent = props => (
    <svg width="24" height="50" viewBox="0 0 24 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="22.6843" y="0.5" width="49" height="21.3684" rx="3.5" transform="rotate(90 22.6843 0.5)" fill="#E5E5E5"/>
        <rect x="22.6843" y="0.5" width="49" height="21.3684" rx="3.5" transform="rotate(90 22.6843 0.5)" stroke="#E5E5E5"/>
    </svg>
    

)

export default SvgComponent
