import * as React from 'react'

const SvgComponent = props => (
  <svg width={76} height={44} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={1} width={75} height={42} rx={3.5} fill="#F2F2F2" />
    <path
      d="M37.993 33.072C44.001 33.072 49 28.176 49 22.277c0-5.9-5.013-10.795-11.02-10.795-5.994 0-10.98 4.896-10.98 10.795 0 5.9 5 10.795 10.993 10.795Zm0-2.033c-4.945 0-8.895-3.893-8.895-8.762 0-4.87 3.937-8.762 8.882-8.762 4.958 0 8.936 3.893 8.936 8.762 0 4.87-3.964 8.762-8.923 8.762Zm-1.975-4.669 6.062-3.504a.657.657 0 0 0 0-1.164l-6.062-3.505c-.477-.28-1.13-.067-1.13.468v7.237c0 .535.599.79 1.13.468Z"
      fill="#FF455B"
    />
    <rect x={0.5} y={1} width={75} height={42} rx={3.5} stroke="#E5E5E5" />
  </svg>
)

export default SvgComponent
