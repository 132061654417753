import { Input } from 'baseui/input'
import Icons from '@components/icons'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { useSelector } from 'react-redux'
import { selectElements } from '@/store/slices/elements/selectors'
import PackContainer from '@/PackContainer'
import { VariableSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { selectGraphicPacks } from '@/store/slices/graphicsPacks/selectors'

function Panel() {
  const graphicPacks = useSelector(selectGraphicPacks)
  const [value, setValue] = useState('')
  const editor = useEditorContext().editor

  const addImageToCanvas = url => {
    const options = {
      type: 'StaticImage',
      metadata: { src: url },
    }
    // editor.handlers.objectsHandler.add(options)
    editor.handlers.objectsHandler.replaceImage(options, false)
  }

  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <div style={{ padding: '2rem 2rem' }}>
        <Input
          startEnhancer={() => <Icons.Search size={18} />}
          value={value}
          onChange={e => setValue((e.target as any).value)}
          placeholder="Search Overlay"
          clearOnEscape
        />
      </div>
      <div style={{ flex: 1 }}>
        <AutoSizer>
          {({ width, height }) => (
            <div>
              <VariableSizeList
                itemCount={graphicPacks.length}
                itemSize={() => 120}
                height={height}
                width={width}
              >
                {({ index, style }) => (
                  <PackContainer
                    style={{ ...style, padding: '0 2rem 2rem' }}
                    pack={graphicPacks[index]}
                    onItemSelected={addImageToCanvas}
                    itemsPerLine="auto"
                  />
                )}
              </VariableSizeList>
            </div>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

export default Panel
