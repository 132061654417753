import { useEffect, useState } from 'react'
import { IStorePack } from '@/interfaces/editor'
import { useSelector } from 'react-redux'
import { selectPack } from '@/store/slices/packs/selectors'
import { useAppDispatch } from './store/store'
import { getPack } from './store/slices/packs/actions'
import { LabelLarge, ParagraphXSmall } from 'baseui/typography'
import { styled } from 'baseui'
import { PackItem } from './components/Gallery/Gallery'
import { lightTheme } from './customTheme'
import { useTranslation } from 'react-i18next'
import Icons from '@/components/icons'
import ButtonCustom from './components/ButtonCustom'
import { SizeButton } from './constants/sizeButton'
import { StatefulTooltip } from 'baseui/tooltip'

const Outline1 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: 0,
  borderRadius: '8px',
  border: `2px solid ${props.$active ? '#FF0560' : 'transparent'}`,
}))
const Outline2 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: '2px',
  borderRadius: '6px',
  border: `2px solid ${props.$active ? '#fff' : 'transparent'}`,
}))

interface props {
  pack: IStorePack
  style: React.CSSProperties
  onItemSelected: Function
  handleClickSubAction?: Function
  itemsPerLine: 'auto' | number
  bgActiveID?: Object
  hasPremium?: boolean,
  showSearchButton?: boolean
  handleClickSearchIcon?: Function
}

function PackContainer({
  pack,
  style,
  onItemSelected,
  handleClickSubAction,
  itemsPerLine,
  bgActiveID,
  hasPremium,
  showSearchButton = false,
  handleClickSearchIcon
}: props) {

  const packObjects = useSelector(selectPack(pack && pack.id))
  const isCompact = !!handleClickSubAction

  const dispath = useAppDispatch()
  const { t } = useTranslation()

  const [defaultItemTotal, setDefaultItemTotal] = useState(10)
  const [nameSubAction, setNameSubAction] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const placeholderFill = new Array(10).fill(null);

  useEffect(() => {
    setDefaultItemTotal(itemsPerLine === 'auto' ? 10 : itemsPerLine * 2)
    if (!packObjects) {
      dispath(getPack(pack && pack.id))
    }
    setNameSubAction(isCompact ? 'See all' : 'Show more')
  }, [pack])

  useEffect(() => {
    if (packObjects && packObjects.length > 0) {
      setIsLoaded(true);
    }
  }, [packObjects]);

  const handleAction = () => {
    if (isCompact) {
      handleClickSubAction()
    } else {
      setDefaultItemTotal(pre =>
        pre !== packObjects?.length ? packObjects.length : itemsPerLine === 'auto' ? 10 : itemsPerLine * 2
      )
      setNameSubAction(pre => (pre === 'Show more' ? 'Show less' : 'Show more'))
    }
  }

  const handleSelectItem = packItem => {
    onItemSelected(packItem)
  }

  return (
    <div style={{ width: '300px', ...style }} className="mt-50">
      <div
        style={{
          height: '26px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <LabelLarge $style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>
          {isCompact ? t('Fill') : pack && t(pack.name)}
        </LabelLarge>
        {showSearchButton && (
        
          <StatefulTooltip
            onMouseEnterDelay={10}
            onMouseLeaveDelay={10}
            accessibilityType={'tooltip'}
            content={t('Search millions of stunning background')}
            showArrow
            placement={'top'}
            popoverMargin={4}
            overrides={{
              Inner: {
                style: ({ $theme }) => ({
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#fff',
                  backgroundColor: lightTheme.colors.blackGray,
                  padding: '9px 14px',
                  textAlign: 'center',
                  letterSpacing: '-0.006em',
                }),
              },
              Body: {
                style: ({ $theme }) => ({
                  zIndex: 110,
                  backgroundColor: lightTheme.colors.blackGray,
                  width: '170px',
                  borderRadius: '8px',
                }),
              },
              Arrow: {
                style: ({ $theme }) => ({
                  zIndex: 110,
                  backgroundColor: lightTheme.colors.blackGray,
                }),
              },
            }}
          >
            <div style={{marginRight: '4px', marginLeft: 'auto'}}>
              <ButtonCustom 
                onClick={() => handleClickSearchIcon()}
                type={SizeButton.SMALL}
                style={{padding: '0px', width: '24px', height: '24px',
                  ':hover': {
                    borderRadius: '50%',
                    backgroundColor: lightTheme.colors.grayScale100
                  }
                }}
              >
                <Icons.Search fill={lightTheme.colors.blackGray}/>
              </ButtonCustom>
            </div>
          </StatefulTooltip>
        )}
        <ParagraphXSmall
          onClick={() => handleAction()}
          $style={{
            boxSizing: 'border-box',
            cursor: 'pointer',
            padding: '0 8px',
            borderRadius: '12px',
            whiteSpace: 'nowrap',
            ':hover': {
              background: 'rgb(229, 229, 229)',
            },
            ...lightTheme.typography.Small12medium,
            color:lightTheme.colors.text.text_black_title
          }}
        >
          {t(nameSubAction)}
        </ParagraphXSmall>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '8px',
          rowGap: itemsPerLine === 6 ? '0' : '8px',
        }}
      >
        {/* packObjects can be null when first loaded */}
        {(isLoaded ? (Array.isArray(packObjects) ? packObjects : placeholderFill)  : placeholderFill).map((packObject, index) => {

            if (index < (itemsPerLine === 'auto' ? 10 : itemsPerLine * 2)) {
              let isSelected = bgActiveID == packObject?.id
              return (
                <div style={{ display: 'flex', position: 'relative' }} key={packObject?.id}>
                  <PackItem
                    onClick={() => {
                      if(packObject) {
                        handleSelectItem(packObject)
                      }
                    }}
                    style={{
                      wrap: {
                        width: itemsPerLine === 6 ? '67px' : '52px',
                        height: itemsPerLine === 6 ? '67px' : '52px',
                        padding: itemsPerLine === 6 ? '8px 8px 9px 9px' : '0',
                        zIndex: '1',
                      },
                      placehoder: {
                        inset: 0,
                      },
                      image: {
                        objectFit: 'cover',
                      },
                    }}
                    pack={{
                      thumbnail: packObject?.thumbnail,
                      id: packObject?.id,
                    }}
                    onDrag={() => {}}
                    isSelected={isSelected}
                    isLocked={!hasPremium && index >= 5}
                    CTA="BtSubscriptionBackgrounds"
                  >
                    <Outline1 $active={isSelected}></Outline1>
                    <Outline2 $active={isSelected}></Outline2>
                  </PackItem>
                </div>
              )
            } else {
              if (defaultItemTotal === packObjects.length) {
                let isSelected = bgActiveID == packObject.id
                let is6sItemPerLine = itemsPerLine === 6
                return (
                  <div style={{ display: 'flex', position: 'relative' }} key={packObject.id}>
                    <PackItem
                      onClick={() => handleSelectItem(packObject)}
                      style={{
                        wrap: {
                          width: is6sItemPerLine ? '67px' : '52px',
                          height: is6sItemPerLine ? '67px' : '52px',
                          padding: is6sItemPerLine ? '8px 8px 9px 9px' : '0',
                          zIndex: '1',
                        },
                        placehoder: {
                          inset: 0,
                        },
                        image: {
                          objectFit: 'cover',
                        },
                      }}
                      pack={{
                        thumbnail: packObject.thumbnail,
                        id: packObject.id,
                      }}
                      onDrag={() => {}}
                      isSelected={isSelected}
                      isLocked={!hasPremium && index >= 5}
                      CTA="BtSubscriptionBackgrounds"
                    >
                      <Outline1 $active={isSelected}></Outline1>
                      <Outline2 $active={isSelected}></Outline2>
                    </PackItem>
                  </div>
                )
              }
            }

          })}
      </div>
    </div>
  )
}

export default PackContainer
