import * as React from 'react'

const SvgComponent = props => (
  <svg width={29} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.92 7.893c2.462-2.477 6.46-2.53 8.913-.116 2.454 2.413 2.446 6.392-.016 8.87l-9.4 9.46c-2.462 2.478-6.46 2.53-8.914.117C.05 23.81.058 19.83 2.52 17.354l9.4-9.46Zm7.304 1.503c1.636 1.609 1.631 4.262-.01 5.914l-.57.573-5.932-5.836.57-.573c1.642-1.652 4.307-1.687 5.942-.078Zm-7.422 13.373 5.62-5.657-5.932-5.835-5.62 5.656 5.932 5.836Zm-7.68-4.078c-1.64 1.652-1.645 4.305-.01 5.914s4.301 1.574 5.943-.078L10.58 24l-5.932-5.836-.525.528Z"
      fill="#666"
    />
    <path
      d="M24.534 6.73c-.335-2.43-.25-2.43-2.91-2.91a.325.325 0 0 1-.202-.102.31.31 0 0 1-.005-.418.312.312 0 0 1 .208-.098c2.66-.355 2.583-.436 2.91-2.903a.3.3 0 0 1 .093-.213.316.316 0 0 1 .506.105.297.297 0 0 1 .022.117c.307 2.43.35 2.422 2.889 2.894a.317.317 0 0 1 .205.1.301.301 0 0 1 .081.209c0 .183-.122.28-.326.309-2.519.4-2.543.464-2.851 2.892a.301.301 0 0 1-.095.2.316.316 0 0 1-.527-.181h.002ZM16.786 4.458c-.223-1.623-.211-1.594-1.924-1.916a.216.216 0 0 1-.138-.067.206.206 0 0 1-.056-.14.198.198 0 0 1 .054-.142.208.208 0 0 1 .14-.065c1.714-.236 1.707-.291 1.924-1.941a.199.199 0 0 1 .066-.133.208.208 0 0 1 .142-.054c.053 0 .104.02.143.057.038.036.06.086.061.138.206 1.623.251 1.617 1.947 1.933a.213.213 0 0 1 .138.067.203.203 0 0 1 .055.14c0 .12-.08.182-.217.206-1.684.267-1.717.281-1.923 1.903a.2.2 0 0 1-.122.191.211.211 0 0 1-.275-.101.199.199 0 0 1-.018-.077l.003.001ZM25.78 13.456c-.224-1.623-.212-1.594-1.925-1.916a.216.216 0 0 1-.137-.067.206.206 0 0 1-.056-.14.198.198 0 0 1 .054-.142.207.207 0 0 1 .14-.065c1.714-.236 1.707-.291 1.924-1.94a.198.198 0 0 1 .066-.134.209.209 0 0 1 .141-.054c.054 0 .105.02.143.057.038.036.06.086.062.138.206 1.623.251 1.617 1.947 1.933a.213.213 0 0 1 .138.067.204.204 0 0 1 .055.14c0 .12-.08.182-.218.206-1.684.267-1.716.281-1.922 1.903a.2.2 0 0 1-.122.191.21.21 0 0 1-.275-.101.197.197 0 0 1-.018-.077l.003.001Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.445 15.465a1.046 1.046 0 0 1-1.475.008 1.034 1.034 0 0 1-.008-1.467 1.047 1.047 0 0 1 1.475-.009c.409.403.413 1.06.008 1.468Zm2.348 2.336a1.046 1.046 0 0 1-1.475.008 1.034 1.034 0 0 1-.008-1.467 1.046 1.046 0 0 1 1.474-.008c.41.402.413 1.06.009 1.467ZM8.56 16.264a1.034 1.034 0 0 0 .008 1.467c.41.403 1.07.399 1.475-.008a1.034 1.034 0 0 0-.009-1.468c-.409-.403-1.07-.399-1.474.009Zm3.885 3.846a1.046 1.046 0 0 1-1.475.008 1.034 1.034 0 0 1-.008-1.467 1.047 1.047 0 0 1 1.475-.009c.409.403.413 1.06.008 1.468Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
