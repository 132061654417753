
export default function extractBoundingBoxFromChannel(options = {}) {
  // @ts-ignore
  const { threshold = this.maxValue, component = this.components, shouldFlip = false, signal = {} } = options;

  let left = findLeft(this, threshold, component, shouldFlip, signal);
  left = Math.max(0, left);

  let top = findTop(this, threshold, component, left, shouldFlip, signal);
  top = Math.max(0, top);

  let bottom = findBottom(this, threshold, component, left, shouldFlip, signal);
  bottom = bottom < 0 ? this.height - 1 : bottom;

  let right = findRight(this, threshold, component, left, top, bottom, shouldFlip, signal);
  right = right < 0 ? this.width - 1 : right;

  let rect = {
    x: left,
    y: top,
    width: right - left + 1,
    height: bottom - top + 1,
  };
  return rect
}

function compare(value, threshold, shouldFlip){
  if (shouldFlip) {
    return value < threshold
  }
  return value >= threshold
}

function findLeft(image, threshold, channel, shouldFlip, signal) {
  for (let x = 0; x < image.width; x++) {
    for (let y = 0; y < image.height; y++) {
      if (signal?.aborted) return -1;
      if (compare(image.getValueXY(x, y, channel), threshold, shouldFlip)) {
        return x;
      }
    }
  }
  return -1;
}

function findTop(image, threshold, channel, left, shouldFlip, signal) {
  for (let y = 0; y < image.height; y++) {
    for (let x = left; x < image.width; x++) {
      if (signal?.aborted) return -1;
      if (compare(image.getValueXY(x, y, channel), threshold, shouldFlip)) {
        return y;
      }
    }
  }
  return -1;
}

function findBottom(image, threshold, channel, left, shouldFlip, signal) {
  for (let y = image.height - 1; y >= 0; y--) {
    for (let x = left; x < image.width; x++) {
      if (signal?.aborted) return -1;
      if (compare(image.getValueXY(x, y, channel), threshold, shouldFlip)) {
        return y;
      }
    }
  }
  return -1;
}

function findRight(image, threshold, channel, left, top, bottom, shouldFlip, signal) {
  for (let x = image.width - 1; x >= left; x--) {
    for (let y = top; y <= bottom; y++) {
      if (signal?.aborted) return -1;
      if (compare(image.getValueXY(x, y, channel), threshold, shouldFlip)) {
        return x;
      }
    }
  }
  return -1;
}