import { Filter } from '@pixi/core'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Size } from '@scenes/engine/objects/media-repository/size'

// thanks - https://codepen.io/timseverien/pen/abdZEbv
// thanks - https://github.com/BradLarson/GPUImage2/blob/3a2275e48716a6ebdab0d4098bd7d90321ef4143/framework/Source/Operations/Shaders/Sharpen_GLES.fsh
//@ts-ignore
export class BasePixiFilter implements fabric.Image.filters.BaseFilter {
  pixijsFilter: Filter

  private _padding: Size
  private _inset: Inset

  constructor(filter: Filter, padding: Size, inset: Inset) {
    this.pixijsFilter = filter
    this._padding = padding
    this._inset = inset
  }

  applyInset(inset: Inset): Inset {
      let newInset = Object.assign({}, inset);
      newInset.top = inset.top ? Math.floor((newInset.top + this._inset.top)) : Math.floor(this._inset.top);
      newInset.left = inset.left ? Math.floor((newInset.left + this._inset.left)) : Math.floor(this._inset.left);
      newInset.bottom = inset.bottom ? Math.floor((newInset.bottom + this._inset.bottom)) : Math.floor(this._inset.bottom);
      newInset.right = inset.right ? Math.floor((newInset.right + this._inset.right)) : Math.floor(this._inset.right);
      return newInset;
  }

  padSize(size: Size): Size {
    let newSize = Object.assign({}, size)

    newSize.width = Math.floor(newSize.width + this._padding.width)
    newSize.height = Math.floor(newSize.height + this._padding.height)

    return newSize
  }

  isNeutralState(): boolean {
    return false
  }

  setOptions(options?: any): void {}

  /**
   * Returns object representation of an instance
   */
  toObject(): any {
    return this;
  }
  /**
   * Returns a JSON representation of an instance
   */
  toJSON(): any {
    return ''
  }
  /**
   * Apply the operation to a Uint8Array representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8Array to be filtered.
   */
  applyTo2d(options: any): void {}
}
