import store from '@/store/store'
import * as amplitude from '@amplitude/analytics-browser'

const SAMPLE_EVENT = [
  //'Selected tool','Magic Heal'
]

/**
 * Tracks custom events using the Amplitude analytics library.
 * If the user is a sample user and the event is included in the SAMPLE_EVENT array,
 * the event will be tracked. Otherwise, the event will only be tracked if it is not
 * included in the SAMPLE_EVENT array.
 *
 * @param event - The name of the event to track.
 * @param data - Optional data to include with the event.
 */
export function customAmplitude(event: string, data?: any) {
  const isSampleUser = store.getState().editor.user.isSampleUser      
  if (SAMPLE_EVENT.includes(event)) {
    if(isSampleUser) {
      data ? amplitude.track(event, data) : amplitude.track(event)
    }
  } else{
    data ? amplitude.track(event, data) : amplitude.track(event)
  }
}
