import lutInstant from "./basic_lut_assets/lut_instant.png"
import lutProcess from "./basic_lut_assets/lut_process.png"
import lutTonal from "./basic_lut_assets/lut_tonal.png"
import lutTransfer from "./basic_lut_assets/lut_transfer.png"
import lutChroma from "./basic_lut_assets/lut_chroma.png"
import lutFade from "./basic_lut_assets/lut_fade.png"
import lutMono from "./basic_lut_assets/lut_mono.png"
import lutNoir from "./basic_lut_assets/lut_noir.png"

import { ColorMapFilter } from 'pixi-filters'
import { Filter, MIPMAP_MODES, SCALE_MODES, Texture } from '@pixi/core'
import { SwapRGBFilter } from '@scenes/engine/utils/PixijsFilters/SwapRGB/SwapRGBFilter'
import { VerticalColorMapFilter } from '@scenes/engine/utils/PixijsFilters/VerticalColorMap/VerticalColorMapFilter'


class BasicFilterFactory
{
    type: FilterPresetType

    constructor(type: FilterPresetType)
    {
        this.type = type;
    }

    async geFilter(intensity: number): Promise<Filter> {
        let lut = this.getLUTForFilterType();
        let lutTexture = await Texture.fromURL(lut)
        let colorMapFilter = new VerticalColorMapFilter(lutTexture, false, intensity);
        return colorMapFilter;
    }

    private getLUTForFilterType(): any | null {
      switch (this.type) {
          case FilterPresetType.transfer:
            return lutTransfer;
          case FilterPresetType.process:
            return lutProcess;
          case FilterPresetType.instant:
            return lutInstant;
          case FilterPresetType.tonal:
            return lutTonal;
          case FilterPresetType.chrome:
            return lutChroma;
          case FilterPresetType.fade:
            return lutFade;
          case FilterPresetType.monochrome:
            return lutMono;
          case FilterPresetType.noir:
            return lutNoir;
          default:
            return null;
      }
    }
}

enum FilterPresetType {
  default,
  chrome,
  fade,
  monochrome,
  noir,
  transfer,
  process,
  instant,
  tonal
}


export { BasicFilterFactory, FilterPresetType };