import * as React from 'react'

const Distort = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.142 15.84a1.427 1.427 0 1 0 0-2.855 1.427 1.427 0 0 0 0 2.855Zm0 1.714a3.141 3.141 0 1 0 0-6.283 3.141 3.141 0 0 0 0 6.283ZM9.146 5.425a1.427 1.427 0 1 0 0-2.854 1.427 1.427 0 0 0 0 2.854Zm0 1.714a3.141 3.141 0 1 0 0-6.282 3.141 3.141 0 0 0 0 6.282ZM20.86 8.277a1.427 1.427 0 1 0 0-2.853 1.427 1.427 0 0 0 0 2.853Zm0 1.714a3.14 3.14 0 1 0 0-6.281 3.14 3.14 0 0 0 0 6.281ZM20.432 21.428a1.427 1.427 0 1 0 0-2.854 1.427 1.427 0 0 0 0 2.854Zm0 1.715a3.141 3.141 0 1 0 0-6.283 3.141 3.141 0 0 0 0 6.283Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.024 7.161-6.658-1.674.419-1.663L18.442 5.5l-.418 1.662ZM17.525 19.846 5.144 15.999l.508-1.637 12.382 3.847-.509 1.637ZM3.703 12.094l3.453-6.217 1.499.833-3.453 6.217-1.499-.833ZM20.002 17.707V9.672h1.715v8.035h-1.715Z"
      fill="#666"
    />
  </svg>
)

export default Distort
