import { createReducer } from '@reduxjs/toolkit'
import {
  setIntroVideoDetail,
  setIsSampleUser,
  setOpenModalTryPremium,
  setUser,
  setUserIsPremium,
} from './actions'

export interface UserState {
  user: any
  userIsPremium: boolean
  openModalTryPremium: {
    isOpen: boolean
    source: string | null
    callback?: Function
    opened?: boolean
    video?: string
  }
  introVideoDetail?: {
    isOpen: boolean
    title: string
    subtitle: string
    videoSrc: string
    poster?: string
    onClickButton: Function
    contentBtn?: string
  }
  isSampleUser: Boolean
}

const initialState: UserState = {
  user: null,
  userIsPremium: false,
  openModalTryPremium: {
    isOpen: false,
    source: null,
    callback: null,
    opened: false
  },
  introVideoDetail: null,
  isSampleUser: false,
}

export const userReducer = createReducer(initialState, builder => {
  builder.addCase(setUser, (state, { payload }) => {
    state.user = payload
  })
  builder.addCase(setUserIsPremium, (state, { payload }) => {
    state.userIsPremium = payload
  })
  builder.addCase(setOpenModalTryPremium, (state, { payload }) => {
    // console.log('setOpenModalTryPremium', payload);
    
    state.openModalTryPremium = payload
  })
  builder.addCase(setIntroVideoDetail, (state, { payload }) => {
    state.introVideoDetail = payload
  })
  builder.addCase(setIsSampleUser, (state, { payload }) => {
    state.isSampleUser = payload
  })
})
