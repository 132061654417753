import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M16.495 14.863a1.001 1.001 0 0 1 .023-1.734l4.884-2.742c.036-.018.054-.04.054-.067 0-.026-.018-.049-.054-.066l-8.934-5.017a1.063 1.063 0 0 0-.5-.146c-.154 0-.32.048-.501.146l-8.87 5.02c-.035.018-.053.04-.053.067 0 .027.018.05.054.067l4.868 2.736a1.002 1.002 0 1 1-.989 1.742l-5.165-2.96c-.478-.275-.816-.53-1.014-.765a1.233 1.233 0 0 1-.298-.82c0-.31.1-.586.298-.826.198-.24.536-.497 1.014-.773l9.153-5.26c.29-.16.553-.278.792-.353.239-.076.476-.113.71-.113.235 0 .472.037.71.113.24.075.504.193.792.353l9.219 5.256c.478.276.816.533 1.014.773.199.24.298.515.298.826 0 .311-.1.584-.298.82-.198.235-.536.49-1.014.766l-5.182 2.965c-.314.18-.7.177-1.01-.008Z"
    />
    <path d="M13 10.594a.998.998 0 0 0-1.997 0V18.4a.998.998 0 0 0 1.997 0v-7.807Z" />
    <path
      fillRule="evenodd"
      d="m11.995 18.87 3.16-2.744a1 1 0 1 1 1.312 1.51l-3.424 2.972a1.6 1.6 0 0 1-2.1-.002l-3.409-2.971a1 1 0 1 1 1.315-1.508l3.146 2.743Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent