import { lightTheme } from '@/customTheme'
import { useStyletron } from 'baseui'
import { LabelLarge } from 'baseui/typography'
import React from 'react'
import WrapImage from './WrapImage'
import { t } from 'i18next'

function SuggestedButtons({
    suggestedListOutside = [null, null, null, null],
    handleClickOnSuggetion,
    base64ImageWithoutBg = null,
    ratioFrame = 1,
    activeItem,
    setActiveItem,
    paddedCrop,
    imageWithoutBgHtmlElement,
  }) {
    const [css] = useStyletron()
  
    return (
      <div>
        <div
          style={{
            height: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '8px',
          }}
        >
          <LabelLarge $style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>{t('Suggested')}</LabelLarge>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          }}
        >
          {new Array(4).fill(0).map((item, index) => (
            <div
              key={`suggested_${index}`}
              className={css({
                width: '142px',
                height: `${142 / ratioFrame}px`,
                borderRadius: '8px',
                overflow: 'hidden',
                background: 'rgb(242, 242, 242)',
                border: `${suggestedListOutside[index] === activeItem ? 2 : 1}px solid ${suggestedListOutside[index] === activeItem ? lightTheme.colors.primary : 'rgba(0, 0, 0, 0.10)'}`,
                cursor: suggestedListOutside[index] ? 'pointer' : 'not-allowed',
                transition: 'all 0.3s ease-in-out',
                // opacity: base64ImageWithoutBg ? 1 : 0.5,
                animation: suggestedListOutside[index] ? 'fade 1s' : 'fade 1s infinite',
              })}
              onClick={() => {
                if(suggestedListOutside[index]) {
                  setActiveItem(suggestedListOutside[index])
                  handleClickOnSuggetion(suggestedListOutside[index])
                }
              }}
            >
              <style>{`
                  @keyframes fade {
                    50% {
                      opacity: 0.5;
                    }
                  }
                `}</style>
              {suggestedListOutside[index] ? (
                <WrapImage srcImage={suggestedListOutside[index]} size={142} ratioFrame={ratioFrame} />
              ) : (
                <WrapImage srcImage={base64ImageWithoutBg} size={142} ratioFrame={ratioFrame} hasHoverState={false} />
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

export default SuggestedButtons