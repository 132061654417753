import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#1877F2"
      d="M15.5 8a7.5 7.5 0 1 0-8.672 7.409v-5.241H4.924V8h1.904V6.348c0-1.88 1.12-2.918 2.833-2.918.82 0 1.679.146 1.679.146v1.846h-.946c-.932 0-1.222.578-1.222 1.171V8h2.08l-.333 2.168H9.172v5.24A7.502 7.502 0 0 0 15.5 8Z"
    />
    <path
      fill="#fff"
      d="M10.92 10.168 11.251 8h-2.08V6.593c0-.593.29-1.171 1.222-1.171h.946V3.576s-.858-.146-1.679-.146c-1.713 0-2.833 1.038-2.833 2.918V8H4.924v2.168h1.904v5.24a7.563 7.563 0 0 0 2.344 0v-5.24h1.747Z"
    />
  </svg>
)
export default SvgComponent
