import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'
import { StyleObject } from 'styletron-react'

type ContextMenuItemProps = {
  startEnhancer?: JSX.Element
  content: string
  endEnhancer?: JSX.Element | string
  handleClick: (e?) => void
  disabled?: boolean
  style?: StyleObject
}

const ContextMenuItem = ({ startEnhancer, content, endEnhancer, handleClick, disabled = false, style }: ContextMenuItemProps) => {
  const normalizeEndEnhancer = () => {
    if (!endEnhancer) return null
    if (typeof endEnhancer === 'string') {
      return (
        <div
          style={{
            display: 'flex',
            padding: '1px 5px',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '4px',
            background: lightTheme.colors.grayScale50,
            ...lightTheme.typography.Small12medium,
            color: disabled ? '#ccc' : lightTheme.colors.contentPrimary,
            letterSpacing: '-0.6px'
          }}
        >
          {endEnhancer}
        </div>
      )
    }
    return endEnhancer
  }
  return (
    <ButtonCustom
      type={SizeButton.STANDARD}
      style={{
        width: '100%',
        padding: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        ...lightTheme.typography.Small14regular, color: lightTheme.colors.contentPrimary,
        ...style
      }}
      startEnhancer={startEnhancer}
      endEnhancer={normalizeEndEnhancer()}
      onClick={(e) => handleClick(e)}
      disabled={disabled}
    >
      <span style={{...lightTheme.typography.Small14regular, marginRight: '8px', textTransform: 'capitalize'}}>{content}</span>
    </ButtonCustom>
  )
}

export default ContextMenuItem
