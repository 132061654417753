import { lightTheme } from '@/customTheme'
import React, { useRef } from 'react'
import UploadsIcon from '../Editor/components/Icons/Uploads'
import { ThemeProvider } from 'baseui'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'styletron-react'
import { customAmplitude } from '@/utils/customAmplitude'

function DropZone({ handleDropFiles }) {
  const inputFileRef = useRef<HTMLInputElement>(null)

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDropFiles(e.target.files, 'add')
    e.target.value = ''
  }
  const { t } = useTranslation()
  const [ css ] = useStyletron()
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          width: '100%',
          borderRadius: '16px',
          overflow: 'hidden',
          padding: '24px',
          background: lightTheme.colors.grayScale50,
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            height: '192px',
            position: 'relative',
            alignItems: 'center',
            borderRadius: '14px',
            justifyContent: 'center',
            gap: '12px',
            border: `2px dashed ${lightTheme.colors.grayScale150}`
          }}
        >
          <h1
            className={css({
              ...lightTheme.typography.Title32Bold,
              color: lightTheme.colors.grayScale900,
              width: '100%',
              textAlign: 'center',
            })}
          >
            {t('Start with a photo')}
          </h1>
          <h2
            style={{
              ...lightTheme.typography.Small14Semibold,
              color: lightTheme.colors.blackGray,
              margin: 0,
              textAlign: 'center',
              width: '619px'
            }}
          >
            {t('Drag and drop your image anywhere')}
          </h2>
          <div
            onClick={() => {
              inputFileRef.current.click()
              customAmplitude('Create', {
                Source: 'Upload photo banner',
              })
            }}
            className={`${css({
              height: '48px',
              width: 'fit-content',
              minWidth: '200px',
              margin: '0 auto',
              padding: '12px',
              borderRadius: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '6px',
              cursor: 'pointer',
              ':hover': {
                boxShadow: '0px 0px 20px 0px rgba(255, 5, 96, 0.50)'
              },
              ':active': {
                boxShadow: '0px 0px 20px 0px rgba(255, 5, 96, 0.50)'
              }
            })} linear-gradient-bg-to-right`}
          >
            <UploadsIcon fill={lightTheme.colors.white} />
            <p style={{ ...lightTheme.typography.Small14Semibold, color: lightTheme.colors.white }}>{t('Upload photo')}</p>
          </div>
          <input
            onChange={handleFileInput}
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            accept="image/png, image/jpg, image/jpeg"
            id="input-ref-on-create-page"
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default DropZone
