import { Amplify } from 'aws-amplify'
import { uploadData } from 'aws-amplify/storage'
import axios, { AxiosInstance } from 'axios'
import { SignInManager } from '@scenes/Editor/components/Navbar/components/SignInManager'
import Cookies from 'universal-cookie'

class AdminApiService {
  base: AxiosInstance
  global: AxiosInstance

  constructor() {
    this.base = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })
    this.global = axios.create()

    this.base.interceptors.request.use(function (config) {
        config.headers.Authorization = `ApiKey ${SignInManager.getInstance().accessToken}`;
        return config;
    });

    Amplify.configure({
      Auth: {
        Cognito: {
          identityPoolId: 'us-east-1:f6401953-3f58-4cdb-9d35-5b6e63ff23ee',
          allowGuestAccess: true,
        },
      },
      Storage: {
        S3: {
          bucket: 'bazaart-content-upload',
          region: 'us-east-1'
        }
      }
    })
  }

  async getAllCategories(): Promise<any[]> {
    const getCategories = (limit, offset) => {
      return new Promise(async (resolve, reject) => {
        let url = `/admin_template_category/?is_hidden=${true}&nocache=1&version__lte=14&limit=${limit}&offset=${offset}`
        try {
          const { data } = await this.base.get(url)
          resolve(data.objects)
        } catch (err) {
          reject(err)
        }
      })
    }

    let results = []
    let iteratedResults = []
    let limit = 100;
    do {
      // @ts-ignore
      iteratedResults = await getCategories(limit, results.length)
      results = [...results, ...iteratedResults];
    } while (iteratedResults.length == limit)
    return results;
  }

  async getTemplatesById(id: number): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      let url = `/template_upload/${id}/?is_hidden=${true}&nocache=1&version__lte=14`
      try {
        const { data } = await this.base.get(url)
        resolve([data])
      } catch (err) {
        reject(err)
      }
    })
  }
  
  async getTemplatesByCategoryWithMeta(id: number, limit: number = 0, offset: number = 0): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      let url = `/template_upload/?limit=${limit}&offset=${offset}&is_hidden=${true}&nocache=1&version__lte=14&categories=${id}`
      try {
        const { data } = await this.base.get(url)
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  async deleteTemplates(templates: any[]): Promise<any>{
    let deleteAllRequests = templates.map ((template) => this.deleteTemplate(template.id))
    return await Promise.all(deleteAllRequests);
  }

  async deleteTemplate(templateId: number): Promise<any>{
    let url = `/template_upload/${templateId}/?is_hidden=true`
    await this.base.delete(url)
  }

  async updateTemplates(templatesJson: any): Promise<any>{
    let updateAllRequests = templatesJson.map ((templateJson) => this.updateTemplate(templateJson))
    return await Promise.all(updateAllRequests);
  }

  async updateTemplate(templateJson: any): Promise<any>{
    
    let parameters = {}
    if (templateJson['name'] !== undefined) {
      parameters['name'] = templateJson.name
    }
    if (templateJson['is_hidden'] !== undefined) {
      parameters['is_hidden'] = templateJson.is_hidden
    }
    let jsonParameters = JSON.stringify(parameters)

    let url = `/template_upload/${templateJson.id}/`
    await this.base.patch(url, jsonParameters, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Referrer-Policy": "origin"
        }
    })
  }

  async generateTemplates(endpoint: string, topic: string, topicsSuggestions: any, results: number, templateIds: number[], categoryId: number){
    let token = SignInManager.getInstance().accessToken;
    const json = JSON.stringify({
        newTopic: topic,
        topicsSuggestions: topicsSuggestions,
        // @ts-ignore
        results: results,
        templateIds: templateIds,
        categoryId: categoryId,
        flushQueue: true,
        token: token
    });

    await axios.post(
      endpoint,
      json,
      {
        timeout: 50000,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    alert('generating')
  }

  private saapi(service: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let cookies = new Cookies()
        let tokenData = cookies.get(service)

        if (tokenData) {
          resolve(tokenData)
          return
        }
        const dataRaw = {
          tokenOwner: 'a',
          metadata: {
            platform: 'mobile',
          },
        }
        const config = {
          headers: {
            'content-type': 'application/json',
          },
        }

        const { data } = await this.base.post(
          `https://api.usesapi.com/v1/token?aid=03984326-ad56-4970-a642-f9532c5c526e`,
          dataRaw,
          config
        )

        let expires = new Date()
        expires.setTime(Date.parse(data.expiresAt))
        cookies.set(service, data.token, { path: '/', expires })

        resolve(data.token)
      } catch (err) {
        reject(err)
      }
    })
  }

  async chatGptSuggestCompletion(prompt: string): Promise<string> {
    let featchSaapiToken = await this.saapi('api-openai-com-bbi6us')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': featchSaapiToken
      }
    }

    // Data for the POST request
    const params = {
      messages: [
        {role: "system", content: "You are a helpful assistant."},
        {role: "user", content: prompt}
      ],
      model: 'gpt-4-turbo',
      max_tokens: 250 // Adjust the number of tokens (words) as per your requirement
    };

    const { data } = await this.global.post(
      'https://api-openai-com-bbi6us.proxy.usesapi.com/v1/chat/completions',
      JSON.stringify(params),
      config
    )
    let results = data.choices[0].message.content.replace('json', '').replaceAll('\n', '').replaceAll('`', '').trim();
    return results;
  }

  async updateTemplateAsset(templateId: number, layerId: string, assetType: number, base64: string, filename: string): Promise<any> {
    let uploadedFile = await uploadData({
      key: `photos/${filename}`,
      data: new Blob([base64], { type: "application/json" }),
      options: {
        contentType: 'application/json',
        accessLevel: 'guest', // defaults to `guest` but can be 'private' | 'protected' | 'guest'
      }
    }).result;

    let params = {
      asset: uploadedFile.key,
      type: assetType,
      template: { "pk": templateId},
      layer: layerId
    }

    let that = this
    return new Promise(function(resolve, reject) {
      that.base
        .post(`/templateasset/`, params)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }

  async createTemplateCategory(categoryTitle: string, categoryDescription: string) {
    let that = this
    let params = {
      name: categoryTitle,
      is_hidden: true,
      is_web_visible: false,
      description: categoryDescription,
      order: 10000
    }
    return new Promise(function(resolve, reject) {
        that.base
        .post(`/admin_template_category/`, params)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }

  async linkToGenAiSuperCategory(categoryId: number){
    let aiSuperCategoryURI = { "pk": 32}
    let categoryURI = { "pk": categoryId}

    let that = this
    let params = {
      objects: [{
        "category_collection": aiSuperCategoryURI,
        category: categoryURI,
        order: 0
      }]
    }

    return new Promise(function(resolve, reject) {
        that.base
        .put(`/categorycollectionorder/`, params)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }
}

export default new AdminApiService()