import { fabric } from 'fabric'

const OUTLINE_SMOOTH_BLUR_RADIUS = 3.0;
const AVOID_OUTLINE_PADDING = 1.15;
const BLUR_RELATIVE_TO_PIXELS = 3.0;

// original implementation from here - https://github.com/evanw/glfx.js/blob/master/src/filters/blur/triangleblur.js
/**
 * @filter       Triangle Blur
 * @description  This is the most basic blur filter, which convolves the image with a
 *               pyramid filter. The pyramid filter is separable and is applied as two
 *               perpendicular triangle filters.
 * @param radius The radius of the pyramid convolved with the image.
 */

// @ts-ignore
fabric.Image.filters.Flexblur = fabric.util.createClass(fabric.Image.filters.BaseFilter, {

  /**
     * Filter type
     * @param {String} type
     * @default
     */
  type: 'Flexblur',

  sampleFromEdges: true,
  /**
   * Constructor
   * @memberOf fabric.Image.filters.RemoveWhite.prototype
   * @param {Object} [options] Options object
   */

  /**
   * Applies filter to canvas element
   * @param {Object} canvasEl Canvas element to apply filter to
   */
  // applyTo2d: function (options) {

  // },
  vertexSource: 'attribute vec2 aPosition;\n' +
    'varying vec2 vTexCoord;\n' +
    'uniform mat3 uTransformMatrix;\n' +
    'void main() {\n' +
    'vTexCoord = (uTransformMatrix * vec3(aPosition, 1.0)).xy;\n' +
    'gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);\n' +
    '}',

  fragmentSource: `
    precision highp float;
    uniform sampler2D uTexture;
    uniform int sampleFromEdges;
    uniform vec2 uDelta;
    varying vec2 vTexCoord;
    const float nSamples = 15.0;
    vec3 v3offset = vec3(12.9898, 78.233, 151.7182);
    float random(vec3 scale) {
      /* use the fragment position for a different seed per-pixel */
      return fract(sin(dot(gl_FragCoord.xyz, scale)) * 43758.5453);
    }
    void main() {
      vec4 color = vec4(0.0);
      float total = 0.0;
      float offset = random(v3offset);
      for (float t = -nSamples; t <= nSamples; t++) {
        float percent = (t + offset - 0.5) / nSamples;
        float weight = 1.0 - abs(percent);
        vec2 newCoordinate = vTexCoord + uDelta * percent;
        if (sampleFromEdges == 1 || (newCoordinate.x >= 0.0 && newCoordinate.y >= 0.0 && newCoordinate.x <= 1.0 && newCoordinate.y <= 1.0)) {
           color += texture2D(uTexture, newCoordinate) * weight;
        } else {
           color += vec4(1.0, 1.0, 1.0, 1.0) * weight;
        }
        total += weight;
      }
      gl_FragColor = color / total;
    }`,
    
  applyBoundingBox: function (rect) {
    let newRect = Object.assign({}, rect);
    let percent = this.paddingPercent(this.blur, rect.width);
    // its on image after down size.
    // so we need to we need to set first time for bring it back to normal size and second time for padding
    let paddingPercent = percent * percent;

    let destinationWidth = 0;
    let destinationHeight = 0;

    if (rect.height > rect.width) {
      let paddingSizeY = paddingPercent * rect.height;
      let paddingSizeDiffY = paddingSizeY - rect.height;
      let paddingSizeX = paddingSizeDiffY + rect.width;
      destinationWidth = paddingSizeX;
      destinationHeight = paddingSizeY;
    } else {
      let paddingSizeX = paddingPercent * rect.width;
      let paddingSizeDiffX = paddingSizeX - rect.width;
      let paddingSizeY = paddingSizeDiffX + rect.height;
      destinationWidth = paddingSizeX;
      destinationHeight = paddingSizeY;
    }

    newRect.x -= (destinationWidth - rect.width) / 2
    newRect.y -= (destinationHeight - rect.height) / 2

    newRect.width = destinationWidth;
    newRect.height = destinationHeight;

    console.log(`new rect.width: ${newRect.width}, new rect.height: ${newRect.height}`)
    // console.log(`new blur: ${this.blur}`);
    return newRect;
  },

  applyTo: function(options) {
    if (options.webgl) {

      // this aspectRatio is used to give the same blur to vertical and horizontal
      this.aspectRatio = options.sourceWidth / options.sourceHeight;
      this.width = options.destinationWidth;
      this.height = options.destinationHeight;

      options.passes++;
      this._setupFrameBuffer(options);
      this.horizontal = true;
      this.applyToWebGL(options);
      this._swapTextures(options);

      this._setupFrameBuffer(options);
      this.horizontal = false;
      this.applyToWebGL(options);
      this._swapTextures(options);
    }
    else {
      this.applyTo2d(options);
    }
  },
  applyTo2d: function(options) {
    // paint canvasEl with current image data.
    //options.ctx.putImageData(options.imageData, 0, 0);
    options.imageData = this.simpleBlur(options);
  },
  
  simpleBlur: function(options) {
    var resources = options.filterBackend.resources, canvas1, canvas2,
        width = options.imageData.width,
        height = options.imageData.height;
    if (!resources.blurLayer1) {
      resources.blurLayer1 = fabric.util.createCanvasElement();
      resources.blurLayer2 = fabric.util.createCanvasElement();
    }
    canvas1 = resources.blurLayer1;
    canvas2 = resources.blurLayer2;
    if (canvas1.width !== width || canvas1.height !== height) {
      canvas2.width = canvas1.width = width;
      canvas2.height = canvas1.height = height;
    }
    var ctx1 = canvas1.getContext('2d'),
        ctx2 = canvas2.getContext('2d'),
        nSamples = 15,
        random, percent, j, i,
        blur = this.blur * 0.06 * 0.5;
    // load first canvas
    ctx1.putImageData(options.imageData, 0, 0);
    ctx2.clearRect(0, 0, width, height);
    for (i = -nSamples; i <= nSamples; i++) {
      random = (Math.random() - 0.5) / 4;
      percent = i / nSamples;
      j = blur * percent * width + random;
      ctx2.globalAlpha = 1 - Math.abs(percent);
      ctx2.drawImage(canvas1, j, random);
      ctx1.drawImage(canvas2, 0, 0);
      ctx2.globalAlpha = 1;
      ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
    }
    for (i = -nSamples; i <= nSamples; i++) {
      random = (Math.random() - 0.5) / 4;
      percent = i / nSamples;
      j = blur * percent * height + random;
      ctx2.globalAlpha = 1 - Math.abs(percent);
      ctx2.drawImage(canvas1, random, j);
      ctx1.drawImage(canvas2, 0, 0);
      ctx2.globalAlpha = 1;
      ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
    }
    options.ctx.drawImage(canvas1, 0, 0);
    var newImageData = options.ctx.getImageData(0, 0, canvas1.width, canvas1.height);
    ctx1.globalAlpha = 1;
    ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
    return newImageData;
  },

  /**
   * Return WebGL uniform locations for this filter's shader.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {WebGLShaderProgram} program This filter's compiled shader program.
   */
  getUniformLocations: function (gl, program) {
    return {
      delta: gl.getUniformLocation(program, 'uDelta'),
      sampleFromEdges: gl.getUniformLocation(program, 'sampleFromEdges'),
      uTransformMatrix: gl.getUniformLocation(program, 'uTransformMatrix'),
    };
  },

  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData: function (gl, uniformLocations) {
    var delta = this.chooseRightDelta();
    var matrix = this.calculateMatrix();
    gl.uniform2fv(uniformLocations.delta, delta);
    gl.uniform1i(uniformLocations.sampleFromEdges, this.sampleFromEdges ? 1 : 0);
    gl.uniformMatrix3fv(uniformLocations.uTransformMatrix, false, matrix);
  },

  /**
   * Calculate a transformMatrix to adapt the image to blend over
   * @param {Object} options
   * @param {WebGLRenderingContext} options.context The GL context used for rendering.
   * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
   */
  calculateMatrix: function (options) {
    let padding = this.getMinPadding();
    return [
      1.0 * (padding), 0, 0,
      0, 1.0 * (padding), 0,
      ((1 - padding) / 2.0), ((1 - padding) / 2.0), 1
    ];
  },

  getMinPadding: function () {
    let paddingPercentX = this.myPaddingPercent();

    let height = this.width / this.aspectRatio;
    let paddingSize = paddingPercentX * this.width - this.width;
    let paddingPercentY = (paddingSize + height) / height ;
    let padding = Math.min(paddingPercentX, paddingPercentY);
    // let sqrtPadding = Math.sqrt(padding);
    // let retPadding = (padding + sqrtPadding) / 2;
    return padding;
  },

  /**
     * choose right value of image percentage to blur with
     * @returns {Array} a numeric array with delta values
     */
     chooseRightDelta() {
    let blurScale = 1;
    const delta = [0, 0];
    if (this.horizontal) {
      if (this.aspectRatio > 1) {
        // image is wide, i want to shrink radius horizontal
        blurScale = 1 / this.aspectRatio;
      }
    } else {
      if (this.aspectRatio < 1) {
        // image is tall, i want to shrink radius vertical
        blurScale = this.aspectRatio;
      }
    }
    const blur = blurScale * this.blur * 0.12;
    if (this.horizontal) {
      delta[0] = blur;
    } else {
      delta[1] = blur;
    }
    return delta;
  },

  myPaddingPercent: function () {
    return this.paddingPercent(this.blur, this.width);
  },

  paddingPercent: function (blur, width) {
    // @ts-ignore
    let rThickness = (fabric.Image.filters.Flexblur.radiusFromBlurSize(blur, width) * BLUR_RELATIVE_TO_PIXELS) / AVOID_OUTLINE_PADDING
    let percent = (width + (2 * rThickness)) / width;
    return percent;
  },
  /**
   * Returns object representation of an instance
   * @return {Object} Object representation of an instance
   */
  toObject: function () {
    return {
      type: this.type,
    };
  }
});
// @ts-ignore
fabric.Image.filters.Flexblur.fromObject = fabric.Image.filters.BaseFilter.fromObject;

// @ts-ignore
fabric.Image.filters.Flexblur.blurSizeFromRadius = (radius, width) => {
  let blurCalc = (radius * (Math.pow(OUTLINE_SMOOTH_BLUR_RADIUS, 1 / 3) / ((width))) / 0.12);
  return blurCalc;
}

// @ts-ignore
fabric.Image.filters.Flexblur.radiusFromBlurSize = function (blurSize, width) {
  let radius = (blurSize * 0.12) / (Math.pow(OUTLINE_SMOOTH_BLUR_RADIUS, 1 / 3) / ((width)));
  return radius;
}