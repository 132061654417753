import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={22}
        height={18}
        x={1}
        y={3}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M22.125 3H1.875v18h20.25V3Z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#666"
          d="M2.588 18.957c1.571 2.098 4.868 2.732 6.945 1.185 1.762-1.316 2.075-3.314.82-4.99-1.137-1.514-3.095-1.9-4.39-.905-1.406 1.05-.84 2.403-1.67 3.015-.704.533-1.285.193-1.739.527-.35.258-.35.682.034 1.168Zm8.692-5.535c.669.669 1.158 1.54 1.299 2.282.664-.119 1.211-.423 1.771-.963.099-.093.694-.756.784-.858-.355-2.192-2.143-3.955-4.309-4.303-.1.09-.76.681-.852.778-.547.565-.853 1.125-.97 1.785.74.132 1.61.611 2.277 1.28Zm7.794-10.09c-.443.32-5.251 3.6-8.249 6.248a6.718 6.718 0 0 1 4.309 4.303c2.645-2.992 5.931-7.798 6.248-8.243 1.092-1.55-.817-3.408-2.308-2.308Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M1.875 3h20.25v18H1.875z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
