function Spacing({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_929_11808" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.1499 3L5.1499 21L3.1499 21L3.1499 3L5.1499 3Z" />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1499 3L5.1499 21L3.1499 21L3.1499 3L5.1499 3Z"
        fill="#666666"
      />
      <path
        d="M5.1499 3L7.1499 3L7.1499 1L5.1499 1L5.1499 3ZM5.1499 21L5.1499 23L7.1499 23L7.1499 21L5.1499 21ZM3.1499 21L1.1499 21L1.1499 23L3.1499 23L3.1499 21ZM3.1499 3L3.1499 1L1.1499 1L1.1499 3L3.1499 3ZM3.1499 3L3.1499 21L7.1499 21L7.1499 3L3.1499 3ZM5.1499 19L3.1499 19L3.1499 23L5.1499 23L5.1499 19ZM5.1499 21L5.1499 3L1.1499 3L1.1499 21L5.1499 21ZM3.1499 5L5.1499 5L5.1499 1L3.1499 1L3.1499 5Z"
        fill="#666666"
        mask="url(#path-1-inside-1_929_11808)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16073 3.70072L6.4913 6.0167C6.79912 6.32258 7.29661 6.32102 7.6025 6.01321C7.90838 5.7054 7.90682 5.2079 7.59901 4.90201L5.04644 2.36543C4.55542 1.87749 3.76233 1.87829 3.2723 2.36723L0.730755 4.90314C0.423565 5.20965 0.423012 5.70715 0.72952 6.01434C1.03603 6.32153 1.53353 6.32208 1.84072 6.01557L4.16073 3.70072Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16073 20.2993L6.4913 17.9833C6.79911 17.6774 7.29661 17.679 7.6025 17.9868C7.90838 18.2946 7.90682 18.7921 7.59901 19.098L5.04644 21.6346C4.55542 22.1225 3.76233 22.1217 3.2723 21.6328L0.730755 19.0969C0.423565 18.7904 0.423012 18.2929 0.72952 17.9857C1.03603 17.6785 1.53353 17.6779 1.84072 17.9844L4.16073 20.2993Z"
        fill="#666666"
      />
      <rect x="9.5" y="13" width="15" height="2" rx="1" fill="#666666" />
      <rect x="9.5" y="18" width="15" height="2" rx="1" fill="#666666" />
      <rect x="9.5" y="3" width="15" height="2" rx="1" fill="#666666" />
      <rect x="9.5" y="8" width="15" height="2" rx="1" fill="#666666" />
    </svg>
  )
}

export default Spacing
