import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#D9252A"
      d="M15.489 6.456a11.586 11.586 0 0 0-.03-.533c-.057-.752-.14-1.612-.637-2.214-.426-.514-1.084-.63-1.709-.702a31.751 31.751 0 0 0-2.383-.174c-1.612-.061-3.226-.026-4.838.01-.828.018-1.657.037-2.483.102-1.014.08-2.057.23-2.529 1.243-.424.91-.371 2.065-.377 3.044C.498 8.11.495 8.988.53 9.864c.046 1.139.113 2.621 1.342 3.12.656.267 1.448.325 2.148.371.742.048 1.487.043 2.23.053 1.56.024 3.119-.03 4.678-.061 1.082-.022 2.194-.034 3.23-.387.894-.305 1.127-1.275 1.249-2.116.12-.835.09-1.683.088-2.524-.001-.622.016-1.244-.006-1.866v.002Z"
    />
    <path fill="#fff" d="M10.364 8.09 6.48 5.81v4.563l3.884-2.283Z" />
  </svg>
)
export default SvgComponent
