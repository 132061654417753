import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M4 16a1 1 0 1 0-2 0v2.5A1.5 1.5 0 0 0 3.5 20h17a1.5 1.5 0 0 0 1.5-1.5V16a1 1 0 1 0-2 0v2H4v-2Z"
      clipRule="evenodd"
    />
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M12 2a1 1 0 0 1 1 1v11a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="m11.985 13.227-4.017-4.46a.96.96 0 1 0-1.425 1.289l4.775 5.26a.924.924 0 0 0 .667.289c.25 0 .49-.104.667-.289l4.8-5.26a.96.96 0 1 0-1.42-1.293l-4.047 4.464Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
