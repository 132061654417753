import React from 'react'

function FillText({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM18.5858 4H15.7566L4 15.7566V18.5858L18.5858 4ZM10.0943 4H12.9282L4 12.9282V10.0943L10.0943 4ZM7.2659 4H5C4.44771 4 4 4.44772 4 5V7.2659L7.2659 4ZM5.41421 20H8.25656L20 8.25656V5.41421L5.41421 20ZM13.9304 20H11.085L20 11.085V13.9304L13.9304 20ZM16.7588 20H19C19.5523 20 20 19.5523 20 19V16.7588L16.7588 20Z"
        fill="#666666"
      />
    </svg>
  )
}

export default FillText
