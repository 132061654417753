import * as React from "react"
const SvgComponent = (props: {direction?: 'right' | 'left' | 'top' | 'bottom', fill?: string, size?: number}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? props.size : 24}
    height={props.size ? props.size : 24}
    fill="none"
    {...props}
    transform={props.direction === 'right' ? 'rotate(0)' : props.direction === 'left' ? 'rotate(180)' : props.direction === 'top' ? 'rotate(270)' : props.direction === 'bottom' ? 'rotate(90)' : 'rotate(0)'}
  >
    <path
      fill={props.fill ? props.fill : "#666"}
      fillRule="evenodd"
      d="m13.76 12.293-4.77 4.571a1.313 1.313 0 1 0 1.816 1.896l5.801-5.558a1.26 1.26 0 0 0 .393-.91 1.26 1.26 0 0 0-.393-.908l-5.801-5.559a1.313 1.313 0 0 0-1.817 1.896l4.771 4.572Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
