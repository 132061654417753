function Photos({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 28 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.087 13.958a1.35 1.35 0 0 0-1.863-.015l-3.44 3.228-.033.031-.087.08a40.606 40.606 0 0 1-.554.501 3.492 3.492 0 0 1-.13.107 1.347 1.347 0 0 1-.196.125h-.001a.915.915 0 0 1-.401.094c-.22 0-.383-.084-.401-.093h-.002a.99.99 0 0 1-.195-.125 3.412 3.412 0 0 1-.13-.108 38.68 38.68 0 0 1-.555-.5l-.087-.08-.026-.025-1.057-.944a.9.9 0 0 0-1.2 0l-3.827 3.423-1.2-1.341 3.828-3.424a2.7 2.7 0 0 1 3.599 0l1.07.957.008.006.03.029.084.077.06.056c.022-.02.043-.038.061-.056l.083-.077.03-.027v-.001l3.437-3.226a3.15 3.15 0 0 1 4.348.035l5.8 5.623-1.252 1.293-5.8-5.623Z"
        fill="currentColor"
      />
      <path
        d="M9.631 12.765c.626 0 1.16-.224 1.605-.673.444-.448.667-.985.667-1.61 0-.626-.223-1.163-.667-1.612a2.178 2.178 0 0 0-1.605-.673c-.625 0-1.162.225-1.611.673a2.198 2.198 0 0 0-.673 1.611c0 .626.224 1.163.673 1.611.449.449.986.673 1.611.673ZM5.448 22h17.105c1.062 0 1.852-.26 2.37-.778.52-.518.778-1.296.778-2.333V7.11c0-1.037-.259-1.815-.777-2.333-.519-.519-1.31-.778-2.37-.778H5.447c-1.045 0-1.831.26-2.358.778-.527.518-.79 1.296-.79 2.333V18.89c0 1.037.263 1.815.79 2.333.527.519 1.313.778 2.358.778Zm.025-1.79c-.445 0-.788-.12-1.031-.358-.243-.239-.364-.589-.364-1.05V7.198c0-.47.121-.821.364-1.056.243-.235.586-.352 1.03-.352h17.056c.445 0 .789.117 1.031.352.243.235.364.586.364 1.056v11.604c0 .461-.12.811-.364 1.05-.242.238-.586.358-1.03.358H5.473Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Photos
