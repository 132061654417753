import { fabric } from 'fabric'
import { CanvasBlur } from '@scenes/engine/objects/CanvasBlur.filter'

export class Brush {
  image: HTMLImageElement;
  size: number;
  softness: number;
  opacity:number;
  sizeForCaching: number;

  constructor(base64: string, softness: number, opacity: number, size: number, sizeForCaching: number){
    this.size = size;
    this.softness = softness;
    this.opacity = opacity;
    this.sizeForCaching = sizeForCaching
    this.image = new Image();

    if (!base64){ return;}

    this.image.src = base64;
    this.image.width = size;
    this.image.height = size;
  }
}

export class BlendBrushObject extends fabric.PencilBrush {
  static type = 'BlendBrush'

  cachedBrush: Brush | null;

  maskScale: number

  zoomScale: number

  setZoomScale(value){
    this.zoomScale = value
  }

  setMaskScale(value){
    this.maskScale = value
  }

  // @ts-ignore

  initialize(canvas) {
    super.initialize(canvas);
  }

  dispose() {
  }

  _reset() {
    // @ts-ignore
    super._reset()
  }

  createCircleImage(radius, { r, g, b, a }, opacity:number, softness: number, backgroundColor: string | null = null) {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    let blurToPixelRatio = 3;
    let blurSize = Math.ceil(softness / blurToPixelRatio)
    let radiusWithRoomForBlur = Math.max(3, radius - softness);

    canvas.width = canvas.height = radius * 2;
    if (backgroundColor){
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    let centerX = (radius * 2) / 2;
    let centerY = (radius * 2) / 2;

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    let blurFilter = `blur(${blurSize}px)`;
    ctx.filter = blurFilter;

    ctx.globalAlpha = opacity;
    ctx.beginPath();
    ctx.arc(centerX, centerY, radiusWithRoomForBlur, 0, 2 * Math.PI);
    ctx.fillStyle = `rgba(${r}, ${g}, ${b}, ${a})`;
    ctx.fill();

    if (this.canvasBlurNotSupported()) {
      CanvasBlur(ctx, blurSize);
    }

    let img = new Image();
    img.src = canvas.toDataURL();
    return img;
  }

  private canvasBlurNotSupported(): Boolean {
    let userAgent = navigator.userAgent;
    // Check for Safari
    let isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    // Safari could also be detected by checking the vendor
    let isAppleVendor = /Apple Computer/.test(navigator.vendor);
    // Combine both checks for a more reliable detection
    return (isSafari && isAppleVendor);
  }

  async createBrush(brushSize: number, color: any, softness: number, opacity: number, backgroundColor: string | null = null): Promise<Brush> {
    let radius = Math.floor(brushSize / 2);
    let circle = this.createCircleImage(radius, color, opacity, softness, backgroundColor);
    let brush = new Brush(circle.src, softness, opacity, radius * 2, brushSize);
    return brush;
  }

  _getBrushSize(): number {
    return this.width / this.maskScale / this.zoomScale;
  }

  _shouldRecreateBrush(): boolean {
    let brushSize = this._getBrushSize()
    let shouldRecreate = !this.cachedBrush || this.cachedBrush.sizeForCaching != brushSize
    return shouldRecreate
  }

  async _createBrush() {
    let brushSize = this._getBrushSize();
    const redColor = { r: 255, g: 5, b: 96, a: 255.0 }
    let softness = 20 - this._normalize(this.zoomScale, 0, 3, 0, 20);
    this.cachedBrush = await this.createBrush(brushSize, redColor, softness, 1.0)
  }

  _normalize(uiValue: number, uiMinValue: number, uiMaxValue: number,
                     minValue: number, maxValue: number): number {
    let normalizedUIValue = (uiValue - uiMinValue) / (uiMaxValue - uiMinValue)
    let normalizedLogicValue = normalizedUIValue * (maxValue - minValue) + minValue
    return normalizedLogicValue
  }

  retinaScalingAdjust(): number {
    // @ts-ignore
    return this.canvas.getRetinaScaling()
  }

  _doDraw(ctx: CanvasRenderingContext2D, point: any) {
      ctx.lineTo(point.x, point.y);

      // for some reason css scale and retina scaling are one instead of the other and aren't accumulated
      let retinaScalingAdjust = this.retinaScalingAdjust()
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      let actualBrushSize = this.cachedBrush.size / 1.05;
      ctx.drawImage(
        this.cachedBrush.image,
        Math.round(point.x * retinaScalingAdjust - actualBrushSize / 2),
        Math.round(point.y * retinaScalingAdjust - actualBrushSize / 2),
        Math.round(actualBrushSize),
        Math.round(actualBrushSize)
      );
  }

  _transform(point: any) {
    return point
  }

  _render(ctx: CanvasRenderingContext2D) {

    const _doRender = () => {
      // @ts-ignore
      var p1 = this._transform(this._points[this._points.length - 2]),
        // @ts-ignore
        p2 = this._transform(this._points[this._points.length - 1]),
        // @ts-ignore
        ctx = ctx || this.canvas.contextTop;

      // @ts-ignore
      this._saveAndTransform(ctx);
      ctx.beginPath();

      let vector = { x: p2.x - p1.x, y: p2.y - p1.y };

      const distance = Math.hypot(vector.x, vector.y);
      vector.x /= distance;
      vector.y /= distance;

      const stepThreshold = 1 // this.maskScale / this.zoomScale * (200 - this.width) / 200 * brush.size / 6;
      if (distance < stepThreshold) {
        this._doDraw(ctx, p2);
        return;
      }

      let step = this.getStepSize()
      for (let i = 0; i < distance; i += step) {
        const p = { x: p1.x + i * vector.x, y: p1.y + i * vector.y };
        this._doDraw(ctx, p);
      }
    }
    if (this._shouldRecreateBrush()) {
      setTimeout(async () => {
        await this._createBrush()
        _doRender()
      })
    } else {
      _doRender()
    }
  }


  undo(){ /*override me */  }

  redo(){ /*override me */  }

  getStepSize() {
    return 1;
  }
  // @ts-ignore
  _finalizeAndAddPath() {
    // @ts-ignore
    const ctx = this.canvas.contextTop;
    ctx.closePath();
  }


  // @ts-ignore
  onMouseMove(pointer, options) {
    // @ts-ignore
    if (!this.canvas._isMainEvent(options.e)) {
      return;
    }
    // @ts-ignore
    this.drawStraightLine = options.e[this.straightLineKey];
    // @ts-ignore
    if (this.limitedToCanvasSize === false && this._isOutSideCanvas(pointer)) {
      return;
    }
    // @ts-ignore
    if (this._captureDrawingPath(pointer) && this._points.length > 1) {
      // @ts-ignore
      this._render();
    }
  }

  _isOutSideCanvas (pointer) {
    // @ts-ignore
    let canvas = this.canvas
    return pointer.x + this._getBrushSize() < 0 || pointer.x - this._getBrushSize() > canvas.getWidth() || pointer.y + this._getBrushSize() < 0 || pointer.y - this._getBrushSize() > canvas.getHeight();
  }
}

fabric.BlendBrush = fabric.util.createClass(BlendBrushObject, {
  type: BlendBrushObject.type,
})

declare module 'fabric' {
  namespace fabric {
    class BlendBrush extends BlendBrushObject {
      constructor(canvas:any)
    }
  }
}
