import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={32} height={32} fill="#fff" rx={16} />
    <g fill={lightTheme.colors.blackGray} fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M17.437 10.72a1.44 1.44 0 0 1-.838 1.309 1.964 1.964 0 0 0 0 .13c0 1.914 1.424 3.46 3.184 3.46 1.459 0 2.679-1.062 3.068-2.503a1.44 1.44 0 1 1 1.07.277c-.251 1.045-.49 2.108-.723 3.173a2.232 2.232 0 0 0-.128.146l-2.25 2.806-.4-.364a2.2 2.2 0 1 0-2.961 3.255l.342.31h-5.698a2.505 2.505 0 0 1-2.452-1.923c-.321-1.38-.613-2.769-.905-4.157-.228-1.086-.456-2.171-.698-3.251a1.44 1.44 0 1 1 1.066-.246c.405 1.418 1.62 2.458 3.056 2.458 1.761 0 3.185-1.546 3.185-3.46a2.142 2.142 0 0 0 0-.131 1.44 1.44 0 0 1-.655-1.914c.216-.484.708-.82 1.277-.815h.03a1.44 1.44 0 0 1 1.431 1.44Z" />
      <path d="M25.287 17.465a.8.8 0 0 1 .123 1.125l-3.609 4.498a.96.96 0 0 1-1.394.11L18.4 21.372a.8.8 0 0 1 1.076-1.184l1.502 1.366 3.183-3.967a.8.8 0 0 1 1.125-.123Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M6.4 6.4h19.2v19.2H6.4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
