import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : "#FF0560"}
      fillRule="evenodd"
      d="m9.173 7.805-3.18-3.048a.875.875 0 1 1 1.21-1.264L11.072 7.2a.84.84 0 0 1 .262.606.84.84 0 0 1-.262.606l-3.867 3.706a.875.875 0 1 1-1.211-1.265l3.18-3.047Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
