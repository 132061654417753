import React from 'react'

function LineSpacing({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.2902 2C21.2902 2.1603 21.1603 2.29024 21 2.29024L3 2.29024C2.8397 2.29024 2.70976 2.16029 2.70976 2C2.70976 1.8397 2.8397 1.70976 3 1.70976L21 1.70976C21.1603 1.70976 21.2902 1.8397 21.2902 2Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.41951"
      />
      <path
        d="M2.70976 22C2.70976 21.8397 2.8397 21.7098 3 21.7098L21 21.7098C21.1603 21.7098 21.2902 21.8397 21.2902 22C21.2902 22.1603 21.1603 22.2902 21 22.2902L3 22.2902C2.8397 22.2902 2.70976 22.1603 2.70976 22Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.41951"
      />
      <path
        d="M17.3702 16.86L13.5902 6.57C13.4602 6.22 13.2702 5.95 13.0102 5.77C12.7602 5.59 12.4402 5.5 12.0802 5.5C11.7202 5.5 11.4102 5.59 11.1602 5.78C10.9102 5.96 10.7202 6.23 10.6002 6.58L6.8202 16.87C6.7402 17.1 6.7002 17.3 6.7002 17.48C6.7002 17.74 6.7702 17.96 6.9002 18.14L7.0102 18.25L7.1102 18.35C7.2902 18.47 7.5202 18.53 7.7802 18.53C8.0902 18.53 8.3502 18.45 8.5402 18.3C8.7302 18.15 8.8702 17.93 8.9802 17.61L9.8902 15.08H14.2602L15.1702 17.61C15.2602 17.86 15.3702 18.06 15.5002 18.2L15.7402 18.4C15.9102 18.48 16.1202 18.53 16.3702 18.53C16.6902 18.53 16.9602 18.43 17.1602 18.24C17.3702 18.04 17.4802 17.78 17.4802 17.46C17.4802 17.27 17.4402 17.08 17.3602 16.85L17.3702 16.86ZM13.6002 13.1H10.5502L12.0702 8.69L13.5902 13.1H13.6002Z"
        fill="#666666"
      />
    </svg>
  )
}

export default LineSpacing
