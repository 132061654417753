function Timefast({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6667 20H4V18.6667H10.6667V20ZM9.33333 16H4V17.3333H9.33333V16ZM8 13.3333H4V14.6667H8V13.3333ZM12 4C9.87897 4.00229 7.84547 4.84589 6.34568 6.34568C4.84589 7.84547 4.00229 9.87897 4 12H5.33333C5.33333 10.6815 5.72433 9.39253 6.45687 8.2962C7.18941 7.19987 8.2306 6.34539 9.44878 5.8408C10.667 5.33622 12.0074 5.2042 13.3006 5.46143C14.5938 5.71867 15.7817 6.3536 16.714 7.28595C17.6464 8.21831 18.2813 9.40619 18.5386 10.6994C18.7958 11.9926 18.6638 13.333 18.1592 14.5512C17.6546 15.7694 16.8001 16.8106 15.7038 17.5431C14.6075 18.2757 13.3185 18.6667 12 18.6667V20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM11.3333 8.66667V12.276L13.5287 14.4713L14.4713 13.5287L12.6667 11.724V8.66667H11.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Timefast
