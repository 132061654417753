import { styled } from 'baseui'
import Icons from '@/scenes/Editor/components/Icons'
import { lightTheme } from '@/customTheme'
import ButtonCustom from '../ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import VideoIntroRemove from '@assets/video/intro-remove.mp4'
import VideoIntroTemplate from '@assets/video/intro-template.mp4'
import VideoIntroMagicBg from '@assets/video/intro-magic-bg.mp4'
import { useEffect, useRef } from 'react'
import { selectIntroVideoDetail, selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { setIntroVideoDetail } from '@/store/slices/user/actions'
import { useAppDispatch } from '@/store/store'
import { useTranslation } from 'react-i18next'

const WrapModal = styled('div', (props: any) => ({
  position: 'absolute',
  zIndex: 1000,
  inset: 0,
  background: 'rgba(0,0,0,0.5)',
  backdropFilter: 'blur(5px)',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'auto',
  transition: 'all 0.4s',
  alignItems: 'center',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  '@media screen and (max-height: 652px)': {
    alignItems: 'start',
  },
  ...props.style,
}))

function IntroVideo() {
  const videoRef = useRef(null)
  const dispatch = useAppDispatch()
  const introVideoDetail = useSelector(selectIntroVideoDetail)
  const hasPremium = useSelector(selectUserIsPremium)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }, [])
  const { t } = useTranslation()
  return (
    <>
      {introVideoDetail ? (
        <WrapModal>
          <div
            style={{
              width: '920px ',
              height: '652px',
              flexDirection: 'row',
              background: 'rgba(255, 255, 255, 1)',
              borderRadius: '16px',
              overflow: 'hidden',
              position: 'relative',
              display: introVideoDetail.isOpen ? 'flex' : 'none',
            }}
            className="scale-modal-0-8"
          >
            <>
              {/* left */}
              <div style={{ width: '50%', position: 'relative' }}>
                <video
                  ref={videoRef}
                  src={introVideoDetail.videoSrc === 'remove' ? VideoIntroRemove : introVideoDetail.videoSrc === 'magic-bg' ? VideoIntroMagicBg : VideoIntroTemplate}
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: 'cover',
                  }}
                  //   controls
                  autoPlay
                  muted
                  loop
                  poster={introVideoDetail.poster}
                >
                  {/* <source src={VideoIntroRemove} type='video/mp4; codecs=hevc"' />
                  <source src={VideoIntroRemoveM4V} type='video/mp4' /> */}
                </video>
              </div>
              {/* right */}
              <div
                style={{
                  padding: '48px 48px 16px 48px',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  width: '50%',
                  justifyContent: 'center',
                }}
              >
                <h2 style={{ ...lightTheme.typography.TitleBold, margin: 0 }}>{introVideoDetail.title}</h2>
                <p
                  style={{
                    ...lightTheme.typography.Small14regular,
                    lineHeight: '22px',
                    margin: '8px 0 32px',
                  }}
                >
                  {introVideoDetail.subtitle}
                </p>
                <ButtonCustom
                  type={SizeButton.LARGE}
                  kind={KIND.primary}
                  style={{
                    position: 'relative',
                    ...lightTheme.typography.Small14Semibold,
                    color: '#fff',
                    width: '180px',
                  }}
                  onClick={e => {
                    dispatch(setIntroVideoDetail(null))
                    introVideoDetail.onClickButton()
                  }}
                >
                  {introVideoDetail.contentBtn ? introVideoDetail.contentBtn : hasPremium ? t('Got it') : t('Continue')}
                </ButtonCustom>
              </div>
              {/* clsoe icon */}
              <div
                style={{
                  position: 'absolute',
                  top: '16px',
                  right: '16px',
                  width: '32px',
                  height: '32px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={e => {
                  dispatch(setIntroVideoDetail(null))
                  hasPremium ?? introVideoDetail.onClickButton()
                }}
              >
                <Icons.WhiteCloseIcon size={24} />
              </div>
            </>
          </div>
        </WrapModal>
      ) : null}
    </>
  )
}

export default IntroVideo
