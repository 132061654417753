import HeadingInspector from '@/components/HeadingInspector'
import { lightTheme } from '@/customTheme'
import { Block } from 'baseui/block'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import React, { useEffect, useState } from 'react'
import Icons from '../../../Icons'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { LIST_LANGUAGE } from '@/scenes/engine/common/constants'
import { setLocalStorage } from '@/utils/localStorage'
import AutoScroll from '@/components/AutoScroll'



export default function LanguageMenu({ handleNavigation }) {
  const [valueSelected, setValueSelected] = useState<{ id: number; label: string, desc: string, value: string }>(LIST_LANGUAGE[0])
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const { t, i18n } = useTranslation()
  useEffect(() => {
    const currentLanguage = localStorage.getItem('language')   
    LIST_LANGUAGE.forEach(lang => {
      if(currentLanguage.includes(lang.value)) {
        setValueSelected(lang)
      }
    })
  }, [])
  return (
    <>
      <HeadingInspector
        hasNavigation={true}
        handleNavigation={handleNavigation}
        title={t('Language')}
        style={{
          padding: 0,
          height: '64px',
        }}
      ></HeadingInspector>
      <p style={{ ...lightTheme.typography.Small14regular, margin: '0 0 8px 0' }}>
        {t('Choose your preferred language.')}
      </p>
      <StatefulPopover
        placement={PLACEMENT.bottom}
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              marginTop: '4px',
              zIndex: '1000',
              maxHeight: '277px',
              transitionDuration: 'none'
            }),
            
          },
          Inner: {
            style: ({ $theme }) => ({
              transitionDuration: 'none'
            }),
          },
        }}
        content={({ close }) => (
          <AutoScroll style={{padding: '16px', borderRadius: '16px',  width: '264px', background: '#fff', maxHeight: '277px'}}>

              {LIST_LANGUAGE.map((language, index) => (
                <Block
                  onClick={() => {
                    setValueSelected(language)
                    i18n.changeLanguage(language.value)
                    setLocalStorage('language', language.value)
                    close()
                  }}
                  $style={{
                    height: '46px',
                    cursor: 'pointer',
                    padding: '8px',
                    borderRadius: '8px',
                    display: 'flex ',
                    flexDirection: 'column',
                    position: 'relative !important',
                    gap: '6px',
                    ':hover': {
                      background: '#F2F2F2',
                    },
                    ':active': {
                      background: lightTheme.colors.grayScale100,
                    },
                  }}
                  key={index}
                >
                  <p style={{ ...lightTheme.typography.Small14regular, margin: 0, lineHeight: '12px' }}>
                    {language.label}
                  </p>
                  <p style={{ ...lightTheme.typography.Small10regular, margin: 0, lineHeight: '12px' }}>
                    {language.desc}
                  </p>
                  {valueSelected.id === language.id && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '15px',
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icons.CheckIcon fill="#FF0560" />
                    </div>
                  )}
                </Block>
              ))}
          </AutoScroll>
        )}
        onOpen={() => setIsOpenMenu(true)}
        onClose={() => setIsOpenMenu(false)}
      >
        {isOpenMenu ? (
          <div>
            <ButtonCustom
              endEnhancer={<Icons.Drop />}
              type={SizeButton.STANDARD}
              kind={KIND.tertiary}
              style={{
                ...lightTheme.typography.Small14regular,
                fontWeight: 400,
                justifyContent: 'space-between',
                background: '#fff!important',
                borderColor: '#B2B2B2!important'
              }}
            >
              {valueSelected.label}
            </ButtonCustom>
          </div>
        ) : (
          <ButtonCustom
            endEnhancer={<Icons.Drop />}
            type={SizeButton.STANDARD}
            kind={KIND.tertiary}
            style={{
              ...lightTheme.typography.Small14regular,
              fontWeight: 400,
              justifyContent: 'space-between',
              ':hover': {
                background: '#fff!important',
                borderColor: '#B2B2B2!important'
              },
              ':active': {
                background: '#fff!important',
                borderColor: '#B2B2B2!important'
              }
            }}
          >
            {valueSelected.label}
          </ButtonCustom>
        )}
      </StatefulPopover>
      {/* <ButtonCustom
        type={SizeButton.STANDARD}
        kind={KIND.primary}
        style={{ padding: '0 12px', gap: '6px', marginTop: '16px' }}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          handleNavigation()
          i18n.changeLanguage(valueSelected.value)
          setLocalStorage('language', valueSelected.value)
        }}
      >
        <LabelLarge $style={{ ...lightTheme.typography.Small14Semibold, color: '#ffffff' }}>
          {t('Select Language')}
        </LabelLarge>
      </ButtonCustom> */}
    </>
  )
}
