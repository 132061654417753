import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'
import { ThemeProvider } from 'baseui'
import { KIND, SHAPE } from 'baseui/button'
import { StyleObject } from 'styletron-react'

const TooltipMenuItem = ({
  label,
  onClick,
  children,
  disabled = false,
  style,
}: {
  icon?: string
  label?: string
  onClick?: (e) => void
  disabled?: boolean
  children?: JSX.Element | JSX.Element[]
  style?: StyleObject
}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <ButtonCustom
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.4 : 1,
          ...style,
        }}
        shape={SHAPE.square}
        kind={KIND.minimal}
        type={SizeButton.SMALL}
        onClick={e => {
          onClick(e)
        }}
        tooltip={{
          content: label,
          placement: 'top',
        }}
      >
        {children}
      </ButtonCustom>
    </ThemeProvider>
  )
}

export default TooltipMenuItem
