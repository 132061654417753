import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Editor from '@scenes/Editor'
import Magic from './scenes/Editor/Magic'
import { Suspense, lazy } from 'react'
import Navbar from './scenes/Editor/components/Navbar'
import Dashboard from './scenes/Dashboard'
import ShowModal from './components/ShowModal'

const TemplateManagerView = lazy(() => import('./views/TemplateManagerView'))
const TemplateGeneratorView = lazy(() => import('./views/TemplateGeneratorView'))
const CategoryManagerView = lazy(() => import('./views/CategoryManagerView'))

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        <Switch>
          <Route path="/generate-templates" component={TemplateGeneratorView} />
          <Route path="/manage-templates" component={TemplateManagerView} />
          <Route path="/manage-categories" component={CategoryManagerView} />
          <Route path="/magic" component={Magic} />
          <Route path="/design/:id/edit" component={Editor} />
          <Route path="/create" component={Dashboard} />
          <Route path="/" component={Editor} />
        </Switch>
        <ShowModal />
      </Suspense>
    </Router>
  )
}

export default Routes
