export enum MediaImageType {
  original,   // 0
  fullRes,    // 1
  fitted,     // 2 trimmed, resized & flipped image
  latest,     // 3
  thumbnail,  // 4
  mask,       // 5
  fittedMask, // 6 trimmed, resized & flipped mask
  depthMask,  // 7
  alphaMatteMask,  // 8
  originalMask, // 9
  removeBgMask, //10
  bzrtBgMask, //11
  appleBgMask, //12
  localModelBgMask, //13
  mattedForeground, //14
}

