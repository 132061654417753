import Icons from '@/scenes/Editor/components/Icons'
import { translate } from '@utils/translationUtils';
const formatSizes = [
  {
    id: 1,
    name: 'Portrait',
    description: '2048 x 2732',
    size: {
      width: 2048,
      height: 2732,
    },
  },
  {
    id: 2,
    name: 'Square',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
  },
  {
    id: 3,
    name: 'Landscape',
    description: '2732 x 2048',
    size: {
      width: 2732,
      height: 2048,
    },
  },
  {
    id: 4,
    name: 'Facebook Post',
    description: '2048 x 1072',
    size: {
      width: 2048,
      height: 1072,
    },
  },
  {
    id: 5,
    name: 'Facebook Cover',
    description: '2050 x 780',
    size: {
      width: 2050,
      height: 780,
    },
  },
  {
    id: 6,
    name: 'Linkedin Post',
    description: '2400 x 2400',
    size: {
      width: 2400,
      height: 2400,
    },
  },
  {
    id: 7,
    name: translate('Twitter Post'),
    description: '2048 x 1024',
    size: {
      width: 2048,
      height: 1024,
    },
    icon: Icons.Twitter,
  },
  {
    id: 8,
    name: 'Twitter Header',
    description: '3000 x 1000',
    size: {
      width: 3000,
      height: 1000,
    },
    icon: Icons.Twitter,
  },
  {
    id: 9,
    name: 'Youtube Channel Cover',
    description: '2560 x 1440',
    size: {
      width: 2560,
      height: 1440,
    },
  },
  {
    id: 10,
    name: 'Youtube Thumbnail',
    description: '1280 x 720',
    size: {
      width: 1280,
      height: 720,
    },
  },
  {
    id: 11,
    name: 'Zoom Desktop',
    description: '1920 x 1080',
    size: {
      width: 1920,
      height: 1080,
    },
  },
  {
    id: 12,
    name: 'Presentation',
    description: '1920 x 1080',
    size: {
      width: 1920,
      height: 1080,
    },
  },
  {
    id: 13,
    name: 'Logo',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
  },
  {
    id: 14,
    name: 'Business Card',
    description: '3150 x 1800',
    size: {
      width: 3150,
      height: 1800,
    },
  },
  {
    id: 15,
    name: 'Amazon Header',
    description: '3000 x 600',
    size: {
      width: 3000,
      height: 600,
    },
  },
  {
    id: 16,
    name: 'Instagram story',
    description: '1080 x 1920',
    size: {
      width: 1080,
      height: 1920,
    },
  },
  {
    id: 17,
    name: 'Flyer',
    description: '2480 x 3508',
    size: {
      width: 2480,
      height: 3508,
    },
  },
  {
    id: 18,
    name: 'Postcard',
    description: '2048 x 3072',
    size: {
      width: 2048,
      height: 3072,
    },
  },
  {
    id: 19,
    name: 'Poster',
    description: '3300 x 5100',
    size: {
      width: 3300,
      height: 5100,
    },
  },
]

export default formatSizes

//
export const formatSizesBasic = [
  {
    id: 1,
    name: 'Portrait',
    description: '2048 x 2732',
    size: {
      width: 2048,
      height: 2732,
    },
  },
  {
    id: 2,
    name: 'Square',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
  },
  {
    id: 3,
    name: 'Landscape',
    description: '2732 x 2048',
    size: {
      width: 2732,
      height: 2048,
    },
  },
  {
    id: 4,
    name: '9:16',
    description: '1242 x 2688',
    size: {
      width: 1242,
      height: 2688,
    },
  },
]
export const formatSizesBasicForCreationPage = [
  {
    id: 1,
    name: 'Custom size',
    description: 'Custom size',
    size: {
      width: 1,
      height: 1,
    },
    analytic: 'bazaart.size.custom',
  },
  {
    id: 2,
    name: 'Portrait',
    description: '2048 x 2732',
    size: {
      width: 2048,
      height: 2732,
    },
    analytic: 'bazaart.size.portrait'
  },
  {
    id: 3,
    name: 'Square',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
    analytic: 'bazaart.size.square'
  },
  {
    id: 4,
    name: 'Landscape',
    description: '2732 x 2048',
    size: {
      width: 2732,
      height: 2048,
    },
    analytic: 'bazaart.size.landscape'
  },
  {
    id: 5,
    name: '9:16',
    description: '1242 x 2688',
    size: {
      width: 1242,
      height: 2688,
    },
    analytic: 'bazaart.size.9:16'
  },
  {
    id: 6,
    name: 'Letter',
    description: '2551 x 3295',
    size: {
      width: 2551,
      height: 3295,
    },
    analytic: 'bazaart.size.letter_portrait'
  },
]

export const formatSizesSocicalMedia = [
  {
    id: 1,
    name: 'Instagram story',
    description: '1080 x 1920',
    size: {
      width: 1080,
      height: 1920,
    },
    icon: Icons.InstagramIcon,
  },
  {
    id: 2,
    name: 'Instagram square',
    description: '1080 x 1080',
    size: {
      width: 1080,
      height: 1080,
    },
    icon: Icons.InstagramIcon,
  },
  {
    id: 3,
    name: 'Instagram portrait',
    description: '1080 x 1350',
    size: {
      width: 1080,
      height: 1350,
    },
    icon: Icons.InstagramIcon,
  },
  {
    id: 4,
    name: 'Instagram landscape',
    description: '1920 x 1006',
    size: {
      width: 1920,
      height: 1006,
    },
    icon: Icons.InstagramIcon,
  },
  {
    id: 5,
    name: 'Facebook landscape post',
    description: '2048 x 1072',
    size: {
      width: 2048,
      height: 1072,
    },
    icon: Icons.FacebookIcon,
  },
  {
    id: 6,
    name: 'Facebook square Post',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
    icon: Icons.FacebookIcon,
  },
  {
    id: 7,
    name: 'Facebook page cover',
    description: '2050 x 780',
    size: {
      width: 2050,
      height: 780,
    },
    icon: Icons.FacebookIcon,
  },
  {
    id: 8,
    name: 'Facebook group cover',
    description: '1640 x 856',
    size: {
      width: 1640,
      height: 856,
    },
    icon: Icons.FacebookIcon,
  },
  {
    id: 9,
    name: 'WhatsApp status',
    description: '1080 x 1920',
    size: {
      width: 1080,
      height: 1920,
    },
    icon: Icons.Whatsapp,
  },
  {
    id: 10,
    name: 'Snapchat story',
    description: '1080 x 1920',
    size: {
      width: 1080,
      height: 1920,
    },
    icon: Icons.Snapchat,
  },
  {
    id: 11,
    name: 'Snapchat geofilter',
    description: '1080 x 2340',
    size: {
      width: 1080,
      height: 2340,
    },
    icon: Icons.Snapchat,
  },
  {
    id: 12,
    name: 'TikTok profile',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
    icon: Icons.TiktokIcon,
  },
  {
    id: 13,
    name: 'Twitter post',
    description: '2048 x 1024',
    size: {
      width: 2048,
      height: 1024,
    },
    icon: Icons.Twitter,
  },
  {
    id: 14,
    name: 'Twitter header',
    description: '3000 x 1000',
    size: {
      width: 3000,
      height: 1000,
    },
    icon: Icons.Twitter,
  },
  {
    id: 15,
    name: 'Pinterest pin',
    description: '1000 x 1500',
    size: {
      width: 1000,
      height: 1500,
    },
    icon: Icons.Pinterest,
  },
  {
    id: 16,
    name: 'Youtube channel cover',
    description: '2560 x 1440',
    size: {
      width: 2560,
      height: 1440,
    },
    icon: Icons.YoutubeIcon,
  },
  {
    id: 17,
    name: 'Youtube thumbnail',
    description: '2048 x 1152',
    size: {
      width: 2048,
      height: 1152,
    },
    icon: Icons.YoutubeIcon,
  },
  {
    id: 18,
    name: 'Zoom mobile',
    description: '1080 x 1920',
    size: {
      width: 1080,
      height: 1920,
    },
    icon: Icons.ZoomIcon,
  },
  {
    id: 19,
    name: 'Zoom desktop',
    description: '1920 x 1080',
    size: {
      width: 1920,
      height: 1080,
    },
    icon: Icons.ZoomIcon,
  },
  {
    id: 20,
    name: 'Wattpad cover',
    description: '1024 x 1600',
    size: {
      width: 1024,
      height: 1600,
    },
    icon: Icons.WattpadIcon,
  },
  {
    id: 21,
    name: 'Linkedin post',
    description: '1080 x 1080',
    size: {
      width: 1080,
      height: 1080,
    },
    icon: Icons.Linkedin,
  },
  {
    id: 22,
    name: 'Linkedin page cover',
    description: '1128 x 191',
    size: {
      width: 1128,
      height: 191,
    },
    icon: Icons.Linkedin,
  },
  {
    id: 23,
    name: 'Linkedin profile background',
    description: '1584 x 396',
    size: {
      width: 1584,
      height: 396,
    },
    icon: Icons.Linkedin,
  },
  {
    id: 24,
    name: 'Lemon 8',
    description: '1080 x 1440',
    size: {
      width: 1080,
      height: 1440,
    },
    icon: Icons.Lemon,
  },
]

export const formatECommerce = [
  {
    id: 1,
    name: 'Amazon product',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
    icon: Icons.AmazonProduct,
  },
  {
    id: 2,
    name: 'Amazon header',
    description: '3000 x 600',
    size: {
      width: 3000,
      height: 600,
    },
    icon: Icons.AmazonHeader,
  },
  {
    id: 3,
    name: 'Ebay',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
    icon: Icons.EbayIcon,
  },
  {
    id: 4,
    name: 'Poshmark',
    description: '1080 x 1080',
    size: {
      width: 1080,
      height: 1080,
    },
    icon: Icons.Poshmask,
  },
  {
    id: 5,
    name: 'Shopify',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
    icon: Icons.ShopifyIcon,
  },
  {
    id: 6,
    name: 'Depop',
    description: '1280 x 1280',
    size: {
      width: 1280,
      height: 1280,
    },
    icon: Icons.DepopIcon,
  },
  {
    id: 7,
    name: 'Etsy cover',
    description: '3360 x 840',
    size: {
      width: 3360,
      height: 840,
    },
    icon: Icons.EstyIcon,
  },
  {
    id: 8,
    name: 'Etsy product',
    description: '2000 x 2000',
    size: {
      width: 2000,
      height: 2000,
    },
    icon: Icons.EstyIcon,
  },
]

export const formatBusiness = [
  {
    id: 1,
    name: 'Presentation',
    description: '1920 x 1080',
    size: {
      width: 1920,
      height: 1080,
    },
  },
  {
    id: 2,
    name: 'Logo',
    description: '2048 x 2048',
    size: {
      width: 2048,
      height: 2048,
    },
  },
  {
    id: 3,
    name: 'Business card',
    description: '3150 x 1800',
    size: {
      width: 3150,
      height: 1800,
    },
  },
  {
    id: 4,
    name: 'Flyer',
    description: '2550 x 3300',
    size: {
      width: 2550,
      height: 3300,
    },
  },
  {
    id: 5,
    name: 'Flyer',
    description: '3300 x 2550',
    size: {
      width: 3300,
      height: 2550,
    },
  },
]

export const formatPrint = [
  {
    id: 1,
    name: 'Letter',
    description: '2551 x 3295',
    size: {
      width: 2551,
      height: 3295,
    },
  },
  {
    id: 2,
    name: 'Letter',
    description: '3295 x 2551',
    size: {
      width: 3295,
      height: 2551,
    },
  },
  {
    id: 3,
    name: 'Half letter',
    description: '1654 x 2551',
    size: {
      width: 1654,
      height: 2551,
    },
  },
  {
    id: 4,
    name: 'Half letter',
    description: '2551 x 1654',
    size: {
      width: 2551,
      height: 1654,
    },
  },
  {
    id: 5,
    name: 'Postcard',
    description: '2048 x 3072',
    size: {
      width: 2048,
      height: 3072,
    },
  },
  {
    id: 6,
    name: 'Postcard',
    description: '3072 x 2048',
    size: {
      width: 3072,
      height: 2048,
    },
  },
  {
    id: 7,
    name: 'Poster',
    description: '3300 x 5100',
    size: {
      width: 3300,
      height: 5100,
    },
  },
  {
    id: 8,
    name: 'Poster',
    description: '5100 x 3300',
    size: {
      width: 5100,
      height: 3300,
    },
  },
  {
    id: 9,
    name: 'A5',
    description: '1748 x 2480',
    size: {
      width: 1748,
      height: 2480,
    },
  },
  {
    id: 10,
    name: 'A5',
    description: '2480 x 1748',
    size: {
      width: 2480,
      height: 1748,
    },
  },
  {
    id: 11,
    name: 'A4',
    description: '2480 x 3508',
    size: {
      width: 2480,
      height: 3508,
    },
  },
  {
    id: 12,
    name: 'A4',
    description: '3508 x 2480',
    size: {
      width: 3508,
      height: 2480,
    },
  },
  {
    id: 13,
    name: 'A3',
    description: '3508 x 4960',
    size: {
      width: 3508,
      height: 4960,
    },
  },
  {
    id: 14,
    name: 'A3',
    description: '4960 x 3508',
    size: {
      width: 4960,
      height: 3508,
    },
  },
]

export const formatStandard = [
  {
    id: 1,
    name: '3:2',
    description: '3072 x 2048',
    size: {
      width: 3072,
      height: 2048,
    },
  },
  {
    id: 2,
    name: '2:3',
    description: '2048 x 3072',
    size: {
      width: 2048,
      height: 3072,
    },
  },
  {
    id: 3,
    name: '4:3',
    description: '2731 x 2048',
    size: {
      width: 2731,
      height: 2048,
    },
  },
  {
    id: 4,
    name: '3:4',
    description: '2048 x 2731',
    size: {
      width: 2048,
      height: 2731,
    },
  },
  {
    id: 5,
    name: '16:9',
    description: '3641 x 2048',
    size: {
      width: 3641,
      height: 2048,
    },
  },
  {
    id: 6,
    name: '9:16',
    description: '2048 x 3641',
    size: {
      width: 2048,
      height: 3641,
    },
  },
]
