import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      fillRule="evenodd"
      d="M4.081 3.033c0-.368.33-.667.738-.667h6.137c.408 0 .738.299.738.667 0 .368-.33.667-.738.667H4.82c-.407 0-.738-.299-.738-.667ZM4.19 13.03c0-.368.332-.666.742-.666H11.1c.41 0 .742.298.742.666 0 .369-.333.667-.742.667H4.932c-.41 0-.742-.298-.742-.667ZM3.01 4.263c.37 0 .668.333.668.743v6.11c0 .41-.299.743-.667.743-.368 0-.667-.333-.667-.743v-6.11c0-.41.299-.743.667-.743ZM13.015 4.263c.369 0 .667.33.667.735v6.044c0 .405-.298.734-.667.734-.368 0-.666-.329-.666-.734V4.998c0-.406.298-.735.666-.735Z"
      clipRule="evenodd"
    />
    <rect
      width={3.333}
      height={3.333}
      x={1.334}
      y={1.333}
      stroke="#666"
      strokeWidth={1.333}
      rx={0.667}
    />
    <rect
      width={3.333}
      height={3.333}
      x={1.334}
      y={11.333}
      stroke="#666"
      strokeWidth={1.333}
      rx={0.667}
    />
    <rect
      width={3.333}
      height={3.333}
      x={11.334}
      y={1.333}
      stroke="#666"
      strokeWidth={1.333}
      rx={0.667}
    />
    <rect
      width={3.333}
      height={3.333}
      x={11.334}
      y={11.333}
      stroke="#666"
      strokeWidth={1.333}
      rx={0.667}
    />
  </svg>
)
export default SvgComponent
