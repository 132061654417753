import { AdjustmentFilter } from '@pixi/filter-adjustment'
import { IValueRangeFilter } from '@scenes/engine/utils/PixijsFilters/IValueRangeFilter'

export class ColorControlFilter implements IValueRangeFilter {

  pixijsFilter: AdjustmentFilter | null;
  contrast: number;
  brightness: number;
  saturation: number;


  minimumForKey(key: string): number {
    switch (key){
      case "contrast":
        return 0.75;
      case "brightness":
        return -0.25;
      case "saturation":
        return 0;
    }
  }

  maximumForKey(key: string): number {
    switch (key){
      case "contrast":
        return 1.25;
      case "brightness":
        return 0.25;
      case "saturation":
        return 2;
    }
  }

  defaultForKey(key: string): number {
    switch (key){
      case "contrast":
        return 1.0;
      case "brightness":
        return 0;
      case "saturation":
        return 1;
    }
  }

  buildFilter({brightness}, {contrast}, {saturation}) {
    if (this.isValid(contrast)) { this.contrast = contrast; }
    if (this.isValid(brightness)) { this.brightness = brightness; }
    if (this.isValid(saturation)) { this.saturation = saturation; }

    if (!this.pixijsFilter) {
      this.pixijsFilter = ColorControlFilter.generateFilter(this.contrast, this.brightness, this.saturation);
    }
    this.pixijsFilter.contrast = this.normalize("contrast", this.contrast);
    this.pixijsFilter.brightness = this.normalize("brightness", this.brightness);
    this.pixijsFilter.saturation = this.normalize("saturation", this.saturation);
  }

  isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

  private normalize(key: string, value: number): number {
    let minBzrtValue = this.minimumForKey(key);
    let maxBzrtValue = this.maximumForKey(key);
    let minFilterValue = 0.05;
    let maxFilterValue = 2;

    let normalizedBzrtValue = (value - minBzrtValue) / Math.abs(maxBzrtValue - minBzrtValue);
    let normalizedFilterValue = normalizedBzrtValue * (maxFilterValue - minFilterValue) + minFilterValue;
    return normalizedFilterValue;
  }

  static generateFilter(contrast: number, brightness: number, saturation: number): AdjustmentFilter {
    let filter = new AdjustmentFilter(
        {
          contrast: contrast,
          brightness: brightness,
          saturation: saturation
        }
    );
    return filter;
  }
}