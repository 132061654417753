import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '@services/api'
import { AxiosError } from 'axios'

export const setEffect = createAction<any[]>('effect/setEffect')

export const getEffect = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  'effect/getEffect',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const effect: any = await api.getFilters()
      dispatch(setEffect(effect))
    } catch (err) {
      return rejectWithValue(((err as AxiosError).response?.data as any)?.error.data || null)
    }
  }
)
