export const colors = [
  {
    name: 'Black',
    color: '#000000',
  },
  {
    name: 'White',
    color: '#ffffff',
  },
  {
    name: 'White',
    color: '#bfbfbf',
  },
  {
    name: 'White',
    color: '#ff0bad',
  },
  {
    name: 'White',
    color: '#bd4be5',
  },
  {
    name: 'White',
    color: '#a88358',
  },
  {
    name: 'White',
    color: '#ff0b18',
  },
  {
    name: 'White',
    color: '#ff8e00',
  },
  {
    name: 'White',
    color: '#ffc900',
  },
  {
    name: 'White',
    color: '#00d119',
  },
  {
    name: 'White',
    color: '#0097ff',
  },
  {
    name: '01 Orange Bold',
    color: '#e65923',
  },
  {
    name: '02 Yellow Bold',
    color: '#f8b31a',
  },
  {
    name: '03 Goldenrod Bold',
    color: '#87b23c',
  },
  {
    name: '04 Green Bold',
    color: '#009b48',
  },
  {
    name: '05 Persian Green Bold',
    color: '#019e79',
  },
  {
    name: '06 Iris Blue Bold',
    color: '#009dc6',
  },
  {
    name: '07 Pacific Blue Bold',
    color: '#016db5',
  },
  {
    name: '08 Cerulean Blue Bold',
    color: '#1b4398',
  },
  {
    name: '09 Rich Blue Bold',
    color: '#74368d',
  },
  {
    name: 'Color Picker',
    color: '#ca1c87',
  },
  {
    name: '10 Hopbush Bold',
    color: '#ca1c87',
  },

  {
    name: '11 Dark Pink Bold',
    color: '#ed2c7f',
  },
  {
    name: '12 Burnt Sienna Bold',
    color: '#e41e25',
  },
  {
    name: '01 Orange Normal',
    color: '#f78e57',
  },
  {
    name: '02 Yellow Normal',
    color: '#fbd350',
  },
  {
    name: '03 Goldenrod Normal',
    color: '#c8dc69',
  },
  {
    name: '04 Green Normal',
    color: '#55bb71',
  },
  {
    name: '05 Persian Green Normal',
    color: '#00b592',
  },
  {
    name: '06 Iris Blue Normal',
    color: '#01bad8',
  },
  {
    name: '07 Pacific Blue Normal',
    color: '#0198d7',
  },
  {
    name: '08 Cerulean Blue Normal',
    color: '#2860ad',
  },
  {
    name: '09 Rich Blue Normal',
    color: '#6459a7',
  },
  {
    name: '10 Hopbush Normal',
    color: '#c65da0',
  },
  {
    name: '11 Dark Pink Normal',
    color: '#ef5d93',
  },
  {
    name: '12 Burnt Sienna Normal',
    color: '#f05e57',
  },
  {
    name: 'Earth 01',
    color: '#EEDAC8',
  },
  {
    name: '14',
    color: '#CEB192',
  },
  {
    name: '15',
    color: '#E5C8A8',
  },
  {
    name: '16',
    color: '#B6A28C',
  },
  {
    name: '17',
    color: '#D2BDA6',
  },
  {
    name: '18',
    color: '#ACA48B',
  },
  {
    name: '19',
    color: '#B5AD92',
  },
  {
    name: '20',
    color: '#878B7F',
  },
  {
    name: '21',
    color: '#8D8D7C',
  },
  {
    name: '22',
    color: '#65635E',
  },
  {
    name: '23',
    color: '#8D7669',
  },
  {
    name: '24',
    color: '#8B6054',
  },
  {
    name: '25',
    color: '#FFCB73',
  },
  {
    name: '25',
    color: '#FFDE6F',
  },
  {
    name: '26',
    color: '#FEF67F',
  },
  {
    name: '27',
    color: '#DFF179',
  },
  {
    name: '28',
    color: '#C7FFB1',
  },
  {
    name: '29',
    color: '#93FFEB',
  },
  {
    name: '30',
    color: '#90EFFF',
  },
  {
    name: '31',
    color: '#78CDFF',
  },
  {
    name: '32',
    color: '#7FA8FF',
  },
  {
    name: '33',
    color: '#9185F5',
  },
  {
    name: '34',
    color: '#B474F2',
  },
  {
    name: '35',
    color: '#EA5F9B',
  },
  {
    name: '36',
    color: '#FD627E',
  },
  {
    name: '37',
    color: '#FAE1B7',
  },
  {
    name: '38',
    color: '#FEF9CA',
  },
  {
    name: '39',
    color: '#F1F4C8',
  },
  {
    name: '40',
    color: '#DFECCB',
  },
  {
    name: '41',
    color: '#CEE4CB',
  },
  {
    name: '42',
    color: '#BBDEDB',
  },
  {
    name: '43',
    color: '#BDE9F1',
  },
  {
    name: '44',
    color: '#BDE2F9',
  },
  {
    name: '45',
    color: '#C1DDF8',
  },
  {
    name: '46',
    color: '#C6CAE6',
  },
  {
    name: '47',
    color: '#DACDEB',
  },
  {
    name: '48',
    color: '#CCB4D4',
  },
  {
    name: '49',
    color: '#E0BDE5',
  },
  {
    name: '50',
    color: '#DFB3BD',
  },
  {
    name: '51',
    color: '#F2CCD5',
  },
  {
    name: '52',
    color: '#DFBCC5',
  },
  {
    name: '53',
    color: '#F1D8DE',
  },
  {
    name: '54',
    color: '#DEB5B5',
  },
  {
    name: '55',
    color: '#F7DAD7',
  },
  {
    name: '56',
    color: '#D8A899',
  },
  {
    name: '57',
    color: '#F2B395',
  },
  {
    name: '58',
    color: '#DD9674',
  },
  {
    name: '59',
    color: '#E7AA82',
  },
  {
    name: '60',
    color: '#D49369',
  },
  {
    name: '61',
    color: '#DFBEA7',
  },
]
