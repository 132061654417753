import { useEditorContext } from '@/scenes/engine'
import { PopoverType, panelListItems } from '@/constants/app-options'
import PanelListItem from '../../Panels/PanelListItem'
import useAppContext from '@/hooks/useAppContext'
import { ObjectType } from '@/scenes/engine/common/constants'
import Text from '../../Panels/PanelItems/Text'
import { lightTheme } from '@/customTheme'
function AddText() {
  const editor = useEditorContext().editor
  const { popoverActive, setPopoverActive } = useAppContext()

  const panelListItem = panelListItems.find(x => x.name === 'Text')

  const { activePanel, isReplacingImage, removingBg } = useAppContext()

  const addStaticText = () => {
    const options = {
      type: ObjectType.BAZAART_TEXT,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: 0.8,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      opacity: 1,
      textProperties: {
        attributedText: {
          runs: [
            {
              attributes: {
                NSColor: lightTheme.colors.blackGray,
                NSFont: { size: 0, systemName: 'Poppins-SemiBold' },
                NSParagraphStyle: { NSWritingDirection: 1, NSAlignment: 2 },
                NSKern: 0,
              },
            },
          ],
          string: 'Add your text',
        },
        arcAngle: 0,
        alignment: 2,

        imageToTextTransformation: {
          fontRelativeToWidth: 0.1,
          sizeRelativeToWidth: {
            width: 1,
            height: 0.2,
          },
          originRelativeToWidth: {
            x: 0.5,
            y: 0.5,
          },
        },
      },
      absoluteRotation: 0,
      effects: {},
      metadata: {
        text: 'Add your text',
        fontSize: 100,
        fontWeight: 900,
        fontFamily: 'Poppins-SemiBold',
        name: 'Aniston',
        originX: 'center',
      },
    }
    editor.handlers.objectsHandler.add(options)
  }

  return (
    <div>
      <div
        onClick={() => {
          setPopoverActive(popoverActive === PopoverType.TEXT ? null : PopoverType.TEXT)
        }}
        id="popover-text"
      >
        <PanelListItem
          disable={panelListItem.disable}
          label={panelListItem.name}
          name={panelListItem.name}
          key={panelListItem.name}
          icon={panelListItem.name}
          activePanel={activePanel}
          panelSelected={popoverActive === PopoverType.TEXT}
        />
      </div>
      <Text
        setIsOpen={() => setPopoverActive(null)}
        isOpen={popoverActive === PopoverType.TEXT}
      />
    </div>
  )
}

export default AddText
