import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 11.998C23 18.074 18.074 23 11.998 23 5.922 23 .996 18.074.996 11.998.996 5.922 5.922.996 11.998.996 18.074.996 23 5.922 23 11.998Zm-9.338 8.848a9.056 9.056 0 0 1-3 .055v-.884h3v.83Zm-3.343-.837h-2.43a9.313 9.313 0 0 1-.226-.12v-2.886h2.656v3.006Zm-2.991-3.325v-3.01H4.653v3.01h2.675Zm-2.999-3.01v-2.997H3.093a9.076 9.076 0 0 0 .06 2.997h1.176Zm2.999-3.333H4.653V7.343h2.675v2.998Zm2.991.336H7.663v2.997h2.656v-2.997ZM7.663 7.343h2.656V4.344H7.663v3Zm-.335-2.999v-.042l-.023.013-.047.03h.07Zm3.333 12.34h3v-3.01h3.006v-2.997h-3.005v2.997h-3v3.01Zm3 3.325v-3.006h3.006v2.693c-.183.11-.37.215-.56.313h-2.445Zm0-9.668V7.343h3.006V4.344h-3.005v3h-3v2.997h3Zm-3-7.246v.915h3v-.86a9.05 9.05 0 0 0-3-.055Zm6.334 13.59v-3.011h2.669v3.01h-2.669Zm2.669-6.344h-2.669V7.343h2.669v2.998Zm.328 3.333v-2.997h.911a9.082 9.082 0 0 1-.06 2.997h-.85Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
