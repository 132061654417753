import * as React from 'react'

const SvgComponent = props => (
  <svg width={34} height={76} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={33.5} y={0.5} width={75} height={33} rx={3.5} transform="rotate(90 33.5 .5)" fill="#F2F2F2" />
    <rect x={33.5} y={0.5} width={75} height={33} rx={3.5} transform="rotate(90 33.5 .5)" stroke="#E5E5E5" />
  </svg>
)

export default SvgComponent
