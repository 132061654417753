import { fabric } from 'fabric'
import { ObjectType, PROPERTIES_TO_EXPORT } from '../common/constants'
import { loadImageFromURL } from './image-loader'
import isNaN from 'lodash/isNaN'
import './fabricjs-custom-filters/filter-flexblur'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { MediaImageRepositoryProcessing } from '@scenes/engine/objects/media-repository/media_image_repository_processing'
import { nanoid } from 'nanoid'
import * as PIXI from 'pixi.js'
import { isColorBright } from './utils'
import { lightTheme } from '@/customTheme'

const textAligns = ['left', 'right', 'center']
const blendMap = {
  hardLightBlendMode: 'hard-light',
  softLightBlendMode: 'soft-light',

  '': 'normal',
  multiplyBlendMode: 'multiply',
  screenBlendMode: 'screen',
  overlayBlendMode: 'overlay',
  darkenBlendMode: 'darken',
  lightenBlendMode: 'lighten',
  colorDodgeBlendMode: 'color-dodge',
  colorBurnBlendMode: 'color-burn',
  differenceBlendMode: 'difference',
  exclusionBlendMode: 'exclusion',
}
class ObjectToFabric {
  async cloneImage(element: fabric.StaticImage) {
    return new Promise(function (resovle, reject) {
      element.clone(cloned => {
        resovle(cloned)
      })
    })
  }

  async run(item, options) {
    let object
    item.type = item.type ? item.type : item.itemType
    switch (item.type) {
      case ObjectType.STATIC_VECTOR:
        object = await this[ObjectType.STATIC_VECTOR](item, options)
        break
      case ObjectType.STATIC_PATH:
        object = await this[ObjectType.STATIC_PATH](item, options)
        break
      case ObjectType.DYNAMIC_TEXT:
        object = await this[ObjectType.DYNAMIC_TEXT](item, options)
        break
      case ObjectType.DYNAMIC_IMAGE:
        object = await this[ObjectType.DYNAMIC_IMAGE](item, options)
        break
      case ObjectType.BAZAART_STICKER:
      case ObjectType.BAZAART_IMAGE:
      case ObjectType.BAZAART_SHAP:
      case ObjectType.BAZAART_DRAWING:
      case ObjectType.STATIC_IMAGE:
        object = await this[ObjectType.BAZAART_STICKER](item, options)
        break
      case ObjectType.BAZAART_TEXT:
      case ObjectType.STATIC_TEXT:
        object = await this[ObjectType.BAZAART_TEXT](item, options)
        break
      case ObjectType.BAZAART_BG:
      case ObjectType.BACKGROUND_IMAGE:
        object = await this[ObjectType.BAZAART_BG](item, options)
        break
      case ObjectType.BAZAART_OVERLAY:
        object = await this[ObjectType.BAZAART_OVERLAY](item, options)
        break
      case ObjectType.GROUP:
        object = await this[ObjectType.GROUP](item, options)
        break
    }
    return object
  }

  [ObjectType.STATIC_TEXT](item, options) {
    return new Promise((resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const metadata = item.metadata
        const { textAlign, fontFamily, fontSize, fontWeight, charSpacing, lineheight, text, arcAngle } =
          metadata
        const textOptions = {
          ...baseOptions,
          text: text ? text : 'Default Text',
          arcAngle: arcAngle ? arcAngle : 0,
          ...(textAlign && { textAlign }),
          ...(fontFamily && { fontFamily }),
          ...(fontSize && { fontSize }),
          ...(fontWeight && { fontWeight }),
          ...(charSpacing && { charSpacing }),
          ...(lineheight && { lineheight }),
        }
        const element = new fabric.StaticText(textOptions)

        const { top, left, width, height } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top + options.height / 2 - height / 2,
            left: options.left + options.width / 2 - width / 2,
          })
        }

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.DYNAMIC_TEXT](item, options) {
    return new Promise((resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const metadata = item.metadata
        const text = metadata.template ? metadata.template : 'Default text'
        const { textAlign, fontFamily, fontSize, fontWeight, charSpacing, lineheight, keyValues } = metadata

        const textOptions = {
          ...baseOptions,
          keyValues: keyValues ? keyValues : [],
          ...(text && { text }),
          ...(textAlign && { textAlign }),
          ...(fontFamily && { fontFamily }),
          ...(fontSize && { fontSize }),
          ...(fontWeight && { fontWeight }),
          ...(charSpacing && { charSpacing }),
          ...(lineheight && { lineheight }),
        }
        const element = new fabric.DynamicText(textOptions)

        const { top, left, width, height } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top + options.height / 2 - height / 2,
            left: options.left + options.width / 2 - width / 2,
          })
        }

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.DYNAMIC_IMAGE](item, options) {
    return new Promise((resolve, reject) => {
      try {
        const { metadata } = item
        const baseOptions = this.getBaseOptions(item, options)
        const { keyValues } = metadata
        // @ts-ignore
        const element = new fabric.DynamicImage({
          ...baseOptions,
          keys: item.keys,
          keyValues: keyValues ? keyValues : [],
        })
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.STATIC_IMAGE](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const src = item.metadata.src
        const image: any = await loadImageFromURL(src)

        const { width, height } = baseOptions
        if (!width || !height) {
          baseOptions.width = image.width
          baseOptions.height = image.height
        }

        const element = new fabric.StaticImage(image, {
          ...baseOptions,
          cropX: item.metadata.cropX || 0,
          cropY: item.metadata.cropY || 0,
        })

        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_SHAP](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let object = (await this[ObjectType.BAZAART_STICKER](item, options)) as fabric.StaticShap
        // object = object as fabric.StaticImage
        // debugger;
        // object.type = fabric.StaticShap.type
        resolve(object)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_STICKER](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let img: any = null
        const optionsAPI = this.getOptionsAPI(item,options)
        let latestImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.latest
        )

        let thumbnailImageUrl = item.loadThumbnail === false ? null : await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.thumbnail
        )

        img = await loadImageFromURL(thumbnailImageUrl ? thumbnailImageUrl : latestImageUrl)

        let imageWidth = item.sizeOnCanvas.width * options.width
        let imageHeight = (imageWidth / img.width) * img.height

        let imageScaleX = item.scaleX ? item.scaleX : imageWidth / img.width
        let imageScaleY = item.scaleY ? item.scaleY : imageHeight / img.height

        let unnormalizeBoundingBox = new Rectangle(0, 0, 0, 0)
        let mediaImageRepositoryProcessing = new MediaImageRepositoryProcessing()
        let boundingBox = new Rectangle(
          item.boundingBox.x,
          item.boundingBox.y,
          item.boundingBox.width,
          item.boundingBox.height
        )

        // // wrong bounding box saved we need to calculate it
        // if (
        //   boundingBox.x === 0 &&
        //   boundingBox.y === 0 &&
        //   boundingBox.width === 1 &&
        //   boundingBox.height === 1
        // ) {
        //   unnormalizeBoundingBox = await mediaImageRepositoryProcessing.findBoundingBox(img, true)
        // } else {
        //   let imageSize = new Size(img.width, img.height)
        //   unnormalizeBoundingBox = await mediaImageRepositoryProcessing.unnormalizeBoundingBox(
        //     boundingBox,
        //     imageSize
        //   )
        // }

        const topOpt = item.top ? item.top : options.top + options.height * item.centerPoint.y
        const leftOpt = item.left ? item.left : options.left + options.width * item.centerPoint.x
// POC template id - 3176
        // if(item.bazaartGuid == "DB4688A4-27BC-43D6-BF14-4D17DE6791A0"){
        //   // img = await loadVectorFromURL(replaceOverlay)
          
        //   fabric.loadSVGFromURL(replaceOverlay, (objects, opts) => {
        //     const baseOptions = {
        //       top: topOpt,
        //       left: leftOpt,
        //       width: img.width,
        //       height: img.height,
        //       // padding: -((paddig)/4),
        //       scaleX: imageScaleX,
        //       scaleY: imageScaleY,
        //       originX: 'center',
        //       originY: 'center',
        //     }
        //     const object = new fabric.StaticVector(objects, opts, { ...baseOptions, replaceOverlay })
        //     const { top, left } = object
        //     if (isNaN(top) || isNaN(left)) {
        //       object.set({
        //         top: options.top,
        //         left: options.left,
        //       })
        //       object.scaleToWidth(320)
        //     }
        //     resolve(object)
        //   })

        //   return;
        // }
//END POC
        
        const element = new fabric.StaticImage(img, {
          ...optionsAPI,
          top: topOpt,
          left: leftOpt,
          width: img.width,
          height: img.height,
          // padding: -((paddig)/4),
          scaleX: imageScaleX,
          scaleY: imageScaleY,
          originX: 'center',
          originY: 'center',
          cropX: 0,
          cropY: 0,
          boundingBox: boundingBox,
          sizeOnCanvas: item.sizeOnCanvas,
          latest: item.latest,
          flipX: item.flipX !== null && item.flipX !== undefined ? item.flipX : item.transformation.horizontalFlip,
          flipY: item.flipY !== null && item.flipY !== undefined ? item.flipY : item.transformation.verticalFlip,
          angle: (item.angle || item.angle === 0) ? item.angle : fabric.util.radiansToDegrees(item.absoluteRotation),
          mask: item.mask,
          effects_from_template: item.effects ?? {},
          effects: {},
          id: item.bazaartGuid,
          type: item.type,
          hasTransparency: item.hasTransparency !== undefined ? item.hasTransparency : true,
          opacity: item.opacity ? item.opacity : 1,
          filters: item.filters ? item.filters : [],
          zoomX: item.zoomX ? item.zoomX : undefined,
          zoomY: item.zoomY ? item.zoomY : undefined,
          globalCompositeOperation: item.globalCompositeOperation ? item.globalCompositeOperation : undefined,
          selected: false,
          evented: true,
          useBzrtBgMask:item.useBzrtBgMask,
          layerAssetStateId: item.layerAssetStateId,
          transformation:{
            horizontalFlip:item.transformation.horizontalFlip,
            verticalFlip:item.transformation.verticalFlip,
            kDistort:item?.transformation?.kDistort,
          },
          fullImgUrl:item?.fullImgUrl,
          hasPeople:item?.hasPeople,
          shouldUseMattedImage:item?.shouldUseMattedImage,
          sourceGraphicsItemName:item?.sourceGraphicsItemName,
          sourceGraphicsPackName:item?.sourceGraphicsPackName,
          sourceGraphicsType:item?.sourceGraphicsType,
          features:item?.features,
          videoSpeed:item?.videoSpeed,
          saliencyResults:item?.saliencyResults,
          isTemplateLayer: item.isTemplateLayer,
          filter:item.filter,
          filterIntensity:item.filterIntensity ? item.filterIntensity : 1,
          isStockImage: item.isStockImage,
          isMagicBackgroundLayer: item?.isMagicBackgroundLayer,
          isIntializedNormalizeMask: item.isIntializedNormalizeMask ? item?.isIntializedNormalizeMask : true,
          isIntializedNormalizedImage: item.isIntializedNormalizedImage ? item?.isIntializedNormalizedImage : true,
          // direction: 1, shadow: { color: 'blue', offsetX:20,offsetY:20,blur:30 }
        })

     
        // const element = await loadImageToStaticWithPadding (item,options) as fabric.StaticImage
        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }

        if (item.blending) {
          element.globalCompositeOperation = blendMap[item.blending]
        }

        if (thumbnailImageUrl) {
          loadImageFromURL(latestImageUrl).then(async (img: any) => {
            console.log('latest Loaded')
            //@ts-ignore
            element.setElement(img)

            let imageWidth = item.sizeOnCanvas.width * options.width
            let imageHeight = (imageWidth / img.width) * img.height
            // let imageScaleX = item.scaleX ? item.scaleX : imageWidth / img.width
            // let imageScaleY = item.scaleY ? item.scaleY : imageHeight / img.height

            // element.scaleX = imageScaleX
            // element.scaleY = imageScaleY
            
            element.set({
              width: img.width,
              height: img.height,
              scaleX: imageWidth / img.width,
              scaleY: imageHeight / img.height,
              // @ts-ignore
              _originalScaleX: imageWidth / img.width,
              _originalScaleY: imageHeight / img.height,
            })
            element.setCoords()
          })
        }

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_TEXT](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let actual_w =
          item.textProperties.imageToTextTransformation.sizeRelativeToWidth.width *
          item.sizeOnCanvas.width *
          options.width
        let actual_h =
          item.textProperties.imageToTextTransformation.sizeRelativeToWidth.height *
          item.sizeOnCanvas.height *
          options.height

        let renderSizeOnCanvas_w = item.sizeOnCanvas.width * options.width

        const topOpt = item.top ? item.top : options.top + options.height * item.centerPoint.y
        const leftOpt = item.left ? item.left : options.left + options.width * item.centerPoint.x
        const optionsAPI = this.getOptionsAPI(item,options)

        let baseOptions: any = {
          ...optionsAPI,
          angle: (item.angle || item.angle === 0) ? item.angle : fabric.util.radiansToDegrees(item.absoluteRotation),
          top: topOpt,
          left: leftOpt,
          width: item.arcAngle ? actual_w : item.width ? item.width : actual_w, //Math.ceil(renderSizeOnCanvas_w) + 20,
          height: item.arcAngle ? actual_h : item.height ? item.height : actual_h,
          originX: 'center',
          originY: 'center',
          scaleX: item.scaleX ? item.scaleX : 1,
          scaleY: item.scaleY ? item.scaleY : 1,
          fill: lightTheme.colors.blackGray,
          metadata: {},
          flipX: item.flipX ? item.flipX : item.transformation.horizontalFlip,
          flipY: item.flipY ? item.flipY : item.transformation.verticalFlip,
          transformation:item.transformation,
          opacity: item.opacity ? item.opacity : 1,
          type: item.type,
          id: item.id ? item.id : nanoid(),
          bazaartGuid: item.bazaartGuid,
          selected: false,
          features:item?.features,
          paintFirst: 'stroke',
          strokeLineJoin: 'round',
          strokeLineCap: 'round'
        }

        const textAttr = item.textProperties.attributedText.runs[0].attributes
        let calcFontSize =
          renderSizeOnCanvas_w * item.textProperties.imageToTextTransformation.fontRelativeToWidth
        const textOptions = {
          ...baseOptions,
          text: item.text
            ? item.text
            : item.textProperties.attributedText.string
            ? item.textProperties.attributedText.string
            : 'Default Text',
          textProperties: item.textProperties,
          fontFamily: item.fontFamily ? item.fontFamily : textAttr.NSFont.systemName,
          fontSize: item.fontSize ? item.fontSize : calcFontSize,
          fill: item.fill ? item.fill : textAttr.NSColor,
          textAlign: item.textAlign
            ? item.textAlign
            : textAligns[
                textAttr.NSParagraphStyle.NSAlignment
                  ? textAttr.NSParagraphStyle.NSAlignment
                  : item.textProperties.alignment
              ],
          // lineHeight : 1,
          lineHeight: item.lineHeight ? item.lineHeight : 1 / fabric.Text.prototype._fontSizeMult,
          charSpacing: item.charSpacing
            ? item.charSpacing
            : textAttr.NSKern
            ? (textAttr.NSKern / textAttr.NSFont.size) * 1000
            : 0,
          sizeOnCanvas: item.sizeOnCanvas,
          transformation: item.transformation,
          effects: item.effects,
        }

        if(item.backgroundColor){
          textOptions.backgroundColor = item.backgroundColor
        }else if(item.textProperties.presentBackground){
          textOptions.backgroundColor = item.textProperties.textColorHex 
        }

        if(textOptions.backgroundColor){
          textOptions.fill = isColorBright(textOptions.backgroundColor) ? lightTheme.colors.blackGray : '#ffffff'
        }

        if (item.effects.outline) {
          textOptions.stroke = item.stroke ? item.stroke : item.effects.outline.color
          textOptions.strokeWidth = item.strokeWidth
            ? item.strokeWidth
            : item.effects.outline.thickness * renderSizeOnCanvas_w
        }

        if (item.effects.kOverlayColorObject) {
          textOptions.fill = item.fill ? item.fill : item.effects.kOverlayColorObject.color
        }

        textOptions.arcAngle = item.arcAngle ? item.arcAngle : item.textProperties.arcAngle

        if (item.effects.shadow) {
          let shadowProps = item.effects.shadow
          const colorNumber = new PIXI.Color(shadowProps.color).toUint8RgbArray()
          textOptions.shadow = item.shadow
          ? item.shadow
          : {
              color: `rgba(${colorNumber[0]}, ${colorNumber[1]}, ${colorNumber[2]}, ${shadowProps.opacity})`,
              offsetX: Math.cos(shadowProps.angle) * shadowProps.distance * actual_w,
              offsetY: Math.sin(shadowProps.angle) * shadowProps.distance * actual_w,
              blur: Math.floor(shadowProps.blur * actual_w),
            }
        }

        const element = new fabric.StaticText(textOptions)

        const { top, left, width, height } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top + options.height / 2 - height / 2,
            left: options.left + options.width / 2 - width / 2,
          })
        }

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_BG](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let baseOptions: any
        let image: any = null


        let latestImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.latest
        )
        let thumbnailImageUrl = item.loadThumbnail === false ? null : await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.thumbnail
        )

        let imageWidth = options.width / item.boundingBox.width
        let imageHeight = options.height / item.boundingBox.height

        if (item.version > 32) {
          image = await loadImageFromURL(thumbnailImageUrl ? thumbnailImageUrl : latestImageUrl)
        }

        const topOpt = item.top ? item.top : options.top + options.height * item.centerPoint.y
        const leftOpt = item.left ? item.left : options.left + options.width * item.centerPoint.x
        const optionsAPI = this.getOptionsAPI(item,options)

        baseOptions = {
          ...optionsAPI,
          angle: (item.angle || item.angle === 0) ? item.angle : 0,
          top: topOpt,
          left: leftOpt,
          width: item.version > 32 ? image.width : options.width,
          height: item.version > 32 ? image.height : options.height,
          originX: 'center',
          originY: 'center',
          scaleX: item.scaleX ? item.scaleX : (item.version > 32 ? imageWidth / image.width : 1),
          scaleY: item.scaleY ? item.scaleY : (item.version > 32 ? imageHeight / image.height : 1),
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          effects: item.effects ?? {},
          filters: item.filters ? item.filters : [],
          metadata: {},
          selectable: false,
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          strokeWidth: 0,
          padding: 0,
          evented: false,
          type: ObjectType.BAZAART_BG,
          id: item.bazaartGuid,
          backgrondIdentifier:item?.backgrondIdentifier,
          layerAssetStateId: item.layerAssetStateId,
          isAnimated:item?.isAnimated,
          isBackgroundImage: item?.isBackgroundImage,
          startTime:item?.startTime,
          _endTime: item?._endTime,
          shouldUseMattedImage: item?.shouldUseMattedImage,
          version: item.version,
        }

        // const { width, height } = baseOptions
        // if (!width || !height) {
        //   baseOptions.width = image.width
        //   baseOptions.height = image.height
        // }

        const element = new fabric.StaticImage(image, {
          ...baseOptions,
          cropX: 0,
          cropY: 0,
          boundingBox: item.boundingBox
        })  

        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }

        if (!thumbnailImageUrl) {
          resolve(element)
          return
        }

        loadImageFromURL(latestImageUrl).then((img: any) => {
          // console.log('latest Loaded')
          //@ts-ignore
          element.setElement(img)
          element.set({
            width: img.width,
            height: img.height,
            scaleX: imageWidth / img.width,
            scaleY: imageHeight / img.height,
            // @ts-ignore
            _originalScaleX: imageWidth / img.width,
            _originalScaleY:imageHeight / img.height,
          })
        })

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_OVERLAY](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let baseOptions: any
        let image: any = null
        let latestImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.latest
        )
        let thumbnailImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.thumbnail
        )
        let imageWidth = options.width / item.boundingBox.width
        let imageHeight = options.height / item.boundingBox.height
        if (item.version <= 32) {
          resolve(null)
          return
        }

        image = await loadImageFromURL(thumbnailImageUrl ? thumbnailImageUrl : latestImageUrl)

        const optionsAPI = this.getOptionsAPI(item,options)

        baseOptions = {
          ...optionsAPI,
          angle: (item.angle || item.angle === 0) ? item.angle : 0,
          top: item.top ? item.top : options.top + options.height * item.centerPoint.y,
          left: item.left ? item.left : options.left + options.width * item.centerPoint.x,
          width: image.width,
          height: image.height,
          originX: 'center',
          originY: 'center',
          scaleX: imageWidth / image.width,
          scaleY: imageHeight / image.height,
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          metadata: {},
          selectable: false,
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          strokeWidth: 0,
          padding: 0,
          evented: false,
          type: ObjectType.BAZAART_OVERLAY,
          id: item.bazaartGuid,
          layerAssetStateId: item.layerAssetStateId,
          blending:item?.blending,
          isAnimated:item?.isAnimated,
          overlayType:item?.overlayType,
          startTime:item?.startTime,
          _endTime:item?._endTime,
        }

        const element = new fabric.StaticImage(image, {
          ...baseOptions,
          boundingBox: item.boundingBox,
          blending: item.blending,
          version: item.version,
          globalCompositeOperation: item.blending ? blendMap[item.blending] : blendMap.screenBlendMode,
          cropX: 0,
          cropY: 0,
        })

        const { top, left } = element
        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }
        if (thumbnailImageUrl) {
          loadImageFromURL(latestImageUrl).then((img: any) => {
            console.log('latest Loaded')
            element.setElement(img)
            element.set({
              width: img.width,
              height: img.height,
              scaleX: imageWidth / img.width,
              scaleY: imageHeight / img.height,
              // @ts-ignore
              _originalScaleX: imageWidth / img.width,
              _originalScaleY: imageHeight / img.height,
            })
          })
        }
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.STATIC_PATH](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const path = item.metadata.value
        const fill = item.metadata.fill
        const element = new fabric.StaticPath({ ...baseOptions, path, fill: fill ? fill : lightTheme.colors.blackGray })

        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(320)
        }
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.STATIC_VECTOR](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const src = item.metadata.src
        fabric.loadSVGFromURL(src, (objects, opts) => {
          const { width, height, top, left } = baseOptions
          if (!width || !height) {
            baseOptions.width = opts.width
            baseOptions.height = opts.height
            baseOptions.top = options.top
            baseOptions.left = options.left
          }
          const object = new fabric.StaticVector(objects, opts, { ...baseOptions, src })
          if (isNaN(top) || isNaN(left)) {
            object.set({
              top: options.top,
              left: options.left,
            })
            object.scaleToWidth(320)
          }
          resolve(object)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.GROUP](item, options){
    return new Promise(async (resolve, reject) => {
      try {
      
        const baseOptions = (({ left, top, width, height, scaleX, scaleY,angle, opacity, flipX, flipY, skewX, skewY}) =>
         ({ left, top, width, height, scaleX, scaleY,angle, opacity, flipX, flipY, skewX, skewY}))(item);

        let objects: fabric.Object[] = []
        for (const object of item.objects) {
          object.loadThumbnail = item.loadThumbnail
          objects = objects.concat(await this.run(object, options))
        }
        // @ts-ignore
        const element = new fabric.Group(objects, baseOptions)

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  getBaseOptions(item, options) {
    const { left, top, width, height, scaleX, scaleY } = item
    let metadata = item.metadata ? item.metadata : {}
    const { fill, angle, originX, originY } = metadata
    let baseOptions = {
      angle: angle ? angle : 0,
      top: options.top + top,
      left: options.left + left,
      width: width,
      height: height,
      originX: originX || 'center',
      originY: originY || 'center',
      scaleX: scaleX || 1,
      scaleY: scaleY || 1,
      fill: fill || lightTheme.colors.blackGray,
      metadata: metadata,
    }

    return baseOptions
  }

  getOptionsAPI(item, options){
    let optionsAPI={}
    for (let prop of PROPERTIES_TO_EXPORT) {
        optionsAPI[prop] = item[prop];
    }
    return optionsAPI
  }
}

export default new ObjectToFabric()
