import * as React from 'react'

const SvgComponent = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.471 2.38495C13.0038 2.12842 12.5167 2 12.0104 2C11.4972 2 11.005 2.12838 10.5344 2.38488C10.0624 2.64209 9.68197 3.02447 9.39359 3.53111L1.40156 17.4764L1.29506 17.6802C1.09842 18.091 1 18.5202 1 18.9675C1 19.5233 1.12325 20.0306 1.36974 20.4886C1.61637 20.9468 1.96704 21.3135 2.42098 21.5881C2.8752 21.8628 3.41137 22 4.02865 22H19.9817L20.207 21.9936C20.7241 21.9636 21.1816 21.8285 21.579 21.5881C22.033 21.3135 22.3836 20.9468 22.6303 20.4886C22.8767 20.0306 23 19.5233 23 18.9675C23 18.7135 22.9665 18.4596 22.8996 18.2059C22.8327 17.9522 22.7324 17.7057 22.5987 17.4665L14.6063 3.53096L14.4772 3.32151C14.208 2.91784 13.8725 2.60542 13.471 2.38495ZM11.4971 4.30135C11.66 4.2215 11.8311 4.18164 12.0106 4.18164C12.183 4.18164 12.3468 4.22137 12.5026 4.30099C12.6564 4.37953 12.7825 4.50404 12.8814 4.67563L20.6866 18.3508L20.7471 18.4719C20.799 18.5953 20.8248 18.7257 20.8248 18.8638C20.8248 19.1411 20.7294 19.3862 20.5373 19.6016C20.3463 19.8157 20.0966 19.9226 19.7848 19.9226H4.20534L4.07842 19.916C3.83193 19.8899 3.62941 19.7853 3.46867 19.6019C3.27982 19.3865 3.18604 19.1412 3.18604 18.8638C3.18604 18.7842 3.19438 18.6974 3.21113 18.6035C3.22768 18.5108 3.25589 18.4178 3.29581 18.3246L11.1087 4.66508L11.1882 4.55026C11.2736 4.44341 11.3765 4.36043 11.4971 4.30135Z"
      fill="#666666"
    />
    <mask
      id="mask0_1718_15053"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="3"
      width="20"
      height="18"
    >
      <path
        d="M12.0104 3.97266C12.2098 3.97266 12.3998 4.01644 12.5799 4.10384C12.7258 4.17463 12.8504 4.27837 12.9537 4.41457L13.0271 4.5228L21.3265 18.337C21.4346 18.5131 21.4889 18.7045 21.4889 18.9101C21.4889 19.2175 21.3769 19.4908 21.1545 19.7278C20.9627 19.932 20.7196 20.0488 20.4273 20.0781L20.2771 20.0854H3.71072C3.35783 20.0854 3.06955 19.9658 2.84956 19.7274C2.63097 19.4905 2.521 19.2173 2.521 18.9101C2.521 18.8228 2.53052 18.7286 2.5495 18.6276C2.56229 18.5595 2.58102 18.4916 2.60653 18.422L2.64998 18.3164L10.9613 4.51131C11.0771 4.32799 11.2301 4.19173 11.4197 4.10348C11.607 4.0163 11.8041 3.97266 12.0104 3.97266Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1718_15053)">
      <path d="M0.0688715 8.04L13.997 32.1642" stroke="#666666" strokeWidth="2" strokeLinecap="square" />
      <path d="M3.01321 6.33981L16.9413 30.464" stroke="#666666" strokeWidth="2" strokeLinecap="square" />
      <path d="M5.95803 4.6401L19.8862 28.7643" stroke="#666666" strokeWidth="2" strokeLinecap="square" />
      <path d="M8.90237 2.93991L22.8305 27.0641" stroke="#666666" strokeWidth="2" strokeLinecap="square" />
    </g>
  </svg>
)

export default SvgComponent
