import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      d="M6.908 17.832c1.794 0 3.169-.8 3.856-2.258h.07v1.192c0 .66.407 1.066.995 1.066.603 0 1.024-.42 1.024-1.08V6.685c0-.66-.42-1.094-1.024-1.094-.588 0-.995.435-.995 1.094v1.163h-.07c-.701-1.416-2.118-2.243-3.856-2.243C3.963 5.605 2 8.059 2 11.718c0 3.674 1.963 6.114 4.908 6.114Zm.533-1.837c-2.006 0-3.254-1.64-3.254-4.277 0-2.636 1.248-4.29 3.254-4.29 2.019 0 3.323 1.696 3.323 4.304 0 2.609-1.29 4.263-3.323 4.263Z"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M14.727 3.773a.91.91 0 0 1 .909-.91h5.454a.91.91 0 1 1 0 1.819h-5.454a.91.91 0 0 1-.91-.91ZM14.727 19.227a.91.91 0 0 1 .909-.909h5.454a.91.91 0 1 1 0 1.819h-5.454a.91.91 0 0 1-.91-.91Z"
      clipRule="evenodd"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M18.362 19.682c-.502 0-.909-.321-.909-.718V4.037c0-.397.407-.719.91-.719.501 0 .908.322.908.719v14.927c0 .397-.407.718-.909.718Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
