import { PROPERTIES_TO_INCLUDE } from '../../common/constants'
import { RemoveHandlerOptions, RootRemoveHandlerOptions } from '../../common/interfaces'
import { RemoveLogicHandler } from '../../objects/RemoveLogicHandler'
import RemoveCanvasHandler from './RemoveCanvasHandler'
import RemoveEventsHandler from './RemoveEventsHandler'
import EraserObjectHandler from './EraserObjectHandler'
import RemoveTransactionHandler from './RemoveTransactionHandler'
import RemoveZoomHandler from './RemoveZoomHandler'
import { PixelManipulationObjectHandler } from './PixelManipulationObjectHandler'
import RemoveObjectHandler from './RemoveObjectHandler'
import EnhanceObjectHandler from './EnhanceObjectHandler'
import MagicBgObjectHandler from './MagicBgObjectHandler'


class RemoveHandlers {
  public eventsRemoveHandler: RemoveEventsHandler
  public canvasRemoveHandler: RemoveCanvasHandler
  public pixelManipulationObjectHandler: PixelManipulationObjectHandler
  public objectEraseHandler: EraserObjectHandler

  public transactionRemoveHandler: RemoveTransactionHandler
  public zoomRemoveHandler: RemoveZoomHandler
  public removeLogicHandler: RemoveLogicHandler
  public propertiesToInclude: string[]
  private handlerOptions: RemoveHandlerOptions

  constructor(props: RootRemoveHandlerOptions) {
    this.propertiesToInclude = PROPERTIES_TO_INCLUDE
    this.handlerOptions = {
      root: this,
      mainContext: props.mainContext,
      canvas: props.canvas,
      context: props.context,
      editorEventManager: props.editorEventManager,
    }
    this.canvasRemoveHandler = new RemoveCanvasHandler(this.handlerOptions)
    // this.pixelManipulationObjectHandler = new PixelManipulationObjectHandler(this.handlerOptions)
    // this.objectEraseHandler = new EraserObjectHandler(handlerOptions)
    this.transactionRemoveHandler = new RemoveTransactionHandler(this.handlerOptions)
    this.zoomRemoveHandler = new RemoveZoomHandler(this.handlerOptions)
    this.eventsRemoveHandler = new RemoveEventsHandler(this.handlerOptions)
    this.removeLogicHandler = RemoveLogicHandler.getInstance(this.handlerOptions);
  }

  destroy = () => { }


  public setPixelToolType(toolType){
    if (toolType === 'Remove') {
      this.pixelManipulationObjectHandler = new RemoveObjectHandler(this.handlerOptions)
    } else if (toolType === 'Eraser') {
      this.pixelManipulationObjectHandler = new EraserObjectHandler(this.handlerOptions)
    } else if (toolType === 'Enhance') {
      this.pixelManipulationObjectHandler = new EnhanceObjectHandler(this.handlerOptions)
    } else if (toolType === 'MagicBg') {
      this.pixelManipulationObjectHandler = new MagicBgObjectHandler(this.handlerOptions)
    }
    this.canvasRemoveHandler.toolType = toolType
    this.canvasRemoveHandler.createBrushInstance(toolType)
  }
}

export default RemoveHandlers
