function Back({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7605 12.2929L8.98937 16.8644C8.44993 17.3812 8.44993 18.2434 8.98937 18.7603V18.7603C9.49717 19.2469 10.2982 19.2469 10.806 18.7603L16.607 13.202C16.8586 12.9609 17 12.6339 17 12.2929C17 11.9519 16.8586 11.6249 16.607 11.3838L10.806 5.8255C10.2982 5.33895 9.49717 5.33895 8.98937 5.8255V5.8255C8.44993 6.34237 8.44993 7.20458 8.98937 7.72145L13.7605 12.2929Z"
        fill="#666666"
      />
    </svg>
  )
}

export default Back
