import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : "#666"}
      d="M11.993 23c1.508 0 2.924-.286 4.25-.859a11.122 11.122 0 0 0 3.508-2.385 11.364 11.364 0 0 0 2.384-3.517C22.712 14.912 23 13.5 23 12c0-1.5-.288-2.912-.865-4.24a11.306 11.306 0 0 0-2.39-3.516A11.202 11.202 0 0 0 16.23 1.86 10.606 10.606 0 0 0 11.98 1c-1.499 0-2.911.286-4.237.859a11.04 11.04 0 0 0-3.5 2.385 11.423 11.423 0 0 0-2.378 3.517A10.519 10.519 0 0 0 1 12c0 1.5.288 2.912.865 4.24a11.364 11.364 0 0 0 2.384 3.516 11.122 11.122 0 0 0 3.508 2.385c1.326.573 2.738.859 4.236.859Zm0-2.072c-1.235 0-2.39-.23-3.467-.688a8.846 8.846 0 0 1-2.833-1.915 8.967 8.967 0 0 1-1.907-2.85A8.809 8.809 0 0 1 3.098 12a8.886 8.886 0 0 1 2.581-6.325A8.847 8.847 0 0 1 8.513 3.76a8.745 8.745 0 0 1 3.467-.688c1.244 0 2.404.23 3.48.688a8.926 8.926 0 0 1 2.84 1.915 9.005 9.005 0 0 1 1.921 2.85A8.734 8.734 0 0 1 20.916 12c0 1.236-.23 2.395-.688 3.476a8.966 8.966 0 0 1-1.907 2.849 8.829 8.829 0 0 1-6.327 2.604Z"
    />
    <path
      fill={props.fill ? props.fill : "#666"}
      d="M11.694 14.176c.31 0 .55-.084.722-.252a.817.817 0 0 0 .26-.607v-.081c0-.282.09-.53.272-.743.181-.214.45-.439.803-.675a6.84 6.84 0 0 0 1.295-1.056c.354-.378.53-.902.53-1.575 0-.936-.356-1.642-1.069-2.12-.713-.476-1.532-.715-2.458-.715-.936 0-1.703.18-2.303.538-.599.36-.98.775-1.144 1.248-.073.2-.109.39-.109.572 0 .264.089.466.266.607.177.14.365.211.565.211.209 0 .382-.04.518-.123.136-.081.263-.195.381-.34l.232-.273c.227-.282.463-.486.708-.613.245-.128.518-.191.817-.191.445 0 .802.12 1.07.36.268.242.402.553.402.935 0 .363-.116.656-.348.879-.231.222-.574.488-1.028.797a5.167 5.167 0 0 0-.947.88c-.268.322-.402.756-.402 1.301v.068c0 .646.323.968.967.968Zm-.027 3.272c.336 0 .629-.116.879-.348.25-.232.374-.516.374-.852 0-.345-.124-.632-.374-.859a1.264 1.264 0 0 0-.879-.34 1.25 1.25 0 0 0-.878.347c-.25.232-.375.516-.375.852 0 .327.125.609.375.845s.542.355.878.355Z"
    />
  </svg>
)
export default SvgComponent
