import React from 'react'

function LetterSpacing({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2.70976C2.1603 2.70976 2.29024 2.8397 2.29024 3L2.29024 21C2.29024 21.1603 2.1603 21.2902 2 21.2902C1.8397 21.2902 1.70976 21.1603 1.70976 21L1.70976 3C1.70976 2.8397 1.8397 2.70976 2 2.70976Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.41951"
      />
      <path
        d="M22 2.70976C22.1603 2.70976 22.2902 2.8397 22.2902 3L22.2902 21C22.2902 21.1603 22.1603 21.2902 22 21.2902C21.8397 21.2902 21.7098 21.1603 21.7098 21L21.7098 3C21.7098 2.8397 21.8397 2.70976 22 2.70976Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.41951"
      />
      <path
        d="M17.3702 16.36L13.5902 6.07C13.4602 5.72 13.2702 5.45 13.0102 5.27C12.7602 5.09 12.4402 5 12.0802 5C11.7202 5 11.4102 5.09 11.1602 5.28C10.9102 5.46 10.7202 5.73 10.6002 6.08L6.8202 16.37C6.7402 16.6 6.7002 16.8 6.7002 16.98C6.7002 17.24 6.7702 17.46 6.9002 17.64L7.0102 17.75L7.1102 17.85C7.2902 17.97 7.5202 18.03 7.7802 18.03C8.0902 18.03 8.3502 17.95 8.5402 17.8C8.7302 17.65 8.8702 17.43 8.9802 17.11L9.8902 14.58H14.2602L15.1702 17.11C15.2602 17.36 15.3702 17.56 15.5002 17.7L15.7402 17.9C15.9102 17.98 16.1202 18.03 16.3702 18.03C16.6902 18.03 16.9602 17.93 17.1602 17.74C17.3702 17.54 17.4802 17.28 17.4802 16.96C17.4802 16.77 17.4402 16.58 17.3602 16.35L17.3702 16.36ZM13.6002 12.6H10.5502L12.0702 8.19L13.5902 12.6H13.6002Z"
        fill="#666666"
      />
    </svg>
  )
}

export default LetterSpacing
