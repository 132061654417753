import { clamp } from 'image-js/src/image/internal/clamp';
import { getOutputImage } from 'image-js/src/image/internal/getOutputImage';
import Image from 'image-js'
import { GREY } from 'image-js/src/image/model/model';

/**
 * Call back that converts the RGB channels to grey. It will be clamped after.
 * @callback GreyAlgorithmCallback
 * @param {number} red - value of the red channel
 * @param {number} green - value of the green channel
 * @param {number} blue - value of the blue channel
 * @return {number} value of the grey channel
 */

/**
 * Converts the current image to greyscale.
 * The source image has to be RGB.
 * If there is an alpha channel we need to decide what to do:
 * * keepAlpha : we will keep the alpha channel and you will get a GREY / A image
 * * mergeAlpha : we will multiply each pixel of the image by the alpha
 * @memberof Image
 * @instance
 * @param {object} [options]
 * @param {GreyAlgorithm|GreyAlgorithmCallback} [options.algorithm='luma709'] - Algorithm to get the grey value from RGB values
 * @param {boolean} [options.keepAlpha=false] - If true, the RGB values are treated
 *          separately from the alpha channel and the method returns a GREYA image.
 * @param {boolean} [options.mergeAlpha=true] - If true, the alpha channel will be used to scale the grey pixel.
 * @param {Image} [options.out]
 * @return {Image}
 */
export default function greyFromAlpha(options = {}) {
  // @ts-ignore
  const { threshold = 10, invert = false, signal = {} } = options;

  this.checkProcessable('grey', {
    bitDepth: [8, 16],
    alpha: [0, 1],
  });


  let newImage = getOutputImage(this, options, {
    components: 1,
    alpha: false,
    colorModel: GREY,
  });


  for (let i = 0; i < this.data.length; i += this.channels) {
    if (signal.aborted) {
      return;
    }
    let value = this.data[i];
    let thresholdedValue = value < threshold ? 0 : value;
    let invertedValue = invert ? 255 - thresholdedValue : thresholdedValue;
    newImage.data[i] = invertedValue;
  }
  return newImage;
}