import * as React from 'react'

const SvgComponent = props => (
  <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.02 2.996H4.023V1A.999.999 0 0 1 5.067.001c.545.024.953.518.953 1.064v1.931ZM0 4.996a1 1 0 0 1 .998-1h15.9a1.1 1.1 0 0 1 1.1 1.1v9.896H16V5.996H.998a1 1 0 0 1-.998-1Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 16.992a1 1 0 0 1-.998 1h-15.9a1.1 1.1 0 0 1-1.1-1.1V6.996H6v8.996h15.002a1 1 0 0 1 .998 1ZM16.014 19.004h1.997V21a.999.999 0 0 1-1.043 1c-.545-.025-.954-.518-.954-1.065v-1.93Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
