import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      fillRule="evenodd"
      d="m7.642 9.057-1.826.006a1 1 0 1 0 .006 2l3.64-.011a1.6 1.6 0 0 0 1.596-1.598l.004-3.633a1 1 0 0 0-2-.002L9.06 7.647 4.928 3.516A1 1 0 0 0 3.514 4.93l4.128 4.127ZM16.355 14.94l4.13 4.131a1 1 0 0 1-1.413 1.414l-4.128-4.127-.006 1.826a1 1 0 0 1-2-.006l.012-3.641a1.6 1.6 0 0 1 1.598-1.595l3.632-.004a1 1 0 1 1 .003 2l-1.828.002Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
