import { styled } from 'baseui'
import { SHAPE, KIND } from 'baseui/button'

import Icons from '../../Icons'
import { useEffect, useState } from 'react'
import { useRemoveEditorContext } from '@/scenes/engine'

import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import useAppContext from '@/hooks/useAppContext'
const Container = styled('div', props => ({
  display: 'flex',
  alignItems: 'center',
}))

function RemoveHistory() {
  const editor = useRemoveEditorContext().editor

  const [historyStatus, setHistoryStatus] = useState({ hasUndo: false, hasRedo: false })
  useEffect(() => {
    const handleHistoryChange = (data: any) => {
      setHistoryStatus({ ...historyStatus, hasUndo: data.hasUndo, hasRedo: data.hasRedo })
    }
    if (editor) {
      editor.on('remove-history:changed', handleHistoryChange)
    }
    return () => {
      if (editor) {
        editor.off('remove-history:changed', handleHistoryChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const { toolType } = useAppContext()

  return (
    <>
      {toolType === 'Enhance' ? (
        <></>
      ) : (
        <Container>
          <ButtonCustom
            style={{
              opacity: historyStatus.hasUndo ? 1 : 0.55,
              pointerEvents: historyStatus.hasUndo ? 'auto' : 'none',
            }}
            onClick={() => {
              editor.undo()
            }}
            type={SizeButton.STANDARD}
            kind={KIND.minimal}
            shape={SHAPE.square}
            tooltip={{
              content: 'Undo',
              placement: 'bottom',
            }}
          >
            <Icons.Undo size={24} />
          </ButtonCustom>
          <ButtonCustom
            style={{
              opacity: historyStatus.hasRedo ? 1 : 0.55,
              pointerEvents: historyStatus.hasRedo ? 'auto' : 'none',
            }}
            onClick={() => {
              editor.redo()
            }}
            type={SizeButton.STANDARD}
            kind={KIND.minimal}
            shape={SHAPE.square}
            tooltip={{
              content: 'Redo',
              placement: 'bottom',
            }}
          >
            <Icons.Redo size={24} />
          </ButtonCustom>
        </Container>
      )}
    </>
  )
}
export default RemoveHistory
