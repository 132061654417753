import React from 'react'

function AA() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2494 19.4276C12.5603 19.4276 12.8045 19.3588 12.9822 19.2211C13.1598 19.0835 13.3064 18.8503 13.4218 18.5217L14.6342 15.1377H20.5761L21.7884 18.5217C21.9039 18.8503 22.0505 19.0835 22.2281 19.2211C22.4057 19.3588 22.65 19.4276 22.9608 19.4276C23.2717 19.4276 23.5226 19.3388 23.7136 19.1612C23.9045 18.9835 24 18.7437 24 18.4418C24 18.2552 23.9556 18.0421 23.8668 17.8023L19.1506 5.10577C18.8752 4.36859 18.3601 4 17.6051 4C16.8591 4 16.3528 4.36859 16.0863 5.10577L11.3701 17.8156C11.2813 18.0554 11.2369 18.2686 11.2369 18.4551C11.2369 18.7571 11.3279 18.9946 11.51 19.1678C11.6921 19.341 11.9386 19.4276 12.2494 19.4276ZM15.207 13.3392L17.5652 6.65121H17.6318L19.9899 13.3392H15.207ZM0.985875 19.4285C1.23457 19.4285 1.44107 19.3619 1.60538 19.2287C1.76969 19.0954 1.89625 18.8823 1.98507 18.5892L2.73115 16.2843H6.67464L7.42072 18.5892C7.51842 18.8911 7.65164 19.1065 7.82039 19.2353C7.98915 19.3641 8.19343 19.4285 8.43323 19.4285C8.73522 19.4285 8.97725 19.3441 9.15933 19.1754C9.3414 19.0066 9.43243 18.7846 9.43243 18.5092C9.43243 18.3938 9.41911 18.2783 9.39248 18.1628C9.36583 18.0474 9.3303 17.9275 9.28588 17.8031L6.20835 9.52977C6.06625 9.15674 5.86863 8.87696 5.6155 8.69044C5.36237 8.50392 5.05818 8.41066 4.70291 8.41066C4.35652 8.41066 4.05676 8.5017 3.80363 8.68378C3.5505 8.86586 3.35732 9.14786 3.22407 9.52977L0.133226 17.8031C0.0888245 17.9186 0.055519 18.0363 0.0333092 18.1562C0.011103 18.2761 0 18.3938 0 18.5092C0 18.7846 0.0888192 19.0066 0.266458 19.1754C0.4441 19.3441 0.683906 19.4285 0.985875 19.4285ZM3.25073 14.7522L4.55634 10.6489H4.84946L6.16838 14.7522H3.25073Z"
        fill="#666666"
      />
    </svg>
  )
}

export default AA
