import { lightTheme } from '@/customTheme';
import FullColor from '@assets/images/full-color.png'

function FillColor({ color = lightTheme.colors.blackGray }: { size?: number; color: string }) {
  return (
    <div>
      {color === lightTheme.colors.blackGray || color === '#ffffff' ? (
        <img style={{ height: '30px', display: 'flex' }} src={FullColor} alt="color picker" />
      ) : (
        <div style={{ height: '30px', width: '30px', background: color }}></div>
      )}
    </div>
  )
}

export default FillColor
