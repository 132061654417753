import Handlers from '@/scenes/engine/handlers'
import { StepType } from '@reactour/tour'
import { Dispatch } from 'react'

export const BUBBLE_TUTORIAL_WIDTH = 280
export const BUBBLE_TUTORIAL_HEIGHT = 124
interface TourState {
  currentStep: number
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  isOpen: boolean
  steps: StepType[]
  setSteps: Dispatch<React.SetStateAction<StepType[]>>
  setCurrentStep: Dispatch<React.SetStateAction<number>>
  isHandleUndo: boolean,
  canvasJSON: any
}

let tourState: TourState = {
  currentStep: 0,
  setIsOpen: () => {},
  isOpen: false,
  steps: [],
  setSteps: () => {},
  setCurrentStep: () => {},
  isHandleUndo: false,
  canvasJSON: null
}

export const getTourState = (): TourState => {
  return tourState
}

export const setTourState = (newState: Partial<TourState>) => {
  tourState = { ...tourState, ...newState }
}

export const setShowedRemoveObjectTutorial = () => {
  const currentPath = window.location.pathname.split('/')[1]
  const value = {
    isOpened: true,
    from: currentPath,
  }
  localStorage.setItem('showedRemoveObjectTutorial', JSON.stringify(value))
}

export const createCloneRemoveObjectText = () => {
  const currentCloneText = document.getElementById('desc-remove-obj-clone')
  const rootElement = document.querySelector('#root') as HTMLElement
  const descRemoveObjBtn = document.getElementById('desc-remove-obj-btn')
  const originalBB = descRemoveObjBtn.getBoundingClientRect()

  if (currentCloneText) {
    currentCloneText.style.top = `${originalBB.top}px`
    currentCloneText.style.left = `${originalBB.left}px`
    currentCloneText.style.width = `${originalBB.width}px`
    currentCloneText.style.height = `${originalBB.height}px`
    return
  }
  const descElm = descRemoveObjBtn.cloneNode(true) as HTMLElement
  descElm.id = 'desc-remove-obj-clone'
  descElm.style.position = 'fixed'
  descElm.style.top = `${originalBB.top}px`
  descElm.style.left = `${originalBB.left}px`
  descElm.style.width = `${originalBB.width}px`
  descElm.style.height = `${originalBB.height}px`
  descElm.style.color = `#fff`
  descElm.style.pointerEvents = 'auto'
  descElm.style.zIndex = '999999999'
  rootElement.appendChild(descElm)
}

export const updateBubblePosition = () => {
  const spotlightElement = document.getElementById('remove-obj-media-content') as HTMLElement
  const spotlightBB = spotlightElement.getBoundingClientRect()
  tourState.setIsOpen(false)
  tourState.steps[tourState.currentStep] = {
    ...tourState.steps[tourState.currentStep],
    position: [spotlightBB.left - 280 - 12 - 3, spotlightBB.top - Math.abs(124 - spotlightBB.height) / 2],
  }
  tourState.setSteps(tourState.steps)
  tourState.setIsOpen(true)
}

export const clearBrushAnimation = () => {
  const brushAnimation = document.querySelector('.brush-animation') as HTMLElement
  if (brushAnimation) {
    const rootElement = document.querySelector('#root') as HTMLElement
    rootElement.removeChild(brushAnimation)
  }
}

export const clearTutorial = (handler: Handlers, fromCreatePage = false) => {
  tourState.isOpen = false
  if(fromCreatePage) { return }
  handler.transactionHandler.replay(tourState.canvasJSON)
  tourState.isHandleUndo = false
}

export const createBrushTutorialAnimation = () => {
  const currentBrushAnimation = document.querySelector('.brush-animation') as HTMLElement
  const rootElement = document.querySelector('#root') as HTMLElement
  if (currentBrushAnimation) {
    rootElement.removeChild(currentBrushAnimation)
  }
  const frameElement = document.querySelector(
    '#wrap-canvas-remove-tool .remove-container-class'
  ) as HTMLElement
  const customCursor = document.querySelector('.custom-cursor') as HTMLElement

  const frameBB = frameElement.getBoundingClientRect()

  const brushAnimation = document.createElement('div')
  brushAnimation.classList.add('brush-animation')

  brushAnimation.style.position = 'absolute'
  const originalLeft = frameBB.left + frameBB.width * 0.1 // 10% from left
  brushAnimation.style.left = `${originalLeft}px`
  brushAnimation.style.top = `${frameBB.top + frameBB.height * 0.3}px` // 35% from top

  brushAnimation.style.width = customCursor.style.width
  brushAnimation.style.height = customCursor.style.height
  brushAnimation.style.borderRadius = '50%'
  brushAnimation.style.zIndex = '2' // same with pixel manipulation canvas
  brushAnimation.style.backgroundColor = 'rgba(255,5,96, 0.8)'
  brushAnimation.style.pointerEvents = 'none'

  const brushAnimationKeyframes = `
      @keyframes brushAnimation {
          0% {
              left: ${originalLeft}px;
          }
          50% {
              left: ${originalLeft + frameBB.width * 0.6}px;
          }
          100% {
              left: ${originalLeft}px;
          }
      }
  `
  const styleSheet = document.createElement('style')
  styleSheet.innerText = brushAnimationKeyframes
  document.head.appendChild(styleSheet)
  rootElement.appendChild(brushAnimation)
  setTimeout(() => {
    brushAnimation.style.animation = 'brushAnimation 2s infinite'
  })
}

export const handleTutorialResize = () => {
  if (!tourState.isOpen) { return }
  if (tourState.currentStep === 0) {
    createCloneRemoveObjectText()
    updateBubblePosition()
    return
  }
  const tourProvider = document.querySelector('.tour-provider') as HTMLElement
  tourState.setIsOpen(false)
  if (tourState.currentStep === 2) {
    const cloneDoneBtn = document.getElementById('done-clone-btn') as HTMLElement
    const footerActionButtons = document.querySelectorAll('#footer-action-buttons button')
    const doneButton = footerActionButtons[1] as HTMLElement
    const doneBtnBB = doneButton.getBoundingClientRect()
    cloneDoneBtn.style.top = `${doneBtnBB.top}px`
    cloneDoneBtn.style.left = `${doneBtnBB.left}px`
    const doneBtnBBClone = cloneDoneBtn.getBoundingClientRect()
    tourState.steps[tourState.currentStep] = {
      ...tourState.steps[tourState.currentStep],
      position: [
        doneBtnBBClone.left - BUBBLE_TUTORIAL_WIDTH + (doneBtnBBClone.width * 3) / 4,
        doneBtnBBClone.top - BUBBLE_TUTORIAL_HEIGHT - 15,
      ],
    }
    cloneDoneBtn.style.opacity = '1'
  }
  let containerElement = document.querySelector(
    '#wrap-canvas-remove-tool .remove-container-class'
  ) as HTMLElement
  const containerBB = containerElement.getBoundingClientRect()
  if (tourState.currentStep === 1) {
    createBrushTutorialAnimation()
    tourState.steps[tourState.currentStep] = {
      ...tourState.steps[tourState.currentStep],
      position: [containerBB.left + containerBB.width + 15, containerBB.top + containerBB.height / 4],
    }
  }
  tourProvider.style.opacity = '1'
  tourState.setSteps(tourState.steps)
  tourState.setCurrentStep(tourState.currentStep)
  tourState.setIsOpen(true)
}

export const handleCurrentState = (handlers, isCreatePage) => {
  if(isCreatePage) {
    return
  }
  const canvasJSON = handlers.canvasHandler.exportToCanvasJSON()
  const activeObject = handlers.canvasHandler.canvas.getActiveObject()
  //@ts-ignore
  setTourState({isHandleUndo: true, canvasJSON: {canvasJSON, activeObjId: activeObject ? activeObject.id : null}})
}