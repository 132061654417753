import { selectBrushSize } from '@/store/slices/removeTool/selector'
import { useSelector } from 'react-redux'
import { useContext, useEffect } from 'react'
import { EditorContext, RemoveEditorContext } from '@/scenes/engine'
import { useAppDispatch } from '@/store/store'
import SliderCustom from '@/components/SliderCustom'
import Icons from '../../Icons'
import { setBrushSize } from '@/store/slices/removeTool/action'
import useAppContext from '@/hooks/useAppContext'
import RemoveObjectHandler from '@/scenes/engine/handlers/remove-handler/RemoveObjectHandler'
import { useTranslation } from 'react-i18next'
import { StepType, useTour } from '@reactour/tour'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { lightTheme } from '@/customTheme'
import { useStyletron } from 'styletron-react'
import { createBrushTutorialAnimation, setTourState } from '@/utils/tutorial'
import { customAmplitude } from '@/utils/customAmplitude'

function RemoveTool() {
    const dispatch = useAppDispatch()
    const brushSize = useSelector(selectBrushSize)
    const removeEditor = useContext(RemoveEditorContext).editor
    const { canvas } = useContext(RemoveEditorContext)
    const { setisOpenPixelManipulationObject, isOpenPixelManipulationObject, toolType, isOpenPixelManipulationWithAnimation, isOpenTutorial, setShowModalType } = useAppContext()
    const { activeObject } = useContext(EditorContext)
    const { setIsOpen, setCurrentStep, setSteps, steps, currentStep, isOpen } = useTour()
    const [css] = useStyletron()

    function doSetBrushSize() {
        removeEditor.handlers.canvasRemoveHandler.originalBrushSize = brushSize
        removeEditor.handlers.canvasRemoveHandler.setBrushSizeOnZoom()
    }

    useEffect(() => {
        removeEditor.handlers.setPixelToolType('Remove')
        // if(toolType != ''){
        setisOpenPixelManipulationObject(true)
        // }
    }, [])

    useEffect(() => {
        removeEditor.handlers.pixelManipulationObjectHandler.isHandleAnimation = isOpenPixelManipulationWithAnimation
    }, [isOpenPixelManipulationWithAnimation])

    useEffect(() => {
        if (!canvas) {
            return
        }
        doSetBrushSize()
    }, [brushSize])

    const addCursorPreview = (logicValue) => {
        removeEditor.handlers.pixelManipulationObjectHandler.addCursorPreview(logicValue)
    }

    useEffect(() => {
        if (!isOpenPixelManipulationObject || !removeEditor || toolType !== 'Remove') {
            return
        }
        (async () => {
            removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject
            await removeEditor.handlers.pixelManipulationObjectHandler.setupImage()
            // duplicate image so it can be animated to fade afterwards
            await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()
            // duplicate image so it can be animated to fade afterwards
            await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()
            let removeObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as RemoveObjectHandler
            let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool');
            canvasRemoveTool.style.zIndex = '2'
            removeObjectHandler.handleAnimate()
            handleTutorial()
            setTimeout(() => {
                removeEditor.handlers.canvasRemoveHandler.canvas.freeDrawingBrush.width = brushSize
                let maskScale = Math.max(removeEditor.handlers.pixelManipulationObjectHandler.maskScaleX, removeEditor.handlers.pixelManipulationObjectHandler.maskScaleY);
                // @ts-ignore
                let brush = removeEditor.handlers.canvasRemoveHandler.canvas.freeDrawingBrush as any;
                brush.setMaskScale(maskScale);
                doSetBrushSize()
            })
        })()
    }, [isOpenPixelManipulationObject, toolType])

    const handleTutorial = () => {
        if (!isOpenTutorial) { return }
        const containerElement = document.querySelector(
            '#wrap-canvas-remove-tool .remove-container-class'
        ) as HTMLElement
        containerElement.style.borderRadius = '16px'
        containerElement.style.overflow = 'hidden'
        const containerBB = containerElement.getBoundingClientRect()
        const step: StepType = {
            selector: '.remove-container-class',
            position: [containerBB.left + containerBB.width + 15, containerBB.top + containerBB.height / 4],
            content:
                <>
                    <p style={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px', marginTop: '4px' }}>{t('Mark the unwanted object')}</p>
                    <div style={{ position: 'absolute', right: 'calc(100% + 23px)', top: '50%', transform: 'translateY(-50%)', borderRight: '12px solid #fff', borderTop: '12px solid transparent', borderBottom: '12px solid transparent' }}></div>
                    <p className={css({
                            width: 'fit-content',
                            ...lightTheme.typography.Small12medium, 
                            color: lightTheme.colors.grayScale400, 
                            marginTop: '8px', 
                            cursor: 'pointer',
                            ':hover': {
                            color: lightTheme.colors.contentPrimary
                            },
                            ':active': {
                            color: lightTheme.colors.blackGray
                            }
                        })}
                        onClick={(e) => {
                        setShowModalType(ShowModalType.CANCEL_TUTORIAL)
                        setIsOpen(false)
                    }}>{t('Skip')}</p>
                </>,
            styles: {
                popover: (base) => ({
                    ...base,
                    width: '347px',
                    height: '124px',
                    borderRadius: '12px',
                    padding: '24px',
                    transition: 'none'
                }),
                maskArea: (base) => ({
                    ...base,
                    rx: 10, // It's close to border-radius: 16px of the css 
                }),
            }
        }
        setSteps([...steps, step])
        setTourState({currentStep: currentStep + 1, steps: [...steps, step]})
        setCurrentStep(currentStep + 1)
        setIsOpen(true)
        createBrushTutorialAnimation()
    }

    useEffect(() => {
        setTourState({isOpen: isOpen})
    }, [isOpen])

    const { t } = useTranslation()
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
                <SliderCustom
                    icon={<Icons.BrushIcon size={24} />}
                    name="Brush size"
                    minValue={12}
                    maxValue={200}
                    listValues={[]}
                    onChangeValue={(uiValue, logicValue) => {
                        addCursorPreview(logicValue)
                        dispatch(setBrushSize(logicValue))
                    }}
                    value={brushSize}
                    style={{ marginTop: 0 }}
                />
            </div>
        </div>
    )
}

export default RemoveTool