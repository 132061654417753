import { IValueRangeFilter } from '@scenes/engine/utils/PixijsFilters/IValueRangeFilter'
import { BlurFilter } from '@pixi/filter-blur'
import { MSAA_QUALITY } from 'pixi.js'

export class HdBlurFilter implements IValueRangeFilter {

  pixijsFilter: BlurFilter | null;
  blur: number;

  minimumForKey(key: string): number {
    if (key == "blur") {
      return 0;
    }
  }
  maximumForKey(key: string): number {
    if (key == "blur") {
      return 2;
    }
  }

  defaultForKey(key: string): number {
    if (key == "blur") {
      return 0;
    }
  }

  buildFilter({ blur }) {
    if (this.isValid(blur)) { this.blur = blur; }

    if (!this.pixijsFilter) {
      this.pixijsFilter = HdBlurFilter.generateFilter(this.normalize("blur", this.blur));
      this.pixijsFilter.repeatEdgePixels = true;
      this.pixijsFilter.quality = 20;
      this.pixijsFilter.resolution = 10;
      this.pixijsFilter.multisample = MSAA_QUALITY.HIGH;
    } else {
      this.pixijsFilter.blur = this.normalize("blur", this.blur);
    }
  }

  isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

   private normalize(key: string, value: number): number {
    let minBzrtValue = this.minimumForKey(key);
    let maxBzrtValue = this.maximumForKey(key);
    let minFilterValue = 0.0;
    let maxFilterValue = 20.0;

    let normalizedBzrtValue = (value - minBzrtValue) / Math.abs(maxBzrtValue - minBzrtValue);
    let normalizedFilterValue = normalizedBzrtValue * (maxFilterValue - minFilterValue) + minFilterValue;
    return normalizedFilterValue;
  }


  static generateFilter(blur: number): BlurFilter {
    let filter = new BlurFilter(blur);
    return filter;
  }
}

