import { fabric } from 'fabric'
import { ObjectType } from '../../common/constants'
import objectToFabric from '../../utils/objectToFabric'
import { customAmplitude } from '@/utils/customAmplitude'
import { PixelManipulationObjectHandler } from './PixelManipulationObjectHandler'
import { loadImageFromURL } from '@scenes/engine/utils/image-loader'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { BUBBLE_TUTORIAL_HEIGHT, BUBBLE_TUTORIAL_WIDTH, createBrushTutorialAnimation, handleTutorialResize, getTourState } from '@/utils/tutorial'

class RemoveObjectHandler extends PixelManipulationObjectHandler {

  duration = 800
  
  async setupImage() {
    if (!this.activeObject) return;

    this.setupInitialPosition();

    let element = this.activeObject._originalElement;
    let src = element.src ? element.src : element.toDataURL('image/png');
    this.img = await loadImageFromURL(src)

    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    containerElement.style.transformOrigin = 'center center';
  }

  handleAnimate(): void {
    this.activeObject.canvas._initEventListeners();

    this.activeObject.canvas._initRetinaScaling();

    // @ts-ignore
    this.canvas._initEventListeners();
    // @ts-ignore
    this.canvas._initRetinaScaling();

    // ------ Set initial position ------ //
    this.setInitialPosition()

    // ------ Start animation ------ //
    if(!this.isHandleAnimation) {
      this.animateZoomIn()
    } else {
      setTimeout(()=> {
        this.animateZoomIn()
      }, 10);
    }
  }

  parseTranslateValues(str: string) {
    let translateX = 0;
    let translateY = 0;

    if (str) {
          const match = str.match(/-?\d+(\.\d+)?/g).map(Number);
        if (match) {
            translateX = match[0] || 0; // Parse X value, default to 0 if NaN
            translateY = match[1] || 0; // Parse Y value, default to 0 if NaN
        }
    }
    return [translateX, translateY]
  }

  public setInitialPosition() {
    if (!this.activeObject) {
      return
    }
    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement

    containerElement.style.transition = 'none'
    containerElement.style.position = 'absolute'
    containerElement.style.translate = `${this.startX}px ${this.startY}px`
    containerElement.style.backgroundColor = 'transparent'

    this.aspectRatio = this.startHeight / this.startWidth;

    this.calculateDestinationSize()

    // @ts-ignore+
    this.canvas.lowerCanvasEl.width = this.canvas.width;
    // @ts-ignore
    this.canvas.lowerCanvasEl.height = this.canvas.height;


    this.canvas.getObjects().forEach(object => {
      object.top = 0;
      object.left = 0;
      object.width = this.canvas.width;
      object.height = this.canvas.height;
      object.scaleX = 1.0;
      object.scaleY = 1.0;
    })

    let offsetX = (this.canvas.width * this.scaleImageToScreen - this.canvas.width) / 2;
    let offsetY = (this.canvas.height * this.scaleImageToScreen - this.canvas.height) / 2;

    containerElement.style.transformOrigin = 'center center';

    let [translateX, translateY] = this.parseTranslateValues(containerElement.style.translate)
    translateX += offsetX;
    translateY += offsetY;

    containerElement.style.translate = `${translateX}px ${translateY}px`
    containerElement.style.rotate = `${this.startDegree}deg`
    containerElement.style.scale = `${this.scaleImageToScreen} ${this.scaleImageToScreen}`

    const upperCanvasElement = containerElement.querySelector('.upper-canvas') as HTMLCanvasElement

    let newWidth = parseInt(containerElement.style.width);
    let newHeight = parseInt(containerElement.style.height);

    if (newWidth > this.maskResolution || newHeight > this.maskResolution){
      if (newWidth > newHeight) {
        newWidth = this.maskResolution;
        newHeight = this.maskResolution * this.aspectRatio;
      } else {
        newWidth = this.maskResolution / this.aspectRatio;
        newHeight = this.maskResolution;
      }
    }

    this.maskScaleX = parseInt(containerElement.style.width) / newWidth;
    this.maskScaleY = parseInt(containerElement.style.height) / newHeight;

    upperCanvasElement.width = newWidth;
    upperCanvasElement.height = newHeight;

    upperCanvasElement.style.transformOrigin = 'top left'
    upperCanvasElement.style.transform = `scale(${this.maskScaleX}, ${this.maskScaleY})`;
  }

  public animateFade(upperLayer, indexToMove, inpaintingResult, handleHistory = true) {
    const animateUpperLayer = setInterval(() => {
      upperLayer.opacity = Math.max(upperLayer.opacity - 0.15, 0)
      if (upperLayer.opacity === 0) {
        clearInterval(animateUpperLayer)
        upperLayer.moveTo(indexToMove)
        upperLayer.opacity = 1
        if (handleHistory) {
          this.root.transactionRemoveHandler.save()
        }
        // @ts-ignore
        upperLayer.getElement().src = inpaintingResult
      }
      this.canvas.renderAll()
    }, 50)
  }

  animateZoomIn(): void {
    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement

    this.activeObject.visible = false;
    this.activeObject.hasBorders = false;

    this.activeObject.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
      mtr: false
    });
    this.activeObject.canvas.renderAll()

    containerElement.style.transition = this.isHandleAnimation ? 'all .7s ease-in-out' : ''
    containerElement.style.transformOrigin = 'center center';

    let originalWidth = parseInt(containerElement.style.width);
    let originalHeight = parseInt(containerElement.style.height);

    this.scaleX = this.destinationWidth / originalWidth
    this.scaleY = this.destinationHeight / originalHeight

    const { top, left } = this.getCenterPosition(containerElement)
 
    containerElement.style.rotate = `${this.startDegree > 180 ? 360 : 0}deg`
    containerElement.style.scale = `${this.scaleX} ${this.scaleY}`

    containerElement.style.translate = `${left}px ${top}px`
    this.canvas.freeDrawingBrush.width /= Math.max(this.scaleX, this.scaleY)
    
    let bottomContainerElement = document.querySelector('.canvas-container') as HTMLElement
    bottomContainerElement.style.transition = this.isHandleAnimation ? 'opacity .7s ease-in-out' : ''
    bottomContainerElement.style.opacity = '0.0'
  }

  addImageToCanvas = async () => {
    // we doing kind of clone because we want different element to each object.
    // @ts-ignore
    let element = this.activeObject._originalElement;
    let src = element.src ? element.src : element.toDataURL('image/png');
    const imgElement = await loadImageFromURL(src)

    const fabricImage = new fabric.StaticImage(imgElement, {
      type: ObjectType.STATIC_IMAGE,
      selectable: false,
      hasControls: false,
      evented: false,
      opacity: 1
    }) as any
    this.canvas.add(fabricImage)
  }


  animateZoomOut(containerElement?): void {
    containerElement = containerElement || document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement

    let bottomContainerElement = document.querySelector(
      '.canvas-container'
    ) as HTMLElement

    bottomContainerElement.style.transition = this.isHandleAnimation ? 'opacity .7s ease-in-out' : ''
    bottomContainerElement.style.opacity = '1.0'

    containerElement.style.transition = this.isHandleAnimation ? 'all .7s ease-in-out' : ''
    if(this.isHandleAnimation) {
      let offsetX = (this.canvas.width * this.scaleImageToScreen - this.canvas.width) / 2;
      let offsetY = (this.canvas.height * this.scaleImageToScreen - this.canvas.height) / 2;
      containerElement.style.transformOrigin = 'center center';
      containerElement.style.translate = `${this.startX + offsetX}px ${this.startY+ offsetY}px`
      containerElement.style.rotate = `${this.startDegree}deg`
      containerElement.style.scale = `${this.scaleImageToScreen} ${this.scaleImageToScreen}`
      setTimeout(() => {
        this.resetActiveObjectState()
      }, this.duration);
    } else {
      this.resetActiveObjectState()
    }
  }

  public reset() {
    let containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    containerElement.style.transformOrigin = '';
    containerElement.style.scale = '';
    containerElement.style.rotate = '';
    containerElement.style.translate = '';
  }

  public blinkAnimation = (canvas) => {
    canvas.upperCanvasEl.classList.add('blink');
  }

  public clearBlinkAnimation = canvas => {
    canvas.upperCanvasEl.classList.remove('blink');
    canvas.clearContext(canvas.contextTop);
  }

  handleRemoveToolResize = () => {
    super.handleRemoveToolResize()
    
    let containerElement = document.querySelector('#wrap-canvas-remove-tool .remove-container-class') as HTMLElement
    let originalWidth = parseInt(containerElement.style.width);
    let originalHeight = parseInt(containerElement.style.height);
    this.scaleX = this.destinationWidth / originalWidth
    this.scaleY = this.destinationHeight / originalHeight
    const {top, left } = this.getCenterPosition(containerElement)

    containerElement.style.rotate = `${this.startDegree > 180 ? 360 : 0}deg`
    containerElement.style.scale = `${this.scaleX} ${this.scaleY}`
    containerElement.style.translate = `${left}px ${top}px`
    handleTutorialResize()
    this.root.zoomRemoveHandler.calculateScrollPosition()
  }
  

  FinishToolState() : Promise<void> {
    return new Promise((resolve, reject) => {
      this.canvas.isDrawingMode = false
      this.animateZoomOut()
      setTimeout(() => {
        this.canvas.isDrawingMode = true
        resolve()
      }, this.duration);
    })
  }

  isZoomAvaliable = () =>{
    return !getTourState().isOpen
  }
}

export default RemoveObjectHandler