import * as React from 'react'

const Stretch = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.71 11.71h18.58v.58H2.71v-.58Z" fill="#666" stroke="#666" strokeWidth={1.42} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m2.165 12.005 2.285-2.3a1 1 0 0 0-1.418-1.41L.465 10.878a1.6 1.6 0 0 0 .002 2.258l2.566 2.57a1 1 0 1 0 1.416-1.412l-2.284-2.29ZM21.836 12.005l-2.286-2.3a1 1 0 1 1 1.419-1.41l2.566 2.583a1.6 1.6 0 0 1-.002 2.258l-2.566 2.57a1 1 0 1 1-1.416-1.412l2.285-2.29Z"
      fill="#666"
    />
  </svg>
)

export default Stretch
