function Redo({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5858 9.70247L14.2929 12.9954L15.7071 14.4096L20.7071 9.40958C21.0977 9.01896 21.0976 8.3856 20.7069 7.99512L15.7069 3L14.2931 4.41471L17.5846 7.70247H14.9999V7.69995H8.99997C5.68626 7.69995 2.99997 10.3862 2.99997 13.7V14.7C2.99997 18.0137 5.68626 20.7 8.99997 20.7H14.9999V18.7H8.99997C6.79083 18.7 4.99997 16.9091 4.99997 14.7V13.7C4.99997 11.4908 6.79083 9.69995 8.99997 9.69995H11V9.70247H17.5858Z"
        fill="#666666"
      />
    </svg>
  )
}

export default Redo
