import { useEditorContext } from '@/scenes/engine'
import { Button, SHAPE, KIND, SIZE } from 'baseui/button'
import Icons from '../../../Icons'

function Delete() {
  const editor = useEditorContext().editor
  return (
    <Button onClick={() => editor.handlers.objectsHandler.delete()} size={SIZE.default} kind={KIND.tertiary} shape={SHAPE.square}>
      <Icons.Delete size={24} />
    </Button>
  )
}

export default Delete
