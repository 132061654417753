import { lightTheme } from '@/customTheme'
import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <g clipPath="url(#amazon_a)">
      <rect width={49} height={49} x={0.5} y={0.5} fill="#E5E5E5" rx={3.5} />
      <path
        fill="#F90"
        d="M29.916 29.525c-5.778 2.75-9.364.449-11.66-.948-.142-.089-.384.02-.174.26.765.928 3.271 3.163 6.542 3.163 3.274 0 5.222-1.786 5.465-2.098.242-.309.071-.48-.173-.377Zm1.623-.896c-.155-.202-.944-.24-1.44-.18-.497.06-1.243.364-1.178.546.033.068.101.038.443.007.342-.034 1.3-.155 1.5.106.201.263-.305 1.516-.398 1.718-.09.202.034.254.202.12.166-.135.466-.483.667-.976.2-.496.322-1.188.204-1.341Z"
      />
      <path
        fill={lightTheme.colors.blackGray}
        fillRule="evenodd"
        d="M26.031 23.913c0 .722.018 1.323-.346 1.964-.295.521-.761.842-1.282.842-.711 0-1.125-.542-1.125-1.342 0-1.578 1.414-1.865 2.753-1.865v.401Zm1.868 4.514a.387.387 0 0 1-.438.045c-.615-.51-.724-.748-1.063-1.235-1.016 1.037-1.735 1.347-3.053 1.347-1.557 0-2.771-.961-2.771-2.886 0-1.503.815-2.527 1.974-3.027 1.006-.443 2.41-.521 3.483-.644v-.24c0-.44.034-.96-.224-1.34-.227-.342-.66-.483-1.04-.483-.705 0-1.336.362-1.49 1.112-.03.167-.153.331-.32.34l-1.797-.194c-.151-.034-.318-.156-.276-.388.414-2.177 2.38-2.834 4.141-2.834.902 0 2.08.24 2.79.922.902.841.816 1.964.816 3.186v2.886c0 .868.36 1.248.698 1.717.12.167.146.367-.005.492-.378.315-1.05.902-1.42 1.23l-.005-.006"
        clipRule="evenodd"
      />
      <path
        fill="#F90"
        d="M29.916 29.525c-5.778 2.75-9.364.449-11.66-.948-.142-.089-.384.02-.174.26.765.928 3.271 3.163 6.542 3.163 3.274 0 5.222-1.786 5.465-2.098.242-.309.071-.48-.173-.377Zm1.623-.896c-.155-.202-.944-.24-1.44-.18-.497.06-1.243.364-1.178.546.033.068.101.038.443.007.342-.034 1.3-.155 1.5.106.201.263-.305 1.516-.398 1.718-.09.202.034.254.202.12.166-.135.466-.483.667-.976.2-.496.322-1.188.204-1.341Z"
      />
      <path
        fill={lightTheme.colors.blackGray}
        fillRule="evenodd"
        d="M26.031 23.913c0 .722.018 1.323-.346 1.964-.295.521-.761.842-1.282.842-.711 0-1.125-.542-1.125-1.342 0-1.578 1.414-1.865 2.753-1.865v.401Zm1.868 4.514a.387.387 0 0 1-.438.045c-.615-.51-.724-.748-1.063-1.235-1.016 1.037-1.735 1.347-3.053 1.347-1.557 0-2.771-.961-2.771-2.886 0-1.503.815-2.527 1.974-3.027 1.006-.443 2.41-.521 3.483-.644v-.24c0-.44.034-.96-.224-1.34-.227-.342-.66-.483-1.04-.483-.705 0-1.336.362-1.49 1.112-.03.167-.153.331-.32.34l-1.797-.194c-.151-.034-.318-.156-.276-.388.414-2.177 2.38-2.834 4.141-2.834.902 0 2.08.24 2.79.922.902.841.816 1.964.816 3.186v2.886c0 .868.36 1.248.698 1.717.12.167.146.367-.005.492-.378.315-1.05.902-1.42 1.23l-.005-.006"
        clipRule="evenodd"
      />
      <rect width={49} height={49} x={0.5} y={0.5} stroke="#E5E5E5" rx={3.5} />
    </g>
    <defs>
      <clipPath id="amazon_a">
        <path fill="#fff" d="M0 0h50v50H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
