import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#306EB0"
        d="M13.19.5H2.81A2.31 2.31 0 0 0 .5 2.81v10.38a2.31 2.31 0 0 0 2.31 2.31h10.38a2.31 2.31 0 0 0 2.31-2.31V2.81A2.31 2.31 0 0 0 13.19.5Z"
      />
      <path
        fill="#fff"
        d="M5.798 6.547H4.004v5.778h1.794V6.547ZM4.898 5.755A1.045 1.045 0 1 1 4.9 3.666a1.045 1.045 0 0 1-.002 2.09ZM12.524 12.326H10.73V9.512c0-.672-.012-1.53-.936-1.53s-1.08.732-1.08 1.482v2.862H6.92V6.548h1.722v.792h.024c.24-.456.828-.936 1.698-.936 1.818 0 2.154 1.2 2.154 2.754v3.168h.006Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={15} height={15} x={0.5} y={0.5} fill="#fff" rx={3} />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
