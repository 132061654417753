import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <g filter="url(#softer)" opacity={0.4}>
      <circle cx={25} cy={25} r={13} fill="#666" />
    </g>
    <defs>
      <filter
        id="softer"
        width={46.6}
        height={46.6}
        x={1.7}
        y={1.7}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_9248_29729"
          stdDeviation={5.15}
        />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
