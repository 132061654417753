function Undo({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41421 9.70247L9.70711 12.9954L8.29289 14.4096L3.29289 9.40958C2.90227 9.01896 2.90238 8.3856 3.29314 7.99512L8.29314 3L9.70686 4.41471L6.41541 7.70247H9.00006V7.69995H15C18.3137 7.69995 21 10.3862 21 13.7V14.7C21 18.0137 18.3137 20.7 15 20.7H9.00006V18.7H15C17.2092 18.7 19 16.9091 19 14.7V13.7C19 11.4908 17.2092 9.69995 15 9.69995H13V9.70247H6.41421Z"
        fill="#666666"
      />
    </svg>
  )
}

export default Undo
