import { FC, createContext, useState } from 'react'
import { fabric } from 'fabric'
import EditorEventManager from '../EditorEventManager'
import { ContextMenuDetails } from '../common/constants'

export interface IEditorContext {
  canvas: fabric.Canvas | null
  setCanvas: (canvas: fabric.Canvas) => void
  activeObject: fabric.Object | null
  setActiveObject: (object: fabric.Object | null) => void
  editor: EditorEventManager | null
  setEditor: (handlers: EditorEventManager) => void
  contextMenuDetails: ContextMenuDetails 
  setContextMenuDetails: (value: ContextMenuDetails) => void
}

export const EditorContext = createContext<IEditorContext>({
  canvas: null,
  setCanvas: () => {},
  activeObject: null,
  setActiveObject: () => {},
  editor: null,
  setEditor: () => {},
  contextMenuDetails: null,
  setContextMenuDetails: (value: ContextMenuDetails) => {},
})

export const EditorProvider: FC = ({ children }) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)
  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null)
  const [editor, setEditor] = useState<EditorEventManager | null>(null)
  const [contextMenuDetails, setContextMenuDetails] = useState<ContextMenuDetails>(null)


  const context = {
    canvas,
    setCanvas,
    activeObject,
    setActiveObject,
    editor,
    setEditor,
    contextMenuDetails,
    setContextMenuDetails
  }

  return <EditorContext.Provider value={context}>{children}</EditorContext.Provider>
}
