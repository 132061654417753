import { createReducer } from '@reduxjs/toolkit'
import { setEffect } from './actions'

const initialState: any = {
  effects: [],
}

export const effectsReducer = createReducer(initialState, builder => {
  builder.addCase(setEffect, (state, { payload }) => {
    state.effects = payload
  })
})
