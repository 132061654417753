import React from 'react'

function InspectorArrow ({ size }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="11.39 2.52 6.61 11.96">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.4804 8.5L11.6737 4.34587C11.2925 3.92987 11.2925 3.29151 11.6737 2.87551C12.105 2.40484 12.8469 2.40484 13.2783 2.87551L17.6943 7.69465C17.89 7.90825 18 8.19794 18 8.5C18 8.80206 17.89 9.09175 17.6943 9.30535L13.2783 14.1245C12.8469 14.5952 12.105 14.5952 11.6737 14.1245C11.2925 13.7085 11.2925 13.0701 11.6737 12.6541L15.4804 8.5Z" fill="#7A7A7E">
      </path>
    </svg>
  )
}

export default InspectorArrow
