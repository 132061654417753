import GridCustom from '@/components/GridCustom'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { useEditorContext } from '@/scenes/engine'
import { useTranslation } from 'react-i18next'

function FilterBg({ handleSelectItem, actionToggle, filterObject, filterActiveId, setFilterActiveId }) {
  const [filteredPacksMapped, setFilteredPacksMapped] = useState([
    {
      description: 'Normal',
    },
    {
      description: '',
    },
    {
      description: '',
    },
    {
      description: '',
    },
  ])
  const { canvas, editor } = useEditorContext()

  const filterPacks = useSelector(selectImageElements)
  const [preValue, setPreValue] = useState(1)
  const [preFilter, setPreFilter] = useState('')
  const [preFilters, setPreFilters] = useState([])

  // useEffect(() => {
  //   setFilterObject(background ? background : activeObject)
  // }, [background, activeObject])

  useEffect(() => {
    if (!filterObject) {
      return
    }
    if (!filterObject.effects) {
      filterObject.effects = {}
    }
    getImageWithFilter()
  }, [
    filterObject,
    filterObject && filterObject._originalElement && filterObject._originalElement.currentSrc,
  ])

  useEffect(() => {
    setPreValue(filterObject?.filterIntensity ?? 1)
    setPreFilters(filterObject?.filters ?? [])
    setPreFilter(filterObject?.filter ?? '')
    setFilterActiveId(filterObject?.filter ?? 'Normal')
  }, [filterObject])

  const getImageWithFilter = () => {
    // const staticCanvas = new fabric.StaticCanvas(null, { width: 200, height: 200 })
    // let latestImageUrl = filterObject._originalElement.currentSrc
    //   ? filterObject._originalElement.currentSrc
    //   : await MediaImageRepository.getInstance().getImage(
    //       filterObject.id,
    //       filterObject.layerAssetStateId,
    //       MediaImageType.thumbnail
    //     )
    // if (!latestImageUrl) {
    //   return
    // }
    // const img: any = await loadImageFromURL(latestImageUrl)
    // let scaleFactor = Math.max(200 / img.width, 200 / img.height)

    let temp = [
      {
        id: '1_Normal',
        display_image: filterObject?._originalElement?.currentSrc,
        description: 'Normal',
        name: 'Normal',
      },
      {
        description: '',
        id: 15974,
        store: 287,
      },
      {
        description: '',
        id: 16017,
        store: 290,
      },
      {
        description: '',
        id: 15935,
        store: 288,
      },
    ]
    // await Promise.all(
    filterPacks.forEach(filter => {
      let temp_index = temp.findIndex(x => x.id == filter.id)
      if (temp_index != -1) {
        if (filterObject.filter === `${filter.id}_${filter.store}`) {
          setFilterActiveId(filterObject.filter)
        }
        temp[temp_index] = {
          ...temp[temp_index],
          ...filter,
          filterPacks: filterPacks,
          // display_image: filteredImage,
          filterIntensity:
            filterObject.filter === `${filter.id}_${filter.store}` && filterObject.filterIntensity
              ? filterObject.filterIntensity
              : 1,
        }
      }
    })
    setFilteredPacksMapped([...temp])
    // )
  }
  // console.log('filteredPacksMapped', filteredPacksMapped)

  const onItemSelected = async (filter, isIntensity = false, isFinal = false) => {
    if (!filter.source_platform) {
      const isItensityChanged = !!filter.filterIntensity && preValue !== filter.filterIntensity
      if (filterObject.filter === `${filter.description}` && !isItensityChanged) {
        return
      }
      editor.handlers.transactionHandler.save()
      filterObject.filters = []
      filterObject.filter = `${filter.description}`
      await editor.canvasImageRenderer.render(filterObject, filterPacks)
      canvas.renderAll()
    } else {
      const isDifferentFilter = !isIntensity && filterObject.filter !== `${filter.id}_${filter.store}`
      if (isDifferentFilter) {
        filterObject.filterIntensity = 1
        filter.filterIntensity = 1
      }
      const isItensityChanged = !!filter.filterIntensity && preValue !== filter.filterIntensity
      if (!isDifferentFilter && !isItensityChanged) {
        return
      }
      let sliderOptions: any = { isSliderUpdate: isFinal }
      if (isFinal) {
        sliderOptions = {
          isSliderUpdate: isFinal,
          property: ['filterIntensity', 'filter', 'filters'],
          value: [preValue, preFilter, preFilters],
        }
        setPreValue(filterObject.filterIntensity)
        setPreFilters(filterObject.filters)
        setPreFilter(filterObject.filter)
      }
      editor.handlers.objectsHandler.updateActive(
        { filterIntensity: filterObject.filterIntensity },
        filterObject,
        sliderOptions
      )
      filterObject.filter = `${filter.id}_${filter.store}`
      filterObject.filterIntensity = filter.filterIntensity

      await editor.canvasImageRenderer.render(filterObject, filterPacks)
      if (!isIntensity && isFinal) {
        setPreFilters(filterObject.filters)
        setPreFilter(filterObject.filter)
      }
    }
  }
  const { t } = useTranslation()
  return (
    <>
      <GridCustom
        itemsPerLine={4}
        handleSelectItem={onItemSelected}
        listItem={filteredPacksMapped}
        name={t('Filters')}
        seeAll={false}
        actionToggle={actionToggle}
        activeIdExternal={filterActiveId}
        setActiveIdExternal={setFilterActiveId}
        filterObject={filterObject}
        hasPremium={true}
      />
    </>
  )
}

export default FilterBg
