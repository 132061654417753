import * as React from 'react'

const SvgComponent = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8565 21.2171C14.6598 21.7373 13.3777 22 12 22C10.6306 22 9.35219 21.7376 8.1548 21.2171C6.94056 20.6894 5.87614 19.966 4.95505 19.045C4.03349 18.1234 3.31012 17.0605 2.78244 15.8499C2.26267 14.6575 2 13.3777 2 12C2 10.6214 2.26115 9.34081 2.77773 8.1479C3.30135 6.93876 4.0242 5.87458 4.94939 4.94939C5.87411 4.02467 6.93978 3.30187 8.15261 2.77814C9.35114 2.2606 10.6266 2 11.9887 2C13.3673 2 14.6501 2.26115 15.8474 2.77814C17.0602 3.30187 18.1259 4.02467 19.0506 4.94939C19.9758 5.87458 20.6986 6.93876 21.2223 8.1479C21.7389 9.34081 22 10.6214 22 12C22 13.3786 21.7389 14.6592 21.2223 15.8521C20.6985 17.0617 19.9773 18.1239 19.0563 19.045C18.1352 19.966 17.0708 20.6894 15.8565 21.2171Z"
      stroke="#666666"
      strokeWidth="2"
    />
    <mask
      id="mask0_1718_15124"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="20"
      height="20"
    >
      <path
        d="M11.9873 22C10.6068 22 9.31053 21.7415 8.09842 21.2245C6.88631 20.7074 5.82361 19.9929 4.91032 19.0807C3.99703 18.1686 3.28366 17.1072 2.77019 15.8966C2.25673 14.686 2 13.3871 2 12C2 10.6213 2.25673 9.32661 2.77019 8.11601C3.28366 6.90542 3.99703 5.84195 4.91032 4.9256C5.82361 4.00925 6.88421 3.29256 8.09211 2.77554C9.30001 2.25851 10.5942 2 11.9746 2C13.3719 2 14.6745 2.25851 15.8824 2.77554C17.0903 3.29256 18.153 4.00925 19.0705 4.9256C19.988 5.84195 20.7056 6.90542 21.2233 8.11601C21.741 9.32661 21.9998 10.6213 21.9998 12C22.0082 13.3871 21.7515 14.686 21.2296 15.8966C20.7077 17.1072 19.9922 18.1686 19.0832 19.0807C18.1741 19.9929 17.1135 20.7074 15.9014 21.2245C14.6893 21.7415 13.3846 22 11.9873 22Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1718_15124)">
      <rect x="-3" y="-3" width="15" height="35" fill="#666666" />
    </g>
    <rect x="11.9624" y="4.2002" width="7.66652" height="2" fill="#666666" />
    <rect x="11.8276" y="7.60001" width="9.66652" height="2" fill="#666666" />
    <rect x="11.9624" y="11" width="9.66652" height="2" fill="#666666" />
    <rect x="11.9624" y="14.3999" width="9" height="2" fill="#666666" />
    <rect x="11.9624" y="17.7998" width="7.13736" height="2" fill="#666666" />
  </svg>
)

export default SvgComponent
