import { lightTheme } from '@/customTheme'
import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={24} fill="none" {...props}>
    <rect width={49} height={21.368} x={0.5} y={1.316} fill="#E5E5E5" rx={3.5} />
    <path
      fill="#F90"
      d="M29.916 16.34c-5.778 2.75-9.364.45-11.66-.948-.142-.088-.384.02-.174.261.765.928 3.271 3.163 6.542 3.163 3.274 0 5.222-1.787 5.465-2.098.242-.31.071-.48-.173-.377Zm1.623-.896c-.155-.202-.944-.24-1.44-.178-.497.059-1.243.363-1.178.545.033.068.101.037.443.007.342-.034 1.3-.155 1.5.106.201.263-.305 1.516-.398 1.718-.09.202.034.254.202.12.166-.135.466-.483.667-.977.2-.495.322-1.187.204-1.34Z"
    />
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M26.031 10.729c0 .721.018 1.323-.346 1.964-.295.521-.761.841-1.282.841-.711 0-1.125-.542-1.125-1.341 0-1.579 1.414-1.865 2.753-1.865v.4Zm1.868 4.514a.387.387 0 0 1-.438.044c-.615-.51-.724-.747-1.063-1.234-1.016 1.036-1.735 1.346-3.053 1.346-1.557 0-2.771-.96-2.771-2.886 0-1.503.815-2.527 1.974-3.027 1.006-.443 2.41-.52 3.483-.643v-.24c0-.44.034-.961-.224-1.341-.227-.342-.66-.482-1.04-.482-.705 0-1.336.362-1.49 1.112-.03.167-.153.331-.32.339l-1.797-.193c-.151-.034-.318-.156-.276-.388.414-2.178 2.38-2.834 4.141-2.834.902 0 2.08.24 2.79.922.902.841.816 1.964.816 3.186v2.886c0 .867.36 1.248.698 1.717.12.166.146.367-.005.492-.378.315-1.05.901-1.42 1.23l-.005-.006"
      clipRule="evenodd"
    />
    <path
      fill="#F90"
      d="M29.916 16.34c-5.778 2.75-9.364.45-11.66-.948-.142-.088-.384.02-.174.261.765.928 3.271 3.163 6.542 3.163 3.274 0 5.222-1.787 5.465-2.098.242-.31.071-.48-.173-.377Zm1.623-.896c-.155-.202-.944-.24-1.44-.178-.497.059-1.243.363-1.178.545.033.068.101.037.443.007.342-.034 1.3-.155 1.5.106.201.263-.305 1.516-.398 1.718-.09.202.034.254.202.12.166-.135.466-.483.667-.977.2-.495.322-1.187.204-1.34Z"
    />
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M26.031 10.729c0 .721.018 1.323-.346 1.964-.295.521-.761.841-1.282.841-.711 0-1.125-.542-1.125-1.341 0-1.579 1.414-1.865 2.753-1.865v.4Zm1.868 4.514a.387.387 0 0 1-.438.044c-.615-.51-.724-.747-1.063-1.234-1.016 1.036-1.735 1.346-3.053 1.346-1.557 0-2.771-.96-2.771-2.886 0-1.503.815-2.527 1.974-3.027 1.006-.443 2.41-.52 3.483-.643v-.24c0-.44.034-.961-.224-1.341-.227-.342-.66-.482-1.04-.482-.705 0-1.336.362-1.49 1.112-.03.167-.153.331-.32.339l-1.797-.193c-.151-.034-.318-.156-.276-.388.414-2.178 2.38-2.834 4.141-2.834.902 0 2.08.24 2.79.922.902.841.816 1.964.816 3.186v2.886c0 .867.36 1.248.698 1.717.12.166.146.367-.005.492-.378.315-1.05.901-1.42 1.23l-.005-.006"
      clipRule="evenodd"
    />
    <rect width={49} height={21.368} x={0.5} y={1.316} stroke="#E5E5E5" rx={3.5} />
  </svg>
)
export default SvgComponent
