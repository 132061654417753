import { createReducer } from '@reduxjs/toolkit'
import { setMagicCategories, setSuggestedImagesFromServer } from './action'

export interface MagicBgToolInterface {
  magicCategories: any[],
  suggestedImagesFromServer: any[]
}
const initialState: MagicBgToolInterface = {
  magicCategories: [],
  suggestedImagesFromServer: []
}

export const magicBgToolReducer = createReducer(initialState, builder => {
  builder.addCase(setMagicCategories, (state, { payload }) => {
    state.magicCategories = payload
  })
  builder.addCase(setSuggestedImagesFromServer, (state, { payload }) => {
    state.suggestedImagesFromServer = payload
  })
})
