import * as React from 'react'

const TextAlignJustify = props => (
  <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="a" fill="#fff">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.15 3v18h-2V3h2Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.15 3v18h-2V3h2Z" fill="#666" />
    <path
      d="M5.15 3h2V1h-2v2Zm0 18v2h2v-2h-2Zm-2 0h-2v2h2v-2Zm0-18V1h-2v2h2Zm0 0v18h4V3h-4Zm2 16h-2v4h2v-4Zm0 2V3h-4v18h4Zm-2-16h2V1h-2v4Z"
      fill="#666"
      mask="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4.16 3.7 2.331 2.317A.786.786 0 0 0 7.6 4.902L5.046 2.365a1.257 1.257 0 0 0-1.774.002L.731 4.903a.786.786 0 1 0 1.11 1.113L4.16 3.7ZM4.16 20.3l2.331-2.317A.786.786 0 0 1 7.6 19.098l-2.553 2.537a1.257 1.257 0 0 1-1.774-.002L.731 19.097a.786.786 0 0 1 1.11-1.113L4.16 20.3Z"
      fill="#666"
    />
    <rect x={9.5} y={13} width={15} height={2} rx={1} fill="#666" />
    <rect x={9.5} y={18} width={15} height={2} rx={1} fill="#666" />
    <rect x={9.5} y={3} width={15} height={2} rx={1} fill="#666" />
    <rect x={9.5} y={8} width={15} height={2} rx={1} fill="#666" />
  </svg>
)

export default TextAlignJustify
