import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={lightTheme.colors.blackGray}
      d="M11.994 17.535c3.021 0 5.544-2.512 5.544-5.535 0-3.035-2.523-5.547-5.544-5.547-3.012 0-5.532 2.512-5.532 5.547 0 3.023 2.523 5.535 5.532 5.535Zm0-1.695c-2.074 0-3.826-1.753-3.826-3.84 0-2.099 1.74-3.852 3.826-3.852 2.086 0 3.838 1.753 3.838 3.852s-1.74 3.84-3.838 3.84Zm9.481-7.692V6.465l-9.421-.012v1.695h9.421Zm-1.371 10.26 1.43-.907-4.644-8.068-1.479.847 4.693 8.128Zm-9.536 3.718 1.488.788 4.68-8.069-1.463-.86-4.705 8.14ZM2.513 15.84v1.695h9.433V15.84H2.513ZM3.896 5.58l-1.443.907 4.657 8.069 1.476-.848-4.69-8.128Zm9.536-3.718-1.488-.788-4.68 8.069 1.463.86 4.705-8.14ZM11.994 23.5C18.281 23.5 23.5 18.29 23.5 12S18.269.5 11.982.5C5.705.5.5 5.71.5 12s5.217 11.5 11.494 11.5Zm0-1.91A9.543 9.543 0 0 1 2.431 12c0-5.323 4.243-9.59 9.551-9.59A9.564 9.564 0 0 1 21.58 12a9.554 9.554 0 0 1-9.585 9.59Z"
    />
  </svg>
)
export default SvgComponent
