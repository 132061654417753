import { lightTheme } from '@/customTheme'
import useAppContext from '@/hooks/useAppContext'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { Modal, ModalBody, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React from 'react'
import Icons from '@/scenes/Editor/components/Icons'
import { t } from 'i18next'
import QR_CODE_IMAGE from '@/assets/images/qr_code.png'
import CH_PLAY_IMAGE from '@/assets/images/ch-play.png'
import APP_STORE_IMAGE from '@/assets/images/app-store.png'
import { customAmplitude } from '@/utils/customAmplitude'
import { DotLottiePlayer } from '@dotlottie/react-player'
// @ts-ignore
import downloadAppLottie from '@assets/lottie/download_app.lottie'

function ModalDownloadApp() {
  const { showModalType, setShowModalType } = useAppContext()
  const onChpPlayButton = () => {
    window.open('https://play.google.com/store/apps/details?id=me.bazaart.app&utm_source=third_party&utm_medium=organic&utm_campaign=webapp', '_blank');
    customAmplitude('Download App', {
      Source: 'Play Store'
    })
  }
  const onAppStoreButton = () => {
    window.open('https://apps.apple.com/app/apple-store/id515094775?pt=1102993&ct=WebApp&mt=8', '_blank');
    customAmplitude('Download App', {
      Source: 'App Store'
    })
  }
  const handleOnClose = () => {
    setShowModalType(null)
    localStorage.setItem('isDisplayedModalDownloadApp', 'true')
  }

  return (
    <Modal
      onClose={handleOnClose}
      isOpen={showModalType === ShowModalType.DOWNLOAD_APP}
      animate
      closeable={true}
      focusLock={false}
      returnFocus={false}
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Backdrop: {
          style: ({ $theme }) => ({
            ...lightTheme.modal.BackdropStyle
          }),
        },
        Root: {
          style: ({ $theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            zIndex: 10000,
          }),
        },
        Dialog: {
          style: ({ $theme, $isOpen, $isVisible }) => ({
            width: '450px',
            height: '672px',
            borderRadius: '16px',
            overflow: 'hidden',
            ...lightTheme.modal.DialogStyle({ $theme, $isOpen, $isVisible })
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: 'none',
          }),
        },
      }}
    >
      <ModalHeader
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0',
          height: '336px',
          position: 'relative',
        }}
        className="linear-gradient-bg"
      >
        <h3 style={{
          ...lightTheme.typography.Header26bold,
          color: '#fff',
          margin: '48px 0 24px'
        }}>Do more with Bazaart app!</h3>
        <DotLottiePlayer
        src={downloadAppLottie}
        autoplay
        style={{
          height: '232px',
        }}
      >
      </DotLottiePlayer>
        <div
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            borderRadius: '50%',
            background: 'rgba(0,0,0,0.5)',
            width: '32px',
            height: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleOnClose}
        >
          <Icons.Close size={24} fill="#fff" />
        </div>
      </ModalHeader>
      <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', margin: '0', padding: '46.5px 0', height: '336px' }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'center'}}>
        <img src={QR_CODE_IMAGE} alt="qr code" style={{width: '150px', height: '150px'}} />
        <p style={{...lightTheme.typography.Small14regular, color: '#000', width: 'fit-content'}}>{t('Scan to download')}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px'}}>
          <img src={CH_PLAY_IMAGE} alt="ch play" style={{ cursor: 'pointer', width: '135px', height: '40px' }} onClick={onChpPlayButton} />
          <img src={APP_STORE_IMAGE} alt="app store" style={{ cursor: 'pointer', width: '135px', height: '40px' }} onClick={onAppStoreButton} />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalDownloadApp
