import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={170} height={106} fill="none" {...props}>
    <g clipPath="url(#drop_a)">
      <rect
        width={44.056}
        height={51.652}
        fill="#FCDBE0"
        rx={7.102}
        transform="scale(-1 1) rotate(-15 85.33 627.66)"
      />
      <path
        fill="#F7A4B2"
        d="m120.341 67.24 5.141 9.421-2.789 10.41-30.082-8.06 20.077-13.624a5.317 5.317 0 0 1 7.653 1.853Z"
      />
      <path
        fill="#FF0560"
        d="m141.016 66.957 8.739 15.963-2.851 10.64-42.921-11.502 29.71-17.153a5.318 5.318 0 0 1 7.323 2.052Z"
      />
      <circle
        cx={4.937}
        cy={4.937}
        r={4.937}
        fill="#FF0560"
        transform="scale(-1 1) rotate(-15 107.794 530.776)"
      />
    </g>
    <rect
      width={46.056}
      height={53.652}
      x={1.225}
      y={-0.707}
      stroke="#fff"
      strokeWidth={2}
      rx={8.102}
      transform="scale(-1 1) rotate(-15 85.329 636.257)"
    />
    <g clipPath="url(#drop_b)">
      <rect
        width={44.056}
        height={51.652}
        x={10}
        y={43.403}
        fill="#FCDBE0"
        rx={7.102}
        transform="rotate(-15 10 43.403)"
      />
      <path
        fill="#F7A4B2"
        d="m49.202 67.17-5.141 9.422 2.79 10.41 30.081-8.06-20.077-13.624a5.317 5.317 0 0 0-7.653 1.853Z"
      />
      <path
        fill="#FF0560"
        d="m28.527 66.887-8.74 15.964L22.64 93.49 65.56 81.989 35.85 64.836a5.317 5.317 0 0 0-7.323 2.051Z"
      />
      <circle cx={41.888} cy={49.407} r={4.937} fill="#FF0560" transform="rotate(-15 41.888 49.407)" />
    </g>
    <rect
      width={46.056}
      height={53.652}
      x={8.775}
      y={42.695}
      stroke="#fff"
      strokeWidth={2}
      rx={8.102}
      transform="rotate(-15 8.775 42.695)"
    />
    <g filter="url(#drop_c)">
      <g clipPath="url(#drop_d)">
        <rect width={61.094} height={71.628} x={52.999} y={13} fill="#FCDBE0" rx={4.213} />
        <path
          fill="#F7A4B2"
          d="M96.977 58.908 86.707 69.682v14.946h43.188l-22.003-25.455c-2.847-3.294-7.913-3.417-10.916-.265Z"
        />
        <path
          fill="#FF0560"
          d="M69.385 51.108 51.95 69.355V84.63h61.622l-33.64-33.64a7.374 7.374 0 0 0-10.546.12Z"
        />
        <circle cx={93.555} cy={32.488} r={6.847} fill="#FF0560" />
      </g>
      <rect
        width={63.201}
        height={73.735}
        x={51.946}
        y={11.947}
        stroke="#fff"
        strokeWidth={2.107}
        rx={5.267}
      />
    </g>
    <defs>
      <clipPath id="drop_a">
        <rect
          width={44.056}
          height={51.652}
          fill="#fff"
          rx={7.102}
          transform="scale(-1 1) rotate(-15 85.33 627.66)"
        />
      </clipPath>
      <clipPath id="drop_b">
        <rect
          width={44.056}
          height={51.652}
          x={10}
          y={43.403}
          fill="#fff"
          rx={7.102}
          transform="rotate(-15 10 43.403)"
        />
      </clipPath>
      <clipPath id="drop_d">
        <rect width={61.094} height={71.628} x={52.999} y={13} fill="#fff" rx={4.213} />
      </clipPath>
      <filter
        id="drop_c"
        width={85.308}
        height={95.842}
        x={40.892}
        y={9.893}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={9} />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.920833 0 0 0 0 0.713646 0 0 0 0 0.739544 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3534_193509" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_3534_193509" result="shape" />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
