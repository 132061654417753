import * as React from 'react'

const FlipV = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 13.003a1 1 0 0 0 0-2H2a1 1 0 1 0 0 2h20ZM12.862 21h1.517c.864 0 1.179-.82.65-1.505l-2.402-3.167a.777.777 0 0 0-1.27 0l-2.392 3.167C8.45 20.17 8.752 21 9.605 21h3.257Zm0-18h1.517c.864 0 1.179.82.65 1.505l-2.402 3.167a.777.777 0 0 1-1.27 0L8.966 4.505C8.45 3.831 8.752 3 9.605 3h3.257Z"
      fill="#666"
    />
  </svg>
)

export default FlipV
