import { fabric } from 'fabric'

export class StaticShapObject extends fabric.StaticImage {
  static type = 'StaticShap'

  initialize(element, options: StaticShapOptions) {
    //@ts-ignore
    super.initialize(element, options)

    return this
  }
  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  static fromObject(options: StaticShapOptions, callback: Function) {
    fabric.util.loadImage(
      options.src,
      function (img) {
        //   @ts-ignore
        return callback && callback(new fabric.StaticOverlay(img, options))
      },
      null,
      // @ts-ignore
      { crossOrigin: 'anonymous' }
    )
  }
}

fabric.StaticShap = fabric.util.createClass(StaticShapObject, {
  type: StaticShapObject.type
})
fabric.StaticShap.fromObject = StaticShapObject.fromObject

export interface StaticShapOptions extends fabric.IImageOptions {
  id: string
  name?: string
  description?: string
  subtype: string
  src: string
}

declare module 'fabric' {
  namespace fabric {
    class StaticShap extends StaticShapObject {
      constructor(options: StaticShapOptions)
    }
  }
}
