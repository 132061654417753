import { Filter } from '@pixi/core';
import fragment from "./temperature.frag";
import vertex from "../default.vert";

// @ts-ignore
export class TemperatureFilter implements IAdjustmentFilter {
  temperature: number;
  tint: number;

  pixijsFilter: Filter | null

  minimumForKey(key: string): number {
    switch (key){
      case "temperature": return 2000;
      case "tint": return -100;
    }
  }
  maximumForKey(key: string): number {
    switch (key){
      case "temperature": return 11000;
      case "tint": return 100;
    }
  }
  defaultForKey(key: string): number {
    switch (key){
      case "temperature": return 6500.0;
      case "tint": return 0;
    }
  }

  constructor() {
    this.temperature = this.defaultForKey("temperature");
    this.tint = this.defaultForKey("tint");
  }

  buildFilter({temperature}, {tint}) {
    if (this.isValid(temperature)) { this.temperature = temperature; }
    if (this.isValid(tint)) { this.tint = tint; }

    if (!this.pixijsFilter) {
      this.pixijsFilter = TemperatureFilter.generateFilter(this.temperature, this.tint);
    }
    this.pixijsFilter.uniformGroup.uniforms["temperature"] = this.normalize("temperature", this.temperature);
    this.pixijsFilter.uniformGroup.uniforms["tint"] = this.normalize("tint", this.tint);
  }

  isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

  private normalize(key: string, value: number): number {
    // thanks - https://github.com/BradLarson/GPUImage2/blob/3a2275e48716a6ebdab0d4098bd7d90321ef4143/framework/Source/Operations/WhiteBalance.swift
    switch (key) {
      case "temperature":
        return value < 5000.0 ? 0.0004 * (value - 5000.0) : 0.00006 * (value - 5000.0);
      case "tint":
        return value / 100.0;
    }
  }

  static generateFilter(temperature: number, tint: number): Filter {
    let filter = new Filter(
      vertex,
      fragment,
        {
          temperature: temperature,
          tint: tint
        }
    );
    return filter;
  }
}