// @ts-ignore
fabric.Image.filters.FlexBlendImage = fabric.util.createClass(fabric.Image.filters.BaseFilter, {

  sourceWidth: 0, 
  sourceHeight: 0, 

  type: 'FlexBlendImage',

  /**
   * Color to make the blend operation with. default to a reddish color since black or white
   * gives always strong result.
   **/
  image: null,

  /**
   * Blend mode for the filter (one of "multiply", "mask")
   * @type String
   * @default
   **/
  mode: 'mask',

  transparentMask : true,

  shouldFlip: false,
  /**
   * alpha value. represent the strength of the blend image operation.
   * not implemented.
   **/
  alpha: 1,

  vertexSource: 'attribute vec2 aPosition;\n' +
    'varying vec2 vTexCoord;\n' +
    'varying vec2 vTexCoord2;\n' +
    'uniform mat3 uTransformMatrix;\n' +
    'void main() {\n' +
      'vTexCoord = aPosition;\n' +
      'vTexCoord2 = (uTransformMatrix * vec3(aPosition, 1.0)).xy;\n' +
      'gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);\n' +
    '}',

  /**
   * Fragment source for the Multiply program
   */
  fragmentSource: {
    multiply: 'precision highp float;\n' +
      'uniform sampler2D uTexture;\n' +
      'uniform sampler2D uImage;\n' +
      'uniform vec4 uColor;\n' +
      'varying vec2 vTexCoord;\n' +
      'varying vec2 vTexCoord2;\n' +
      'void main() {\n' +
        'vec4 color = texture2D(uTexture, vTexCoord);\n' +
        'vec4 color2 = texture2D(uImage, vTexCoord2);\n' +
        'color.rgba *= color2.rgba;\n' +
        'gl_FragColor = color;\n' +
      '}',
    mask: 'precision highp float;\n' +
      'uniform sampler2D uTexture;\n' +
      'uniform sampler2D uImage;\n' +
      'uniform vec4 uColor;\n' +
      'varying vec2 vTexCoord;\n' +
      'varying vec2 vTexCoord2;\n' +
      'uniform int transparentMask;\n' +
      'uniform int shouldFlip;\n' +
      'void main() {\n' +
        'vec4 color = texture2D(uTexture, vTexCoord);\n' +
        'vec4 color2 = texture2D(uImage, vTexCoord2);\n' +
        'bool isTexCoorOutOfBounds = vTexCoord2.x < 0.0 || vTexCoord2.y < 0.0 || vTexCoord2.x > 1.0 || vTexCoord2.y > 1.0 ? true : false;\n' +
        'float suggestedAlpha = color.a; \n' +
        'if (shouldFlip == 1 && transparentMask == 1) { \n'  +
        'suggestedAlpha = 1.0 - color.a; \n' +
        '}\n' +
        'if (shouldFlip == 0 && transparentMask == 1) { \n'  +
        'suggestedAlpha = color.a; \n' +
        '}\n' +
        'if (shouldFlip == 1 && transparentMask == 0) { \n'  +
        'suggestedAlpha = 1.0 - color2[0]; \n' +
        '}\n' +
        'if (shouldFlip == 0 && transparentMask == 0) { \n'  +
        'suggestedAlpha = color2[0]; \n' +
        '}\n' +
        'color.a = min(suggestedAlpha, color.a);\n' +
        'gl_FragColor = isTexCoorOutOfBounds ? vec4(0.0) : color;\n' +
      '}',
  },

  /**
   * Retrieves the cached shader.
   * @param {Object} options
   * @param {WebGLRenderingContext} options.context The GL context used for rendering.
   * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
   */
  retrieveShader: function(options) {
    var cacheKey = this.type + '_' + this.mode;
    var shaderSource = this.fragmentSource[this.mode];
    if (!options.programCache.hasOwnProperty(cacheKey)) {
      options.programCache[cacheKey] = this.createProgram(options.context, shaderSource);
    }
    return options.programCache[cacheKey];
  },

  applyToWebGL: function(options) {
    // load texture to blend.
    var gl = options.context,
        texture = this.createTexture(options.filterBackend, this.image);

    this.sourceHeight = options.sourceHeight;
    this.sourceWidth = options.sourceWidth;

    this.bindAdditionalTexture(gl, texture, gl.TEXTURE1);
    this.callSuper('applyToWebGL', options);
    this.unbindAdditionalTexture(gl, gl.TEXTURE1);
  },

  createTexture: function(backend, image) {
    return backend.getCachedTexture(image.cacheKey, image._element);
  },

  /**
   * Calculate a transformMatrix to adapt the image to blend over
   * @param {Object} options
   * @param {WebGLRenderingContext} options.context The GL context used for rendering.
   * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
   */
  calculateMatrix: function() {
     var mask = this.image,
        maskWidth = mask._element.width,
        maskHeight = mask._element.height;

      let scaleX = 1;
      let scaleY = 1;
      
      // Aspect Fill
      let aspectRatioImage = this.sourceWidth/this.sourceHeight;
      let aspectRatioMask = maskWidth / maskHeight;
      if (aspectRatioImage > aspectRatioMask) {
        scaleX = aspectRatioImage / aspectRatioMask;
        scaleY = 1;
      }
      else {
        scaleX = 1;
        scaleY = 1 / (aspectRatioImage / aspectRatioMask);
      }

      let offsetX = (1-scaleX) / 2;
      let offsetY = (1-scaleY) / 2;

      // debugger;
      return [
        scaleX, 0, 0,
        0, scaleY, 0,
        offsetX, offsetY, 1
      ];
  },

  /**
   * Apply the Blend operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d: function(options) {
    var imageData = options.imageData,
        resources = options.filterBackend.resources,
        data = imageData.data, iLen = data.length,
        width = imageData.width,
        height = imageData.height,
        tr, tg, tb, ta,
        r, g, b, a,
        canvas1, context, image = this.image, blendData;

    if (!resources.blendImage) {
      resources.blendImage = fabric.util.createCanvasElement();
    }
    canvas1 = resources.blendImage;
    context = canvas1.getContext('2d');
    if (canvas1.width !== width || canvas1.height !== height) {
      canvas1.width = width;
      canvas1.height = height;
    }
    else {
      context.clearRect(0, 0, width, height);
    }
    context.setTransform(image.scaleX, 0, 0, image.scaleY, image.left, image.top);
    context.drawImage(image._element, 0, 0, width, height);
    blendData = context.getImageData(0, 0, width, height).data;
    for (var i = 0; i < iLen; i += 4) {

      r = data[i];
      g = data[i + 1];
      b = data[i + 2];
      a = data[i + 3];

      tr = blendData[i];
      tg = blendData[i + 1];
      tb = blendData[i + 2];
      ta = blendData[i + 3];

      switch (this.mode) {
        case 'multiply':
          data[i] = r * tr / 255;
          data[i + 1] = g * tg / 255;
          data[i + 2] = b * tb / 255;
          data[i + 3] = a * ta / 255;
          break;
        case 'mask':
          data[i + 3] = ta;
          break;
      }
    }
  },

  /**
   * Return WebGL uniform locations for this filter's shader.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {WebGLShaderProgram} program This filter's compiled shader program.
   */
  getUniformLocations: function(gl, program) {
    return {
      uTransformMatrix: gl.getUniformLocation(program, 'uTransformMatrix'),
      uImage: gl.getUniformLocation(program, 'uImage'),
      transparentMask: gl.getUniformLocation(program, 'transparentMask'),
      shouldFlip: gl.getUniformLocation(program, 'shouldFlip'),
    };
  },

  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData: function(gl, uniformLocations) {
    var matrix = this.calculateMatrix();
    gl.uniform1i(uniformLocations.uImage, 1); // texture unit 1.
    gl.uniformMatrix3fv(uniformLocations.uTransformMatrix, false, matrix);
    gl.uniform1i(uniformLocations.transparentMask, this.transperentMask);
    gl.uniform1i(uniformLocations.shouldFlip, this.shouldFlip);
  },

  /**
   * Returns object representation of an instance
   * @return {Object} Object representation of an instance
   */
  toObject: function() {
    return {
      type: this.type,
      image: this.image && this.image.toObject(),
      mode: this.mode,
      alpha: this.alpha,
      transparentMask: this.transparentMask,
      shouldFlip: this.shouldFlip
    };
  }
});
// @ts-ignore
// fabric.Image.filters.FlexBlendImage.fromObject = function(object, callback) {
//   fabric.Image.fromObject(object.image, function(image) {
//     var options = fabric.util.object.clone(object);
//     options.image = image;
//     callback(new fabric.Image.filters.BlendImage(options));
//   });
// };
// @ts-ignore
fabric.Image.filters.FlexBlendImage.fromObject = fabric.Image.filters.BaseFilter.fromObject;