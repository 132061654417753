import { Filter } from '@pixi/core';
import { Size } from '@scenes/engine/objects/media-repository/size'
import fragment from "./sharpen.frag";
import vertex from "./sharpen.vert";
import { IAdjustmentFilter } from '@scenes/engine/utils/PixijsFilters/IAdjustmentFilter'

// thanks - https://github.com/BradLarson/GPUImage2/blob/3a2275e48716a6ebdab0d4098bd7d90321ef4143/framework/Source/Operations/Sharpen.swift#L2
export class SharpenFilter implements IAdjustmentFilter {
  imageWidth: number;
  imageHeight: number;
  sharpness: number;
  pixijsFilter: Filter | null

  minimumForKey(key: string): number {
    switch (key){
      case "sharpness": return -0.25;
    }
  }
  maximumForKey(key: string): number {
    switch (key){
      case "sharpness": return 1.0;
    }
  }
  defaultForKey(key: string): number {
    switch (key){
      case "sharpness": return 0;
    }
  }

  constructor() {
    this.imageWidth = 0;
    this.imageHeight = 0;
    this.sharpness = this.defaultForKey("sharpness");
  }

  buildFilter({imageHeight}, {imageWidth}, {sharpness}) {
    if (this.isValid(sharpness)) { this.sharpness = sharpness; }
    if (this.isValid(imageWidth)) { this.imageWidth = imageWidth; }
    if (this.isValid(imageHeight)) { this.imageHeight = imageHeight; }

    if (!this.pixijsFilter) {
      this.pixijsFilter = SharpenFilter.generateFilter(this.imageWidth, this.imageHeight, this.sharpness);
    }
    this.pixijsFilter.uniformGroup.uniforms["sharpness"] = this.normalize("sharpness", this.sharpness);
    this.pixijsFilter.uniformGroup.uniforms["imageWidthFactor"] = 1 / this.imageWidth;
    this.pixijsFilter.uniformGroup.uniforms["imageHeightFactor"] = 1 / this.imageHeight;
  }

  private normalize(key: string, value: number): number {
    let minBzrtValue = this.minimumForKey(key);
    let maxBzrtValue = this.maximumForKey(key);
    let minFilterValue = -0.25;
    let maxFilterValue = 1.0;

    let normalizedBzrtValue = (value - minBzrtValue) / Math.abs(maxBzrtValue - minBzrtValue);
    let normalizedFilterValue = normalizedBzrtValue * (maxFilterValue - minFilterValue) + minFilterValue;
    return normalizedFilterValue;
  }

  isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

  static generateFilter(texelWidth: number, texelHeight: number, sharpness: number): Filter {
    let filter = new Filter(
      vertex,
      fragment,
        {
          imageWidthFactor: 1 / texelWidth,
          imageHeightFactor: 1 / texelHeight,
          sharpness: sharpness
        }
    );
    return filter;
  }
}