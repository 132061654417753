import { fabric } from 'fabric'
import BaseHandler from './BaseHandler'

class ScrollbarHandler extends BaseHandler {
  private canvasHeight: number
  private canvasWidth: number
  private frame
  private container: HTMLElement
  private scrollBarX: HTMLDivElement
  private scrollBarY: HTMLDivElement
  private isOnClickScrollbarX = false
  private isOnClickScrollbarY = false

  private startX = 0
  private startY = 0

  public scaledVerticalPadding = 0
  public scaledHorizontalPadding = 0

  public scrollHeight = 0
  public scrollWidth = 0
  public PADDING_OVERLAP = 10 //add padding to avoid 2 scrollbar overlap

  // previewporttransform before enter erase state
  preVptBeforeErase

  private baseScrollBarStyle = {
    position: 'absolute',
    background: '#7D7D7D',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'none',
    zIndex: 99,
  }

  constructor(props) {
    super(props)
    this.initialize()
  }

  initialize = () => {
    this.canvasHeight = this.canvas.getHeight()
    this.canvasWidth = this.canvas.getWidth()
    this.frame = this.root.frameHandler.get()
    this.container = document.getElementById('uibox-editor-container')

    this.initializeScrollBarElement()
    this.setConfigFrame()
    this.registerEventListener()
  }

  destroy = () => {
    this.canvas.off('mouse:wheel', this.onMouseWheel)
    this.scrollBarX.removeEventListener('mousedown', this.handleScrollbarXMouseDown)
    this.scrollBarY.removeEventListener('mousedown', this.handleScrollbarYMouseDown)
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUp)
  }

  public handleFrameSizeChange() {
    this.setConfigFrame()
    this.updateScrollPosition()
  }

  private setConfigFrame() {
    this.frame = this.root.frameHandler.get()
    this.canvasHeight = this.canvas.getHeight()
    this.canvasWidth = this.canvas.getWidth()
  }

  public updateScrollPosition(fromPixelManipulation = false) {
    const zoomRatio = this.canvas.getZoom();
    const fitRatio = this.root.frameHandler.getFitRatio()
    if(zoomRatio === fitRatio) {
      this.root.zoomHandler.zoomToFit()
    }
    this.checkScrollBarVisibility(fromPixelManipulation)
    this.updateScrollSize()
    this.updateScrollbarSize()
    this.updateScrollbarPosition()
  }

  private updateScrollSize() {
    const zoomRatio = this.canvas.getZoom();
    const fitRatio = this.root.frameHandler.getFitRatio()
    
    const scrollWidth = this.canvas.width * zoomRatio / fitRatio
    const adjustedScrollWidth = this.getMaxScrollLeft() - this.getMaxScrollRight() + window.innerWidth

    this.scrollWidth = scrollWidth > adjustedScrollWidth ? adjustedScrollWidth : scrollWidth
    this.scrollHeight = this.canvas.height * zoomRatio / fitRatio
  }

  private updateScrollbarSize() {
    const scrollbarHeight = (this.canvasHeight / this.scrollHeight) * this.canvasHeight;
    const scrollbarWidth = (this.canvasWidth / this.scrollWidth) * this.canvasWidth;
    this.scrollBarX.style.width = `${scrollbarWidth}px`;
    this.scrollBarY.style.height = `${scrollbarHeight}px`;
  }

  private updateScrollbarPosition() {
    // const vpt = this.canvas.viewportTransform
    // const initialVptY = this.root.zoomHandler.initialViewportY
    // const initialVptX = this.root.zoomHandler.initialViewportX
    // const scaleFactor = this.canvas.getZoom() / this.root.frameHandler.getFitRatio();

    // const normalizedScrollY = vpt[5] - initialVptY * scaleFactor // Normalize current scroll position
    // const normalizedScrollX = vpt[4] - initialVptX * scaleFactor

    // let scrollBarTop = (-normalizedScrollY / (this.scrollHeight - (this.canvasHeight - this.PADDING_OVERLAP ))) * ((this.canvasHeight - this.PADDING_OVERLAP ) - this.scrollBarY.offsetHeight)
    // scrollBarTop = Math.max(scrollBarTop, 0)

    // let scrollBarLeft = ((-normalizedScrollX / (this.scrollWidth - (this.canvasWidth - this.PADDING_OVERLAP))) * ((this.canvasWidth - this.PADDING_OVERLAP) - this.scrollBarX.offsetWidth))
    // scrollBarLeft = Math.max(Math.min(scrollBarLeft, this.canvasWidth - this.scrollBarX.offsetWidth), 0)

    const {x, y} = this.getScrollBarPosition()
    this.scrollBarY.style.top = `${y}px`;
    this.scrollBarX.style.left = `${x}px`;
  }

  public getScrollBarPosition = () => {
    const vpt = this.canvas.viewportTransform
    const initialVptY = this.root.zoomHandler.initialViewportY
    const initialVptX = this.root.zoomHandler.initialViewportX
    const scaleFactor = this.canvas.getZoom() / this.root.frameHandler.getFitRatio();

    const normalizedScrollY = vpt[5] - initialVptY * scaleFactor // Normalize current scroll position
    const normalizedScrollX = vpt[4] - (initialVptX * scaleFactor > this.getMaxScrollLeft() ? this.getMaxScrollLeft() : initialVptX * scaleFactor)

    let scrollBarTop = (-normalizedScrollY / (this.scrollHeight - (this.canvasHeight - this.PADDING_OVERLAP ))) * ((this.canvasHeight - this.PADDING_OVERLAP ) - this.scrollBarY.offsetHeight)
    scrollBarTop = Math.max(scrollBarTop, 0)
    
    let scrollBarLeft = ((-normalizedScrollX / (this.scrollWidth - (this.canvasWidth - this.PADDING_OVERLAP))) * ((this.canvasWidth - this.PADDING_OVERLAP) - this.scrollBarX.offsetWidth))
    scrollBarLeft = Math.max(Math.min(scrollBarLeft, this.canvasWidth - this.scrollBarX.offsetWidth), 0)

    return {x: scrollBarLeft, y: scrollBarTop}
  }

  private initializeScrollBarElement() {
    // Scroll X 
    const scrollBarXContainer = document.createElement('div')
    scrollBarXContainer.setAttribute('id', 'scrollbarX-container')

    const scrollBarXTrack = document.createElement('div')
    scrollBarXTrack.setAttribute('id', 'scrollbarX-track')
    scrollBarXContainer.appendChild(scrollBarXTrack)

    this.scrollBarX = document.createElement('div')
    this.scrollBarX.setAttribute('id', 'scrollbarX')
    scrollBarXTrack.appendChild(this.scrollBarX)

    // Scroll Y
    const scrollBarYContainer = document.createElement('div')
    scrollBarYContainer.setAttribute('id', 'scrollbarY-container')

    const scrollBarYTrack = document.createElement('div')
    scrollBarYTrack.setAttribute('id', 'scrollbarY-track')
    scrollBarYContainer.appendChild(scrollBarYTrack)

    this.scrollBarY = document.createElement('div')
    this.scrollBarY.setAttribute('id', 'scrollbarY')
    scrollBarYTrack.appendChild(this.scrollBarY)

    // Append to container
    this.container.appendChild(scrollBarXContainer)
    this.container.appendChild(scrollBarYContainer)
  }

  private registerEventListener() {
    this.canvas.on('mouse:wheel', this.onMouseWheel)
    this.scrollBarX.addEventListener('mousedown', this.handleScrollbarXMouseDown)
    this.scrollBarY.addEventListener('mousedown', this.handleScrollbarYMouseDown)
  }

  onMouseWheel = event => {
    const zoomRatio = this.canvas.getZoom();
    if(zoomRatio <= this.root.frameHandler.getFitRatio()) {
      return
    }
    this.processScroll(event)
    this.root.frameHandler.updateLayersCoords()
    this.updateScrollbarPosition()
  }

  processScroll = event => {
    const isCtrlKey = event.e.ctrlKey
    if (isCtrlKey) {
      return
    }
    const isShiftKey = event.e.shiftKey
    if(isShiftKey || event.e.deltaX !== 0) {
      event.e.preventDefault();
      this.handleScrollX(event.e.deltaX !== 0 ? event.e.deltaX : event.e.deltaY)
    } else {
      this.handleScrollY(event.e.deltaY)
    }
  }

  handleScrollX = (delta: number) => {
    if(!this.isScrollXVisible()) { return }
    const maxScrollLeft = this.getMaxScrollLeft()
    const maxScrollRight = this.getMaxScrollRight()
    // scroll left
    if (delta < 0) {

      if (this.canvas.viewportTransform[4] - delta > maxScrollLeft) {
        delta = this.canvas.viewportTransform[4] - maxScrollLeft
      }
    }
    // scroll right
    if (delta > 0) {
      if (this.canvas.viewportTransform[4] - delta < maxScrollRight) {
        delta = this.canvas.viewportTransform[4] - maxScrollRight
      }
    }
    this.canvas.relativePan(new fabric.Point(-delta, 0))
  }

  handleScrollY = (delta: number) => {
    const maxScrollTop = this.getMaxScrollTop()
    
    // scroll up
    if (delta < 0) {
      if (this.canvas.viewportTransform[5] - delta > maxScrollTop) {
        delta = this.canvas.viewportTransform[5] - maxScrollTop;
      }
    }
    // scroll down
    if (delta > 0) {
      const maxScrollDown = maxScrollTop - (this.scrollHeight - this.canvasHeight)
      if (this.canvas.viewportTransform[5] - delta < maxScrollDown) {
        delta = this.canvas.viewportTransform[5] - maxScrollDown
      }
    }
    this.canvas.relativePan(new fabric.Point(0, -delta))
  }

  public isScrollXVisible(ignorePixelManipulation = false): boolean {
    return this.canvasWidth - (this.root.frameHandler.FRAME_PADDING_ADD_MENU + this.root.frameHandler.FRAME_PADDING_INSPECTOR) < this.frame.width * this.canvas.getZoom() && (ignorePixelManipulation || !this.root.canvasHandler.isOpenPixelManipulationObject)
  }

  public isScrollYVisible(ignorePixelManipulation = false): boolean {
    const zoomRatio = this.canvas.getZoom();
    return zoomRatio > this.root.frameHandler.getFitRatio() && (ignorePixelManipulation || !this.root.canvasHandler.isOpenPixelManipulationObject)
  }

  public getMaxScrollTop = () => {
    const initialVptY = this.root.zoomHandler.initialViewportY
    const scaleFactor = this.canvas.getZoom() / this.root.frameHandler.getFitRatio();
    return initialVptY * scaleFactor
  }

  public getMaxScrollLeft = () => {
    const frame = this.root.frameHandler.get()
    const vpt = this.canvas.viewportTransform
    const frameViewportLeft = -frame.left * vpt[0]
    return frameViewportLeft + ((window.innerWidth + this.root.frameHandler.FRAME_PADDING_ADD_MENU - this.root.frameHandler.FRAME_PADDING_INSPECTOR) / 2)
  }
  
  public getMaxScrollRight = () => { 
    const frame = this.root.frameHandler.get()
    const vpt = this.canvas.viewportTransform
    const frameViewportLeft = -frame.left * vpt[0]
    return frameViewportLeft - frame.width * vpt[0] + ((window.innerWidth + this.root.frameHandler.FRAME_PADDING_ADD_MENU - this.root.frameHandler.FRAME_PADDING_INSPECTOR) / 2)
  }

  public checkScrollBarVisibility = (fromPixelManipulation = false) => { 
    this.scrollBarY.style.display = this.isScrollYVisible(fromPixelManipulation) ? 'block' : 'none'
    this.scrollBarX.style.display = this.isScrollXVisible(fromPixelManipulation) ? 'block' : 'none'
    const scrollbarYContainer = document.getElementById('scrollbarY-container')
    const scrollbarXContainer = document.getElementById('scrollbarX-container')

    this.isScrollYVisible(fromPixelManipulation) ? scrollbarYContainer?.classList.add('scrollbar-visible') : scrollbarYContainer?.classList.remove('scrollbar-visible')
    this.isScrollXVisible(fromPixelManipulation) ? scrollbarXContainer?.classList.add('scrollbar-visible') : scrollbarXContainer?.classList.remove('scrollbar-visible')
  }
  // Event handlers
  handleScrollbarXMouseDown = e => {
    document.addEventListener('mousemove', this.handleMouseMove)
    e.stopPropagation()
    e.preventDefault()
    this.isOnClickScrollbarX = true
    this.isOnClickScrollbarY = false
    this.startX = e.clientX // For horizontal scrollbar
    this.startY = e.clientY // For vertical scrollbar
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  handleScrollbarYMouseDown = e => {
    document.addEventListener('mousemove', this.handleMouseMove)
    e.stopPropagation()
    e.preventDefault()

    this.isOnClickScrollbarX = false
    this.isOnClickScrollbarY = true
    this.startX = e.clientX // For horizontal scrollbar
    this.startY = e.clientY // For vertical scrollbar
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseUp = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.isOnClickScrollbarX = false
    this.isOnClickScrollbarY = false
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mousemove', this.handleMouseMove)
  }

  handleMouseMove = e => {
    e.stopPropagation()
    e.preventDefault()
    
    let delta
    if (this.isOnClickScrollbarX) {
      const scrollbarXTrack = document.getElementById('scrollbarX-track')
      scrollbarXTrack.style.backgroundColor = '#dddddd'
      delta = e.clientX - this.startX
      const step = 1.5
      const currentZoom = this.canvas.getZoom()
      delta = delta * step * currentZoom
      this.startX = e.clientX
      this.handleScrollX(delta)
      this.root.frameHandler.updateLayersCoords()
      this.updateScrollPosition()
    }

    if (this.isOnClickScrollbarY) {
      const scrollbarYTrack = document.getElementById('scrollbarY-track')
      scrollbarYTrack.style.backgroundColor = '#dddddd'
      const currentZoom = this.canvas.getZoom()
      const step = 1.5
      delta = e.clientY - this.startY
      delta = delta * step * currentZoom
      this.startY = e.clientY
      this.handleScrollY(delta)
      this.root.frameHandler.updateLayersCoords()
      this.updateScrollPosition()
    }
  }

  public hideScrollbar = () => { 
    this.scrollBarX.style.display = 'none'
    this.scrollBarY.style.display = 'none'
    const scrollbarYContainer = document.getElementById('scrollbarY-container')
    const scrollbarXContainer = document.getElementById('scrollbarX-container')
    scrollbarYContainer.classList.remove('scrollbar-visible')
    scrollbarXContainer.classList.remove('scrollbar-visible')
  }
  
  public handleAfterScroll = (fromPixelManipulation = false) => {
    this.root.frameHandler.updateLayersCoords()
    this.updateScrollPosition(fromPixelManipulation)
  }
  
  setElementStyle = (element, styles) => {
    Object.keys(styles).forEach(style => {
      element.style[style] = styles[style]
    })
  }
}

export default ScrollbarHandler