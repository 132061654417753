import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M13 4h6a1 1 0 0 1 1 1v4h-7V4Zm0 7h7v8a1 1 0 0 1-1 1h-6v-9Zm-2 2V4H5a1 1 0 0 0-1 1v8h7Zm-7 2v4a1 1 0 0 0 1 1h6v-5H4ZM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
