import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import Download from '../Icons/Download'
import { useParams } from 'react-router'
import { KIND } from 'baseui/button'
import { selectTemplateConfig, selectBackgroundActive } from '@/store/slices/templates/selectors'
import { useSelector } from 'react-redux'
import useAppContext from '@/hooks/useAppContext'
import { selectUser, selectUserIsPremium } from '@/store/slices/user/selectors'
import store, { useAppDispatch } from '@/store/store'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
import { StatefulPopover } from 'baseui/popover'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import HeadingInspector from '@/components/HeadingInspector'
import Icons from '../Icons'
import { lightTheme } from '@/customTheme'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import Loading from '@/components/Loading'
import { useLocation } from 'react-router-dom'
import { SHOW_DOWNLOAD_APP_TIME_DURATION, ShowModalType } from '@/scenes/engine/common/constants'
import { displayConfetti } from '@/utils/confettiAnimation'

function ExportImage() {
  const [isOpen, setIsOpen] = useState(false)
  const editor = useEditorContext().editor
  const [previewImage, setPreviewImage] = useState<any>(null)
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(false)
  const { id } = useParams<{ id: string }>()
  const location = useLocation();


  const hasPremium = useSelector(selectUserIsPremium)

  const templateConfig = useSelector(selectTemplateConfig)
  const backgroundActive = useSelector(selectBackgroundActive)

  const { isOpenPixelManipulationObject, setShowModalType } = useAppContext()
  const user = useSelector(selectUser)
  const dispatch = useAppDispatch()
  const [isOpenPopover, setIsOpenPopover] = useState(false)

  useEffect(() => {
    if (isOpen && editor) {
      console.log('TODO: PreviewTemplate exportToJSON')
      // TODO : exportToJSON
      // const template = editor.exportToJSON()
      // const keys = template.objects.map(object => {
      //   return object.metadata && object.metadata.keys ? object.metadata.keys : []
      // })

      // const params: Record<string, string> = {}
      // const uniqElements = uniq(flatten(keys))
      // uniqElements.forEach(key => {
      //   params[key] = ''
      // })

      // setOptions(params)
      // if (uniqElements.length === 0) {
      //   handleBuildImage()
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, editor])

  const cropCanvas = (image, newX, newY, newWidth, newHeight) => {
    var croppedImage = document.createElement('canvas')
    const ctx = croppedImage.getContext('2d')

    //set the canvas size to the new width and height
    croppedImage.width = newWidth
    croppedImage.height = newHeight

    //draw the image
    ctx.drawImage(image, newX, newY, newWidth, newHeight, 0, 0, newWidth, newHeight)
    return croppedImage
  }

  const handleBuildImage = async () => {
    // TODO: make export by this flow:
    const image_png = await editor.exportPNG()
    return image_png

    // const frame = editor.handlers.frameHandler.get()

    // // let frameRect = frame.getBoundingRect()

    // const c = document.getElementById('canvas')

    // let guid = nanoid()

    // //frame.width * 0.1 / Watermark.
    // let watermarkUrl = new URL(Watermark)
    // await MediaImageRepository.getInstance().storeImageUrl(guid, MediaImageType.latest, watermarkUrl);

    // const watermarkOption = {
    //   id: guid,
    //   type: ObjectType.BAZAART_STICKER,
    //   centerPoint: {
    //     x: 0.88,
    //     y: 0.97,
    //   },
    //   sizeOnCanvas: {
    //     width: 0.2,
    //   },
    //   transformation: {
    //     horizontalFlip: false,
    //     verticalFlip: false,
    //   },
    //   boundingBox : {y: 0, width: 1, height: 1, x: 0},
    //   absoluteRotation: 0,
    //   bazaartGuid: guid,
    // }
    // await editor.handlers.objectsHandler.add(watermarkOption)

    // // editor.handlers.objectsHandler.scale("fit")
    // // editor.handlers.objectsHandler.update({
    // //   scaleX:0.1,
    // //   scaleY:0.1,
    // // })
    // // editor.handlers.objectsHandler.alignBottom()
    // // editor.handlers.objectsHandler.alignRight()
    // var cloneZoomRatio = zoomRatio

    // editor.handlers.zoomHandler.zoomToFit()
    // let frameRect = frame.getBoundingRect()

    // editor.handlers.objectsHandler.deselect()

    // // we need to wait zoom will be fit
    // await new Promise(r => setTimeout(r, 500))

    // Object.keys(frameRect).map(function (key, index) {
    //   frameRect[key] *= window.devicePixelRatio
    // })

    // let coppedCanvas = cropCanvas(c, frameRect.left, frameRect.top, frameRect.width, frameRect.height)
    // let image = coppedCanvas.toDataURL('image/png')

    // editor.handlers.objectsHandler.removeById(watermarkOption.id)
    // editor.handlers.zoomHandler.zoomToRatio(cloneZoomRatio)

    // return image
  }

  const GAReportDownload = async () => {
    let template_id = templateConfig?.templateId
      ? templateConfig?.templateId
      : Number(id) !== 6764
        ? Number(id)
        : undefined

    const eventProperties = {
      'Number of Layers': editor.handlers.objectsHandler.getLayers().length,
      'Watermark': !store.getState().editor.user.userIsPremium,
      'Template': !!template_id,
      'Template ID': templateConfig?.templateId ? templateConfig?.templateId : template_id,
      'Template Category': templateConfig?.templateCategory ?? 'none',
      // @ts-ignore
      'layer_type_stock': editor.handlers.objectsHandler.getLayers().some(x => x.isStockImage),
      // @ts-ignore
      'layer_type_magic_background':editor.handlers.objectsHandler.getLayers().some(x=>x.isMagicBackgroundLayer),
      // @ts-ignore
      'finger': editor.handlers.objectsHandler.getLayers().some(x => x.finger) ? 1 : 0,
    }
    if (backgroundActive) {
      eventProperties['Background ID'] = backgroundActive.id
      eventProperties['Background Category'] = backgroundActive.category
    }
    customAmplitude('Image Shared', eventProperties)

    // @ts-ignore
    window.dataLayer.push({ event: 'download_click', ...eventProperties })
  }

  const createAndDownloadImage = async () => {
    GAReportDownload()
    let image = await handleBuildImage()

    const a = document.createElement('a')
    a.href = image
    a.download = 'Bazaart.png'
    a.click()
  }

  const { t } = useTranslation()
  const createAndSetPreviewImage = async () => {
    setIsLoadingPreviewImage(true)
    let image = await handleBuildImage()
    setPreviewImage(image)
    setIsLoadingPreviewImage(false)
  }

  const downloadFromPreviewModal = () => {
    GAReportDownload()
    const a = document.createElement('a')
    a.href = previewImage
    a.download = 'Bazaart.png'
    a.click()
  }

  if (location.pathname.includes('create')) {
    return null
  }

  const handleDisplayConfentti = () => {
    const isDisplayedModalDownloadApp = localStorage.getItem('isDisplayedModalDownloadApp')
    if(isDisplayedModalDownloadApp) {
      return
    }
    customAmplitude('Get App Modal', { Source: '1st time' })
    displayConfetti()
    setTimeout(() => {
      setShowModalType(ShowModalType.DOWNLOAD_APP)
    }, SHOW_DOWNLOAD_APP_TIME_DURATION)
  }
  return (
    <StatefulPopover
      content={({ close }) => (
        <div
          style={{
            width: '450px',
            height: '565px',
            background: '#fff',
            borderRadius: '16px',
          }}
        >
          <HeadingInspector
            title={t("Download")}
            hasNavigation={false}
            hasClose={true}
            handleClose={close}
            style={{ height: '72px', paddingTop: '24px', paddingBottom: '16px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '305px', padding: '16px', background: 'rgba(249, 249, 249, 1)' }}>
            {
              isLoadingPreviewImage ? <Loading /> : <img
                width="100%"
                height="100%"
                style={{ objectFit: 'contain' }}
                alt=""
                src={previewImage}
              />
            }
          </div>
          <div style={{ padding: '12px 24px 24px' }}>
            <p
              style={{
                ...lightTheme.typography.Small11medium,
                color: '#666666',
                margin: '0 0 24px 0',
                textAlign: 'center',
              }}
            >
              {editor.handlers.frameHandler.get()?.width} X {editor.handlers.frameHandler.get()?.height} px
            </p>
            <ButtonCustom type={SizeButton.LARGE} kind={KIND.primary} startEnhancer={<Icons.PremiumIcon />} onClick={() => {
              dispatch(setOpenModalTryPremium({
                isOpen: true,
                source: 'BtSubscriptionWatermark',
                callback: () => createAndDownloadImage()
              }))
              const eventProperties = {
                Source: 'BtSubscriptionWatermark',
              }
              customAmplitude('Premium Prompt', eventProperties)
            }}>
              {t('Remove watermark')}
            </ButtonCustom>
            <ButtonCustom type={SizeButton.LARGE}
              kind={KIND.secondary}
              style={{ marginTop: '16px' }}
              startEnhancer={<Download size={24} fill='#666' />}
              onClick={() => {
                handleDisplayConfentti()
                downloadFromPreviewModal()
                close()
              }}>
              {t('Download')}
            </ButtonCustom>
          </div>
        </div>
      )}
      triggerType="click"
      returnFocus={false}
      autoFocus={false}
      placement="bottomRight"
      popoverMargin={4}
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            zIndex: 1000,
          }),
        },
      }}
      stateReducer={(a, b, c) => {
        setIsOpenPopover(b.isOpen)
        return b
      }}
    >
      <div>
        <ButtonCustom
          type={SizeButton.STANDARD}
          kind={KIND.primary}
          disabled={isOpenPixelManipulationObject}
          onClick={(e) => {
            if (hasPremium) {
              handleDisplayConfentti()
              e.preventDefault()
              e.stopPropagation()
              createAndDownloadImage()
            } else {
              createAndSetPreviewImage()
            }
          }}
          style={{ display: 'flex', width: 'fit-content', gap: '6px', background: isOpenPixelManipulationObject ? lightTheme.colors.grayScale50 : isOpenPopover ? `${lightTheme.colors.primary100} !important` : 'auto' }}
        >
          <Download size={24} fill={isOpenPixelManipulationObject ? lightTheme.colors.grayScale200 : lightTheme.colors.white} />
          <span
            style={{
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.006em',
              color: isOpenPixelManipulationObject ? '#cccccc' : lightTheme.colors.white,
            }}
          >
            {t('Download')}
          </span>
        </ButtonCustom>
      </div>
    </StatefulPopover>
  )
}

export default ExportImage
