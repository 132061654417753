import throttle from 'lodash/throttle'
import { ObjectType } from '@/scenes/engine/common/constants'
import RemoveBaseHandler from './RemoveBaseHandler'
import * as _ from 'lodash'

class RemoveTransactionHandler extends RemoveBaseHandler {
  redos = []
  undos = []
  active = false

  save = () => {
    const canvasJSON = _.cloneDeep(this.canvas.toJSON(this.root.propertiesToInclude))
    canvasJSON.objects = canvasJSON.objects.filter(obj => obj.type !== 'path')
    // @ts-ignore
    this.undos.push({
      canvasJSON,
    })
    this.redos = []
    let value = {
      hasUndo: this.undos.length > 0,
      hasRedo: this.redos.length > 0,
    }
    this.editorEventManager.emit('remove-history:changed', value)
  }

  clear = () => {
    this.redos = []
    this.undos = []
  }

  undo = throttle(() => {
    const undo = this.undos.pop()
    if (!undo) {
      return
    }
    const canvasJSON =  _.cloneDeep(this.canvas.toJSON(this.root.propertiesToInclude))
    this.replay(undo)
    if(this.root.canvasRemoveHandler.toolType === 'Remove') {
      const objects = undo.canvasJSON.objects
      const layerIndexes = this.getIndexImageLayers(objects)
      const [index1, index2] = layerIndexes;
      [objects[index1], objects[index2]] = [objects[index2], objects[index1]];
    }

    this.redos.push(
      this.root.canvasRemoveHandler.toolType === 'Remove' ? undo : {canvasJSON}
    )
  }, 100)

  redo = throttle(() => {
    const redo = this.redos.pop()
    if (!redo) {
      return
    }

    this.replay(redo)
    if(this.root.canvasRemoveHandler.toolType === 'Remove') {
      const objects = redo.canvasJSON.objects
      const layerIndexes = this.getIndexImageLayers(objects)
      const [index1, index2] = layerIndexes;
      [objects[index1], objects[index2]] = [objects[index2], objects[index1]];
    }
    const canvasJSON = this.root.canvasRemoveHandler.exportToCanvasJSON()

    this.undos.push(
      this.root.canvasRemoveHandler.toolType === 'Remove' ? redo : {canvasJSON}
    )

  }, 100)

  replay = async transaction => {
    const canvas = transaction.canvasJSON
    this.active = true
    this.canvas.loadFromJSON(canvas, async () => {
      if(this.root.canvasRemoveHandler.toolType === 'Remove') {
        const allObjects = this.canvas.getObjects()
        const layerIndexes = this.getIndexImageLayers(allObjects)
        const lowerLayer = allObjects[layerIndexes[0]]
        const upperLayer = allObjects[layerIndexes[1]]
        // @ts-ignore
        this.root.pixelManipulationObjectHandler.animateFade(upperLayer, layerIndexes[0], lowerLayer.getElement().src, false)
      } else if(this.root.canvasRemoveHandler.toolType === 'Eraser') {
        let brush = this.canvas.freeDrawingBrush as any
        await brush.update(canvas.objects[0].src)
      }
      this.historyChanged()
    })
    this.active = false
  }

  getAll = () => {
    return {
      undos: this.undos,
      redos: this.redos,
    }
  }

  getIndexImageLayers = objects => {
    return objects.reduce((acc, obj, index) => {
      if (obj.type === ObjectType.STATIC_IMAGE) {
        acc.push(index)
      }
      return acc
    }, [])
  }

  historyChanged = () => {
    let value = {
      hasUndo: this.undos.length > 0,
      hasRedo: this.redos.length > 0,
    }
    this.editorEventManager.emit('remove-history:changed', value)
  }
}

export default RemoveTransactionHandler
