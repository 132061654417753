import { lightTheme } from "@/customTheme"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? props.size : 24}
    height={props.size ? props.size : 24}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="M8.923 7.032C9.889 5.158 11.92 4 14 4c1.481 0 3.004.536 4.155 1.726 1.07 1.105 1.745 2.7 1.84 4.757C21.248 11.524 22 13.19 22 15.063c0 2.77-2.449 4.939-5 4.939H7c-2.567 0-5-2.256-5-5a4.942 4.942 0 0 1 2.231-4.15c.3-.897.79-1.797 1.575-2.509.791-.716 1.824-1.188 3.117-1.311ZM14 6c-1.535 0-2.976.96-3.468 2.337l-.237.66L9.593 9c-1.165.004-1.925.355-2.444.826-.538.486-.896 1.173-1.111 1.96l-.118.43-.4.2c-.703.35-1.52 1.278-1.52 2.585 0 1.597 1.495 3.001 3 3.001h4v-3.588l-1.293 1.293-1.414-1.414L12 10.586l3.707 3.707-1.414 1.414L13 14.414v3.588h4c1.53 0 3-1.357 3-2.938 0-1.503-.663-2.657-1.543-3.236l-.45-.296v-.54c0-1.895-.564-3.125-1.29-3.876C15.986 6.36 15.004 6 14 6Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
