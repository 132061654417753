import { BlendBrushObject, Brush } from '@scenes/engine/objects/BlendBrush'
import { fabric } from 'fabric'
import {
  BaseTexture,
  ALPHA_MODES,
  Sprite,
  Application,
} from 'pixi.js'

import * as PIXI from 'pixi.js'

import { Texture } from '@pixi/core'
import { PremultiplyFilter } from '@scenes/engine/utils/PixijsFilters/Premultiply/PremultiplyFilter'
import { RestoringMaskFilter } from '@scenes/engine/objects/RestoringMaskFilter'

export enum EraseType {
    Erase,
    Restore
}

export class EraseBrushObject extends BlendBrushObject {
  static type = 'EraseBrush'

  softness: number = 0.5
  opacity: number = 0.5

  private _eraseType: EraseType = EraseType.Erase;
  get eraseType(): EraseType {
    return this._eraseType;
  }

  set eraseType(value: EraseType) {
    this._eraseType = value;
  }

  rotationRadians: number;

  private eraseBufferCanvas: HTMLCanvasElement | null
  private restoreBufferCanvas: HTMLCanvasElement | null

  private originalMaskTexture: Texture | null;
  private eraseTexture: Texture | null;
  private restoreTexture: Texture | null;
  private accumlatedMaskTexture: Texture | null;

  private premultiplyFilter:  PremultiplyFilter;
  private maskFilter: RestoringMaskFilter
  private container: HTMLElement;

  private imageSprite: Sprite
  private pixiApp: Application

  private eraseSprite: Sprite;
  private restoreSprite: Sprite;
  private originalMask: Sprite;
  private accumlatedMask: Sprite;

  private webglCanvas: HTMLCanvasElement

  initialize(canvas) {
    super.initialize(canvas)
    this.container = document.getElementById('wrap-canvas-remove-zoom-tool')
    this.listenOutsideOfCanvas()
    this.rotationRadians = 0;

    // @ts-ignore
    let canvasElement = this.canvas.lowerCanvasEl
    
    // @ts-ignore
    let upperCanvasElement = this.canvas.upperCanvasEl

    this.webglCanvas = document.createElement('canvas')
    canvasElement.parentNode.insertBefore(this.webglCanvas, upperCanvasElement)

    this.webglCanvas.style.width = "100%"
    this.webglCanvas.style.height = "100%"
    this.webglCanvas.style.position = 'absolute'

    this.eraseBufferCanvas = document.createElement('canvas');
    this.restoreBufferCanvas = document.createElement('canvas');

    this.eraseTexture = PIXI.Texture.from(this.eraseBufferCanvas);
    this.restoreTexture = PIXI.Texture.from(this.restoreBufferCanvas);

    this.accumlatedMaskTexture = PIXI.Texture.from(canvasElement);
    this.accumlatedMask = new Sprite(this.accumlatedMaskTexture)

    // this.maskFilter = new RestoringMaskFilter(this.eraseTexture, this.restoreTexture, this.originalMaskTexture, this.accumlatedMaskTexture);

    this.premultiplyFilter = new PremultiplyFilter();

     // Create a Pixi Application by pointing it to use the existing canvas
    this.pixiApp = new Application({
        view: this.webglCanvas, // Use the existing canvas element
        backgroundAlpha: 0.0,
        antialias: true,
        premultipliedAlpha: true
    });
  }

  dispose() {
    this.container.removeEventListener('mousedown', this.propagateMouseDown)
    this.container.removeEventListener('mouseup', this.propagateMouseUp);

    // @ts-ignore
    let canvasElement = this.canvas.lowerCanvasEl
    canvasElement.style.visibility = 'visible'

    this.webglCanvas.parentNode.removeChild(this.webglCanvas);

    this.disposeSprite(this.originalMask);
    this.disposeSprite(this.accumlatedMask);
    this.disposeSprite(this.eraseSprite);
    this.disposeSprite(this.restoreSprite);
    this.disposeSprite(this.imageSprite);

    this.maskFilter.destroy();
    this.premultiplyFilter.pixijsFilter.destroy();
    this.pixiApp.destroy(true, {
        children: true, // destroy all children
        texture: true, // destroy all textures
        baseTexture: true // destroy all base textures
    });
  }

  disposeSprite(sprite: Sprite){
    if (sprite.parent){
      // @ts-ignore
      sprite.parent.removeChild(sprite);
    }
    sprite.destroy({
      children: true, // Optional: true if you also want to destroy any children of the sprite
      texture: true, // True to destroy the sprite's texture
      baseTexture: true // True to destroy the base texture of the sprite's texture
    });
  }

  isDown: boolean = false
  private propagateMouseDown(event){
      this.propagateEvent(event, 'mousedown', true)
      this.isDown = true;
      return false
  }

  private propagateMouseUp(event){
      this.propagateEvent(event, 'mouseup', false)
      this.isDown = false;
      return false
  }

  private propagateEvent(event, eventName, isDown){
      // Prevent the default action to avoid any side effects (optional)
      event.preventDefault();

      if (this.isDown) {
        return;
      }
      this.isDown = isDown

      let newEvent = new MouseEvent(eventName, {
        bubbles: false, // disable bubbling
        cancelable: true, // Allow it to be cancelable
        clientX: event.clientX, // Include mouse position info if needed
        clientY: event.clientY
      });

      // @ts-ignore
      this.canvas.upperCanvasEl.dispatchEvent(newEvent);
  }

  private listenOutsideOfCanvas() {
    this.container.addEventListener('mousedown', this.propagateMouseDown.bind(this))
    this.container.addEventListener('mouseup', this.propagateMouseUp.bind(this));
  }

  _transform(inputPoint: any) {
    // @ts-ignore
    let htmlDrawingElement = this.canvas.upperCanvasEl

    let point = {
      x: inputPoint.x * this.retinaScalingAdjust(),
      y: inputPoint.y * this.retinaScalingAdjust()
    }

     // Gets the element's position & dimensions adjusted for rotation by calculating bounding box
    const rect = htmlDrawingElement.getBoundingClientRect();
    const rotationRadians = this.rotationRadians

    const elementX = point.x ;
    const elementY = point.y;

    let centerX = htmlDrawingElement.width / 2;
    let centerY = htmlDrawingElement.height / 2;

    let boundingBoxRatioX = htmlDrawingElement.width / rect.width * this.zoomScale
    let boundingBoxRatioY = htmlDrawingElement.height / rect.height * this.zoomScale

    let xRelativeToCenter = (elementX - centerX) / boundingBoxRatioX;
    let yRelativeToCenter = (elementY - centerY) / boundingBoxRatioY;

    // Adjust for rotation
    const cosAngle = Math.cos(-rotationRadians);
    const sinAngle = Math.sin(-rotationRadians);

    let relativeX = xRelativeToCenter * cosAngle - yRelativeToCenter * sinAngle;
    let relativeY = xRelativeToCenter * sinAngle + yRelativeToCenter * cosAngle;

    return {
      x: (centerX + relativeX) * this.maskScale,
      y: (centerY + relativeY) * this.maskScale
    };
  }

  _shouldRecreateBrush(): boolean {
    let brushSize = this._getBrushSize();
    let scaledSoftness = this._normalize(this.softness, 1, 100, 0, 30 / (100 / brushSize) )
    let scaledOpacity = this._normalize(this.opacity, 1, 100, 0, 1 )
    return super._shouldRecreateBrush()|| this.cachedBrush.opacity != scaledOpacity || this.cachedBrush.softness != scaledSoftness;
  }

  _getBrushSize(): number {
    let softnessScaleSize =  this._normalize(this.softness, 1, 100, 1 / this.zoomScale, 2 / this.zoomScale)
    return this.width * softnessScaleSize * this.maskScale
  }

  async _createBrush() {
    let brushSize = this._getBrushSize();

    let scaledSoftness = this._normalize(this.softness, 1, 100, 0, 30 / (100 / brushSize) )
    let scaledOpacity = this._normalize(this.opacity, 1, 100, 0, 1)

    const whiteColor = { r: 255, g: 255, b: 255, a: 255 }
    this.cachedBrush = await this.createBrush(brushSize, whiteColor, scaledSoftness, scaledOpacity, 'black') ;
  }

  convertImageToSprite(image: HTMLImageElement){
    const baseTexture = new BaseTexture(image.src, { alphaMode: ALPHA_MODES.PMA });
    const inputTexture = new Texture(baseTexture);
    let sprite = new Sprite(inputTexture);
    return sprite
  }

  setupCanvasForRestore(originalImage: HTMLImageElement) {
    this.pixiApp.renderer.resize(originalImage.width, originalImage.height);

    this.originalMask = this.convertImageToSprite(originalImage);
    this.originalMaskTexture = this.originalMask.texture;
    
    if (!this.maskFilter) {
      this.maskFilter = new RestoringMaskFilter(this.eraseTexture, this.restoreTexture, this.originalMaskTexture, this.accumlatedMaskTexture);
    }
    
    // @ts-ignore
    let canvasElement = this.canvas.lowerCanvasEl

    this.eraseBufferCanvas.width = originalImage.width;
    this.eraseBufferCanvas.height = originalImage.height;

    this.restoreBufferCanvas.width = originalImage.width;
    this.restoreBufferCanvas.height = originalImage.height;

    this.eraseSprite = new Sprite(this.eraseTexture)
    this.restoreSprite = new Sprite(this.restoreTexture)

    this.restoreSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
    this.eraseSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;

    this.imageSprite = this.convertImageToSprite(originalImage)
    this.imageSprite.filters = [this.maskFilter, this.premultiplyFilter.pixijsFilter]
    this.imageSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;

    this.pixiApp.stage.removeChildren(0, this.pixiApp.stage.children.length)
    // @ts-ignore
    this.pixiApp.stage.addChild(this.imageSprite)

    canvasElement.style.visibility = 'hidden'
  }

  _doDraw(inputCtx: CanvasRenderingContext2D, point: any) {
    const draw = () => {
      // window.requestAnimationFrame(() => {
        let actualBrushSize = Math.round(this.cachedBrush.size);

        let sx = Math.round(point.x - actualBrushSize / 2);
        let sy = Math.round(point.y - actualBrushSize / 2);

        let canvasContext: CanvasRenderingContext2D;
        let texture: Texture
        if (this.eraseType == EraseType.Erase) {
          canvasContext = this.eraseBufferCanvas.getContext('2d');
          texture = this.eraseTexture;
        } else {
          canvasContext = this.restoreBufferCanvas.getContext('2d');
          texture = this.restoreTexture;
        }

        canvasContext.globalCompositeOperation = 'lighten';
        canvasContext.imageSmoothingEnabled = true;
        canvasContext.imageSmoothingQuality = 'high';

        canvasContext.drawImage(this.cachedBrush.image, sx, sy);

        texture.update();
      // })
    }

    if (this._shouldRecreateBrush()) {
      setTimeout(async () => {
        await this._createBrush()
        draw()
      })
    } else {
      draw()
    }
  }

  async update(base64: string){
    let image = await this.baseToHtmlImage(base64);
    // @ts-ignore
    let canvasElement = this.canvas.lowerCanvasEl
    this.writeImageToCanvas(image, canvasElement);
    this.accumlatedMaskTexture.update();
  }

  private writeImageToCanvas(image: HTMLImageElement, canvas: HTMLCanvasElement) {
    let context = canvas.getContext('2d');
    let backupComposition = context.globalCompositeOperation;
    context.globalCompositeOperation = "copy"
    context.drawImage(image, 0, 0);
    context.globalCompositeOperation = backupComposition;
  }

  private async baseToHtmlImage(base64: string): Promise<HTMLImageElement> {
    return new Promise(function(resolve, reject) {
      // Create a new Image object
      let img = new Image();

      // Set up what to do once the image is loaded
      img.onload = function() {
        // Resize canvas to match image dimensions if needed
        resolve(img)
      };

      img.onerror = (err) => {
        reject(err)
      }
      // Set the source of the image to the base64 string
      img.src = base64;
    })
  }

  // @ts-ignore
  onMouseMove(pointer, options) {
    // @ts-ignore
    if (!this.canvas._isMainEvent(options.e)) {
      return;
    }
    // @ts-ignore
    this.drawStraightLine = options.e[this.straightLineKey];
    // @ts-ignore
    if (this.limitedToCanvasSize === true && this._isOutSideCanvas(pointer)) {
      return;
    }

    // @ts-ignore
    if (this._captureDrawingPath(pointer) && this._points.length > 1) {
      // @ts-ignore
      this._render();
    }
  }

  getStepSize() {
    return 1
  }

  // @ts-ignore
  _finalizeAndAddPath() {
    return
  }

  // @ts-ignore
  onMouseUp(e){
    // @ts-ignore
    super.onMouseUp(e)

    this.finalize()

    this.accumlatedMaskTexture.update();

    this.clearEraseAndRestore();
  }

  private clearEraseAndRestore(){
    this.eraseBufferCanvas.getContext('2d').clearRect(0, 0, this.eraseBufferCanvas.width, this.eraseBufferCanvas.height);
    this.restoreBufferCanvas.getContext('2d').clearRect(0, 0, this.restoreBufferCanvas.width, this.restoreBufferCanvas.height);

    this.eraseTexture.update();
    this.restoreTexture.update();
  }

  finalize() {
    // @ts-ignore
    let canvasElement = this.canvas.lowerCanvasEl
    let canvasContext = canvasElement.getContext('2d');
    let backupComposition = canvasContext.globalCompositeOperation;
    canvasContext.globalCompositeOperation = "copy"

    const extract = this.pixiApp.renderer.plugins.extract;
    const tempCanvas = extract.canvas(this.imageSprite);
    canvasContext.drawImage(tempCanvas, 0, 0);

    canvasContext.globalCompositeOperation = backupComposition;
  }
}

fabric.EraseBrush = fabric.util.createClass(EraseBrushObject, {
  type: BlendBrushObject.type,
  eraseType: EraseType.Erase
})

declare module 'fabric' {
  namespace fabric {
    class EraseBrush extends EraseBrushObject {
      constructor(canvas:any)
    }
  }
}
