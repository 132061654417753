import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#FF6122"
      d="M3.401 13.642c2.262 0 2.577-2.773 4.122-5.248-.06.887-.009 1.664.162 2.21.674 2.125 2.987 2.287 4.036.128 1.485-3.038 1.912-3.755 3.405-5.948.99-1.442-.153-2.38-1.485-1.707-.682.342-1.783 1.212-3.165 3.038.238-1.39.179-3.746-1.835-3.61-1.058.069-2.432 1.264-4.31 4.336.154-1.724.214-2.944-.537-3.584-.512-.436-1.724-.606-2.45.332-.733.948-.904 2.8-.827 4.745.162 4.08 1.604 5.308 2.884 5.308Z"
    />
  </svg>
)
export default SvgComponent
