import { IUpload, Uploading } from '@/interfaces/editor'
import { uniqueFilename } from '@/utils/unique'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '@services/api'
import axios, { AxiosError } from 'axios'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'

export const setUploads = createAction<IUpload[]>('uploads/setUploads')
export const setUploading = createAction<Uploading>('uploads/setUploading')
export const closeUploading = createAction('uploads/closeUploading')

export const getUploads = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  'uploads/getUploads',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const uploads = await api.getUploads()
      dispatch(setUploads(uploads))
    } catch (err) {
      return rejectWithValue(((err as AxiosError).response?.data as any)?.error.data || null)
    }
  }
)

export const uploadFile = createAsyncThunk<void, { file: File; fromCreationPage?: boolean }, any>(
  'uploads/uploadFile',
  async (args, { dispatch }) => {
    const file = args.file
    setUploading({
      progress: 0,
      status: 'IN_PROGRESS',
    })
    const updatedFileName = uniqueFilename(file.name)
    const updatedFile = new File([file], updatedFileName)
    // const response = await api.getSignedURLForUpload({ name: updatedFileName })

    var reader = new FileReader()

    reader.onprogress = function updateProgress(progressEvent) {
      // evt is an ProgressEvent.
      if (progressEvent.lengthComputable) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploading({
          progress: percentCompleted,
          status: 'IN_PROGRESS',
        })
      }
    }

    reader.onload = (function (theFile) {
      return async function (e) {
        let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing

        const htmlImage = await imageProcessing.loadImage(e.target.result as string)
        let imageWidth = htmlImage.width, imageHeight = htmlImage.height
        let resizedImage
        if(Math.max(htmlImage.width, htmlImage.height) > 1280) {
          resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(e.target.result as string, 1280)
          if(htmlImage.width > htmlImage.height) {
            imageWidth = 1280
            imageHeight = htmlImage.height * (1280 / htmlImage.width)
          } else {
            imageHeight = 1280
            imageWidth = htmlImage.width * (1280 / htmlImage.height)
          }
        } else {
          resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(e.target.result as string, Math.max(htmlImage.width, htmlImage.height)) 
        }

        const uploadedFile = await api.updateUploadFile({ name: resizedImage })
        if (args.fromCreationPage) {
          uploadedFile['fromCreationPage'] = true
          uploadedFile['size'] = {
            width: imageWidth,
            height: imageHeight,
          }
        }
        dispatch(closeUploading())
        dispatch(setUploads([uploadedFile]))
      }
    })(updatedFile)

    reader.readAsDataURL(updatedFile)

    let db
    let request = indexedDB.open('WritableFilesDemo')
    request.onerror = function (e) {
      console.log(e)
    }
    // @ts-ignore
    request.onsuccess = function (e) {
      // @ts-ignore
      db = e.target.result
    }

    if (file) {
      // Save the reference to open the file later.
      let transaction = db.transaction(['filerefs'], 'readwrite')
      let request = transaction.objectStore('filerefs').add(file)
      request.onsuccess = function (e) {
        console.log(e)
      }

      // Do other useful things with the opened file.
    }

    // await axios.put(response.url, updatedFile, {
    //   headers: { 'Content-Type': 'image/png' },
    //   onUploadProgress: progressEvent => {
    //     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //     setUploading({
    //       progress: percentCompleted,
    //       status: 'IN_PROGRESS',
    //     })
    //   },
    // })
    // const uploadedFile = await api.updateUploadFile({ name: updatedFileName })
    // dispatch(closeUploading())
    // dispatch(setUploads([uploadedFile]))
  }
)