import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect
      width={13}
      height={22}
      x={5}
      y={1}
      stroke={lightTheme.colors.blackGray}
      strokeWidth={2}
      rx={2}
    />
    <rect width={5} height={2} x={9} y={3} fill={lightTheme.colors.blackGray} rx={1} />
    <rect width={7} height={2} x={8} y={19} fill={lightTheme.colors.blackGray} rx={1} />
  </svg>
)
export default SvgComponent
