import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke={lightTheme.colors.blackGray}
      strokeWidth={2}
      d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Z"
    />
    <path
      stroke={lightTheme.colors.blackGray}
      strokeWidth={2}
      d="M2 8h14a2 2 0 0 1 2 2v9H5a3 3 0 0 1-3-3V8Z"
    />
    <path stroke={lightTheme.colors.blackGray} strokeWidth={2} d="M2 8h11v11H5a3 3 0 0 1-3-3V8Z" />
  </svg>
)
export default SvgComponent
