import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#04FFFA"
      d="M13.653 3.51v2.42a5.385 5.385 0 0 1-2.995-.904v5.151a4.529 4.529 0 1 1-3.91-4.487v2.466a2.113 2.113 0 1 0 1.494 2.021V.5h2.416v.025a2.986 2.986 0 0 0 2.986 2.986h.01Z"
    />
    <path
      fill="#FF0076"
      d="M14.447 4.305v2.42a5.385 5.385 0 0 1-2.995-.904v5.15a4.529 4.529 0 1 1-3.91-4.486V8.95a2.113 2.113 0 1 0 1.494 2.021V1.294h2.416v.025a2.986 2.986 0 0 0 2.986 2.986h.01Z"
    />
    <path
      fill="#333"
      d="M14.05 3.908v2.42a5.385 5.385 0 0 1-2.995-.904v5.15a4.529 4.529 0 1 1-3.909-4.486v2.465a2.113 2.113 0 1 0 1.494 2.02V.898h2.415v.025a2.986 2.986 0 0 0 2.986 2.986h.01Z"
    />
  </svg>
)
export default SvgComponent
