import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.002 13A1 1 0 1 0 13 11.002 1 1 0 0 0 13.002 13ZM9.004 13a1 1 0 1 0-.002-1.998A1 1 0 0 0 9.004 13Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.741 5a1 1 0 0 1 1 1v12a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1ZM3 5a1 1 0 0 1 1 1v12a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.164 11.996 2.286 2.3a1 1 0 0 1-1.418 1.41l-2.567-2.583a1.6 1.6 0 0 1 .002-2.258l2.566-2.571a1 1 0 1 1 1.416 1.412l-2.285 2.29ZM16.577 12.004l-2.286-2.3a1 1 0 0 1 1.419-1.41l2.566 2.583a1.6 1.6 0 0 1-.002 2.258l-2.566 2.571a1 1 0 1 1-1.416-1.412l2.285-2.29Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
