import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M16.495 9.138a1.001 1.001 0 0 0 .023 1.733l4.884 2.742c.036.018.054.04.054.067 0 .026-.018.049-.054.066l-8.934 5.017c-.18.098-.347.146-.5.146-.154 0-.32-.048-.501-.146l-8.87-5.02c-.035-.018-.053-.04-.053-.067 0-.027.018-.05.054-.067l4.868-2.736a1.002 1.002 0 1 0-.989-1.743l-5.165 2.96c-.478.276-.816.53-1.014.766a1.233 1.233 0 0 0-.298.82c0 .31.1.586.298.826.198.24.536.497 1.014.773l9.153 5.26a4.4 4.4 0 0 0 .792.353c.239.076.476.113.71.113.235 0 .472-.037.71-.113a4.4 4.4 0 0 0 .792-.353l9.219-5.256c.478-.276.816-.533 1.014-.773.199-.24.298-.515.298-.826 0-.311-.1-.584-.298-.82-.198-.235-.536-.49-1.014-.766l-5.182-2.966c-.314-.18-.7-.176-1.01.01Z"
    />
    <path d="M13 13.406a.998.998 0 0 1-1.997 0V5.6A.998.998 0 1 1 13 5.6v7.807Z" />
    <path
      fillRule="evenodd"
      d="m11.995 5.13 3.16 2.744a1 1 0 1 0 1.312-1.51l-3.424-2.972a1.6 1.6 0 0 0-2.1.002L7.534 6.365A1 1 0 1 0 8.85 7.873l3.146-2.743Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
