import { lightTheme } from '@/customTheme'
import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      // fill="#FF0560"
      fill={lightTheme.colors.blackGray}
      {...props}
      fillRule="evenodd"
      d="m10.251 14.661 5.598-7.016a.844.844 0 0 1 1.164-.142.795.795 0 0 1 .146 1.135l-6.157 7.716a.844.844 0 0 1-1.222.094l-3.518-3.216a.794.794 0 0 1-.038-1.142.845.845 0 0 1 1.172-.037l2.855 2.608Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
