import { BasePixiFilter } from '@scenes/engine/utils/PixijsFilters/BasePixiFilter'
import { Filter } from '@pixi/core';
import { Matrix } from 'pixi.js'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Size } from '@scenes/engine/objects/media-repository/size'

// thanks - https://github.com/pixijs/pixijs/wiki/v5-Creating-filters
// @ts-ignore
export class FilterSimpleResize implements BasePixiFilter {
  scaleX: number;
  scaleY: number;

  padding: Size
  pixijsFilter: Filter

  constructor(scaleX: number, scaleY: number) {
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.pixijsFilter = FilterSimpleResize.generateFilter(scaleX, scaleY);
  }

  updateFilter(scaleX: number, scaleY: number) {
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.pixijsFilter = FilterSimpleResize.generateFilter(scaleX, scaleY);
  }

  static generateFilter(scaleX: number, scaleY: number): Filter {
    let filter = new Filter(
            `
      attribute vec2 aVertexPosition;
      uniform mat3 projectionMatrix;
      uniform mat3 filterMatrix;
      
      
      varying vec2 vTextureCoord;
      
      uniform vec4 inputSize;
      uniform vec4 outputFrame;
      
      vec4 filterVertexPosition( void )
      {
          vec2 position = aVertexPosition * max(outputFrame.zw, vec2(0.)) + outputFrame.xy;
          return vec4((projectionMatrix * filterMatrix * vec3(position, 1.0)).xy, 0.0, 1.0);
      }
      
      vec2 filterTextureCoord( void )
      {
          return aVertexPosition * (outputFrame.zw * inputSize.zw);
      }
      
      void main(void)
      {
          gl_Position = filterVertexPosition();
          vTextureCoord = filterTextureCoord();
      }

    `,
    `
      precision mediump float;
      
      varying vec2 vTextureCoord;
      uniform sampler2D uSampler;
      
      void main(void) {
        gl_FragColor = texture2D(uSampler, vTextureCoord);
      }
    `,
        {
          filterMatrix: new Matrix().scale(scaleX, scaleY)
        }
    );
    return filter;
  }

  applyInset(inset: Inset): Inset {
    let newInset = Object.assign({}, inset);
    newInset.top = Math.floor((newInset.top * this.scaleY));
    newInset.left = Math.floor((newInset.left * this.scaleX));
    newInset.bottom = Math.floor((newInset.bottom * this.scaleY));
    newInset.right = Math.floor((newInset.right * this.scaleX));
    return newInset;
  }

  padSize(size: Size): Size {
    let newSize = Object.assign({}, size);

    let offsetX = (size.width * this.scaleX - size.width) / 2;
    let offsetY = (size.height * this.scaleY - size.height) / 2;

    newSize.width = Math.floor(newSize.width + offsetX * 2);
    newSize.height = Math.floor(newSize.height + offsetY * 2);

    return newSize;
  }

  applyTo2d(options: any): void {
  }

  isNeutralState(): boolean {
    return false;
  }

  setOptions(options?: any): void {
  }

  toObject() {
  }

  toJSON(): string {
    return '';
  }
}