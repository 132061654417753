import { IStorePack } from '@/interfaces/editor'
import { createReducer } from '@reduxjs/toolkit'
import { setBackgroundPacks } from './actions'

export interface BackgroundPacksState {
  backgroundPacks: IStorePack[]
}

const initialState: BackgroundPacksState = {
  backgroundPacks: [],
}

export const backgroundPacksReducer = createReducer(initialState, builder => {
  builder.addCase(setBackgroundPacks, (state, { payload }) => {
    state.backgroundPacks = payload
  })
})
