import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      stroke="#666"
      strokeWidth={1.42}
      d="M5.431 5.43a.29.29 0 0 1 .41 0l4.244 4.243a.29.29 0 0 1-.41.41L5.43 5.842a.29.29 0 0 1 0-.41ZM13.917 13.916a.29.29 0 0 1 .41 0l4.243 4.243a.29.29 0 1 1-.41.41l-4.243-4.242a.29.29 0 0 1 0-.411Z"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="m5.042 5.048 3.243-.01a1 1 0 1 0-.006-2l-3.641.012a1.6 1.6 0 0 0-1.595 1.598L3.039 8.28a1 1 0 1 0 2 .002l.003-3.234ZM18.952 18.958l.01-3.243a1 1 0 1 1 2 .007l-.011 3.64a1.6 1.6 0 0 1-1.598 1.596l-3.633.004a1 1 0 1 1-.002-2l3.234-.004Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
