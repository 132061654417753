import * as React from 'react'

const SvgComponent = props => (
  <svg width="29" height="50" viewBox="0 0 29 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="28.3157" y="0.5" width="49" height="27.2895" rx="3.5" transform="rotate(90 28.3157 0.5)" fill="#E5E5E5"/>
      <rect x="28.3157" y="0.5" width="49" height="27.2895" rx="3.5" transform="rotate(90 28.3157 0.5)" stroke="#E5E5E5"/>
  </svg>

)

export default SvgComponent
