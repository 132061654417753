import Icons from '../../../Icons'
import { KIND } from 'baseui/button'
import { useEditorContext } from '@/scenes/engine'
import { useEffect, useState } from 'react'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'
import SliderCustom from '@/components/SliderCustom'
import ColorsPackContainer from '@/ColorsPackContainer'
import { LabelLarge } from 'baseui/typography'
import { Point } from '@scenes/engine/objects/media-repository/point'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { useSelector } from 'react-redux'
import { ObjectType } from '@/scenes/engine/common/constants'
import * as PIXI from 'pixi.js'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'

function Shadow() {
  const canvasImageRenderer = CanvasImageRenderer.getInstance()
  let shadowFilter = canvasImageRenderer.shadowFilter

  const [distanceValue, setDistanceValue] = useState(shadowFilter.minimumForKey('distance'))
  const [angleValue, setAngleValue] = useState(shadowFilter.minimumForKey('angle'))
  const [blurValue, setBlurValue] = useState(shadowFilter.minimumForKey('blur'))
  const [shadowValue, setShadowValue] = useState(shadowFilter.minimumForKey('opacity'))
  const [preEffectsValue, setPreEffectsValue] = useState(null)
  const [preFiltersValue, setPreFiltersValue] = useState([])
  const [colorValue, setColorValue] = useState(null)
  const [isInit, setIsInit] = useState(true)
  const [isSecond, setIsSecond] = useState(true)
  const [isReset, setIsReset] = useState(false)
  const [isFirstChange, setIsFirstChange] = useState(true)
  const filterPacks = useSelector(selectImageElements)
  const [isColor, setIsColor] = useState(false)
  const [scaleXBeforeFilter, setScaleXBeforeFilter] = useState(null)
  const [scaleYBeforeFilter, setScaleYBeforeFilter] = useState(null)
  const { editor, canvas } = useEditorContext()
  const [filterScalingXBeforeFilter, setFilterScalingXBeforeFilter] = useState(null)
  const [filterScalingYBeforeFilter, setFilterScalingYBeforeFilter] = useState(null)

  const activeObject = useEditorContext().activeObject as unknown as any

  const updatedProperties = ['effects', 'filters', 'scaleX', 'scaleY', '_filterScalingX', '_filterScalingY']

  useEffect(() => {

    updateOptions(activeObject)
    activeObject?.on('modified', setScaleBeforeFilter)
    return () => {
      activeObject?.off('modified', setScaleBeforeFilter)
    }
  }, [activeObject])

  const setScaleBeforeFilter = (e)=>{
    setScaleXBeforeFilter(activeObject.scaleX)
    setScaleYBeforeFilter(activeObject.scaleY)
  }

  useEffect(() => {
    if (!editor) return
    const handleChanges = (e) => {
      if(e.afterUndo) {
        setIsInit(true)
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
  }, [editor])
  
  useEffect(() => {
    if (activeObject) {
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
    }
  }, [JSON.stringify(activeObject)])


  const radianToDegrees = (radians: number): number => {
    let degrees = (radians * 180) / Math.PI
    return degrees
  }

  const degreesToRadians = (degrees: number): number => {
    let radians = (degrees / 180) * Math.PI
    return radians
  }

  const updateOptions = (object: any) => {
    if (!activeObject || !object) {
      return
    }

    // @ts-ignore
    let shadowState = object?.effects?.shadow
    if (shadowState) {
      let updatedRadianValue = 0
      let updatedDistanceValue = 0
      
      if(shadowState.angle) {
        let radians = degreesToRadians(shadowState.angle)
        shadowState.offsetX = shadowState.offsetX === null || shadowState.offsetX === undefined ? Math.cos(radians) * shadowState.distance : shadowState.offsetX
        shadowState.offsetY = shadowState.offsetY === null || shadowState.offsetY === undefined ? Math.sin(radians) * shadowState.distance : shadowState.offsetY
      }

      updatedRadianValue = activeObject.type === ObjectType.BAZAART_TEXT ? Math.atan(Math.sin(shadowState.angle) / Math.cos(shadowState.angle)) : Math.atan2(shadowState.offsetY, shadowState.offsetX)
      if(activeObject.type === ObjectType.BAZAART_TEXT) {
        updatedDistanceValue = Math.sin(updatedRadianValue) === 0 ? shadowState.distance : (Math.sin(shadowState.angle) * shadowState.distance) / Math.sin(updatedRadianValue)
      } else { 
        if(Math.sin(updatedRadianValue) === 0) {
          updatedDistanceValue = shadowState.offsetX / (Math.cos(updatedRadianValue) === 0 ? 1 : Math.cos(updatedRadianValue))
        } else {
          updatedDistanceValue = shadowState.offsetY / Math.sin(updatedRadianValue)
        }
      }
   
      let updatedAngleValue = radianToDegrees(updatedRadianValue)

      setAngleValue(Math.round(updatedAngleValue))
      setDistanceValue(updatedDistanceValue)
      setColorValue(shadowState.color)
      setShadowValue(shadowState.opacity)
      setBlurValue(shadowState.blur)
      setIsFirstChange(false)
    } else {
      reset()
      setIsReset(false)
      if (colorValue || distanceValue || angleValue || blurValue || shadowValue) {
        setIsInit(true)
      }
      setIsSecond(true)
      setIsColor(false)
      setIsFirstChange(true)
    }
    // @ts-ignore
    let assign = Object.assign({}, object?.effects)
    setPreEffectsValue(assign)
    // @ts-ignore
    let assignObj = Object.assign({}, object?.effects)
    setPreEffectsValue(assignObj)
    //@ts-ignore
    let filters=Object.assign([], object?.filters);
    setPreFiltersValue(filters)
    setScaleXBeforeFilter(activeObject.scaleX)
    setScaleYBeforeFilter(activeObject.scaleY)
    setFilterScalingXBeforeFilter(activeObject._filterScalingX)
    setFilterScalingYBeforeFilter(activeObject._filterScalingY)
  }

  const updateEffect = (finalValue = false) => {
    let sliderOptions: any = { isSliderUpdate: finalValue }
    if (finalValue) {
      const updatedPropertiesValues = [preEffectsValue, preFiltersValue, scaleXBeforeFilter, scaleYBeforeFilter, filterScalingXBeforeFilter, filterScalingYBeforeFilter]
      //@ts-ignore
      sliderOptions = {
        isSliderUpdate: finalValue,
        property: updatedProperties,
        value: updatedPropertiesValues
      }
      //@ts-ignore
      let assignObj = Object.assign({}, activeObject.effects)
      setPreEffectsValue(assignObj)
      //@ts-ignore
      let filters = Object.assign([], activeObject.filters)
      setPreFiltersValue(filters)
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
      setFilterScalingXBeforeFilter(activeObject._filterScalingX)
      setFilterScalingYBeforeFilter(activeObject._filterScalingY)
    }
    //@ts-ignore
    editor.handlers.objectsHandler.updateActive(
      { effects: activeObject.effects, filters: activeObject.filters },
      undefined,
      sliderOptions
    )
  }

  const getOffset = (): Point => {
    let radians = degreesToRadians(angleValue)
    const offX = Math.cos(radians) * distanceValue
    const offY = Math.sin(radians) * distanceValue

    let p = new Point(offX, offY)
    return p
  }

  const updateColor = async (color: string) => {
    setColorValue(color)
    setIsColor(true)
  }

  const updateDistance = async (value: number) => {
    setDistanceValue(value)
  }

  const updateAngle = async (value: number) => {
    setAngleValue(value)
  }

  const updateBlur = async (value: number) => {
    setBlurValue(value)
  }

  const updateShadow = async (value: number) => {
    setShadowValue(value)
  }

  const reset = () => {
    if (!activeObject) {
      return
    }
    setAngleValue(shadowFilter.minimumForKey("angle"))
    setBlurValue(shadowFilter.minimumForKey("blur"))
    setDistanceValue(shadowFilter.minimumForKey("distance"))
    setShadowValue(shadowFilter.minimumForKey("opacity"))
    setColorValue(null)
    setIsReset(!isFirstChange)
  }

  const renderFilter = async () => {
    if (!activeObject) {
      return
    }
    if (isFirstChange) {
      if (!activeObject.effects.shadow) {
        setShadowValue(pre => (pre ? pre : shadowFilter.defaultForKey("opacity")))
        setDistanceValue(pre => (pre ? pre : shadowFilter.defaultForKey("distance")))
        setAngleValue(pre => (pre ? pre : shadowFilter.defaultForKey("angle")))
        setBlurValue(pre => (pre ? pre : activeObject.type === ObjectType.BAZAART_TEXT ? shadowFilter.defaultForKey("blurText") : shadowFilter.defaultForKey("blur")))
        setColorValue(pre => (pre ? pre : lightTheme.colors.blackGray))
      }
      setIsFirstChange(false)
      return
    }
    applyShadowFilter()
    if (isColor) {
      updateEffect(true)
      setIsColor(false)
    }
    if (isReset) {
      setIsReset(false)
      updateEffect(true)
      setIsFirstChange(true)
    }
  }

  const applyShadowFilter = async () => {
    if (!activeObject) {
      return
    }
    switch (activeObject.type) {
      case ObjectType.BAZAART_TEXT:
        await renderText()
        break;
      default:
        await renderImage()
        break;
    }
    canvas.renderAll()
  }

  const renderImage = async () => {
    let offset = getOffset()
    let newShadowState = {
      offsetX: offset.x,
      offsetY: offset.y,
      blur: blurValue,
      opacity: shadowValue,
      color: colorValue,
    }
    activeObject.effects.shadow = isReset ? null : newShadowState
    await canvasImageRenderer.render(activeObject, filterPacks)
  }

  const renderText = async () => {
    let offset = getOffset()
    let newShadowState = {
      angle: degreesToRadians(angleValue),
      blur: blurValue,
      color: colorValue,
      distance: distanceValue,
      opacity: shadowValue,
    }
    activeObject.effects.shadow = isReset ? null : newShadowState
    if(isReset) {
      activeObject.set({
        shadow: {}
      })
    } else {
      const colorNumber = new PIXI.Color(colorValue).toUint8RgbArray()
      activeObject.shadow = {
        color: `rgba(${colorNumber[0]}, ${colorNumber[1]}, ${colorNumber[2]}, ${shadowValue})`,
        offsetX: Math.floor(offset.x * activeObject.width),
        offsetY: Math.floor(offset.y * activeObject.width),
        blur: Math.floor(blurValue * activeObject.width),
      }
    }
  }

  useEffect(() => {
    if (!activeObject) {
      return
    }
    if (isInit) {
      setIsInit(false)
      if (activeObject.type === ObjectType.BAZAART_TEXT && !activeObject?.effects.shadow) {
        activeObject.shadow = null
        editor.handlers.objectsHandler.renderAll()
      }
    } else {
      ; (async () => {
        if (isSecond) {
          setIsSecond(false)
          //@ts-ignore
          let assign = Object.assign({}, activeObject.effects)
          setPreEffectsValue(assign)
          //@ts-ignore
          let filters = Object.assign([], activeObject.filters)
          setPreFiltersValue(filters)
        }
        await renderFilter()
      })()
    }
  }, [colorValue, distanceValue, angleValue, blurValue, shadowValue])

  const { t } = useTranslation()

  return (
    <>
          {/* Shadow */}
          <SliderCustom
            icon={<Icons.Shadow size={24} />}
            name={t("Shadow")}
            minValue={0}
            maxValue={100}
            logicMaximum={shadowFilter.maximumForKey("opacity")}
            logicMinimum={shadowFilter.minimumForKey("opacity")}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(value, logicalValue) => {
              updateShadow(logicalValue)
              updateEffect(false)
            }}
            value={shadowValue}
            onFinalChangeValue={(value, logicalValue) => {
              const eventProperties = {
                Tool: 'bazaart.style.effects',
                'Layer Type': activeObject.type,
              }
              customAmplitude('Selected tool', eventProperties)
              updateEffect(true)
            }}
            style={{marginTop: 0}}
          />
          {/* Distance */}
          <SliderCustom
            icon={<Icons.Distance size={24} />}
            name={t("Distance")}
            minValue={0}
            maxValue={100}
            logicMaximum={shadowFilter.maximumForKey("distance")}
            logicMinimum={shadowFilter.minimumForKey("distance")}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(value, logicalValue) => {
              setDistanceValue(logicalValue)
              updateEffect(false)
            }}
            value={distanceValue}
            onFinalChangeValue={(value, logicalValue) => {
              const eventProperties = {
                Tool: 'bazaart.style.effects',
                'Layer Type': activeObject.type,
              }
              customAmplitude('Selected tool', eventProperties)
              updateEffect(true)
            }}
          />
          {/* Angle */}
          <SliderCustom
            icon={<Icons.Angle size={24} />}
            name={t("Angle")}
            minValue={0}
            maxValue={360}
            logicMaximum={shadowFilter.maximumForKey("angle")}
            logicMinimum={shadowFilter.minimumForKey("angle")}
            listValues={[
              0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
              210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360,
            ]}
            onChangeValue={(value, logicalValue) => {
              if (!activeObject) {
                return
              }
              setAngleValue(logicalValue)
              updateEffect(false)
            }}
            value={angleValue}
            onFinalChangeValue={(value, logicalValue) => {
              const eventProperties = {
                Tool: 'bazaart.style.effects',
                'Layer Type': activeObject.type,
              }
              customAmplitude('Selected tool', eventProperties)
              updateEffect(true)
            }}
          />
          {/* Blur */}
          <SliderCustom
            icon={<Icons.Blur size={24} />}
            name={t("Blur")}
            minValue={0}
            maxValue={100}
            logicMaximum={activeObject?.type === ObjectType.BAZAART_TEXT ? shadowFilter.maximumForKey("blurText") : shadowFilter.maximumForKey("blur")}
            logicMinimum={shadowFilter.minimumForKey("blur")}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(value, logicalValue) => {
              setBlurValue(logicalValue)
              updateEffect(false)
            }}
            value={blurValue}
            onFinalChangeValue={(value, logicalValue) => {
              const eventProperties = {
                Tool: 'bazaart.style.effects',
                'Layer Type': activeObject.type,
              }
              customAmplitude('Selected tool', eventProperties)
              updateEffect(true)
            }}
          />
          <ColorsPackContainer
            style={{
              boxSizing: 'border-box',
              padding: '0',
              width: '300px',
              paddingRight: '8px',
            }}
            onItemSelected={value => updateColor(value)}
            colorSelected={colorValue}
          ></ColorsPackContainer>
          <div className="mt-50">
            <ButtonCustom
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
              style={{ marginBottom: '300px' }}
              onClick={() => {
                reset()
              }}
            >
              <LabelLarge>{t("Reset")}</LabelLarge>
            </ButtonCustom>
          </div>
        {/* <Accordion>
          <Panel
            title={
              <Button size={SIZE.default} kind={KIND.tertiary} shape={SHAPE.square}>
                Shadow
              </Button>
            }
          >
            <div
              style={{
                background: '#ffffff',
                fontSize: '14px',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div>
                  <div>Distance</div>
                  <Slider
                    overrides={{
                      InnerThumb: () => null,
                      ThumbValue: () => null,
                      TickBar: () => null,
                      Thumb: {
                        style: {
                          height: '12px',
                          width: '12px',
                        },
                      },
                    }}
                    min={0.01}
                    max={0.99}
                    step={0.002}
                    marks={false}
                    value={distanceValue}
                    onChange={({ value }) => updateDistance(value)}
                  />
                </div>
                <div>
                  <div>Angle</div>
                  <Slider
                    overrides={{
                      InnerThumb: () => null,
                      ThumbValue: () => null,
                      TickBar: () => null,
                      Thumb: {
                        style: {
                          height: '12px',
                          width: '12px',
                        },
                      },
                    }}
                    min={-1.56}
                    max={1.56}
                    step={0.05}
                    marks={false}
                    value={angleValue}
                    onChange={({ value }) => updateAngle(value)}
                  />
                </div>
                <div>
                  <div>Blur</div>
                  <Slider
                    overrides={{
                      InnerThumb: () => null,
                      ThumbValue: () => null,
                      TickBar: () => null,
                      Thumb: {
                        style: {
                          height: '12px',
                          width: '12px',
                        },
                      },
                    }}
                    min={0.0}
                    max={0.3}
                    step={0.001}
                    marks={false}
                    value={blurValue}
                    onChange={({ value }) => updateBlur(value)}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </Panel>
        </Accordion> */}
    </>
  )
}

export default Shadow