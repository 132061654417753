import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path stroke="#666" strokeWidth={2} d="M25 16a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      <path stroke="#666" strokeLinecap="round" strokeWidth={2} d="M16 15v5" />
      <path fill="#666" fillRule="evenodd" d="M16 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clipRule="evenodd" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 8a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8Z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
