import * as React from 'react'

const FlipH = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.003 2a1 1 0 0 0-2 0v20a1 1 0 1 0 2 0V2ZM3 11.137V9.621c0-.865.82-1.179 1.505-.651l3.167 2.403a.777.777 0 0 1 0 1.27l-3.167 2.391c-.674.517-1.505.214-1.505-.64v-3.257Zm18 0V9.621c0-.865-.82-1.179-1.505-.651l-3.167 2.403a.777.777 0 0 0 0 1.27l3.167 2.391c.674.517 1.505.214 1.505-.64v-3.257Z"
      fill="#666"
    />
  </svg>
)

export default FlipH
