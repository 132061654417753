import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12Z"
      stroke="#666"
      strokeWidth={2}
    />
    <path
      d="m2.281 10.904 19.021.026a1.1 1.1 0 0 1 .126 2.194l-.129.007-19.02-.026a1.1 1.1 0 0 1-.126-2.194l.128-.007Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.018a6.015 6.015 0 0 0-6.015 6.015h-2a8.015 8.015 0 0 1 16.03 0h-2A6.015 6.015 0 0 0 12 6.018Z"
      fill="#666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.977 8.974a2.972 2.972 0 0 0-2.983 2.96h-2c0-2.744 2.236-4.96 4.983-4.96 2.747 0 4.984 2.216 4.984 4.96h-2c0-1.63-1.33-2.96-2.984-2.96Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
