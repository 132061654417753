import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={170} height={106} fill="none" {...props}>
    <g clipPath="url(#a)">
      <rect
        width={15.794}
        height={18.517}
        fill="#FCDBE0"
        rx={2.546}
        transform="scale(-1 1) rotate(-15 132.454 449.102)"
      />
      <path
        fill="#F7A4B2"
        d="m97.67 58.105 1.842 3.378-1 3.732-10.784-2.89 7.198-4.884a1.906 1.906 0 0 1 2.743.664Z"
      />
      <path
        fill="#FF0560"
        d="m105.081 58.004 3.133 5.723-1.022 3.813-15.387-4.122 10.651-6.15a1.906 1.906 0 0 1 2.625.736Z"
      />
      <circle
        cx={1.77}
        cy={1.77}
        r={1.77}
        fill="#FF0560"
        transform="scale(-1 1) rotate(-15 140.509 414.37)"
      />
    </g>
    <rect
      width={16.511}
      height={19.234}
      x={0.439}
      y={-0.253}
      stroke="#fff"
      strokeWidth={0.717}
      rx={2.904}
      transform="scale(-1 1) rotate(-15 132.454 452.182)"
    />
    <g clipPath="url(#b)">
      <rect
        width={15.794}
        height={18.517}
        x={58.113}
        y={49.56}
        fill="#FCDBE0"
        rx={2.546}
        transform="rotate(-15 58.113 49.56)"
      />
      <path
        fill="#F7A4B2"
        d="m72.166 58.08-1.843 3.377 1 3.732 10.784-2.89-7.197-4.883a1.906 1.906 0 0 0-2.744.664Z"
      />
      <path
        fill="#FF0560"
        d="m64.755 57.978-3.133 5.723 1.022 3.814 15.387-4.123-10.651-6.149a1.906 1.906 0 0 0-2.625.735Z"
      />
      <circle cx={69.544} cy={51.712} r={1.77} fill="#FF0560" transform="rotate(-15 69.544 51.712)" />
    </g>
    <rect
      width={16.511}
      height={19.234}
      x={57.674}
      y={49.306}
      stroke="#fff"
      strokeWidth={0.717}
      rx={2.904}
      transform="rotate(-15 57.674 49.306)"
    />
    <g filter="url(#c)">
      <g clipPath="url(#d)">
        <rect width={21.902} height={25.678} x={73.528} y={38.661} fill="#FCDBE0" rx={1.51} />
        <path
          fill="#F7A4B2"
          d="m89.293 55.118-3.68 3.863v5.358h15.482l-7.888-9.126a2.643 2.643 0 0 0-3.913-.095Z"
        />
        <path
          fill="#FF0560"
          d="m79.402 52.322-6.25 6.542v5.475h22.09l-12.06-12.06a2.643 2.643 0 0 0-3.78.043Z"
        />
        <circle cx={88.067} cy={45.647} r={2.455} fill="#FF0560" />
      </g>
      <rect
        width={22.657}
        height={26.433}
        x={73.15}
        y={38.283}
        stroke="#fff"
        strokeWidth={0.755}
        rx={1.888}
      />
    </g>
    <rect
      width={63.201}
      height={73.735}
      x={-1.29}
      y={-0.745}
      fill="#E5E5E5"
      rx={5.267}
      transform="rotate(-15 119.742 -162.54)"
    />
    <circle cx={6.847} cy={6.847} r={6.847} fill="#B2B2B2" transform="rotate(-15 152.18 -296.117)" />
    <rect
      width={63.201}
      height={73.735}
      x={-1.29}
      y={-0.745}
      stroke="#fff"
      strokeWidth={2.107}
      rx={5.267}
      transform="rotate(-15 119.742 -162.54)"
    />
    <g clipPath="url(#e)">
      <rect width={61.094} height={71.628} x={52.999} y={16.684} fill="#E5E5E5" rx={4.213} />
      <path
        fill="#CCC"
        d="M96.977 62.592 86.707 73.366v14.947h43.188l-22.003-25.456c-2.847-3.293-7.913-3.416-10.916-.265Z"
      />
      <path
        fill="#B2B2B2"
        d="M69.385 54.793 51.95 73.04v15.273h61.622l-33.64-33.64a7.373 7.373 0 0 0-10.546.12Z"
      />
      <circle cx={93.555} cy={36.173} r={6.847} fill="#B2B2B2" />
    </g>
    <rect width={63.201} height={73.735} x={51.946} y={15.631} stroke="#fff" strokeWidth={2.107} rx={5.267} />
    <defs>
      <clipPath id="a">
        <rect
          width={15.794}
          height={18.517}
          fill="#fff"
          rx={2.546}
          transform="scale(-1 1) rotate(-15 132.454 449.102)"
        />
      </clipPath>
      <clipPath id="b">
        <rect
          width={15.794}
          height={18.517}
          x={58.113}
          y={49.56}
          fill="#fff"
          rx={2.546}
          transform="rotate(-15 58.113 49.56)"
        />
      </clipPath>
      <clipPath id="d">
        <rect width={21.902} height={25.678} x={73.528} y={38.661} fill="#fff" rx={1.51} />
      </clipPath>
      <clipPath id="e">
        <rect width={61.094} height={71.628} x={52.999} y={16.684} fill="#fff" rx={4.213} />
      </clipPath>
      <filter
        id="c"
        width={30.582}
        height={34.358}
        x={69.188}
        y={37.547}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3.226} />
        <feGaussianBlur stdDeviation={1.792} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.920833 0 0 0 0 0.713646 0 0 0 0 0.739544 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3534_193486" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_3534_193486" result="shape" />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
