import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a-tw)">
      <mask
        id="b-tw"
        width={16}
        height={16}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M15.5.5H.5v15h15V.5Z" />
      </mask>
      <g mask="url(#b-tw)">
        <path
          fill={lightTheme.colors.blackGray}
          d="M9.395 6.848 14.855.5h-1.293L8.82 6.012 5.032.5H.664l5.727 8.335-5.727 6.657h1.294l5.008-5.82 4 5.82h4.368l-5.94-8.644Zm-1.773 2.06-.58-.83-4.617-6.604h1.987l3.727 5.33.58.83 4.843 6.928h-1.987L7.622 8.91Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a-tw">
        <path fill="#fff" d="M.5.5h15v15H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
