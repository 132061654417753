import { MediaImageType } from './media_image_type'
import { ImageFilesystem } from './image_filesystem'
import { LatestImageInfo } from './latest_image_info'
import { MediaImageRepositoryProcessing } from './media_image_repository_processing'
import ApiService from '@services/api'

export class MediaImageRepository {
  private _filesystem: ImageFilesystem

  _mediaImageRepositoryProcessing: MediaImageRepositoryProcessing

  private static instance: MediaImageRepository

  public static getInstance(): MediaImageRepository {
    if (!MediaImageRepository.instance) {
      let filesystem = new ImageFilesystem()
      MediaImageRepository.instance = new MediaImageRepository(filesystem)
    }
    return MediaImageRepository.instance
  }

  private constructor(filesystem: ImageFilesystem) {
    this._filesystem = filesystem
    this._mediaImageRepositoryProcessing = new MediaImageRepositoryProcessing()
  }

  async storeLatestImageInfo(layer: string, layerStateId: string, latestImageInfo: LatestImageInfo): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        let success = true
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.fitted, this._mediaImageRepositoryProcessing.base64ToBlobUrl(latestImageInfo.fittedImage.toDataURL())))
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.fittedMask, this._mediaImageRepositoryProcessing.base64ToBlobUrl(latestImageInfo.fittedMask.toDataURL())))
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.latest, this._mediaImageRepositoryProcessing.base64ToBlobUrl(latestImageInfo.latestImage.toDataURL())))
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.mask, this._mediaImageRepositoryProcessing.base64ToBlobUrl(latestImageInfo.mask.toDataURL())))
        resolve(success)
      })
    })
  }

  async storeImageUrl(layer: string, layerStateId: string, imageType: MediaImageType, imageUrl: URL | null, abortSignal?:any): Promise<boolean> {
    if (!imageUrl) {
      return Promise.resolve(false)
    }
    // let cachedURL = getResizeUrl({ size: '', url: imageUrl});
    let base64 = await this.getDataBlob(imageUrl,abortSignal)
    return await this.storeImageBase64(layer, layerStateId, imageType, base64)
  }

  async storeImageBase64(layer: string, layerStateId: string, imageType: MediaImageType, imageBase64: string): Promise<boolean> {
    return this._filesystem.setImage(layer, layerStateId, imageType, imageBase64)
  }

  async getImage(layer: string, layerStateId: string, imageType: MediaImageType): Promise<string> {
    return this._filesystem.getImage(layer, layerStateId, imageType)
  }

  async removeImage(layer: string, layerStateId: string, imageType: MediaImageType) {
    this._filesystem.removeImage(layer, layerStateId, imageType)
  }

  async generateLatestImageInfo(layer: string, layerStateId: string,
                                threshold: number | null = null): Promise<LatestImageInfo> {
    let original = await this.getImage(layer, layerStateId, MediaImageType.original)
    let mask = await this.getImage(layer, layerStateId, MediaImageType.mask)

    let result = await this._mediaImageRepositoryProcessing.generateLatestImageInfo(original, mask, threshold)

    return result
  }

  async duplicate(originalLayerId: string, newLayerId: string, layerStateId: string) {
    await this._filesystem.duplicate(originalLayerId, newLayerId, layerStateId);
  }

  async getDataBlob(url: URL, signal?:AbortSignal): Promise<string> {
    try {
      let blob = await ApiService.downloadAsset(url, {
        responseType: 'blob',
        signal
      });
      if (signal?.aborted) {
        return null;
      }

      let imageFormats = ['image/png', 'image/jpeg', 'image/jpg'];
      let base64img = URL.createObjectURL(blob.data)

      if (blob.data.size > 2097152 && imageFormats.indexOf(blob.data.type) !== -1) { // 2 MB
        return this._mediaImageRepositoryProcessing.resizeBlobToMaxEdgeSize(base64img, 1280, signal);
      } else {
        return base64img
      }
    } catch(err) {
      return null;
    }
  }

  private async parseURI(blob: Blob): Promise<string> {
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((res, rej) => {
      reader.onload = e => {
        let base64Result = e.target.result as string
        res(base64Result)
      }
    })
  }
}
