import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      fillRule="evenodd"
      d="M3.818 5.636c0-.502.367-.909.819-.909h14.727c.452 0 .819.407.819.91 0 .501-.367.908-.819.908H4.637c-.452 0-.819-.407-.819-.909Z"
      clipRule="evenodd"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M18.107 4.832c.46 0 .834.373.834.833v12.933a3.334 3.334 0 0 1-3.334 3.334H8.402a3.334 3.334 0 0 1-3.334-3.334V5.665a.833.833 0 0 1 1.667 0v12.933c0 .92.746 1.667 1.667 1.667h7.205c.92 0 1.667-.746 1.667-1.667V5.665c0-.46.373-.833.833-.833Z"
      clipRule="evenodd"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M17.205 5.665a.902.902 0 0 1 1.804 0v12.933A3.402 3.402 0 0 1 15.607 22H8.402A3.402 3.402 0 0 1 5 18.598V5.665a.902.902 0 1 1 1.803 0v12.933c0 .883.716 1.599 1.599 1.599h7.205c.883 0 1.598-.716 1.598-1.599V5.665Zm.902-.765a.765.765 0 0 0-.765.765v12.933c0 .958-.777 1.735-1.735 1.735H8.402a1.735 1.735 0 0 1-1.735-1.735V5.665a.765.765 0 1 0-1.53 0v12.933a3.266 3.266 0 0 0 3.265 3.266h7.205a3.265 3.265 0 0 0 3.265-3.266V5.665a.765.765 0 0 0-.765-.765Z"
      clipRule="evenodd"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M15.048 8.11c.46 0 .834.374.834.834v8.334a.834.834 0 0 1-1.667 0V8.944c0-.46.373-.833.833-.833ZM12.003 8.11c.46 0 .834.374.834.834v8.334a.833.833 0 0 1-1.667 0V8.944c0-.46.373-.833.833-.833ZM8.95 8.11c.46 0 .834.374.834.834v8.334a.833.833 0 0 1-1.667 0V8.944c0-.46.373-.833.834-.833ZM9.676 4.573c0-.42.34-.76.76-.76h3.103c.42 0 .76.34.76.76v.832a.906.906 0 0 0 1.813 0v-.832A2.573 2.573 0 0 0 13.539 2h-3.103a2.573 2.573 0 0 0-2.573 2.573v.832a.906.906 0 0 0 1.813 0v-.832Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
