import * as React from 'react'
const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          fill="#666"
          d="M9.224 20.3h2.347c.565 0 .938-.391.938-.941v-1.817c0-.56-.373-.942-.938-.942H9.224c-.566 0-.94.383-.94.942v1.817c0 .55.374.94.94.94Zm-3.481-6.918h20.524v-2.296H5.743v2.296ZM7.81 24h16.38c2.078 0 3.143-1.086 3.143-3.181v-10.62c0-2.096-1.065-3.19-3.142-3.19H7.81c-2.068 0-3.143 1.094-3.143 3.19v10.62c0 2.095 1.075 3.18 3.143 3.18Zm.108-1.924c-.89 0-1.374-.471-1.374-1.425V10.368c0-.956.484-1.435 1.374-1.435h16.164c.875 0 1.374.48 1.374 1.435v10.283c0 .954-.5 1.425-1.374 1.425H7.918Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 8a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8Z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M4.667 7h22.666v17H4.667z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
