import { fabric } from 'fabric'
import { CanvasOptions, HandlerOptions } from '../common/interfaces'
import BaseHandler from './BaseHandler'
import * as _ from 'lodash'
import { ObjectType } from '../common/constants'

class CanvasHandler extends BaseHandler {
  public options: CanvasOptions
  public isOpenPixelManipulationObject: boolean = false

  constructor(props: HandlerOptions) {
    super(props)
    this.options = {
      width: props.canvas.width,
      height: props.canvas.height,
    }
  }
  resize(nextWidth, nextHeight) {
    this.canvas.setWidth(nextWidth).setHeight(nextHeight)
    this.canvas.renderAll()
    const diffWidth = nextWidth / 2 - this.options.width / 2
    const diffHeight = nextHeight / 2 - this.options.height / 2

    this.options.width = nextWidth
    this.options.height = nextHeight

    const deltaPoint = new fabric.Point(diffWidth, diffHeight)
    this.canvas.relativePan(deltaPoint)
  }

  exportToCanvasJSON(): { version: string; objects: Object[] } {
    const json = this.canvas.toJSON(this.root.propertiesToInclude)

    // @ts-ignore
    json.objects.forEach(object => {
      if (object.clipPath) {
        // @ts-ignore

        fabric.util.enlivenObjects([object.clipPath], function (arg1) {
          object.clipPath = arg1[0]
        })
        if(object.type === ObjectType.GROUP) {
          // @ts-ignore
          object.objects.forEach(o => {
            // @ts-ignore
            fabric.util.enlivenObjects([o.clipPath], function (arg1) {
              o.clipPath = arg1[0]
            })
          })
        }
      }
    })
    // @ts-ignore
    return _.cloneDeep(json)
  }
  destroy() {
    this.canvas.discardActiveObject()
  }
}

export default CanvasHandler
