import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'
import Back from '@/scenes/Editor/components/Icons/Back'
import { ThemeProvider } from 'baseui'
import { KIND, SHAPE } from 'baseui/button'
import ButtonCustom from '../ButtonCustom'
import Icons from '@/scenes/Editor/components/Icons'
import { useTranslation } from 'react-i18next'
import { CSSProperties } from 'react'

function HeadingInspector({
  hasBoxShadow,
  hasNavigation,
  handleNavigation,
  title,
  hasClose,
  handleClose,
  style,
  hasQuestionMarkButton,
  questionMarkButtonAction
}: {
  hasBoxShadow?: boolean
  hasNavigation: boolean
  handleNavigation?: () => void
  title: string
  hasClose?: boolean
  handleClose?: () => void
  style?: CSSProperties
  hasQuestionMarkButton?: boolean
  questionMarkButtonAction?: ()=>void
  onClickSecondaryShowDiscardModal?: boolean
}) {
  const { t } = useTranslation()
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        className={ style ? '' : 'h-heading-inspector'}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '8px',
          alignItems: 'center',
          paddingLeft: hasNavigation ? '16px' : '24px',
          paddingRight: '16px',
          boxShadow: hasBoxShadow ? '0px 1px 6px rgba(0, 0, 0, 0.1)' : 'none',
          ...style
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 'inherit'}}>
          {hasNavigation && (
            <ButtonCustom
              shape={SHAPE.square}
              kind={KIND.minimal}
              type={SizeButton.SMALL}
              onClick={handleNavigation}
              style={{borderRadius: '360px'}}
            >
              <Back size={24} fill={lightTheme.colors.blackGray}/>
            </ButtonCustom>
          )}
          <p
            style={{
              ...lightTheme.typography.Header20bold,
              lineHeight: '14px',
              textTransform: 'capitalize',
              margin: 0
            }}
          >
            {t(title)}
          </p>
        </div>
        {hasClose && (
          <ButtonCustom
            shape={SHAPE.square}
            kind={KIND.minimal}
            type={SizeButton.SMALL}
            onClick={handleClose}
            style={{ borderRadius: '360px'}}
          >
            <Icons.Close size={24} />
          </ButtonCustom>
        )}
        { hasQuestionMarkButton &&
          <ButtonCustom
          style={{  width: '32px', padding: 0 }}
          type={SizeButton.SMALL}
          kind={KIND.minimal}
          onClick={() => {
            questionMarkButtonAction()
          }}
        >
          <Icons.HelpIcon />
        </ButtonCustom>
        }
      </div>
    </ThemeProvider>
  )
}

export default HeadingInspector
