import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={21}
    fill="none"
    {...props}
  >
    <g filter="url(#app_store_a)">
      <path
        fill="url(#app_store_b)"
        d="M.36 1.153c-.226.23-.36.589-.36 1.053v16.587c0 .465.134.823.36 1.054l.059.054 9.658-9.292v-.219L.419 1.098l-.058.055Z"
      />
      <path
        fill="url(#app_store_c)"
        d="m13.296 13.708-3.22-3.098v-.22l3.22-3.097.073.04 3.814 2.085c1.09.595 1.09 1.57 0 2.166l-3.814 2.085-.073.039Z"
      />
      <g filter="url(#app_store_d)">
        <path
          fill="url(#app_store_e)"
          d="M13.369 13.668 10.076 10.5.361 19.847c.359.366.952.411 1.62.046l11.388-6.225Z"
        />
      </g>
      <path
        fill="url(#app_store_f)"
        d="M13.369 7.332 1.98 1.107C1.313.742.72.787.361 1.153l9.716 9.348 3.292-3.17Z"
      />
    </g>
    <defs>
      <linearGradient
        id="app_store_b"
        x1={9.221}
        x2={-3.357}
        y1={2.031}
        y2={15.104}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF" />
        <stop offset={0.007} stopColor="#00A1FF" />
        <stop offset={0.26} stopColor="#00BEFF" />
        <stop offset={0.512} stopColor="#00D2FF" />
        <stop offset={0.76} stopColor="#00DFFF" />
        <stop offset={1} stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="app_store_c"
        x1={18.601}
        x2={-0.261}
        y1={10.5}
        y2={10.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset={0.409} stopColor="#FFBD00" />
        <stop offset={0.775} stopColor="orange" />
        <stop offset={1} stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="app_store_e"
        x1={11.581}
        x2={-5.475}
        y1={12.22}
        y2={29.949}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44" />
        <stop offset={1} stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="app_store_f"
        x1={-2.086}
        x2={5.53}
        y1={-4.368}
        y2={3.548}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071" />
        <stop offset={0.069} stopColor="#2DA771" />
        <stop offset={0.476} stopColor="#15CF74" />
        <stop offset={0.801} stopColor="#06E775" />
        <stop offset={1} stopColor="#00F076" />
      </linearGradient>
      <filter
        id="app_store_a"
        width={18}
        height={19.291}
        x={0}
        y={0.855}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-0.155} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="shape" result="effect1_innerShadow_12301_24015" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.155} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_12301_24015"
          result="effect2_innerShadow_12301_24015"
        />
      </filter>
      <filter
        id="app_store_d"
        width={13.008}
        height={9.646}
        x={0.361}
        y={10.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-0.155} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend in2="shape" result="effect1_innerShadow_12301_24015" />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
