import { lightTheme } from '@/customTheme'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import ModalCustom from '../../../Modal/Modal'

type FooterActionButtonsProps = {
  onClickPrimary: () => void;
  onClickSecondary: () => void;
  isPrimaryButtonActive: boolean;
  isSecondaryButtonActive: boolean;
  onClickSecondaryShowDiscardModal: boolean;
  children?: React.ReactNode; // Add this line to allow children
};

const FooterActionButtons: React.FC<FooterActionButtonsProps> = ({
  onClickPrimary,
  onClickSecondary,
  isPrimaryButtonActive,
  isSecondaryButtonActive,
  onClickSecondaryShowDiscardModal,
  children, // Destructure children here
}) => {
  const [isOpenPopupDiscard, setIsOpenPopupDiscard] = useState(false)
  const disabledButtonBgColor = '#F2F2F2'
  const disabledButtonTextColor = '#ACACAC'

  const { t } = useTranslation()

  return (
    <div
      id="footer-action-buttons"
      style={{
        bottom: 0,
        left: 0,
        right: 0,
        height: '80px',
        borderTop: '1px solid #E5E5E5',
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        padding: '16px',
        background: '#fff',
        borderRadius: '0 0 12px 12px'
      }}
    >
      <ButtonCustom
        type={SizeButton.LARGE}
        kind={KIND.secondary}
        onClick={() => {
          if (isPrimaryButtonActive && onClickSecondaryShowDiscardModal) {
            setIsOpenPopupDiscard(true)
          }
          else {
            onClickSecondary()
          }
        }}
        style={{
          background: !isSecondaryButtonActive ? '#F2F2F2' : 'auto',
          pointerEvents: !isSecondaryButtonActive ? 'none' : 'auto',
          transition: 'none'
        }}
      >
        <span style={{ ...lightTheme.typography.Small14Semibold, color: !isSecondaryButtonActive ? '#CCCCCC' : '#666666' }}>{t('Cancel')}</span>
      </ButtonCustom>
      <ButtonCustom
        type={SizeButton.LARGE}
        kind={KIND.primary}
        onClick={() => {
          onClickPrimary()
        }}
        style={{
          background: isPrimaryButtonActive  ? '#FF0560' : '#F2F2F2',
          pointerEvents: isPrimaryButtonActive ? 'auto' : 'none',
          transition: 'none'
        }}
      >
        <span
          style={{
            ...lightTheme.typography.Small14Semibold,
            color: isPrimaryButtonActive ? '#FFFFFF' : '#CCCCCC',
          }}
        >
          {t('Done')}
        </span>
      </ButtonCustom>
      { children }
      <ModalCustom
        isOpen={isOpenPopupDiscard}
        onClose={setIsOpenPopupDiscard}
        content={{ header: t('Discard changes?'), desc: t('Your changes will not be saved.') }}
        action={{
          primary: {
            name: 'Discard',
            handle: () => {
              setIsOpenPopupDiscard(false)
              setTimeout(() => {
                onClickSecondary()
              }, 0)
            },
          },
          secondary: {
            name: 'Cancel',
            handle: () => {
              setIsOpenPopupDiscard(false)
            },
          },
        }}
      />
    </div>
  )
}

export default FooterActionButtons
