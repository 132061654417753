import { useEffect, useState } from 'react'
import { ThemeProvider, LightTheme } from 'baseui'
import { flatten, uniq } from 'lodash'
import { useEditorContext } from '@/scenes/engine'
import { useStyletron } from 'baseui'
import Download from '../Icons/Download'
import { useParams } from 'react-router'
import Watermark from '@assets/images/watermark_black_and_white.png'
import { ObjectType } from '@/scenes/engine/common/constants'
import { Button, KIND } from 'baseui/button'
import objectToFabric from '@/scenes/engine/utils/objectToFabric'
import { nanoid } from 'nanoid'
import * as amplitude from '@amplitude/analytics-browser'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { selectTemplateConfig, selectBackgroundActive } from '@/store/slices/templates/selectors'
import { useSelector } from 'react-redux'
import useAppContext from '@/hooks/useAppContext'
import { selectUser } from '@/store/slices/user/selectors'
import AdminApiService from '@/services/AdminApiService'
import { useTranslation } from 'react-i18next'
import { lightTheme } from '@/customTheme'

function ExportJson() {
  const editor = useEditorContext().editor

  const templateConfig = useSelector(selectTemplateConfig)

  const { isOpenPixelManipulationObject } = useAppContext()
  const user = useSelector(selectUser)

  const getLastPathSegment = (url: string): number => {
    try {
      const urlObject = new URL(url);
      const pathSegments = urlObject.pathname.split('/').filter(segment => segment.length > 0);
      let segmentStr = pathSegments[pathSegments.length - 2];
      let segmentInt = Number(segmentStr)
      return segmentInt
    } catch (error) {
      console.error("Invalid URL:", error);
      return 0;
    }
  }

  const updateTemplate = async () => {
    let template_id: number = templateConfig.parentId != 0 ? templateConfig.parentId : getLastPathSegment(window.location.href);

    let filename = `${nanoid()}.json`
    let jsonObject = editor.handlers.templateHandler.exportToJSON()

    for (let object of jsonObject) {
      for (let layer of Object.values(object)) {
        // @ts-ignore
        delete layer.src
      }
    }
    const jsonString = JSON.stringify(jsonObject);
    const blob = new Blob([jsonString], { type: "application/json" });
    const file = new File([blob], filename);
    await AdminApiService.updateTemplateAsset(template_id, "layer.json", 0, jsonString, filename)
  }
  const { t } = useTranslation()

  return (
    <Button
      // startEnhancer={() => <Download size={24} />}
      disabled={isOpenPixelManipulationObject}
      kind={user ? KIND.primary : KIND.secondary}
      onClick={() => updateTemplate()}
      overrides={{
        BaseButton: {
          style: ({ $disabled }) => {
            return {
            display: 'flex',
            gap: '6px',
            borderRadius: '8px',
            padding: '8px 12px',
            background: $disabled ? '#f2f2f2 !important' : 'auto'
            }
          },
        },
      }}
    >
    <Download size={24} fill={isOpenPixelManipulationObject ? '#cccccc' : user ? '#fff' : lightTheme.colors.blackGray} />
      <span style={{ fontSize: '14px', fontWeight: '600', lineHeight: '24px', letterSpacing: '-0.006em', color: isOpenPixelManipulationObject ? '#cccccc' :  user ? 'inherit' : lightTheme.colors.blackGray }}>
        {t('Update Template')}
      </span>
    </Button>
  )
}

export default ExportJson
