import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { useSelector } from 'react-redux'
import { selectBackgroundPacks } from '@/store/slices/backgroundPacks/selectors'
import PackContainer from '@/PackContainer'
import ColorsPackContainer from '@/ColorsPackContainer'
import { useStyletron } from 'baseui'
import { lightTheme } from '@/customTheme'
import { KIND } from 'baseui/button'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import Transparent from '@/components/icons/Transparent'
import { LabelLarge } from 'baseui/typography'
import useAppContext from '@/hooks/useAppContext'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { ObjectType } from '@/scenes/engine/common/constants'
import BtnOpenNewSlide from '@/components/BtnOpenNewSlide'
import Icons from '@/scenes/Editor/components/Icons'
import { findSizeId } from '@/scenes/Editor/components/Navbar/components/Resize'
import { setBackgoundActive } from '@/store/slices/templates/actions'
import { useAppDispatch } from '@store/store'
import GridFilter from '../../Toolbox/ToolboxItems/components/FilterBg'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { convertUnitValue } from '@/components/ShowModal/ModalCustomSize'
import { InspectorPanelType, useInspector } from '../../Toolbox/InspectorDataProvider'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { FillProps } from './Fill'

interface ResizeContentProps {
  name: string,
  icon: Icons,
  description: string,
  size: {
    width: number,
    height: number,
  },
  unit: string
}

  const Background: React.FC<{openResize?:boolean}> = ({ openResize = false }) => {
  const dispatch = useAppDispatch()
  const backgroundPacks = useSelector(selectBackgroundPacks)
  const [preObj, setPreObj] = useState(null)
  // bg select
  const [bgActiveID, setBgActiveID] = useState(null)
  const [colorSelected, setColorSelected] = useState(null)
  const [activeBackground, setActiveBackground] = useState(null)
  const [filterActiveId, setFilterActiveId] = useState(null)
  const [resizeActiveItem, setResizeActiveItem] = useState<ResizeContentProps | null>(null)
  
  const [css] = useStyletron()

  const { editor, canvas } = useEditorContext()
  const { setHasBgImage, isLoadedJsonSuccess } = useAppContext()
  const [abortForAddImage, setAbortForAddImage] = useState(null)
  const debouncedSearch = 200
  const { addInspector } = useInspector();

  const handleChangesBg = () => {
    let bgImage = editor.handlers.frameHandler.getBackgroundImage()
    let bgID = null;
    let color = null;
    if(!bgImage) {
      setBgActiveID(bgID)
      setColorSelected(color)
      return
    }
    setActiveBackground(bgImage)
    //@ts-ignore
    bgID = bgImage.backgrondIdentifier || bgImage.id
    //@ts-ignore
    color = bgImage.source_platform == 'color' ? bgID : null;
    setBgActiveID(bgID)
    setColorSelected(color)
  }

  const handleFrameSizeChange = () => {
    if(!editor) return
    const frame = editor.handlers.frameHandler.get()
    const {sizeId, icon} = findSizeId(frame.width, frame.height, true)
    // @ts-ignore
    const { width, height } = convertUnitValue('px', frame.unit, {width: frame.width, height: frame.height})
    
    if(sizeId) {
      setResizeActiveItem({
        name: sizeId.split('_')[1],
        description: `${width} x ${height}`,
        size: {
          width: frame.width,
          height: frame.height,
        },
        // @ts-ignore
        icon: icon,
        // @ts-ignore
        unit: frame.unit
      })
    } else {
      setResizeActiveItem({
        name: 'Custom size',
        icon: Icons.CustomSize,
        description: `${width} x ${height}`,
        size: {
          width: frame.width,
          height: frame.height,
        },
        // @ts-ignore
        unit: frame.unit
      })
    }
  }

  useEffect(() => {
    if (!editor) {
      return
    }

    if (preObj && preObj.id !== activeBackground.id) {
      setPreObj(activeBackground)
    } else {
      setPreObj(activeBackground)
    }
  }, [activeBackground])

  useEffect(() => {
    let isMounted = true
    const safeHandleChangesBg = () => {
      if (isMounted) {
        handleChangesBg()
      }
    }
  
    if (editor) {
      editor.on('history:changed', value => {
        //TODO: Filter image will reload after undo/redo so previews reload. Close Filter and Adjust for better UX. Check again after image do not need to reload
        if (!value || !value.afterUndo) { return }
        safeHandleChangesBg()
      });
  
      editor.on('background:changed', () => {
        safeHandleChangesBg()
      })
  
      return () => {
        isMounted = false
        editor.off('history:changed')
        editor.off('background:changed')
      }
    }

    return () => {
      isMounted = false
    }
  }, [editor])

  useEffect(() => {
    if(isLoadedJsonSuccess) {
      handleFrameSizeChange()
    }
    if(!editor) return
    editor.on('frame:changed', handleFrameSizeChange)

    return () => {
      editor.off('frame:changed', handleFrameSizeChange)
    }
  }, [isLoadedJsonSuccess, editor])

  useEffect(()=>{
    if (openResize) {
      addInspector(InspectorPanelType.Resize, { state: resizeActiveItem, setState: setResizeActiveItem })
    }
  }, [openResize])

  // function
  const loadColor = async (color: string) => {
    setBgActiveID(null)
    if (abortForAddImage) {
      abortForAddImage.abort()
    }
    editor.handlers.frameHandler.setBackgroundColor(color)

    setColorSelected(color)
    const eventProperties = {
      Tool: 'bazaart.add.background',
      Type: 'color',
      'Layer Type': ObjectType.BAZAART_BG,
    }
    customAmplitude('Selected tool', eventProperties)
  }

  const loadBackgroundImage = async backgroundObject => {
    if (backgroundObject && backgroundObject.id !== bgActiveID) {
      if (abortForAddImage) {
        abortForAddImage.abort()
      }
      let abortController = new AbortController()

      setAbortForAddImage(abortController)
      setBgActiveID(backgroundObject.id)
      setColorSelected(null)
      let resizeLatestURL
      let resizeThumbnailURL
      if(backgroundObject.urls) {
        let imageProcessing = new MediaImageRepositoryProcessing()
        resizeLatestURL = await imageProcessing.resizeBlobToMaxEdgeSize(backgroundObject.urls.regular, 1600)
        resizeThumbnailURL = await imageProcessing.resizeBlobToMaxEdgeSize(backgroundObject.urls.thumb, 32)
      } else {
        resizeLatestURL = getResizeUrl({ size: '0x1600', url: backgroundObject.image.slice(8) })
        resizeThumbnailURL = backgroundObject.thumbnail ? backgroundObject.thumbnail : getResizeUrl({ size: '0x32', url: backgroundObject.image.slice(8) })
      }
      await editor.handlers.frameHandler.setBackgroundImageURL(
        backgroundObject,
        resizeLatestURL,
        resizeThumbnailURL,
        abortController.signal
      )
      setHasBgImage(true)
      dispatch(setBackgoundActive({ id: backgroundObject.id, category: backgroundObject.source_platform ?? 'unsplash' }))
      const eventProperties = {
        Tool: 'bazaart.add.background',
        Type: 'fill',
        'Layer Type': ObjectType.BAZAART_BG,
      }
      customAmplitude('Selected tool', eventProperties)
    }
  }

  const handleSelectBgImage = async backgroundObject => {
    loadBackgroundImage(backgroundObject)
  }

  const loadTransparent = async () => {
    if (abortForAddImage) {
      abortForAddImage.abort()
    }
    await editor.handlers.frameHandler.setTransparentBg()
    setHasBgImage(false)
    setBgActiveID('Transparent')
    setColorSelected(null)

    const eventProperties = {
      Tool: 'bazaart.transparent',
    }
    customAmplitude('Selected tool', eventProperties)
  }

  const handleTransparentBg = async () => {
    if (bgActiveID === 'Transparent') {
      return
    }
    loadTransparent()
  }

  const fillProps: FillProps = {
    handleSelectBgImage,
    debouncedSearch,
    isFocusSearchBox: false
  }

  useEffect(() => {
    if (canvas && isLoadedJsonSuccess) {
      let canvasObjs = canvas.getObjects()
      let numberObj = canvasObjs.length

      if (!numberObj) {
        setColorSelected('#ffffff')
      } else if (
        numberObj === 2 &&
        canvasObjs[0].type === ObjectType.FRAME &&
        canvasObjs[1].type === ObjectType.BAZAART_BG
      ) {
      }
    }
  }, [canvas, isLoadedJsonSuccess])

  const { t } = useTranslation()

  const onSearchIcon = () => {
    fillProps.isFocusSearchBox = true
    addInspector(InspectorPanelType.Fill, { props: fillProps, state: bgActiveID, setState: setBgActiveID })
    customAmplitude('Selected Tool', {
      Tool: 'bazaart.add.background.search',
    })
  }

  const content = (resizeActiveItem: ResizeContentProps | null) => {
    return <>
      {/* TODO */}
      <div
      style={{
        width: '100%',
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
      }}
    >
      <span style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>{t('Resize')}</span>
    </div>
    {resizeActiveItem ? (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          border: lightTheme.borders.borderDefault,
          borderRadius: '8px',
          height: '66px',
          cursor: 'pointer',
          position: 'relative',
          boxSizing: 'border-box',
          ':hover': {
            background: lightTheme.colors.grayScale50,
          },
        })}
        onClick={e => {
          addInspector(InspectorPanelType.Resize, { state: resizeActiveItem, setState: setResizeActiveItem });
        }}
      >
        <>
          <div
            style={{
              padding: '8px',
              width: '66px',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: `${
                  (resizeActiveItem.name === 'Custom size' ? 50 : resizeActiveItem.size.width > resizeActiveItem.size.height
                    ? 50
                    : resizeActiveItem.size.width / resizeActiveItem.size.height) * 50
                }px`,
                height: `${
                  resizeActiveItem.name === 'Custom size' ? 50 : resizeActiveItem.size.height > resizeActiveItem.size.width
                    ? 50
                    : (resizeActiveItem.size.height / resizeActiveItem.size.width) * 50
                }px`,
                background: lightTheme.colors.grayScale100,
                borderRadius: '4px',
              }}
            >
              {resizeActiveItem.icon ? resizeActiveItem.icon : null}
            </div>
          </div>
          <span
            style={{
              position: 'absolute',
              left: '66px',
              top: '8px',
              bottom: '9px',
              borderRight: '1px solid rgba(229, 229, 229, 1)',
            }}
          ></span>
          <div
            style={{
              width: '170px',
              display: 'flex',
              flexDirection: 'column',
              padding: '16px 0 16px 8px',
              gap: '2px',
            }}
          >
            {resizeActiveItem.name ? (
              <p
                style={{
                  margin: 0,
                  ...lightTheme.typography.Small11medium,
                  color: lightTheme.colors.contentPrimary,
                }}
              >
                {t(resizeActiveItem.name)}
              </p>
            ) : null}
            {resizeActiveItem.description ? (
              <p
                style={{
                  margin: 0,
                  ...lightTheme.typography.Small11medium,
                  color: lightTheme.colors.contentPrimary,
                }}
              >
                {resizeActiveItem.description} {resizeActiveItem.unit ? resizeActiveItem.unit : t('px')}
              </p>
            ) : null}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: '12px',
          }}>
            <Icons.CustomArrow size={24} direction='right' />
          </div>
        </>
      </div>
    ) : null}
    <ColorsPackContainer
      style={{
        boxSizing: 'border-box',
        padding: '0',
        width: '300px',
      }}
      onItemSelected={loadColor}
      colorSelected={colorSelected}
    ></ColorsPackContainer>
    <PackContainer
      style={{
        padding: '0 ',
        height: 'fit-content',
        boxSizing: 'border-box',
        // marginTop: '50px',
      }}
      pack={backgroundPacks ? backgroundPacks[0] : null}
      onItemSelected={handleSelectBgImage}
      handleClickSubAction={() => {
        fillProps.isFocusSearchBox = false
        addInspector(InspectorPanelType.Fill, { props: fillProps, state: bgActiveID, setState: setBgActiveID })
        customAmplitude('Selected Tool', {
          Tool: 'bazaart.add.background.see_all',
        })
      }}
      bgActiveID={bgActiveID}
      itemsPerLine={5}
      hasPremium={true}
      showSearchButton={true}
      handleClickSearchIcon={onSearchIcon}
    />
    <div className="mt-50">
      <ButtonCustom
        kind={KIND.tertiary}
        type={SizeButton.LARGE}
        startEnhancer={<Transparent size={24} />}
        onClick={handleTransparentBg}
      >
        <LabelLarge>{t('Transparent')}</LabelLarge>
      </ButtonCustom>
    </div>
    {bgActiveID && bgActiveID !== 'Transparent' ? (
      <div>
        <GridFilter
          handleSelectItem={() => {}}
          actionToggle={() => {
            addInspector(InspectorPanelType.Filter, { object: activeBackground })
          }}
          filterObject={activeBackground}
          filterActiveId={filterActiveId}
          setFilterActiveId={setFilterActiveId}
        />
        <BtnOpenNewSlide
          startEnhancer={<Icons.Adjust size={24} />}
          endEnhancer={<Icons.BackRight size={24} />}
          handleOnClick={() => addInspector(InspectorPanelType.Adjust, { object: activeBackground })}
          nameAction={t('Adjust')}
          
        ></BtnOpenNewSlide>
      </div>
    ) : null}
    </>
  }
  
  return (
    <>
      {content(resizeActiveItem)}
    </>
  )
}

export default Background
