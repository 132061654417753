export const displayConfetti = () => {
    //-----------Var Inits--------------
    const canvas = document.getElementById('canvas-confetti') as HTMLCanvasElement
    let ctx = canvas.getContext('2d')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    canvas.style.zIndex = '9999'
    let confetti = []
    const confettiCount = 300
    const gravity = 0.3
    const terminalVelocity = 2.5
    const drag = 0.075
    const colors = [
      { front: '#EDC65E', back: '#F0C269' },
      { front: '#4ABEE2', back: '#42C2D4' },
      { front: '#E8548B', back: '#DD5293' },
      { front: '#B74CDB', back: '#A457DE' },
      { front: '#6F1CB8', back: '#6424C0' },
    ]
    //-----------Functions--------------
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    const randomRange = (min, max) => Math.random() * (max - min) + min
    const initConfetti = () => {
      for (let i = 0; i < confettiCount; i++) {
        confetti.push({
          color: colors[Math.floor(randomRange(0, colors.length))],
          dimensions: {
            x: randomRange(5, 10),
            y: randomRange(5, 15),
          },
          position: {
            x: randomRange(0, canvas.width),
            y: canvas.height - 1,
          },
          rotation: randomRange(0, 2 * Math.PI),
          scale: {
            x: 1,
            y: 1,
          },
          velocity: {
            x: randomRange(-25, 25),
            y: randomRange(0, -25),
          },
        })
      }
    }
    //---------Render-----------
    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      confetti.forEach((confetto, index) => {
        let width = confetto.dimensions.x * confetto.scale.x
        let height = confetto.dimensions.y * confetto.scale.y
        // Move canvas to position and rotate
        ctx.translate(confetto.position.x, confetto.position.y)
        ctx.rotate(confetto.rotation)
        // Apply forces to velocity
        confetto.velocity.x -= confetto.velocity.x * drag
        confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity)
        confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()
        // Set position
        confetto.position.x += confetto.velocity.x
        confetto.position.y += confetto.velocity.y
        // Delete confetti when out of frame
        if (confetto.position.y >= canvas.height) confetti.splice(index, 1)
        // Loop confetto x position
        if (confetto.position.x > canvas.width) confetto.position.x = 0
        if (confetto.position.x < 0) confetto.position.x = canvas.width
        // Spin confetto by scaling y
        confetto.scale.y = Math.cos(confetto.position.y * 0.1)
        ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back
        // Draw confetto
        ctx.fillRect(-width / 2, -height / 2, width, height)
        // Reset transform matrix
        ctx.setTransform(1, 0, 0, 1, 0, 0)
      })
      const idAnimation = window.requestAnimationFrame(render)
      // Fire off another round of confetti
      if (confetti.length === 0) {
        window.cancelAnimationFrame(idAnimation);
        const isDisplayedModalDownloadApp = localStorage.getItem('isDisplayedModalDownloadApp')
        if(isDisplayedModalDownloadApp) {
          canvas.style.display = 'none'
          return
        }
        initConfetti()
        render()
      }
    }
    //---------Execution--------
    initConfetti()
    render()
  }