import { useEditorContext } from '@/scenes/engine'
import { styled } from 'baseui'
import { useEffect, useState } from 'react'
import ToolboxItems from './ToolboxItems'
import Locked from './ToolboxItems/Locked'
import isArray from 'lodash/isArray'
import { ObjectType } from '@/scenes/engine/common/constants'

const Container = styled('div', props => ({
  // width: '100%',
  backgroundColor: props.$theme.colors.background,
  // filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))',
  boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.08)',
  marginLeft: '1px',
  display: 'flex',
  width: '340px',
  position: 'absolute',
  height: '100%',
  borderRadius: '12px',
}))

export const getContextMenuType = (selection: any) => {

  if (!selection) {
    return 'Default'
  }

  // if we want to group multi select of same type and show inpector of this type,
  // to be able to make 1 acotion to multi items for example to change font of few texts with 1 action 

  // const types = new Set()
  // if (selection._objects) {
  //   for (const object of selection._objects) {
  //     types.add(object.type)
  //   }
  // } else {
  //   types.add(selection.type)
  // }

  // const typesArray = Array.from(types)

  const typesArray = Array.from(selection._objects ? selection._objects.map(x=>x.type): [selection.type])

  if (typesArray.length === 1) {
    if (typesArray[0] === 'Background') {
      return 'Default'
    } else {
      return typesArray[0]
    }
  } else {
    return typesArray
  }
}

const toolboxOptions = {
  Default: 'Default',
  StaticText: 'StaticText',
  StaticPath: 'StaticPath',
  StaticImage: 'StaticImage',
  MultiElement: 'MultiElement',
  DynamicText: 'DynamicText',
  DynamicImage: 'DynamicImage',
}

const getToolBoxItem = (objectType: ObjectType) => {
  switch (objectType) {
    case ObjectType.BAZAART_IMAGE: {
      return 'StaticImage'
    }
    case ObjectType.BAZAART_STICKER: {
      return 'Sticker'
    }
    case ObjectType.BAZAART_SHAP: {
      return 'Sticker'
    }
    case ObjectType.BAZAART_TEXT: {
      return 'StaticText'
    }
    case ObjectType.STATIC_TEXT: {
      return 'StaticText'
    }
    default:
      return toolboxOptions[objectType]
  }
}

function EditorToolbox() {
  const [activeToolbox, setActiveToolbox] = useState('Default')

  const [locked, setLocked] = useState(false)
  const { activeObject, editor } = useEditorContext()
  // const editor = useEditorContext().

  useEffect(() => {
    if (activeObject) {
      // @ts-ignore
      setLocked(activeObject.locked)
      const activeObjectType = getContextMenuType(activeObject)
        // @ts-ignore
      if (isArray(activeObjectType)) {
        // @ts-ignore
        setActiveToolbox(getToolBoxItem('MultiElement'))
      } else {
        // @ts-ignore
        setActiveToolbox(getToolBoxItem(activeObjectType))
      }
    } else {
      setLocked(false)
      setActiveToolbox(null)
    }
  }, [activeObject])

  useEffect(() => {
    const handleHistoryChange = () => {
      if (activeObject) {
        // @ts-ignore
        setLocked(activeObject.locked)
      } else {
        setLocked(false)
        // setActiveToolbox(null)
      }
    }
    if (editor) {
      editor.on('history:changed', handleHistoryChange)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleHistoryChange)
      }
    }
  }, [editor, activeObject])

  if (!activeObject) {
    return (
      <></>
      // <Container>{/* <ToolboxItems.Default /> */}</Container>
    )
  }
  if (locked) {
    return (
      <Container>
        <Locked />
      </Container>
    )
  }

  const Toolbox = activeObject ? ToolboxItems[activeToolbox] : null
  return <Container>{Toolbox ? <Toolbox /> : <></>}</Container>
}

export default EditorToolbox
