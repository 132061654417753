import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { setUploads } from '@/store/slices/uploads/actions'
import { useAppDispatch } from '@/store/store'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

function ModalConfirmExit() {
  const { showModalType, setShowModalType, setisOpenPixelManipulationObject, setToolType } = useAppContext()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.CONFIRM_EXIT_EDITING}
      onClose={() => setShowModalType(null)}
      content={{
        header: t('Discard your design?'),
        desc: t("You're leaving this page and your design will not be saved."),
      }}
      action={{
        primary: {
          name: t('Discard'),
          handle: () => {
            dispatch(setUploads([]))
            history.push('/create')
            setShowModalType(null)
            dispatch(setCallbackFromCreationPage(null))
            setisOpenPixelManipulationObject(false)
            setToolType(null)
            customAmplitude('Open', { Source: 'Editor' })
          },
        },
        secondary: {
          name: t('Cancel'),
          handle: () => {
            setShowModalType(null)
          },
        },
      }}
    />
  )
}

export default ModalConfirmExit