import { IStorePack } from '@/interfaces/editor'
import { createReducer } from '@reduxjs/toolkit'
import { setPack } from './actions'

export interface PacksState {
  packs: any[][]
}

const initialState: PacksState = {
  packs: [],
}

export const packsReducer = createReducer(initialState, builder => {
  builder.addCase(setPack, (state, { payload }) => {
    state.packs[payload[0].store] = payload
  })
})
