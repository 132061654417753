import { PanelType, PopoverType } from '@/constants/app-options'
import { SubMenuType } from '@/constants/editor'
import {
  ContextMenuDetails,
  DRAGGABLE_TYPE,
  ObjectType,
  ShowModalType,
  ToolType,
} from '@/scenes/engine/common/constants'
import React, { createContext, useState, FC } from 'react'

type Template = any
interface IAppContext {
  isMobile: boolean | undefined
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>
  templates: Template[]
  setTemplates: (templates: Template[]) => void
  uploads: any[]
  setUploads: (templates: any[]) => void
  shapes: any[]
  setShapes: (templates: any[]) => void
  activePanel: PanelType
  setActivePanel: (option: PanelType) => void
  activeSubMenu: SubMenuType | null
  setActiveSubMenu: (option: SubMenuType) => void
  currentTemplate: any
  setCurrentTemplate: any
  removingBg: boolean
  setRemovingBg: (value: boolean) => void
  removeBgSuccess: boolean
  setRemoveBgSuccess: (status: boolean) => void
  isOpenInspector: boolean
  setIsOpenInspector: (status: boolean) => void
  removeBgAbortController: AbortController
  setRemoveBgAbortController: (value: AbortController) => void
  hasBgImage: boolean
  setHasBgImage: (value: boolean) => void
  isLoadedJsonSuccess: boolean
  setIsLoadedJsonSuccess: (value: boolean) => void
  objDragging: {
    item: any
    type: DRAGGABLE_TYPE
    dropped?: boolean
  } | null
  setObjDragging: (value: { item: any; type: DRAGGABLE_TYPE, dropped?: boolean }) => void
  isReplacingImage: boolean
  setIsReplacingImage: (value: boolean) => void
  isOpenPixelManipulationObject: boolean
  setisOpenPixelManipulationObject: (value: boolean) => void
  isOpenPixelManipulationWithAnimation: boolean
  setIsOpenPixelManipulationWithAnimation: (value: boolean) => void
  popoverActive: null | PopoverType
  setPopoverActive: (value: null | PopoverType) => void
  toolType: ToolType
  setToolType: (value: ToolType) => void
  showModalType: ShowModalType | null
  setShowModalType: (value: ShowModalType | null) => void
  isOpenTutorial: boolean,
  setIsOpenTutorial: (value: boolean) => void,
  canCancelRemoveBg: boolean,
  setCanCancelRemoveBg: (value: boolean) => void
  isOpenFontFamily: boolean, 
  setIsOpenFontFamily: (value: boolean) => void,
  // common function
  cancelPixelManipulation: (cancelRemoveBg?: boolean) => void
}

export const AppContext = createContext<IAppContext>({
  isMobile: false,
  setIsMobile: () => {},
  templates: [],
  setTemplates: () => {},
  uploads: [],
  setUploads: () => {},
  shapes: [],
  setShapes: () => {},
  activePanel: PanelType.BACKGROUND,
  setActivePanel: () => {},
  activeSubMenu: null,
  setActiveSubMenu: (value: SubMenuType) => {},
  currentTemplate: {},
  setCurrentTemplate: {},
  removingBg: false,
  setRemovingBg: (value: boolean) => {},
  removeBgSuccess: false,
  setRemoveBgSuccess: (value: Boolean) => {},
  isOpenInspector: true,
  setIsOpenInspector: (value: Boolean) => {},
  removeBgAbortController: null,
  setRemoveBgAbortController: (value: AbortController) => {},
  hasBgImage: false,
  setHasBgImage: (value: boolean) => {},
  isLoadedJsonSuccess: false,
  setIsLoadedJsonSuccess: (value: boolean) => {},
  objDragging: null,
  setObjDragging: (value: { item: any; type: DRAGGABLE_TYPE }) => {},
  isReplacingImage: false,
  setIsReplacingImage: (value: boolean) => {},
  isOpenPixelManipulationObject: false,
  setisOpenPixelManipulationObject: (value: boolean) => {},
  isOpenPixelManipulationWithAnimation: true,
  setIsOpenPixelManipulationWithAnimation: (value: boolean) => {},
  popoverActive: null,
  setPopoverActive: (value: null | PopoverType) => {},
  toolType: '',
  setToolType: (value: ToolType) => {},
  showModalType: null,
  setShowModalType: (value: ShowModalType | null) => {},
  isOpenTutorial: false,
  setIsOpenTutorial:  (value: boolean) => {},
  canCancelRemoveBg: true,
  setCanCancelRemoveBg: (value: boolean) => {},
  cancelPixelManipulation: () => {},
  isOpenFontFamily: false, 
  setIsOpenFontFamily: (value: boolean) => {}
})

export const AppProvider: FC = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(undefined)
  const [templates, setTemplates] = useState<Template[]>([])
  const [uploads, setUploads] = useState<any[]>([])
  const [shapes, setShapes] = useState<Template[]>([])
  const [activePanel, setActivePanel] = useState<PanelType>(PanelType.BACKGROUND)
  const [activeSubMenu, setActiveSubMenu] = useState<SubMenuType | null>(null)
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [removingBg, setRemovingBg] = useState(false)
  const [removeBgSuccess, setRemoveBgSuccess] = useState(false)
  const [isOpenInspector, setIsOpenInspector] = useState(true)
  const [removeBgAbortController, setRemoveBgAbortController] = useState(null)
  const [hasBgImage, setHasBgImage] = useState(false)
  const [isLoadedJsonSuccess, setIsLoadedJsonSuccess] = useState(true)
  const [objDragging, setObjDragging] = useState<{
    item: any
    type: DRAGGABLE_TYPE
  }>({
    item: null,
    type: ObjectType.BAZAART_IMAGE,
  })
  const [isReplacingImage, setIsReplacingImage] = useState(false)
  const [isOpenPixelManipulationObject, setisOpenPixelManipulationObject] = useState(false)
  const [isOpenPixelManipulationWithAnimation, setIsOpenPixelManipulationWithAnimation] = useState(true)
  const [popoverActive, setPopoverActive] = useState(null)
  const [toolType, setToolType] = useState<ToolType>('')
  const [showModalType, setShowModalType] = useState<ShowModalType | null>(null)
  const [isOpenTutorial, setIsOpenTutorial] = useState(false)
  const [canCancelRemoveBg, setCanCancelRemoveBg] = useState(true)
  const [isOpenFontFamily, setIsOpenFontFamily] = useState(false)


  // common function
  const cancelPixelManipulation = (cancelRemoveBg = false) => {
    setisOpenPixelManipulationObject(false)
    setIsOpenPixelManipulationWithAnimation(true)
    setToolType("")
    if(cancelRemoveBg) {
      setRemovingBg(false)
      setRemoveBgSuccess(true)
    }
  }

  const context = {
    isMobile,
    setIsMobile,
    templates,
    setTemplates,
    activePanel,
    setActivePanel,
    shapes,
    setShapes,
    activeSubMenu,
    setActiveSubMenu,
    uploads,
    setUploads,
    currentTemplate,
    setCurrentTemplate,
    removingBg,
    setRemovingBg,
    removeBgSuccess,
    setRemoveBgSuccess,
    isOpenInspector,
    setIsOpenInspector,
    removeBgAbortController,
    setRemoveBgAbortController,
    hasBgImage,
    setHasBgImage,
    isLoadedJsonSuccess,
    setIsLoadedJsonSuccess,
    objDragging,
    setObjDragging,
    isReplacingImage,
    setIsReplacingImage,
    isOpenPixelManipulationObject,
    setisOpenPixelManipulationObject,
    isOpenPixelManipulationWithAnimation,
    setIsOpenPixelManipulationWithAnimation,
    popoverActive,
    setPopoverActive,
    toolType,
    setToolType,
    showModalType,
    setShowModalType,
    isOpenTutorial, 
    setIsOpenTutorial,
    canCancelRemoveBg,
    setCanCancelRemoveBg,
    cancelPixelManipulation,
    isOpenFontFamily, 
    setIsOpenFontFamily
  }
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}
