import { fabric } from 'fabric'
import RemoveBaseHandler from './RemoveBaseHandler'

class RemoveZoomHandler extends RemoveBaseHandler {
  minZoom = 1
  maxZoom = 5
  currentZoom = 1
  prevZoom = 1
  isOpenInspector = true
  baseStep = 1
  sliderStep = 1

  prevScrollLeft = 0
  prevScrollTop = 0
  prevScrollWidth = 0
  prevScreenWidth = 0
  prevScrollHeight = 0
  prevScreenHeight = 0

  zoomIn() {
    if (this.currentZoom >= 5) {
      return
    }
    this.prevZoom = this.currentZoom
    this.currentZoom = Math.min(
      Number((Math.round(this.currentZoom * 100) / 100).toFixed(2)) + this.baseStep,
      this.maxZoom
    )
    this.updateZoom()
  }

  zoomOut() {
    if (this.currentZoom <= 1) {
      return
    }
    this.prevZoom = this.currentZoom
    this.currentZoom = Math.max(this.currentZoom - this.baseStep, this.minZoom)
    this.updateZoom()
  }

  updateZoom(pointer = null) {
    let wrapCanvasRemoveTool = document.getElementById('wrap-canvas-remove-zoom-tool')
    const container = document.getElementById('wrap-canvas-remove-tool')
    this.prevScreenWidth = container.offsetWidth
    this.prevScreenHeight = container.offsetHeight
    wrapCanvasRemoveTool.style.width = this.currentZoom > 1 ? '100%' : 'unset'
    wrapCanvasRemoveTool.style.height = this.currentZoom > 1 ? '100%' : 'unset'
    wrapCanvasRemoveTool.style.transformOrigin = this.currentZoom > 1 ? 'top left' : 'unset'
    let pageX, pageY, clientX, clientY = 0
    let newPageX, newPageY, newScrollLeft, newScrollTop = 0
    if(pointer) {
      pageX = pointer.pageX
      pageY = pointer.pageY
      clientX = pointer.clientX
      clientY = pointer.clientY
      if(this.currentZoom > this.prevZoom) {
        newPageX = pageX * this.currentZoom
        newPageY = (pageY- 64) * this.currentZoom
        newScrollLeft = newPageX - clientX
        newScrollTop = newPageY - (clientY - 64)
      } else {
        newPageX = pageX * this.currentZoom
        newPageY = (pageY- 64) * this.currentZoom
        newScrollLeft = newPageX - clientX
        newScrollTop = newPageY - (clientY - 64)
      }
    }
    wrapCanvasRemoveTool.style.transform = `scale(${this.currentZoom})`
    container.style.overflow = this.currentZoom > 1 ? 'auto' : 'hidden'
    // center image
    let centerLeftImage = (window.innerWidth - 356) / 2 + 44
    let centerTopImage = (window.innerHeight - 64) / 2

    if(!this.isOpenInspector) {
      centerLeftImage = window.innerWidth / 2
    }

    this.root.eventsRemoveHandler.programScrolling = true
    if(pointer) {
      container.scrollLeft = newScrollLeft
      container.scrollTop = newScrollTop 
    } else {
      if (this.currentZoom === 1) {
        // Scroll to center position
        container.scrollLeft = centerLeftImage * this.baseStep
        container.scrollTop = centerTopImage * this.baseStep
      } else {
        if(this.currentZoom > this.prevZoom) {
          container.scrollLeft += centerLeftImage * (this.currentZoom - this.prevZoom)
          container.scrollTop += centerTopImage * (this.currentZoom - this.prevZoom)
        } else {
          container.scrollLeft -= centerLeftImage * (this.prevZoom - this.currentZoom)
          container.scrollTop -= centerTopImage * (this.prevZoom - this.currentZoom)
        }
      }
    }
    this.editorEventManager.emit('updateZoomRatio', this.currentZoom)
    this.root.pixelManipulationObjectHandler.addChessBg()
    this.prevScrollLeft = container.scrollLeft
    this.prevScrollTop = container.scrollTop
    this.prevScrollWidth = container.scrollWidth
    this.prevScrollHeight = container.scrollHeight
  }

  zoomToOne() {
    this.zoomToRatio(this.minZoom + 1)
  }

  zoomToFit() {
    this.currentZoom = 1
    this.updateZoom()
  }

  zoomToRatio(zoomRatio, pointer = null) {
    if (zoomRatio === this.currentZoom) { return }
    this.prevZoom = this.currentZoom
    this.currentZoom = zoomRatio
    this.updateZoom(pointer)
  }

  zoomToPoint(point, zoom, toFit = false) {
    // const minZoom = 10
    // const maxZoom = 300
    let zoomRatio = zoom
    if (zoom <= this.minZoom / 100) {
      zoomRatio = this.minZoom / 100
    } else if (zoom >= this.maxZoom / 100) {
      zoomRatio = this.maxZoom / 100
    }
    if (toFit) {
      let addMenuOffset = 88 / 2.0

      // when resizing canvas we apply an affine transform with scalex and scaley (== zoom) on both size and center of the canvas.
      // We want to reposition the center to the initial value so we substract the change.
      //
      //
      //   (4,4) _______________
      //        |               |
      //        |               |
      //        |    (10, 10)   |
      //        |               |
      //        |               |
      //        |_______________|
      //
      //  Downscale by 2:
      //   (2,2) ________
      //        |       |
      //        | (5, 5)|
      //        |_______|
      //
      //  but we wanted to keep it at the center of the view! so move it back there manually
      //   (4,4) ________
      //        |       |
      //        | (5, 5)|
      //        |_______|
      // TADAM

      let oldCenter = point
      let newCenter = new fabric.Point(point.x * zoom, point.y * zoom)

      let centerX = oldCenter.x - newCenter.x
      let centerY = oldCenter.y - newCenter.y

      this.canvas.setViewportTransform([zoom, 0, 0, zoom, centerX + addMenuOffset, centerY])
    } else {
      this.canvas.zoomToPoint(point, zoomRatio)
    }

    // this.context.setZoomRatio(zoomRatio)
    this.canvas.forEachObject(obj => obj.setCoords())
  }

  calculateScrollPosition() {
    const scrollElement = document.getElementById('wrap-canvas-remove-tool')
    if(scrollElement.style.overflow !== 'auto') { return }
    
    const newScrollWidth = scrollElement.scrollWidth
    const newScrollHeight = scrollElement.scrollHeight
    const newScreenWidth = scrollElement.offsetWidth
    const newScreenHeight = scrollElement.offsetHeight

    const oldAvailableScrollX = this.prevScrollWidth - this.prevScreenWidth
    const oldAvailableScrollY = this.prevScrollHeight - this.prevScreenHeight

    const prevScrollXRatio = this.prevScrollLeft / oldAvailableScrollX
    const prevScrollYRatio = this.prevScrollTop / oldAvailableScrollY

    const newScrollLeft = prevScrollXRatio * (newScrollWidth - newScreenWidth)
    const newScrollTop = prevScrollYRatio * (newScrollHeight - newScreenHeight)

    scrollElement.scrollLeft = newScrollLeft
    scrollElement.scrollTop = newScrollTop

    this.prevScrollLeft = newScrollLeft
    this.prevScrollTop = newScrollTop
    this.prevScreenWidth = newScreenWidth
    this.prevScreenHeight = newScreenHeight
    this.prevScrollHeight = newScrollHeight
    this.prevScrollWidth = newScrollWidth
  }
}

export default RemoveZoomHandler