import * as React from 'react'

const SvgComponent = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m13.76 12.293-4.77 4.572a1.313 1.313 0 0 0 1.816 1.896l5.801-5.559a1.26 1.26 0 0 0 .393-.909 1.26 1.26 0 0 0-.393-.909l-5.801-5.558a1.313 1.313 0 0 0-1.817 1.896l4.771 4.571Z"
      fill="#666"
    />
  </svg>
)

export default SvgComponent
