import * as React from 'react'

const SvgComponent = props => (
  <svg width={26} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 4.519c-.608.006-.932.346-.926.957l.048 4.949c.006.618.336.945.944.939l2.061-.025c1.389-.018 2.308-.803 2.296-1.964-.006-.92-.63-1.588-1.52-1.638v-.074c.727-.148 1.178-.729 1.172-1.507-.006-1.032-.806-1.668-2.062-1.656l-2.014.019Zm2.506 1.94c.006.488-.313.821-1.046.828l-.77.006-.017-1.607.943-.012c.56-.006.884.303.89.785Zm.282 2.798c.006.556-.348.902-1.208.915l-.86.012-.017-1.823 1.021-.012c.698-.012 1.058.352 1.064.908ZM3.379 15.35c.156.315.264.482.378.593.283.272.686.241.992-.086.295-.321.295-.649-.006-1.168l-.396-.754 1.538-1.668.752.371c.51.272.853.253 1.148-.068.318-.346.33-.76.024-1.063-.127-.117-.295-.222-.553-.352L3.12 9.197c-.763-.359-1.244-.31-1.725.216-.487.525-.517 1.032-.126 1.785l2.11 4.152Zm-.902-4.887 2.338 1.23-1.088 1.174-1.304-2.348.054-.056Z"
      fill="#666"
    />
    <path
      d="M19.807 9.256c1.353-1.396 2.91-1.458 4.208-.13.613.63.973 1.378.985 2.064.006.463-.12.877-.336 1.1-.277.284-.65.278-.926-.007-.18-.185-.246-.395-.246-.766.06-.593-.043-.988-.373-1.328-.62-.636-1.43-.525-2.224.296l-.409.42c-.847.872-.955 1.7-.33 2.342.336.34.745.47 1.304.402.373-.006.559.05.74.234.276.285.282.674.011.952-.27.278-.763.401-1.286.321-.601-.074-1.232-.408-1.797-.988-1.317-1.353-1.244-2.929.168-4.38l.511-.532ZM19.634 18.292c.163.464-.143.94-.613 1.037-.456.093-.894-.21-1.066-.653a5.606 5.606 0 0 0-1.785-2.398 5.847 5.847 0 0 0-3.667-1.23 5.84 5.84 0 0 0-3.635 1.32 5.596 5.596 0 0 0-1.721 2.437c-.161.45-.593.764-1.052.682-.471-.084-.789-.553-.638-1.02a7.256 7.256 0 0 1 2.306-3.374 7.58 7.58 0 0 1 4.718-1.712 7.59 7.59 0 0 1 4.76 1.595 7.27 7.27 0 0 1 2.393 3.316Z"
      fill="#666"
    />
    <path
      clipRule="evenodd"
      d="M11.5 4.519c-.608.006-.932.346-.926.957l.048 4.949c.006.618.336.945.944.939l2.061-.025c1.389-.018 2.308-.803 2.296-1.964-.006-.92-.63-1.588-1.52-1.638v-.074c.727-.148 1.178-.729 1.172-1.507-.006-1.032-.806-1.668-2.062-1.656l-2.014.019Zm2.506 1.94c.006.488-.313.821-1.046.828l-.77.006-.017-1.607.943-.012c.56-.006.884.303.89.785Zm.282 2.798c.006.556-.348.902-1.208.915l-.86.012-.017-1.823 1.021-.012c.698-.012 1.058.352 1.064.908ZM3.379 15.35c.156.315.264.482.378.593.283.272.686.241.992-.086.295-.321.295-.649-.006-1.168l-.396-.754 1.538-1.668.752.371c.51.272.853.253 1.148-.068.318-.346.33-.76.024-1.063-.127-.117-.295-.222-.553-.352L3.12 9.197c-.763-.359-1.244-.31-1.725.216-.487.525-.517 1.032-.126 1.785l2.11 4.152Zm-.902-4.887 2.338 1.23-1.088 1.174-1.304-2.348.054-.056Z"
      stroke="#666"
      strokeWidth={0.5}
    />
    <path
      d="M19.807 9.256c1.353-1.396 2.91-1.458 4.208-.13.613.63.973 1.378.985 2.064.006.463-.12.877-.336 1.1-.277.284-.65.278-.926-.007-.18-.185-.246-.395-.246-.766.06-.593-.043-.988-.373-1.328-.62-.636-1.43-.525-2.224.296l-.409.42c-.847.872-.955 1.7-.33 2.342.336.34.745.47 1.304.402.373-.006.559.05.74.234.276.285.282.674.011.952-.27.278-.763.401-1.286.321-.601-.074-1.232-.408-1.797-.988-1.317-1.353-1.244-2.929.168-4.38l.511-.532ZM19.634 18.292c.163.464-.143.94-.613 1.037-.456.093-.894-.21-1.066-.653a5.606 5.606 0 0 0-1.785-2.398 5.847 5.847 0 0 0-3.667-1.23 5.84 5.84 0 0 0-3.635 1.32 5.596 5.596 0 0 0-1.721 2.437c-.161.45-.593.764-1.052.682-.471-.084-.789-.553-.638-1.02a7.256 7.256 0 0 1 2.306-3.374 7.58 7.58 0 0 1 4.718-1.712 7.59 7.59 0 0 1 4.76 1.595 7.27 7.27 0 0 1 2.393 3.316Z"
      stroke="#666"
      strokeWidth={0.5}
    />
  </svg>
)

export default SvgComponent
